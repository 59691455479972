import React from 'react';
import { Box } from '@material-ui/core';
import { useLayoutStyles, CONTENT_MIN_WIDTH } from './Globals';

const MIN_LOGIN_WIDTH_PX = CONTENT_MIN_WIDTH;
const MAX_LOGIN_WIDTH_PX = 377;

export function LoginLayout({ children }) {
    const styles = useLayoutStyles();
    return (
        <Box height="100vh" display="flex" justifyContent="center" alignItems="center">
            <Box flexGrow={1} minWidth={MIN_LOGIN_WIDTH_PX} maxWidth={MAX_LOGIN_WIDTH_PX} className={styles.contentPadding}>
                {children}
            </Box>
        </Box>
    );
}
