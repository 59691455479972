import produce from 'immer';
import * as actions from '../actions/actionTypes';
import { LoadingStatus, LoadingResult } from '../utils/LoadingUtils';

const dataLoading = (
    state = {
        visit: {
            loading: LoadingStatus.NONE,
            loadingResult: LoadingResult.NONE,
        },
        labReqId: {
            loading: LoadingStatus.NONE,
            loadingResult: LoadingResult.NONE,
        },
        wellnessSurveyQuestionnaire: {
            loading: LoadingStatus.NONE,
            loadingResult: LoadingResult.NONE,
        },
        fhirPatientInfo: {
            loading: LoadingStatus.NONE,
            loadingResult: LoadingResult.NONE,
        },
        nedUserInfo: {
            loading: LoadingStatus.NONE,
            loadingResult: LoadingResult.NONE,
        },
        observations: {
            loading: LoadingStatus.NONE,
            loadingResult: LoadingResult.NONE,
        },
    },
    action
) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.DATA_VISIT_LOADING:
                draft.visit.loading = action.visitLoading;
                break;
            case actions.DATA_VISIT_LOADING_RESULT:
                draft.visit.loadingResult = action.loadingResult;
                break;
            case actions.DATA_LAB_REQ_ID_LOADING:
                draft.labReqId.loading = action.labReqIdLoading;
                break;
            case actions.DATA_LAB_REQ_ID_LOADING_RESULT:
                draft.labReqId.loadingResult = action.loadingResult;
                break;
            case actions.DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING:
                draft.wellnessSurveyQuestionnaire.loading = action.questionnaireLoading;
                break;
            case actions.DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING_RESULT:
                draft.wellnessSurveyQuestionnaire.loadingResult = action.loadingResult;
                break;
            case actions.DATA_FHIR_PATIENT_INFO_LOADING:
                draft.fhirPatientInfo.loading = action.fhirPatientInfoLoading;
                break;
            case actions.DATA_FHIR_PATIENT_INFO_LOADING_RESULT:
                draft.fhirPatientInfo.loadingResult = action.loadingResult;
                break;
            case actions.DATA_NED_USER_INFO_LOADING:
                draft.nedUserInfo.loading = action.nedUserInfoLoading;
                break;
            case actions.DATA_NED_USER_INFO_LOADING_RESULT:
                draft.nedUserInfo.loadingResult = action.loadingResult;
                break;
            case actions.DATA_OBSERVATIONS_LOADING:
                draft.observations.loading = action.observationsLoading;
                break;
            case actions.DATA_OBSERVATIONS_LOADING_RESULT:
                draft.observations.loadingResult = action.loadingResult;
                break;
            default:
                break;
        }
    });

export default dataLoading;
