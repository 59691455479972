/* This file contains sagas related to login and authentication */

import { call, put, putResolve } from 'redux-saga/effects';
import * as AuthenticationAPI from '../apis/authentication';
import { clearStore, loginNetworkError, loginFailure, loginSuccess, logoutPatientError, logoutPatient, loginProcessData, loginResetState } from '../actions';
import { clearToken, setToken, clearDeviceToken } from '../apis/api';
import { clearClientSession, persistLogin } from '../utils/ClientSessionHandler';

export function* loginSaga(action) {
    yield put(loginResetState());
    try {
        const response = yield call(AuthenticationAPI.login, action.username, action.password);
        yield put(loginProcessData(response.data));
    } catch (error) {
        if (!!error.response) {
            if (error.response.status === 401) {
                // This is an authentication/unauthorized error.
                yield put(loginFailure(error.response.data));
            } else {
                // TODO: need to handle other non-2xx errors
                console.log(error);
            }
        } else {
            // Either the request was made but no response was received, or
            // something happened in setting up the request that triggered an Error (most likely network error)
            yield put(loginNetworkError());
        }
    }
}

export function* loginProcessDataSaga(action) {
    const data = action.data;
    const token = data.token;
    try {
        yield call(setToken, token);
        yield put(loginSuccess(data));
        yield call(persistLogin, { hasAuthenticated: true, isNetworkError: false, session: data });
    } catch (error) {
        console.log(error);
    }
}

export function* logoutSaga() {
    try {
        yield call(AuthenticationAPI.logout);
        yield call(clearToken);
        yield call(clearDeviceToken);
        yield call(clearClientSession);
        yield putResolve(clearStore());
    } catch (error) {
        yield put(logoutPatientError(error.response));
    }
}

export function* loginRequestPasswordResetSaga(action) {
    try {
        yield call(AuthenticationAPI.requestPasswordReset, action.email);
        yield call(action.setRequestSubmissionStatus, { type: 'success' });
    } catch (error) {
        if (!error.response) {
            // network error
            yield call(action.setRequestSubmissionStatus, { type: 'failure' });
        } else {
            // For privacy reasons we don't want to inform the patient on the UI that we couldn't find their email,
            // we will just fail silently.
            yield call(action.setRequestSubmissionStatus, { type: 'success' });
            console.log(error);
        }
    }
}

export function* loginRetrieveSessionSaga() {
    try {
        const response = yield call(AuthenticationAPI.retrieveLoginSession);
        yield put(loginProcessData(response.data));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // login session is expired
            yield put(logoutPatient());
        }
        console.log(error);
    }
}
