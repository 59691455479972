/* Renders the Doctor's note */

import React, { useState } from 'react';
import { useCurrentVisit, useCurrentVisitAPI, CurrentVisitUser } from '../../userDataProviders/CurrentVisitProvider';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import { useHistory } from 'react-router-dom';
import { PATH_HOME } from '../../containers/RoutePaths';
import { SingleNoteComponent } from '../noteHistory/NotePage';
import { noteFromOutcome } from '../noteHistory/NoteUtils';
import AlertModalYesNo from '../utilities/AlertModal/AlertModalYesNo';
import { VisitAssessments } from './TaskHome';

function DoctorNoteContent() {
    const currentVisit = useCurrentVisit();
    const { currentVisitClose } = useCurrentVisitAPI();

    const outcome = currentVisit.visit && currentVisit.visit.assessment;
    const visitAssessment = outcome && outcome.visitAssessmentResult;
    const requiresPrompt = visitAssessment === VisitAssessments.NO_CONCERNS || visitAssessment === VisitAssessments.IN_PERSON;
    let followUpModalTitle, followUpModalBody, currentVisitCloseHandler;

    if (visitAssessment === VisitAssessments.NO_CONCERNS) {
        followUpModalTitle = 'Follow-up Complete';
        followUpModalBody = 'Great job, your follow-up is now complete!';
        currentVisitCloseHandler = () => currentVisitClose(true, false);
    } else if (visitAssessment === VisitAssessments.IN_PERSON) {
        followUpModalTitle = 'Virtual Follow-up Paused';
        followUpModalBody =
            'Your routine follow-ups in Ned Virtual Clinic have been paused because your doctor would like to see you for an in-person follow-up. Please check your email for appointment details.';
        currentVisitCloseHandler = () => currentVisitClose(true, true);
    }

    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <CurrentVisitUser>
            <SingleNoteComponent
                note={outcome && noteFromOutcome(outcome)}
                parentUrl={PATH_HOME}
                onClick={requiresPrompt ? handleClickOpenDialog : undefined}
            />
            {requiresPrompt ? (
                <FollowUpCompleteDialog
                    open={openDialog}
                    handleClose={handleCloseDialog}
                    currentVisitClose={currentVisitCloseHandler}
                    followUpModalTitle={followUpModalTitle}
                    followUpModalBody={followUpModalBody}
                />
            ) : null}
        </CurrentVisitUser>
    );
}

export default function DoctorNote() {
    return (
        <RequiresLogin>
            <DoctorNoteContent />
        </RequiresLogin>
    );
}

function FollowUpCompleteDialog({ open, handleClose, currentVisitClose, followUpModalTitle, followUpModalBody }) {
    const history = useHistory();

    const onButtonClick = () => {
        handleClose();
        currentVisitClose(); // close current visit of user
        history.push(PATH_HOME); // redirect to home page
    };

    return (
        <AlertModalYesNo
            isDisplayed={open}
            onCloseSelected={handleClose}
            alertModalTitle={followUpModalTitle}
            alertModalBody={followUpModalBody}
            onYesSelected={onButtonClick}
            yesButtonText="Ok"
        />
    );
}
