import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AdaptiveNavBar from './utilities/NavBar/AdaptiveNavBar';
import { RequiresLogin } from './utilities/commonUtilities/RequiresLogin';

const useStyles = makeStyles(theme => ({
    loadingPage: {
        height: '100vh',
        position: 'fixed',
        width: '100%',
        top: 0,
    },
}));

export default function IsLoadingPage({ loginNotRequired, noNavBar }) {
    const classes = useStyles();

    const pagecontent = (
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.loadingPage}>
            <CircularProgress />
        </Box>
    );

    if (loginNotRequired) {
        return (
            <>
                {!noNavBar && <AdaptiveNavBar mobilePresent />}
                {pagecontent}
            </>
        );
    } else {
        return (
            <RequiresLogin>
                {!noNavBar && <AdaptiveNavBar mobilePresent />}
                {pagecontent}
            </RequiresLogin>
        );
    }
}
