import { call, takeEvery } from 'redux-saga/effects';

/**
 * Internal identifier for the dispatch saga action.
 */
const DISPATCH_ACTION_SOURCE = 'DISPATCH_SAGA_SOURCE';

/**
 * Internal helper for unpacking and invoking a saga from an action.
 * @param {*} action An action created from actionSaga(...)
 */
export function* sagaFromAction(action) {
    const { saga, params } = action;
    yield call(saga, ...params);
}

/**
 * Watches for dispatch saga actions and invokes their enclosed sagas.
 * Register this saga with the redux-saga middleware using createSagaMiddleware(...).
 * Alternatively, use createDispatchSagaMiddleware(...) to create a standalone middleware for use with applyMiddleware(...)
 *
 * @see createSagaMiddleware
 * @see createDispatchSagaMiddleware
 */
export function* watchDispatchSaga() {
    yield takeEvery(matchSagaAction, sagaFromAction);
}

/**
 * Returns true if the provided action is sourced from the dispatch saga mechanism.
 */
export function matchSagaAction(action) {
    return !!(action.saga && action.dispatchSource === DISPATCH_ACTION_SOURCE);
}

/**
 * Action creator for dispatching a redux-saga saga with an dispatch saga augmented redux-saga middleware.
 * This action can be dispatched wherever a regular redux action can be dispatched.
 *
 * e.g.
 * function *mySaga(arg1, arg2) {
 *    ...
 * }
 * dispatch(actionSaga(mySaga, 16, {other: param}))
 *
 * @param type Action type.
 * @param {*} saga A generator function that implements the saga to dispatch.
 * @param  {...any} params Optional starting parameters to pass to the saga upon being invoked.
 *
 */
export function actionSaga(type, saga, ...params) {
    return {
        type,
        saga,
        params,
        dispatchSource: DISPATCH_ACTION_SOURCE,
    };
}
