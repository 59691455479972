/*Handles state transitions associated with wellness survey actions dispatched */

import produce from 'immer';
import * as actions from '../actions/actionTypes';

const wellnessSurvey = (state = initWellnessSurveyStore(), action) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.WELLNESS_BUILD_QUESTIONNAIRE:
                draft.questions = action.questions;
                draft.currQuestionIndex = 0;
                draft.questionnaireRetrieved = true;
                draft.displayWellnessSurveyAlert = false;
                break;
            case actions.WELLNESS_UPDATE_CURRENT_QUESTION:
                draft.currQuestionIndex = action.index;
                break;
            case actions.WELLNESS_UPDATE_ACTIVE_ANSWER_LINK_ID:
                draft.questions[draft.currQuestionIndex].activeAnswerLinkId = action.linkId;
                break;
            case actions.WELLNESS_DISCARD_ANSWERS:
                for (const question of draft.questions) {
                    question.activeAnswerLinkId = null;
                }
                draft.currQuestionIndex = 0;
                break;
            case actions.WELLNESS_TOGGLE_ALERT:
                draft.displayWellnessSurveyAlert = !draft.displayWellnessSurveyAlert;
                break;
            case actions.WELLNESS_DISCARD_ENTIRE_QUESTIONNAIRE:
                draft.questions = [
                    {
                        // question object
                        linkId: '',
                        title: '',
                        text: '',
                        prev: null,
                        next: null,
                        answers: [
                            {
                                // answer object
                                linkId: '',
                                text: '',
                            },
                        ],
                        activeAnswerLinkId: null,
                    },
                ];
                draft.currQuestionIndex = 0;
                draft.questionnaireRetrieved = false;
                draft.displayWellnessSurveyAlert = false;
                break;
            case actions.WELLNESS_SET_IS_SURVEY_INITIATED:
                draft.isSurveyInitiated = action.isSurveyInitiated;
                break;
            default:
        }
    });

export const initWellnessSurveyStore = () => {
    return {
        questions: [
            {
                // question object
                linkId: '',
                title: '',
                text: '',
                prev: null,
                next: null,
                answers: [
                    {
                        // answer object
                        linkId: '',
                        text: '',
                    },
                ],
                activeAnswerLinkId: null,
            },
        ],
        currQuestionIndex: 0,
        questionnaireRetrieved: false,
        displayWellnessSurveyAlert: false,
        isSurveyInitiated: false,
    };
};

export default wellnessSurvey;
