/**
 * The My Profile landing page for changing passwords, help, etc.
 */
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import MainLayout from '../utilities/layouts/MainLayout';
import { putPatienFhirInfoIntoStore, loadFhirPatientInfo, setFhirPatientInfoLoadingResult, retrievePatientInfoFromFhir } from '../../actions';
import { getPatientResource } from '../../apis/patient';
import { makeStyles } from '@material-ui/core';
import { PrimaryButton } from '../utilities/commonUtilities/GenericButtonElements';
import { actionLogout } from '../../sagas/logoutSaga';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { COLOR_WHITE } from '../utilities/Material';
import { styleBreakMobile, styleBreakSmallMobile, styleBreakDesktop } from '../utilities/layouts/Globals';
import { ReactComponent as ChervonRight } from '../../resources/images/chervon-right.svg';
import { PATH_PROFILE_ACCOUNT_DETAILS, PATH_PROFILE_PASSWORD, PATH_PROFILE_SECURITY_QUESTIONS } from '../../containers/RoutePaths';
import clsx from 'clsx';
import { LoadingStatus, LoadingResult } from '../../utils/LoadingUtils';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../actions/actionTypes';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';
import { version } from '../../../package.json';

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(4),
        width: '100%',
        [styleBreakDesktop(theme)]: {
            width: '280px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    versionText: {
        textAlign: 'center',
        fontSize: '10px',
        lineHeight: '14px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    initialsEllipse: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        background: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    initialsText: {
        color: COLOR_WHITE,
        margin: theme.spacing(2),
    },
    nameText: {
        marginTop: theme.spacing(2),
    },
    initialAndNameContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
        marginBottom: theme.spacing(5),
        [styleBreakMobile(theme)]: {
            marginBottom: theme.spacing(4),
            marginLeft: theme.spacing(-3),
        },
        [styleBreakSmallMobile(theme)]: {
            marginLeft: theme.spacing(-2),
        },
    },
    fieldLine: {
        border: '1px solid #EFEFEF',
        width: '100%',
        margin: theme.spacing(0),
    },
    fieldDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    fieldName: {
        lineHeight: '22px',
        letterSpacing: '0.24px',
    },
    fieldRowsContainer: {
        background: COLOR_WHITE,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: theme.spacing(4),
        [styleBreakMobile(theme)]: {
            marginLeft: theme.spacing(-3),
            marginRight: theme.spacing(-3),
        },
        [styleBreakSmallMobile(theme)]: {
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
        },
    },
    profilePage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        [styleBreakMobile(theme)]: {
            justifyContent: 'space-between',
            height: '90vh',
        },
    },
    profilePageFooter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    noBottomMargin: {
        marginBottom: theme.spacing(0),
    },
    chevron: {
        display: 'block',
    },
}));

/* URL CONSTANTS */
const ABOUT_NED_URL = 'https://nedclinic.ca/about';
const PRIVACY_POLICY_URL = 'https://nedclinic.ca/privacy-policy';
const TERMS_OF_SERVICE_URL = 'https://nedclinic.ca/terms-conditions';
const HELP_URL = 'https://nedclinic.ca/help';

/* components */

const ProfileCard = ({ patientName }) => {
    const classes = useStyles();

    const firstName = patientName ? patientName.firstName : '';
    const lastName = patientName ? patientName.familyName : '';

    return (
        <Box className={classes.initialAndNameContainer}>
            <Box className={classes.initialsEllipse}>
                <Typography variant="h1" className={classes.initialsText}>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</Typography>
            </Box>
            <Typography variant="h2" className={classes.nameText}>{`${firstName} ${lastName}`}</Typography>
        </Box>
    );
};

const AccountManagementCard = ({ fields, noBottomMargin }) => {
    const classes = useStyles();

    if (!fields || fields.length === 0) {
        return null;
    }
    let numberOfFieldLines = fields.length;
    const ManagementField = ({ field }) => {
        return (
            <Box onClick={field.onClickHandler} className={classes.fieldDiv}>
                <Typography variant="body2" className={classes.fieldName}>
                    {field.name}
                </Typography>
                <Box className={classes.chevron}>
                    <ChervonRight aria-labelledby="View" />
                </Box>
            </Box>
        );
    };

    const fieldRows = fields.map((item, indx) => {
        numberOfFieldLines = numberOfFieldLines - 1;
        return (
            <Fragment key={indx}>
                <ManagementField field={item} />
                {numberOfFieldLines > 0 && <hr className={classes.fieldLine} />}
            </Fragment>
        );
    });

    return (
        <Box
            className={clsx(classes.fieldRowsContainer, {
                [classes.noBottomMargin]: noBottomMargin,
            })}
        >
            {fieldRows}
        </Box>
    );
};

const ProfilePageContent = ({ patientName }) => {
    const history = useHistory();

    const topHalfFields = [
        {
            name: 'Account Details',
            onClickHandler: () => {
                history.push(PATH_PROFILE_ACCOUNT_DETAILS);
            },
        },
        {
            name: 'Change Password',
            onClickHandler: () => {
                history.push(PATH_PROFILE_PASSWORD);
            },
        },
        {
            name: 'Change Security Questions',
            onClickHandler: () => {
                history.push(PATH_PROFILE_SECURITY_QUESTIONS);
            },
        },
    ];

    function openExtLink(extLink) {
        window.open(extLink, '_blank');
    }

    const bottomHalfFields = [
        {
            name: 'About Ned',
            onClickHandler: () => {
                openExtLink(ABOUT_NED_URL);
            },
        },
        {
            name: 'Privacy Policy',
            onClickHandler: () => {
                openExtLink(PRIVACY_POLICY_URL);
            },
        },
        {
            name: 'Terms of Service',
            onClickHandler: () => {
                openExtLink(TERMS_OF_SERVICE_URL);
            },
        },
        {
            name: 'Help',
            onClickHandler: () => {
                openExtLink(HELP_URL);
            },
        },
    ];

    return (
        <Box>
            <ProfileCard patientName={patientName} />
            <AccountManagementCard fields={topHalfFields} />
            <AccountManagementCard fields={bottomHalfFields} noBottomMargin />
        </Box>
    );
};

const ProfilePage = ({ fhirpatientInfo, patientId, retrievePatientInfo, onLogout, isLoadingFhirPatientInfo, fhirPatientInfoLoadingResult, token }) => {
    const classes = useStyles();
    useEffect(() => {
        if (token && !fhirpatientInfo) {
            retrievePatientInfo(patientId);
        }
    }, [patientId, retrievePatientInfo, fhirpatientInfo, token]);

    if (isLoadingFhirPatientInfo) {
        return <IsLoadingPage />;
    } else {
        if (fhirPatientInfoLoadingResult === LoadingResult.FAILED) {
            return <LoadingErrorPage />;
        } else {
            const patientName = fhirpatientInfo.name;
            return (
                <MainLayout verticalOverlay={true}>
                    <Box className={classes.profilePage}>
                        <ProfilePageContent patientName={patientName} />
                        <form onSubmit={onLogout}>
                            <Box className={classes.profilePageFooter}>
                                <PrimaryButton className={classes.button} type="submit">
                                    Sign Out
                                </PrimaryButton>
                                <Typography variant="body1" className={classes.versionText}>
                                    {`Version ${version}`}
                                </Typography>
                            </Box>
                        </form>
                    </Box>
                </MainLayout>
            );
        }
    }
};

/* connect to redux store */

const mapStateToProps = ({ login, patientAccountDetails, dataLoading }) => ({
    patientId: login?.session?.patientId,
    token: login?.session?.token,
    fhirpatientInfo: patientAccountDetails?.fhirPatientInfo,
    isLoadingFhirPatientInfo: dataLoading?.fhirPatientInfo?.loading !== LoadingStatus.ENDED,
    fhirPatientInfoLoadingResult: dataLoading?.fhirPatientInfo?.loadingResult,
});

const mapDispatchToProps = dispatch => {
    const retrievePatientInfo = patientId => {
        dispatch(retrievePatientInfoFromFhir(patientId));
    };

    return {
        retrievePatientInfo,
        onLogout: e => {
            e.preventDefault();
            dispatch(actionLogout());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

/* api layer */

export function* retrievePatientFhirInfoSaga(action) {
    yield put(loadFhirPatientInfo(LoadingStatus.STARTED));
    try {
        const response = yield call(getPatientResource, action.patientId);
        yield put(putPatienFhirInfoIntoStore(response.data.entry[0].resource));
        yield put(setFhirPatientInfoLoadingResult(LoadingResult.SUCCESSFUL));
    } catch (error) {
        yield put(setFhirPatientInfoLoadingResult(LoadingResult.FAILED));
        console.log(error);
    }
    yield put(loadFhirPatientInfo(LoadingStatus.ENDED));
}

export function* watchFhirPatientInfoRetrieve() {
    yield takeLatest(actions.PATIENT_FHIR_INFO_RETRIEVE, retrievePatientFhirInfoSaga);
}
