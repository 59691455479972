import axios from 'axios';

const pathRoot = '/api/reset';

export const retrieveSecurityQuestions = token => {
    return axios.get(pathRoot + `/challenge`, {
        params: {
            token,
        },
    });
};

export const submitAnswersToSecurityQuestions = securityQuestionsAndAnswersAndToken => {
    return axios.post(pathRoot + `/challenge`, securityQuestionsAndAnswersAndToken);
};

export const submitNewPassword = newPasswordAndOneTimeToken => {
    return axios.post(pathRoot + `/resetPassword`, newPasswordAndOneTimeToken);
};
