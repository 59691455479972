/* This file contains sagas related to Account Setup */

import { put, call, select } from 'redux-saga/effects';
import * as AccountSetupAPI from '../apis/accountSetup';
import {
    setSetupAccountVerificationParameters,
    setSetupAccountVerificationResponse,
    accountSetupCreationCompleteSuccess,
    accountSetupCreationCompleteError,
} from '../actions';
import { getPasswordAndSecurityQuestions, getAccountSetupVerificationParameters } from '../selectors';
import { setToken, setDeviceToken } from '../apis/api';
import { persistLogin, persistDeviceToken } from '../utils/ClientSessionHandler';

export function* AccountSetupSubmitSaga(action) {
    try {
        const passwordAndSecurityQuestions = yield call(getPasswordAndSecurityQuestions, action.securityQuestionsAndPassword);
        yield call(
            AccountSetupAPI.postAccountSetup,
            passwordAndSecurityQuestions.password,
            passwordAndSecurityQuestions.question1,
            passwordAndSecurityQuestions.answer1,
            passwordAndSecurityQuestions.question2,
            passwordAndSecurityQuestions.answer2,
            passwordAndSecurityQuestions.question3,
            passwordAndSecurityQuestions.answer3
        );
        yield put(accountSetupCreationCompleteSuccess());

        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(true));
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorResponse = error.response.data;
            errorResponse.status = error.response.status;

            if (error.response.status === 401 || error.response.status === 404 || error.response.status === 403) {
                console.log('account setup submission error: server did not like the params we submitted');
            } else {
                console.log('account setup submission error: ' + error.response.status);
            }
            yield put(accountSetupCreationCompleteError(errorResponse));
            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(true));
            }
        } else {
            // Either the request was made but no response was received, or
            // something happened in setting up the request that triggered an Error
            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(false));
            }
        }
    }
}

export function* AccountSetupVerifySaga(action) {
    try {
        yield put(setSetupAccountVerificationParameters(action.token));
        const accountSetupVerificationParameters = yield select(getAccountSetupVerificationParameters);
        accountSetupVerificationParameters.healthCardNumber = action.healthCardNumber;
        accountSetupVerificationParameters.healthCardSystem = action.healthCardSystem;
        accountSetupVerificationParameters.dateOfBirth = action.dateOfBirth;
        // accountSetupVerificationParameters contains: accountSetupToken, agreementVersion, ohip, dateOfBirth
        const response = yield call(AccountSetupAPI.verifyNedPatient, accountSetupVerificationParameters);
        yield call(saveSessionTokenAndDeviceToken, response.data);
        yield put(setSetupAccountVerificationResponse(response.data));

        // Set our token.
        yield call(setToken, response.data.token);

        // Set our device token
        yield call(setDeviceToken, response.data.extraFields.DEVICE_TOKEN);

        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(true));
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            let errorResponse = error.response.data ?? {};
            errorResponse.status = error.response.status;

            if (error.response.status === 401 || error.response.status === 404 || error.response.status === 403) {
                console.log('account verify submission error: server did not like the params we submitted');
            } else {
                console.log('account verify submission error: ' + error.response.status);
            }
            yield put(setSetupAccountVerificationResponse(errorResponse));
            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(true));
            }
        } else {
            // Either the request was made but no response was received, or
            // something happened in setting up the request that triggered an Error
            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(false));
            }
        }
    }
}

function saveSessionTokenAndDeviceToken(data) {
    const session = {
        controlUserForStudy: data.isControlUser,
        patientId: data.patientId,
        diseaseType: data.diseaseType,
        token: data.token,
        userId: data.userId,
    };

    // Create a login session so that user does not have to login upon account setup completion
    persistLogin({ hasAuthenticated: true, isNetworkError: false, session });

    // Persist device token inorder to skip 1+ authentication upon account setup completion
    persistDeviceToken(data.extraFields.DEVICE_TOKEN);
}
