/* This file contains generic divs*/
import styled, { css } from 'styled-components';

export const MedBody1 = styled.div`
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #000000;
`;

export const MedBody3 = styled(MedBody1)`
    font-weight: 600;
    line-height: 150%;
    color: #212121;
`;

export const MedBody3A = styled(MedBody3)`
    color: #a1a1a1;
`;

export const MedBody2 = styled(MedBody3)`
    font-weight: 500;
    text-align: right;
    color: #000000;
`;

export const PointerMedBody2 = styled(MedBody2)`
    cursor: pointer;
    user-select: none;

    ${props =>
        props.disabled &&
        css`
            cursor: auto;
        `}
`;

export const RelativeDiv = styled.div`
    position: relative;
`;

export const PointerDiv = styled.div`
    cursor: pointer;
`;

export const ErrorMessageDiv = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.16px;
    color: #ff0000;
    display: none;

    ${props => {
        if (props.show) {
            return css`
                display: block;
            `;
        } else {
            return css`
                display: none;
            `;
        }
    }}
`;

export const MedBody1A = styled(MedBody1)`
    width: 100%;
    height: 60px;
    margin-top: 8px;

    ${props =>
        props.Title &&
        css`
            height: 25px;
            font-size: 18px;
            font-weight: 600;
            margin-top: 10px;
        `}
`;

export const MedBody1B = styled(MedBody1)`
    color: #a1a1a1;
`;

export const MedBody1C = styled(MedBody1)`
    color: #1a1a1a;
`;

export const MedBody1D = styled(MedBody1)`
    color: #212121;
    margin-bottom: 20px;
`;

export const MedBody1E = styled(MedBody1D)`
    margin-bottom: 20px;
`;

export const MedBody1F = styled(MedBody1D)`
    font-weight: 500;
    margin-bottom: 0px;
`;

export const LargeBody1 = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: rgba(26, 26, 26, 0.9);
`;

export const MedBody2A = styled(MedBody2)`
    margin-top: 20px;
    text-align: left;
    color: rgba(26, 26, 26, 0.9);
`;

export const SmallBody1 = styled.div`
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.16px;
    color: #626262;
`;

export const SmallBody1Black = styled(SmallBody1)`
    color: #000000;
`;

export const SmallBody1A = styled(SmallBody1)`
    background: #e9e9e9;
    border-radius: 3px;
    padding-top: 6px;
    padding-bottom: 5px;
    padding-left: 9px;
    padding-right: 8px;
`;

export const SmallBody1B = styled(SmallBody1)`
    font-weight: 600;
    color: #212121;
`;

export const SmallBody1C = styled(SmallBody1B)`
    font-weight: 500;
    margin-bottom: 15px;
`;

export const SmallBody1D = styled(SmallBody1)`
    color: #212121;
`;

export const SmallBody2 = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.16px;
    color: #000000;
`;

export const PdfDiv = styled.div`
    background: #ffffff;
    border: 0.5px solid #979797;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
`;

export const FullWidthDiv = styled.div`
    width: 100%;
`;

export const DialogBodyMargin = styled.div`
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #000000;
    padding-top: 20px;
`;

export const PageTitleBlock = styled.div`
    margin-bottom: 16px;
`;
