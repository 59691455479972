import React, { useRef, useState } from 'react';
import * as PageNames from './WizardPageNames';
import { PasswordSetup } from '../PasswordSetup';
import { useStyles } from '../setupAccountWizard/SetupAccountStep4Password';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import StepsLayout from '../utilities/layouts/StepsLayout';

export default function ResetYourPassword({ currentPage, setCurrentPage, oneTimeResetToken, submitNewPassword }) {
    const FAILED_SUBMIT_PASSWORD_MESSAGE = 'Could not submit the password.';
    const classes = useStyles();
    const formikBagRef = useRef();
    const [submitDisable, setSubmitDisable] = useState(true);
    const [password, setPassword] = useState('');
    const passwordSubtitle = 'Please make sure your new password:';
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmitPasswordFail = response => {
        if (response?.message) {
            console.log(response?.message);
        }
        // Show an error.
        setErrorMessage(FAILED_SUBMIT_PASSWORD_MESSAGE);
    };

    const onSubmitPasswordSuccess = () => {
        setCurrentPage(PageNames.RESET_PASSWORD_COMPLETE_PAGE);
    };

    if (currentPage !== PageNames.RESET_YOUR_PASSWORD_PAGE) {
        return null;
    }

    const processPassword = password => {
        setPassword(password);
        submitNewPassword({ password, token: oneTimeResetToken }, onSubmitPasswordSuccess, onSubmitPasswordFail);
    };
    const defaultOnNextClick = () => processPassword(password);

    return (
        <StepsLayout
            currentCount={2}
            totalCount={2}
            titleText={'Reset Password'}
            nextButtonText={'Next'}
            isNextButtonDisabled={submitDisable}
            id={'Next-button-of-reset-password'}
            onNextClick={formikBagRef.current?.submitForm || defaultOnNextClick}
            alwaysShowNedLogoInDesktop
        >
            <Paper elevation={0} className={classes.container}>
                <Box className={classes.headingText}>
                    <Typography variant="h2">Reset Password</Typography>
                </Box>
                <PasswordSetup
                    password={password}
                    processPassword={processPassword}
                    formikBagRef={formikBagRef}
                    setSubmitDisable={setSubmitDisable}
                    passwordSubtitle={passwordSubtitle}
                />
                {errorMessage && (
                    <Typography component="div" variant="caption" color="error" className={classes.errorMessage}>
                        {errorMessage}
                    </Typography>
                )}
            </Paper>
        </StepsLayout>
    );
}
