import React from 'react';
import { Typography, Box, Grid, CardActionArea, makeStyles } from '@material-ui/core';
import { ReactComponent as ChervonRight } from '../../../resources/images/chervon-right.svg';
import { styleBreakMobile, styleBreakDesktop } from '../layouts/Globals';
import { COLOR_WHITE } from '../Material';
import { PrimaryButton } from './GenericButtonElements';
import { PATH_HOME } from '../../../containers/RoutePaths';

const CARD_BOX_SHADOW = '0px 2px 2px rgba(116, 123, 132, 0.25)';
const CARD_RADIUS = 6;

const useCardStyles = makeStyles(theme => ({
    // For small card contents, only horizontal paddings change
    // Not actually same as breakpoint padding. Shrinks to 16 in mobile, 24 in desktop.
    smallContentPadding: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [styleBreakDesktop(theme)]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    // For large card contents, all paddings change.
    largeContentPadding: {
        padding: theme.spacing(3), // 3 x 8px = 24px
        [styleBreakMobile(theme)]: {
            padding: theme.spacing(2), // 2 x 8px = 16px
        },
    },
    startMargin: {
        marginRight: theme.spacing(2),
        [styleBreakMobile(theme)]: {
            marginRight: theme.spacing(1.5), // 12px
        },
    },
    endMargin: {
        marginLeft: theme.spacing(2),
        [styleBreakMobile(theme)]: {
            marginLeft: theme.spacing(1.5), // 12px
        },
    },
    homeButton: {
        [styleBreakDesktop(theme)]: {
            width: '280px',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        [styleBreakMobile(theme)]: {
            position: 'absolute',
            bottom: theme.spacing(3),
            left: theme.spacing(3),
            right: theme.spacing(3),
        },
    },
    emptyItemBox: {
        paddingTop: theme.spacing(8),
    },
    emptyItemContentTitle: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 * Box that contains card contents with standard card padding.
 * Can be variant "large" or "small", determining the padding behavior.
 */
function CardContentBox({ children, variant, ...props }) {
    const styles = useCardStyles();
    return (
        <Box minHeight={80} className={variant === 'large' ? styles.largeContentPadding : styles.smallContentPadding} {...props}>
            {children}
        </Box>
    );
}

function CustomCard({ children, ...props }) {
    return (
        <Box boxShadow={CARD_BOX_SHADOW} borderRadius={CARD_RADIUS} overflow="hidden" bgcolor={COLOR_WHITE} {...props}>
            {children}
        </Box>
    );
}

/**
 * Just a card with content padding. Provide the children.
 * Can be variant "large" or "small". Default large.
 */
export function CardContainer({ children, variant = 'large', ...props }) {
    return (
        <CustomCard {...props}>
            <CardContentBox variant={variant}>{children}</CardContentBox>
        </CustomCard>
    );
}

/**
 * A clickable card item. Shows a Badge, title, and subtitle.
 *
 * @param Badge An image src to display as the card type.
 * @param title Main text content for the card.
 * @param subtitle Sub text content for the card.
 * @param onClick Function called when the card is clicked.
 * @param href Path to destination for when a card is clicked
 */
export function CardItem({ Badge, title, subtitle, onClick, actionText, href, datatestid, ...props }) {
    const styles = useCardStyles();
    return (
        <CustomCard {...props}>
            <CardActionArea onClick={onClick} href={href} datatestid={datatestid ?? ''}>
                <CardContentBox variant="small" display="flex">
                    <Grid container wrap="nowrap" alignItems="center">
                        {Badge && (
                            <Grid item className={styles.startMargin}>
                                <Badge aria-labelledby="Survey" />
                            </Grid>
                        )}
                        <Grid item xs zeroMinWidth>
                            <Typography variant="subtitle1">{title}</Typography>
                            <Typography>{subtitle}</Typography>
                        </Grid>
                        <Grid item className={styles.endMargin}>
                            {!!actionText ? <Typography variant="body2">{actionText}</Typography> : <ChervonRight aria-labelledby="View" />}
                        </Grid>
                    </Grid>
                </CardContentBox>
            </CardActionArea>
        </CustomCard>
    );
}

/**
 * A vertical list container of cards. Configures the spacing between cards.
 *
 * Usage:
 *
 * <CardList>
 *    <Child ... />
 *    <Child ... />
 *    <Child ... />
 *    ...
 * </CardList>
 *
 * @param empty Optional. Component to show if the CardList is empty.
 *              May be an EmptyItem component or any other component.
 */
export function CardList({ children = [], empty, ...props }) {
    /*
     * Woah, react passes in single children as not arrays. Coerce all children to arrays,
     * otherwise can't blind map them below.
     *
     * i.e. Support
     *
     * <CardList>
     *    <CardItem ... />
     * <CardList>
     */
    let items = Array.isArray(children) ? children : [children];
    if (items.length === 0 && empty) {
        items = [empty];
    }
    return (
        <Grid container spacing={1} direction="column" {...props}>
            {items.map((child, index) => {
                return (
                    <Grid item key={index}>
                        {child}
                    </Grid>
                );
            })}
        </Grid>
    );
}

/**
 * Item to show in an empty list.
 *
 * Usage:
 *
 * const empty = <EmptyList image={image} title={title} message={message} />
 * <CardList empty={empty}>
 * ...
 *
 * @param {*} image The splash image to display with this card.
 * @param {*} title  The title of the card.
 * @param {*} message The message body of the card.
 */
export function EmptyItem({ image, title, message, ...props }) {
    const classes = useCardStyles();
    return (
        <Box display="flex" justifyContent="center" {...props} className={classes.emptyItemBox}>
            <Box display="flex" flexDirection="column" maxWidth={320}>
                <EmptyItemContent Image={image} title={title} message={message} style={{ title: classes.emptyItemContentTitle }} />
                <PrimaryButton href={PATH_HOME} className={classes.homeButton}>
                    Back to Home
                </PrimaryButton>
            </Box>
        </Box>
    );
}

/**
 * Empty item contents to show in an empty list
 *
 * Usage:
 *
 * const empty = <EmptyItemContent image={image} title={title} message={message} style={style}/>
 * <CardList empty={empty}>
 *
 *
 * @param {*} image The splash image to display with this card.
 * @param {*} title  The title of the card.
 * @param {*} message The message body of the card.
 * @param {*} style The optional styling for each component.
 *            Title typography style expects "style.title"
 *            Message typography style expects "style.message"
 */
export function EmptyItemContent({ Image, title, message, style }) {
    return (
        <>
            <Box display="flex" justifyContent="center">
                <Image aria-labelledby="icon" />
            </Box>
            <Box mb={4} mt={4}>
                <Typography variant="h2" align="center" className={style?.title}>
                    {title}
                </Typography>
                <Typography align="center" className={style?.message}>
                    {message}
                </Typography>
            </Box>
        </>
    );
}
