/* Combine all sagas into a root saga for use in configuring the store */

import { all } from 'redux-saga/effects';
import {
    watchUserAuthentication,
    watchquestionnaireRetrieval,
    watchquestionnaireResponseSubmission,
    watchAccountSetupSubmission,
    watchAccountSetupVerifyPatient,
    watchCurrentVisitRetrieval,
    watchPasswordReset,
    watchOutcome,
    watchCurrentVisitClose,
    watchSendClientAnalytics,
} from './watchers';
import { watchDispatchSaga } from './dispatchSaga';
import { watchDataSubmissionResult } from '../components/DataSubmissionHandler';
import { watchProfilePasswordChange } from '../components/profile/ChangePasswordPage';
import { watchProfileSecurityQuestionsChange } from '../components/profile/ChangeSecurityQuestionsPage';
import { watchFhirPatientInfoRetrieve } from '../components/profile/ProfilePage';
import { watchNedUserInfoRetrieve } from '../components/profile/ProfileAccountDetailsPage';
import { watchSetLabWorkTaskToIncomplete } from '../components/home/TaskHome';
import { watchSetLabWorkTaskComplete } from '../components/home/LabRequisitionHome';

export default function* rootSaga() {
    yield all([
        watchUserAuthentication(),
        watchquestionnaireRetrieval(),
        watchquestionnaireResponseSubmission(),
        watchAccountSetupSubmission(),
        watchAccountSetupVerifyPatient(),
        watchCurrentVisitRetrieval(),
        watchPasswordReset(),
        watchDispatchSaga(),
        watchOutcome(),
        watchCurrentVisitClose(),
        watchDataSubmissionResult(),
        watchProfilePasswordChange(),
        watchProfileSecurityQuestionsChange(),
        watchFhirPatientInfoRetrieve(),
        watchNedUserInfoRetrieve(),
        watchSetLabWorkTaskToIncomplete(),
        watchSetLabWorkTaskComplete(),
        watchSendClientAnalytics(),
    ]);
}
