/* This field combines all reducers into a root reducer */

import { combineReducers } from 'redux';
import login from './loginReducer';
import wellnessSurvey from './wellnessSurveyReducer';
import accountSetup from './accountSetupReducer';
import currentVisit from './currentVisitReducer';
import passwordReset from './passwordResetReducer';
import observation from './ObservationReducer';
import currentOutcome from './CurrentOutcomeReducer';
import labReqId from './labReqIdReducer';
import patientAccountDetails from './patientReducer';
import completedSurveys from './questionnaireResponseReducer';
import outcomes from './outcomeReducer';
import { CLEAR_STORE } from '../actions/actionTypes';
import dataLoading from './dataLoadingReducer';

export function newStoreState() {
    return {};
}

export const rootReducer = (state, action) => {
    if (action.type === CLEAR_STORE) {
        state = newStoreState();
    }
    return appReducer(state, action);
};

const appReducer = combineReducers({
    accountSetup,
    login,
    wellnessSurvey,
    currentVisit,
    passwordReset,
    observation,
    currentOutcome,
    labReqId,
    patientAccountDetails,
    completedSurveys,
    outcomes,
    dataLoading,
});
