/* Handles state transitions associated with Account setup */

import produce from 'immer';
import * as actions from '../actions/actionTypes';

const accountSetup = (state = initAccountSetupStore(), action) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.ACCOUNT_SETUP_SET_DISPLAY_ALERT:
                draft.displayAccountSetupAlert = action.isDisplay;
                break;
            case actions.ACCOUNT_SETUP_DISCARD:
                draft.verificationResponse = {};
                draft.accountSetupToken = '';
                draft.agreementVersion = '';
                draft.displayAccountSetupAlert = false;
                draft.isAccountSetupSuccessful = false;
                draft.accountCreationCompleteResponse = {};
                draft.currentPage = 1;
                draft.patientVerificationInfo = {
                    healthCardSystem: '',
                    healthCardNumber: '',
                    dateOfBirth: '',
                };
                break;
            case actions.ACCOUNT_SETUP_SET_VERIFICATION_PARAMETERS:
                draft.accountSetupToken = action.token;
                break;
            case actions.ACCOUNT_SETUP_SET_CURRENT_PAGE:
                draft.currentPage = action.currentPage;
                break;
            case actions.ACCOUNT_SETUP_SET_AGREEMENT_VERSION:
                draft.agreementVersion = action.version;
                break;
            case actions.ACCOUNT_SETUP_SET_VERIFICATION_RESPONSE:
                draft.verificationResponse = action.response;
                break;
            case actions.ACCOUNT_SETUP_CREATION_COMPLETE_ERROR:
                draft.isAccountSetupSuccessful = false;
                draft.accountCreationCompleteResponse = action.response;
                break;
            case actions.ACCOUNT_SETUP_CREATION_COMPLETE_SUCCESS:
                draft.isAccountSetupSuccessful = true;
                break;
            case actions.ACCOUNT_SETUP_SET_PATIENT_VERIFICATION_INFO:
                draft.patientVerificationInfo = action.patientVerificationInfo;
                break;
            default:
                break;
        }
    });

export const initAccountSetupStore = () => {
    return {
        verificationResponse: {},
        accountSetupToken: '',
        agreementVersion: '',
        displayAccountSetupAlert: false,
        isAccountSetupSuccessful: false,
        accountCreationCompleteResponse: {},
        currentPage: 1,
        patientVerificationInfo: {
            healthCardSystem: '',
            healthCardNumber: '',
            dateOfBirth: '',
        },
    };
};

export default accountSetup;
