/* This file contains higher-order components/elements for page layout */
import React from 'react';
import styled, { css } from 'styled-components';
import { TitleDiv1, TitleDiv2 } from './Typography';
import { PointerDiv } from './GenericDivElements';
import cross from '../../../resources/images/cross.png';
import backArrow from '../../../resources/images/back_arrow.png';

export const PageHeader = ({ onPrevClick, cancelClickHandler, currentCount, totalCount, titleText, className }) => (
    <>
        <FlexRowDiv className={className}>
            {onPrevClick ? (
                <PointerDiv onClick={onPrevClick} data-testid="onPreviousDiv">
                    <img src={backArrow} alt="back arrow" />
                </PointerDiv>
            ) : (
                <div style={{ visibility: 'hidden' }} />
            )}
            <div>
                {titleText ? <TitleDiv2>{titleText}</TitleDiv2> : <div style={{ display: 'none' }} />}
                {currentCount && totalCount ? <TitleDiv1 count>{currentCount + '/' + totalCount}</TitleDiv1> : <div style={{ display: 'none' }} />}
            </div>
            {cancelClickHandler ? (
                <PointerDiv onClick={cancelClickHandler}>
                    <img src={cross} alt="cross" />
                </PointerDiv>
            ) : (
                <div style={{ visibility: 'hidden' }} />
            )}
        </FlexRowDiv>
    </>
);

export const AdaptivePageWrapper = ({ children }) => {
    return (
        <>
            <AdaptivePageWrapperA>{children}</AdaptivePageWrapperA>
            <AdaptivePageWrapperB>{children}</AdaptivePageWrapperB>
        </>
    );
};

export const AdaptivePageWrapperWithSpaceBtn = ({ children }) => {
    return (
        <>
            <AdaptivePageWrapperAWithSpaceBtn>{children}</AdaptivePageWrapperAWithSpaceBtn>
            <AdaptivePageWrapperBWithSpaceBtn>{children}</AdaptivePageWrapperBWithSpaceBtn>
        </>
    );
};

export const FlexRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
`;

export const FlexRowDiv = styled(FlexRowWrapper)`
    align-items: center;
    justify-content: space-between;
    height: auto;
`;

export const FlexRowTaskCardA = styled(FlexRowDiv)`
    background: #c4c4c4;
    border-radius: 5px;
    padding: 15px 20px;
    ${props =>
        props.taskDone &&
        css`
            opacity: 0.5;
        `}
`;

export const FlexRowTaskCardB = styled(FlexRowDiv)`
    background: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
    padding-left: 40px;
`;

export const FlexRowTaskCardC = styled(FlexRowTaskCardB)`
    padding: 15px 20px;
`;

export const FlexRowDivWithMarginA = styled(FlexRowDiv)`
    margin-top: 42px;
    justify-content: center;
`;

/* styled */
export const PageHeaderWithMarginA = styled(PageHeader)`
    margin-top: 14px;
`;

export const SpaceBtnColumnFlexWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const SpaceBtnColumnFlexWrapperWithMarginA = styled(SpaceBtnColumnFlexWrapper)`
    align-items: center;
    margin-top: 36px;
`;

export const PageWrapper = styled.div`
    max-width: 720px;
    margin: 0 auto;
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const RelativeWrapper = styled(PageWrapper)`
    position: relative;
    justify-content: flex-start;
`;

export const AdaptivePageWrapperA = styled(PageWrapper)`
    justify-content: flex-start;
    @media (min-width: 720px) {
        display: none;
    }
`;

export const AdaptivePageWrapperAWithSpaceBtn = styled(AdaptivePageWrapperA)`
    justify-content: space-between;
`;

export const AdaptivePageWrapperB = styled(PageWrapper)`
    margin: 0px;
    justify-content: flex-start;
    @media (max-width: 720px) {
        display: none;
    }
`;

export const AdaptivePageWrapperBWithSpaceBtn = styled(AdaptivePageWrapperB)`
    justify-content: space-between;
`;

export const Container = styled.div`
    max-width: 720px;
    flex: 1;
`;

export const ColumnCenteredFlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
`;
