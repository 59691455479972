/* Renders the task home */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NoVisitSubPage } from './TaskHomeNoVisit';
import { BetweenVisitSubPage, computeGreetingInstForBetweenVisit } from './TaskHomeBetween';
import PausedHomePage from './PausedHomePage';
import { withRouter, useHistory } from 'react-router-dom';
import {
    VisitState,
    taskCompleted,
    checkLabworkStatus,
    LabworkStatus,
    checkForLabWorkBusinessStatus,
    TASK_BUSINESS_STATUS_MANUAL_LAB,
    isTaskPresent,
} from '../../utils/VisitTaskHandler';
import * as TaskNames from '../home/TaskNames';
import MainLayout from '../utilities/layouts/MainLayout';
import { makeStyles, Typography, Box, Grid, Paper } from '@material-ui/core';
import { WaitingReviewTaskBoard } from './WaitingForReview';
import { CardItem, CardList } from '../utilities/commonUtilities/CardItem';
import { ReactComponent as RecordBadge } from '../../resources/images/record-badge.svg';
import { ReactComponent as BloodBadge } from '../../resources/images/blood-badge.svg';
import { ReactComponent as DoneBadge } from '../../resources/images/done-badge.svg';
import { daysLeftFromToday, formatDateMMMMDYYYY, isoMoment } from '../utilities/commonUtilities/dateTools';
import moment from 'moment';
import { styleBreakDesktop, styleBreakMobile, styleBreakSmallMobile } from '../utilities/layouts/Globals';
import { PATH_LAB_REQUISITION, PATH_WELLNESS_SURVEY_HOME, PATH_DOCTOR_NOTE } from '../../containers/RoutePaths';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import { useCurrentVisit, CurrentVisitUser } from '../../userDataProviders/CurrentVisitProvider';
import NextUp from '../utilities/commonUtilities/NextUpComponent';
import { ReactComponent as ClinicianIcon } from '../../resources/images/clinician.svg';
import { ReactComponent as CalendarIcon } from '../../resources/images/calendar.svg';
import AlertModalYesNo from '../utilities/AlertModal/AlertModalYesNo';
import { closeCurrentVisit, labWorkTaskInProgressMarking, retrieveCurrentVisit, sendClientAnalytics } from '../../actions';
import { connect } from 'react-redux';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';
import { LoadingStatus, LoadingResult } from '../../utils/LoadingUtils';
import { markLabWorkTaskAsInProgress } from '../../apis/currentVisit';
import DataSubmissionHandler from '../DataSubmissionHandler';
import * as actions from '../../actions/actionTypes';
import { call, takeLatest, put } from 'redux-saga/effects';
import { AuditEventAction, createClientAnalytics } from '../../utils/ClientAnalytics';

export const VisitAssessments = {
    VIDEO_REQUIRED: 'VIDEO_REQUIRED',
    PHONE_REQUIRED: 'PHONE_REQUIRED',
    IN_PERSON: 'IN_PERSON',
    NO_CONCERNS: 'NO_CONCERNS',
};

export const AccountStates = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
};

const useStyles = makeStyles(theme => ({
    // There must be a better way to do this.  The warning and the regular only differ by palette.
    timeUntilBox: {
        background: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    timeUntilBoxWarning: {
        background: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    greetingTextTypography: {
        marginBottom: theme.spacing(0.5),
    },
    introSection: {
        [styleBreakDesktop(theme)]: {
            paddingBottom: theme.spacing(2),
        },
    },
    subPageBox: {
        // Fixed dimensions required to achieve the overlap of the oval shape
        height: '240px',
        width: '2755px',
        [styleBreakMobile(theme)]: {
            height: '200px',
            width: '1500px',
            top: theme.spacing(12.75),
        },
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.light,
        zIndex: '-1',
        position: 'absolute',
        top: '70px',
        transform: 'translateY(-50%)',
        boxSizing: 'border-box',
    },
    viewDoctorNoteTitle: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [styleBreakDesktop(theme)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(2),
        },
    },
    subsection: {
        display: 'flex',
        justifyContent: 'center',
    },
    instructionBox: {
        [styleBreakMobile(theme)]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        [styleBreakSmallMobile(theme)]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    subPageContent: {
        [styleBreakMobile(theme)]: {
            paddingTop: theme.spacing(4),
        },
    },
}));

const TaskHomeContent = ({ accountState, isLoadingVisit, visitLoadingResult }) => {
    const currentVisit = useCurrentVisit();
    const classes = useStyles();

    if (isLoadingVisit) {
        return <IsLoadingPage loginNotRequired={true} />;
    } else {
        if (visitLoadingResult === LoadingResult.FAILED) {
            return <LoadingErrorPage loginNotRequired={true} />;
        } else {
            const visitState = currentVisit && currentVisit.visit && currentVisit.visit.visitState;
            // NEDVC-1132: If the visit has been reviewed by a doctor, allow the patient to review the note before showing the PAUSED screen.
            if (accountState === AccountStates.PAUSED && visitState !== VisitState.REVIEWED) {
                return <PausedHomePage />;
            } else {
                const taskDueDate = currentVisit.visit && currentVisit.visit.taskDueDate;
                const allTasksCompletedDate = currentVisit.visit && currentVisit.visit.allTasksCompletedDate;
                const targetClinicianReviewDueDate = currentVisit.visit && currentVisit.visit.clinicianReviewDueDate;
                const taskOpenDate = currentVisit.visit && currentVisit.visit.taskOpenDate;

                const wellnessSurveyTaskDone = taskCompleted(currentVisit.visit, TaskNames.WELLNESS_SURVEY);
                const labWorkStatus = checkLabworkStatus(currentVisit.visit);

                const showNoVisit = !visitState;
                const showBetweenVisit = visitState === VisitState.NEW;
                const showViewDoctorNote = visitState === VisitState.REVIEWED;
                const isLabWorkTaskPresent = isTaskPresent(currentVisit.visit, TaskNames.LAB_WORK);
                const isWellnessSurveyTaskPresent = isTaskPresent(currentVisit.visit, TaskNames.WELLNESS_SURVEY);

                let greetingText = '';
                let instructionText = '';
                let SubPageContent = null;
                let SubPageHeader = null;

                if (showViewDoctorNote) {
                    greetingText = 'Welcome back,';
                    instructionText = "Your doctor's response is now available. Please view the note for your next steps.";
                    const currentVisitAssessment = currentVisit?.visit?.assessment?.visitAssessmentResult ?? '';
                    SubPageContent = () => (
                        <>
                            <ViewDoctorNote />
                            <NextUpCardSection currentVisitAssessment={currentVisitAssessment} />
                        </>
                    );
                    SubPageHeader = () => <SubPageHeading greetingText={greetingText} instructionText={instructionText} />;
                } else if (showNoVisit) {
                    SubPageContent = () => <NoVisitSubPage />;
                    SubPageHeader = null;
                } else if (showBetweenVisit) {
                    const greetingsInstObj = computeGreetingInstForBetweenVisit(taskOpenDate);
                    instructionText = greetingsInstObj.instructionText;
                    greetingText = greetingsInstObj.greetingText;

                    SubPageHeader = () => <SubPageHeading greetingText={greetingText} instructionText={instructionText} />;
                    SubPageContent = () => <BetweenVisitSubPage taskOpenDate={taskOpenDate} />;
                } else if ((!isWellnessSurveyTaskPresent || wellnessSurveyTaskDone) && (!isLabWorkTaskPresent || labWorkStatus === LabworkStatus.COMPLETED)) {
                    greetingText = 'Great job,';
                    instructionText = "Your tasks are complete! Please wait for your doctor's response.";
                    const isPendingClinicianManualLabsRetrieval = visitState === VisitState.PENDING_CLINICIAN_MANUAL_LABS_RETRIEVAL;

                    SubPageHeader = () => <SubPageHeading greetingText={greetingText} instructionText={instructionText} />;
                    SubPageContent = () => (
                        <CompleteTaskBoard
                            submittedDate={allTasksCompletedDate}
                            expectedResponseDate={targetClinicianReviewDueDate}
                            isPendingClinicianManualLabsRetrieval={isPendingClinicianManualLabsRetrieval}
                        />
                    );
                } else {
                    if (wellnessSurveyTaskDone && labWorkStatus !== LabworkStatus.COMPLETED) {
                        greetingText = 'Nice work,';
                        instructionText = 'When your lab results arrive, your tasks will be sent to your doctor.';
                    } else if (!wellnessSurveyTaskDone && labWorkStatus === LabworkStatus.COMPLETED) {
                        greetingText = 'Almost done,';
                        instructionText = 'When your survey is done, your tasks will be sent to your doctor.';
                    } else {
                        greetingText = 'Welcome back,';
                        instructionText = 'Complete your tasks to let your doctor know how you are doing.';
                    }

                    const isLabworkManual = checkForLabWorkBusinessStatus(currentVisit.visit) === TASK_BUSINESS_STATUS_MANUAL_LAB;

                    SubPageHeader = () => <SubPageHeading greetingText={greetingText} instructionText={instructionText} />;
                    SubPageContent = () => (
                        <>
                            <IncompleteTaskBoard
                                taskDueDate={taskDueDate}
                                labWorkStatus={labWorkStatus}
                                wellnessSurveyTaskDone={wellnessSurveyTaskDone}
                                isLabworkManual={isLabworkManual}
                                isLabWorkTaskPresent={isLabWorkTaskPresent}
                                isWellnessSurveyTaskPresent={isWellnessSurveyTaskPresent}
                            />
                        </>
                    );
                }

                return (
                    <MainLayout SubPageHeader={SubPageHeader}>
                        <div className={classes.subPageContent}>
                            <SubPageContent />
                        </div>
                    </MainLayout>
                );
            }
        }
    }
};

/**
 * A page heading hat includes the greeting text and instruction text.
 * Usage:
 *
 * <SubPage greetingText='...' instructionText='...'>
 */
export const SubPageHeading = ({ greetingText, instructionText }) => {
    const classes = useStyles();
    return (
        <Box component="div" className={classes.subsection}>
            <Box component="div" display="flex" flexDirection="column" alignItems="center" paddingTop="32px" className={classes.instructionBox}>
                <Typography align="center" variant="h3" className={classes.greetingTextTypography}>
                    {greetingText}
                </Typography>
                <Typography align="center">{instructionText}</Typography>
            </Box>
            <Box component="div" className={classes.subPageBox}></Box>
        </Box>
    );
};

const IncompleteTaskBoard = ({ taskDueDate, labWorkStatus, wellnessSurveyTaskDone, isLabworkManual, isLabWorkTaskPresent, isWellnessSurveyTaskPresent }) => {
    const styling = useStyles();
    const history = useHistory();
    const [submitting, setSubmitting] = useState(false);

    const nextUpCardTitle = "Doctor's Review";
    const nextUpCardText = 'Your lab results and survey will be sent to your doctor for review once they are complete.';
    const nextUpCardImage = ClinicianIcon;
    const labWorkInProgressSubmissionFailureTitle = 'Failed to Update Lab Work';
    const labWorkInProgressSubmissionFailureBody = 'Your computer might be offline or the Ned server may be experiencing problems. Please try again.';

    const cardClickHandler = urlPath => {
        history.push(urlPath);
    };

    const labWorkTaskToIncompletesSubmissionSuccess = () => {
        history.push(PATH_LAB_REQUISITION);
    };

    const onLabWorkCardClick = () => {
        if (isLabworkManual && labWorkStatus !== LabworkStatus.COMPLETED && labWorkStatus !== LabworkStatus.INPROGRESS) {
            //set lab work to incomplete (in-progress)
            setSubmitting(true);
        } else {
            labWorkTaskToIncompletesSubmissionSuccess();
        }
    };

    return (
        <>
            <Box minHeight={80} pt={4} pb={1} display="flex" className={styling.introSection}>
                <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                    <Grid item xs zeroMinWidth>
                        <Typography variant="h2">Your Tasks</Typography>
                        <Typography>Due {formatDateMMMMDYYYY(taskDueDate)}</Typography>
                    </Grid>
                    <Grid item zeroMinWidth>
                        {/* This one is similar to SurveyResponsePage answers, but not the same. */}
                        <Paper
                            elevation={0}
                            className={
                                moment()
                                    .startOf('day')
                                    .isBefore(isoMoment(taskDueDate).startOf('day'), 'day')
                                    ? styling.timeUntilBox
                                    : styling.timeUntilBoxWarning
                            }
                            component={Box}
                            display="flex"
                            alignItems="center"
                            flexGrow={0}
                        >
                            <Typography variant="body2">{daysLeftFromToday(taskDueDate)}</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <CardList>
                {isLabWorkTaskPresent && (
                    <CardItem
                        Badge={labWorkStatus === LabworkStatus.COMPLETED ? DoneBadge : BloodBadge}
                        title="Get a blood test"
                        subtitle={
                            labWorkStatus === LabworkStatus.COMPLETED ? 'Done' : labWorkStatus === LabworkStatus.INPROGRESS ? 'Incomplete' : 'Tap to begin'
                        }
                        onClick={() => onLabWorkCardClick()}
                    />
                )}
                {isWellnessSurveyTaskPresent && (
                    <CardItem
                        Badge={wellnessSurveyTaskDone ? DoneBadge : RecordBadge}
                        title="Record your symptoms"
                        subtitle={wellnessSurveyTaskDone ? 'Done' : 'Tap to begin'}
                        onClick={() => cardClickHandler(PATH_WELLNESS_SURVEY_HOME)}
                    />
                )}
            </CardList>
            <NextUp cardTitle={nextUpCardTitle} cardText={nextUpCardText} CardImage={nextUpCardImage} />
            <DataSubmissionHandler
                setSubmitting={setSubmitting}
                submitting={submitting}
                actionCreator={dispatchDataSubmissionResult => {
                    return labWorkTaskInProgressMarking(dispatchDataSubmissionResult, true);
                }}
                failureMsgTitle={labWorkInProgressSubmissionFailureTitle}
                failureMsgBody={labWorkInProgressSubmissionFailureBody}
                submissionSuccessHandler={labWorkTaskToIncompletesSubmissionSuccess}
            />
        </>
    );
};

const CompleteTaskBoard = withRouter(WaitingReviewTaskBoard);

export const ViewDoctorNote = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onCardClick = () => {
        const noteViewedAnalytics = createClientAnalytics(AuditEventAction.NOTE_VIEWED.category, AuditEventAction.NOTE_VIEWED.action);
        dispatch(sendClientAnalytics(noteViewedAnalytics));
        window.location = PATH_DOCTOR_NOTE;
    };

    return (
        <>
            <Typography variant="h2" className={classes.viewDoctorNoteTitle}>
                Your Tasks
            </Typography>
            <CardList>
                <CardItem Badge={RecordBadge} title="View your Doctor's Note" subtitle={'Tap to view'} onClick={onCardClick} datatestid="viewDoctorNoteCard" />
            </CardList>
        </>
    );
};

function NextUpCardSection({ currentVisitAssessment }) {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    let cardTitle, cardText, cardImage, linkText, onClick, dialog;
    switch (currentVisitAssessment) {
        case VisitAssessments.PHONE_REQUIRED:
        case VisitAssessments.VIDEO_REQUIRED: {
            const handleConfirm = () => {
                dispatch(closeCurrentVisit(true));
                handleCloseDialog();
            };
            cardTitle = 'Call with your doctor';
            cardText =
                "Your doctor's office will get in touch with you about your upcoming call. If you have not heard back within three business days, please contact your doctor's office.";
            linkText = "I already completed the call with my doctor. What's next?";
            dialog = (
                <AlertModalYesNo
                    isDisplayed={openDialog}
                    alertModalTitle="Complete Your Follow-up"
                    alertModalBody="Confirm that you've completed the call with your doctor to receive your next routine follow-up date."
                    noButtonText="Cancel"
                    yesButtonText="Confirm"
                    onCloseSelected={handleCloseDialog}
                    onNoSelected={handleCloseDialog}
                    onYesSelected={handleConfirm}
                />
            );
            onClick = handleClickOpenDialog;
            cardImage = CalendarIcon;
            break;
        }
        case VisitAssessments.NO_CONCERNS:
        case VisitAssessments.IN_PERSON:
        default:
            return null;
    }
    return (
        <>
            <NextUp cardTitle={cardTitle} cardText={cardText} CardImage={cardImage} linkText={linkText} onClick={onClick} />
            {dialog}
        </>
    );
}

const mapStateToProps = state => {
    return {
        accountState: state?.login?.session?.extraFields?.accountState ?? '',
        isLoadingVisit: state?.dataLoading?.visit?.loading !== LoadingStatus.ENDED,
        visitLoadingResult: state?.dataLoading?.visit?.loadingResult,
    };
};

const TaskHome = connect(mapStateToProps)(TaskHomeContent);

export default function GuardedTaskHome() {
    return (
        <RequiresLogin>
            <CurrentVisitUser>
                <TaskHome />
            </CurrentVisitUser>
        </RequiresLogin>
    );
}

export function* setLabWorkTaskToIncompleteSaga(action) {
    try {
        yield call(markLabWorkTaskAsInProgress);

        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(true));
        }

        if (action.refreshVisit) {
            yield put(retrieveCurrentVisit());
        }
    } catch (error) {
        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(false));
        }
        console.dir(error);
    }
}

export function* watchSetLabWorkTaskToIncomplete() {
    yield takeLatest(actions.MARK_LAB_WORK_TASK_AS_IN_PROGRESS, setLabWorkTaskToIncompleteSaga);
}
