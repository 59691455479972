import React from 'react';
import { formatDateMMMMDYYYY, daysLeftFromToday } from '../utilities/commonUtilities/dateTools';
import { Container, makeStyles, Paper, Typography } from '@material-ui/core';
import { FONT_WEIGHT_BOLD } from '../utilities/Material';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px',
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            marginTop: theme.spacing(4),
        },
    },
    h2Text: {
        fontWeight: FONT_WEIGHT_BOLD,
    },
    dateText: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        textAlign: 'center',
        fontWeight: FONT_WEIGHT_BOLD,
        color: theme.palette.info.contrastText,
    },
    taskBoard: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginTop: theme.spacing(4),
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            marginTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
        },
        boxShadow: '0px 2px 4px rgba(179, 179, 179, 0.5)',
        borderRadius: '4px',
    },
    datePaper: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        background: theme.palette.info.main,
        borderRadius: '4px',
    },
}));

export function computeGreetingInstForBetweenVisit(taskOpenDate) {
    const greetingText = 'Welcome to Ned Virtual Clinic,';

    const onlyDayCount = true;
    const numOfDays = daysLeftFromToday(taskOpenDate, onlyDayCount);
    let until = '';
    if (numOfDays === 0) {
        until = 'today';
    } else {
        until = `in ${numOfDays} days`;
    }

    const instructionText = `We'll see you ${until}!`;

    return {
        greetingText,
        instructionText,
    };
}

export function BetweenVisitSubPage({ taskOpenDate }) {
    const classes = useStyles();

    return (
        <>
            <Container className={classes.root}>
                <Paper className={classes.taskBoard}>
                    <Typography variant="h2" className={classes.h2Text}>
                        Next Routine Follow-up
                    </Typography>
                    <Typography>Your tasks will be available on:</Typography>
                    <Paper className={classes.datePaper} elevation={0}>
                        <Typography variant="h2" className={classes.dateText}>
                            {formatDateMMMMDYYYY(taskOpenDate)}
                        </Typography>
                    </Paper>
                </Paper>
            </Container>
        </>
    );
}
