import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ReactComponent as BackIcon } from '../../../resources/images/back.svg';
import { ReactComponent as CloseIcon } from '../../../resources/images/close.svg';
import { COLOR_BLACK, COLOR_WHITE } from '../Material';
import { CONTENT_MAX_WIDTH, CONTENT_MIN_WIDTH, styleBreakMobile, styleBreakSmallMobile } from './Globals';
import { PrimaryButton } from '../commonUtilities/GenericButtonElements';
import { ReactComponent as NedLogo } from '../../../resources/images/Ned-logo.svg';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SizeSelector } from './SizeSelector';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    closeIcon: {
        fill: COLOR_BLACK,
    },
    count: {
        fontWeight: 'normal',
    },
    marginSelector: {
        margin: '0 auto',
        [theme.breakpoints.down(theme.breakpoints.values['md'])]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.between(theme.breakpoints.values['md'], theme.breakpoints.values['lg'])]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
    },
    widthControl: {
        minWidth: CONTENT_MIN_WIDTH,
        maxWidth: CONTENT_MAX_WIDTH,
    },
    nedLogo: {
        display: 'block',
        height: '37px',
        width: '67px',
    },
    whiteBackground: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: COLOR_WHITE,
        boxShadow: `0px 1px 4px rgba(186, 186, 186, 0.25)`,
        zIndex: '9999',
    },
    stepsLayoutContent: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(13),
        height: '100%',
        [theme.breakpoints.up(theme.breakpoints.values['lg'])]: {
            marginTop: theme.spacing(12),
            marginBottom: theme.spacing(12),
            width: '100%',
        },
    },
    StepsLayoutContainer: {
        height: '100vh',
        overflowY: 'auto',
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            background: COLOR_WHITE,
        },
    },
    nextButton: {
        width: CONTENT_MIN_WIDTH,
        [styleBreakMobile(theme)]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            width: '100%',
            minWidth: CONTENT_MIN_WIDTH,
        },
        [styleBreakSmallMobile(theme)]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    mobileAppBar: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        background: COLOR_WHITE,
        boxShadow: '0px 1px 4px rgba(186, 186, 186, 0.25)',
        zIndex: 9999,
    },
    h4: {
        textAlign: 'center',
    },
    textHeadingMargin: {
        marginBottom: theme.spacing(0.5),
    },
    midHeader: {
        width: 'auto',
    },
    mobileFooter: {
        background: 'linear-gradient(0.93deg, #FFFFFF 63.2%, rgba(255, 255, 255, 0) 97.19%)',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        zIndex: 9999,
    },
    buttonCircularProgress: {
        marginRight: theme.spacing(0.5),
    },
}));

const BackArrow = ({ onPrevClick }) => {
    const visibility = onPrevClick ? 'visible' : 'hidden';
    return (
        <IconButton aria-label="back arrow" edge="start" onClick={onPrevClick} data-testid="onPreviousDiv" style={{ visibility: visibility }}>
            <BackIcon />
        </IconButton>
    );
};

const TextHeading = ({ titleText, currentCount, totalCount }) => {
    const classes = useStyles();
    return (
        <div>
            {titleText && (
                <Typography variant="h4" component="div" className={`${classes.h4} ${classes.textHeadingMargin}`}>
                    {titleText}
                </Typography>
            )}
            {currentCount && totalCount && (
                <Typography variant="h4" component="div" className={`${classes.count} ${classes.h4}`}>
                    {currentCount + '/' + totalCount}
                </Typography>
            )}
        </div>
    );
};

const Close = ({ onCancelClick }) => {
    const classes = useStyles();
    const visibility = onCancelClick ? 'visible' : 'hidden';
    return (
        <IconButton aria-label="close icon" edge="end" onClick={onCancelClick} style={{ visibility: visibility }} className={classes.closeIconContainer}>
            <CloseIcon className={classes.closeIcon} />
        </IconButton>
    );
};

const MidHeader = ({ onPrevClick, titleText, onCancelClick, currentCount, totalCount }) => {
    const classes = useStyles();
    return (
        <Grid container alignItems="center" justify="space-between" className={`${classes.widthControl} ${classes.marginSelector} ${classes.midHeader}`}>
            <Grid item component={BackArrow} onPrevClick={onPrevClick} />
            <Grid item component={TextHeading} titleText={titleText} currentCount={currentCount} totalCount={totalCount} />
            <Grid item component={Close} onCancelClick={onCancelClick} />
        </Grid>
    );
};

const DesktopAppBar = ({ alwaysShowNedLogoInDesktop, children }) => {
    const classes = useStyles();
    const theme = useTheme();
    //hide the Ned logo at 1024px per design recommendations
    const hideLogo = useMediaQuery('(max-width:1024px)');

    return (
        <Box position="fixed" width="100%" className={classes.whiteBackground}>
            {(alwaysShowNedLogoInDesktop || !hideLogo) && (
                <Box position="fixed" top={theme.spacing(2)} left={theme.spacing(6)}>
                    <NedLogo aria-labelledby="Ned logo" className={classes.nedLogo} />
                </Box>
            )}
            {children}
        </Box>
    );
};

const MobileAppBar = ({ children }) => {
    const classes = useStyles();
    return (
        <Box position="fixed" width="100%" className={`${classes.mobileAppBar}`}>
            {children}
        </Box>
    );
};

const AppBar = ({ onPrevClick, onCancelClick, currentCount, totalCount, titleText, alwaysShowNedLogoInDesktop }) => {
    const AppBarContent = () => (
        <MidHeader onPrevClick={onPrevClick} titleText={titleText} onCancelClick={onCancelClick} currentCount={currentCount} totalCount={totalCount} />
    );

    const Mobile = (
        <MobileAppBar>
            <AppBarContent />
        </MobileAppBar>
    );

    const Desktop = (
        <DesktopAppBar alwaysShowNedLogoInDesktop={alwaysShowNedLogoInDesktop}>
            <AppBarContent />
        </DesktopAppBar>
    );

    return <SizeSelector mobile={Mobile} desktop={Desktop} />;
};

const Footer = ({ isNextButtonDisabled, onNextClick, nextButtonText, submitting, submittingText, ...otherButtonProps }) => {
    const classes = useStyles();
    const FooterContent = ({ className }) => (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" position="fixed" bottom="0px" className={`${className ? className : ''}`}>
            <PrimaryButton disabled={isNextButtonDisabled || submitting} onClick={onNextClick} className={`${classes.nextButton}`} {...otherButtonProps}>
                {submitting ? (
                    <>
                        <CircularProgress className={classes.buttonCircularProgress} size={20} />
                        <span>{submittingText || 'Sending...'}</span>
                    </>
                ) : (
                    nextButtonText
                )}
            </PrimaryButton>
        </Box>
    );

    const Mobile = <FooterContent className={classes.mobileFooter} />;
    const Desktop = <FooterContent className={classes.whiteBackground} />;

    return <SizeSelector mobile={Mobile} desktop={Desktop} />;
};

const LayoutContents = ({ children, backgroundStretch = false }) => {
    const classes = useStyles();
    const pageContent = (
        <Grid item className={`${classes.marginSelector} ${classes.stepsLayoutContent} ${classes.widthControl}`}>
            {children}
        </Grid>
    );
    //WellnessSurveyQuestion card seems to be fixed in height
    //Where as SetupAccountStep2TermsOfService grows with the contents
    return backgroundStretch ? <div>{pageContent}</div> : pageContent;
};

export default function StepsLayout({
    onPrevClick,
    onCancelClick,
    currentCount,
    totalCount,
    titleText,
    onNextClick,
    nextButtonText,
    isNextButtonDisabled,
    children,
    stepsContainerClass,
    alwaysShowNedLogoInDesktop,
    submitting,
    submittingText,
    backgroundStretch = false,
    ...others //currently these extra props are for the bottom button
}) {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justify="flex-start"
            wrap="nowrap"
            className={stepsContainerClass ? stepsContainerClass : classes.StepsLayoutContainer}
        >
            <AppBar
                onPrevClick={onPrevClick}
                onCancelClick={onCancelClick}
                currentCount={currentCount}
                totalCount={totalCount}
                titleText={titleText}
                alwaysShowNedLogoInDesktop={alwaysShowNedLogoInDesktop}
            />
            <LayoutContents children={children} backgroundStretch={backgroundStretch} />
            <Footer
                isNextButtonDisabled={isNextButtonDisabled}
                onNextClick={onNextClick}
                nextButtonText={nextButtonText}
                submitting={submitting}
                submittingText={submittingText}
                {...others}
            />
        </Grid>
    );
}
