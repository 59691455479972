import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Box, Typography, makeStyles, Grid, Button } from '@material-ui/core';
import { PrimaryButton } from '../commonUtilities/GenericButtonElements';
import { styleBreakDesktop, styleBreakMobile } from '../layouts/Globals';

const useStyles = makeStyles(theme => ({
    dialogBox: {
        padding: theme.spacing(3),
        maxWidth: '368px',
        [styleBreakDesktop(theme)]: {
            maxWidth: '473px',
        },
    },
    dialogText: {
        marginTop: '12px',
        marginBottom: theme.spacing(3),
    },
    dialogText2: {
        marginTop: '-12px',
        marginBottom: theme.spacing(3),
    },
    dialogContainer: {
        padding: theme.spacing(0),
        [styleBreakDesktop(theme)]: {
            justifyContent: 'flex-end',
        },
    },
    emptyItem: {
        display: 'none',
        [styleBreakDesktop(theme)]: {
            display: 'block',
        },
    },
    dialogButton: {
        [styleBreakMobile(theme)]: {
            width: '100%',
        },
        [styleBreakDesktop(theme)]: {
            minWidth: '145px',
        },
    },
    dialogGridItem: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    dialogPaper: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
    },
    dialogPaperWidthFalse: {
        maxWidth: 'calc(100% - 32px)',
    },
}));

/**
 * An alert modal that displays Yes and No.
 * If we get another AlertModal type here, maybe something like AlertModalOk, we could then extract an AlertModal base.
 *
 * @param onCloseSelected Handler to call when user dismisses the dialog (by clicking outside).
 *
 * @param onNoSelected Optional. Handler when user clicks no. If not provided, no Negative button is displayed.
 * @param noButtonText Optional. Text to display on the No button. Only required if onNoSelected is also provided. Default is 'No'.
 *
 * @param onYesSelected Handler when user clicks yes.
 * @param yesButtonText Optional. Text to display on the yes button. Default is 'Yes'.
 */
const AlertModalYesNo = ({
    isDisplayed,
    alertModalTitle,
    alertModalBody,
    alertModalBody2,
    onNoSelected,
    onYesSelected,
    onCloseSelected,
    noButtonText = 'No',
    yesButtonText = 'Yes',
    datatestid,
}) => {
    const classes = useStyles();
    return (
        // using an inline style for z-index to override the default inline style value on Dialog.
        // Need a much higher value so that it can overlay on any html element. The default value is 'z-index: 1300',
        // which is not high enough for certain elements such as the footer and header on stepsLayout
        <Dialog
            open={isDisplayed}
            onClose={onCloseSelected}
            maxWidth={false}
            style={{ zIndex: 10000 }}
            classes={{
                paper: classes.dialogPaper,
                paperWidthFalse: classes.dialogPaperWidthFalse,
            }}
        >
            <Box component="div" display="flex" flexDirection="column" className={classes.dialogBox}>
                <Typography variant="h2">{alertModalTitle}</Typography>
                <Typography component="div" variant="body1" className={classes.dialogText}>
                    {alertModalBody}
                </Typography>
                {alertModalBody2 && (
                    <Typography component="div" variant="body1" className={classes.dialogText2}>
                        {alertModalBody2}
                    </Typography>
                )}
                <Grid container spacing={2} className={classes.dialogContainer}>
                    <Grid item xs className={classes.emptyItem}></Grid>
                    {onNoSelected && (
                        <Grid item xs className={classes.dialogGridItem}>
                            <Button variant="outlined" onClick={onNoSelected} className={classes.dialogButton}>
                                {noButtonText}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs className={classes.dialogGridItem}>
                        <PrimaryButton onClick={onYesSelected} className={classes.dialogButton} datatestid={datatestid ?? ''}>
                            {yesButtonText}
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
};

/* type checking for props */
AlertModalYesNo.propTypes = {
    isDisplayed: PropTypes.bool.isRequired,
    alertModalTitle: PropTypes.string.isRequired,
    alertModalBody: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    onNoSelected: PropTypes.func,
    onYesSelected: PropTypes.func.isRequired,
    onCloseSelected: PropTypes.func.isRequired,
    noButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    yesButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default AlertModalYesNo;
