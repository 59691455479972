import React from 'react';
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { menuLinks } from './AdaptiveNavBar';
import { NavLink } from 'react-router-dom';
import { ReactComponent as NedLog } from '../../../resources/images/Ned-white.svg';
import { ReactComponent as CloseIcon } from '../../../resources/images/close.svg';
import { ReactComponent as MenuIcon } from '../../../resources/images/menu.svg';
import AppBar from '@material-ui/core/AppBar';
import produce from 'immer';

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
    },
    menuCard: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: theme.spacing(41),
        backgroundColor: theme.palette.primary.dark,
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
    },
    closeIcon: {
        cursor: 'pointer',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        fill: theme.palette.primary.contrastText,
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.dark,
        position: 'relative',
    },
    menuIcon: {
        fill: theme.palette.primary.contrastText,
        right: theme.spacing(3),
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        position: 'absolute',
    },
    linkItem: {
        width: 'fit-content',
        marginRight: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        textAlign: 'left',
    },
    nedLogoImage: {
        width: '55px',
        height: '30px',
    },
}));

const MobileStyledNavLink = styled(NavLink)`
    &&& {
        font-style: normal;
        font-weight: 600;
        text-decoration: none;
        line-height: 41px;
        letter-spacing: 0.16px;
        font-family: ${props => props.theme.typography.fontFamily};
        color: ${props => props.theme.palette.primary.contrastText};
        font-size: ${props => props.theme.typography.body1.fontSize};
        margin-left: ${props => `${props.theme.spacing(6)}px`};
        margin-top: ${props => `${props.theme.spacing(3)}px`};
    }
`;

function ListLinkItem({ link, children }) {
    const classes = useStyles();
    const theme = useTheme();

    // use immer to avoid mutating the theme object
    const themeCopy = produce(theme, draftTheme => {
        draftTheme.typography.body1.fontSize = '30px';
    });

    return (
        <MobileStyledNavLink
            to={link}
            activeStyle={{
                color: theme.palette.primary.light,
                borderBottom: '2px solid #EEEDFC',
            }}
            theme={themeCopy}
            className={classes.linkItem}
        >
            {children}
        </MobileStyledNavLink>
    );
}

const LinkList = () => {
    const classes = useStyles();
    return (
        <div className={classes.list}>
            {menuLinks.map((item, index) => (
                <ListLinkItem link={item.link} key={index}>
                    {item.text}
                </ListLinkItem>
            ))}
        </div>
    );
};

const MenuCard = ({ clickHandler }) => {
    const classes = useStyles();
    return (
        <div className={classes.menuCard}>
            <div className={classes.iconContainer}>
                <NedLog aria-labelledby="Ned logo" />
                <CloseIcon title={'close icon'} onClick={clickHandler} className={classes.closeIcon} />
            </div>
            <LinkList />
        </div>
    );
};

const MobileAppBar = ({ clickHandler }) => {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <div className={classes.toolBar}>
                <NedLog aria-labelledby="Ned logo" className={classes.nedLogoImage} />
                <MenuIcon title={'menu icon'} onClick={clickHandler} className={classes.menuIcon} />
            </div>
        </AppBar>
    );
};

export default function MobileNavBar() {
    const [state, setState] = React.useState(false);

    const toggleDrawer = open => {
        setState(open);
    };

    return (
        <>
            <MobileAppBar clickHandler={() => toggleDrawer(true)} />
            <Drawer anchor={'right'} open={state} onClose={() => toggleDrawer(false)}>
                <MenuCard clickHandler={() => toggleDrawer(false)} />
            </Drawer>
        </>
    );
}
