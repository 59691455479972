import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ReactComponent as NedLog } from '../../../resources/images/Ned-white.svg';
import produce from 'immer';

export const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    font-family: ${props => props.theme.typography.fontFamily};
    color: ${props => props.theme.palette.primary.contrastText};
    font-style: normal;
    font-weight: 600;
    font-size: ${props => props.theme.typography.body1.fontSize};
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.16px;
    padding-bottom: 18px;
    margin-right: 24px;
    margin-left: 24px;
`;

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.dark,
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    nedLogoImage: {
        width: '67px',
        height: '37px',
        display: 'block',
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(4),
    },
}));

function PageLink({ link, children }) {
    const theme = useTheme();

    // use immer to avoid mutating the theme object
    const themeCopy = produce(theme, draftTheme => {
        draftTheme.typography.body1.fontSize = '18px';
    });

    return (
        <StyledNavLink
            to={link}
            activeStyle={{
                color: theme.palette.primary.light,
                borderBottom: '5px solid #EEEDFC',
            }}
            theme={themeCopy}
        >
            {children}
        </StyledNavLink>
    );
}

export default function DesktopNavBar({ linkObj }) {
    const classes = useStyles();

    const mappedLinkObj = linkObj.map((item, indx) => {
        return (
            <PageLink key={indx} link={item.link}>
                {item.text}
            </PageLink>
        );
    });

    return (
        <AppBar position="static" className={classes.root}>
            <div className={classes.toolBar}>
                <NedLog aria-labelledby="Ned logo" className={classes.nedLogoImage} />
                <div>{mappedLinkObj}</div>
            </div>
        </AppBar>
    );
}
