import { version, name } from '../../package.json';
import moment from 'moment';
import platform from 'platform';

export const AuditEventAction = {
    NOTE_VIEWED: {
        action: 'NOTE_VIEWED',
        category: 'TASKS',
    },
    LAB_VIEWED: {
        action: 'LAB_VIEWED',
        category: 'TASKS',
    },
    LABREQ_DOWNLOADED: {
        action: 'LABREQ_DOWNLOADED',
        category: 'TASKS',
    },
    MANUAL_LAB_COMPLETION: {
        action: 'MANUAL_LAB_COMPLETION',
        category: 'TASKS',
    },
    OPEN_SURVEY: {
        action: 'OPEN_SURVEY',
        category: 'SURVEY',
    },
    CANCEL_SURVEY: {
        action: 'CANCEL_SURVEY',
        category: 'SURVEY',
    },
};

export function createClientAnalytics(category, action) {
    const client = {
        action: action,
        category: category,
        appName: name,
        appVersion: version,
        clientCreated: moment(moment(), moment.ISO_8601).toISOString(),
        clientDeviceIdentifier: 'N/A',
        clientDeviceModel: 'N/A',
        clientOsVersion: getClientOsVersion(),
        clientPlatform: 'WEB',
        clientScreenSize: getScreenSize(),
    };

    return client;
}

function getScreenSize() {
    if (window?.screen?.width) {
        return `${window.screen.width}px`;
    }
    return 'N/A';
}

function getClientOsVersion() {
    let osVersion;
    try {
        osVersion = platform.os.toString();
    } catch (e) {
        osVersion = 'N/A';
    }
    return osVersion;
}
