/* This file contains typographical elements like heading and paragraph elements */
import styled from 'styled-components';
import { FONT_WEIGHT_SEMI } from '../Material';
import Typography from '@material-ui/core/Typography';

export const Heading1 = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 41px;
    letter-spacing: 0.3px;
    color: #212121;
`;

export const Heading1B = styled(Heading1)`
    font-size: 30px;
`;

export const Header1BMargin = styled(Heading1B)`
    margin-top: 15px;
`;

export const Heading2 = styled(Heading1)`
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.24px;
    color: #282828;
`;

export const Heading1WithMarginA = styled(Heading1)`
    margin-top: 27px;
    margin-bottom: 14px;
`;

export const Heading3 = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.16px;
    color: #000000;
`;

export const Heading3WithMarginA = styled(Heading3)`
    margin-top: 23px;
    margin-bottom: 8px;
`;

export const TitleDiv1 = styled.div`
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.16px;
    color: #212121;
`;

export const TitleDiv2 = styled(TitleDiv1)`
    font-weight: 600;
    letter-spacing: 0.3px;
`;

export const HorizontalLine = styled.hr`
    border: 1px solid #dadada;
    margin-top: 0px;
    margin-bottom: 0px;
`;

export const HorizontalLineWithMarginA = styled(HorizontalLine)`
    margin-left: 28px;
`;

export const LargeHeading = styled(Heading1)`
    font-size: 70px;
    line-height: 96px;
`;

export const ErrorTypography = styled(Typography)`
    line-height: 19px;
    letter-spacing: 0.16px;
    font-weight: ${FONT_WEIGHT_SEMI};
`;
