/* This file creates the Redux store, connecting saga middleware to the store as well */

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loadSessionStorage } from '../utils/ClientSessionHandler';
import { configureAxios } from '../apis/api';
import { newStoreState } from '../reducers';
import { rootReducer } from '../reducers';
import rootSaga from '../sagas';
import { initWellnessSurveyStore } from '../reducers/wellnessSurveyReducer';
import { initAccountSetupStore } from '../reducers/accountSetupReducer';

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();

    const preloadedState = newStoreState();

    const { login, deviceToken, patientVerificationInfo, accountSetupCurrentPage, accountSetupAgreementVersion, isSurveyInitiated } = loadSessionStorage();
    if (login) preloadedState.login = login;

    if (isSurveyInitiated) {
        preloadedState.wellnessSurvey = initWellnessSurveyStore();
        preloadedState.wellnessSurvey.isSurveyInitiated = isSurveyInitiated;
    }

    if (patientVerificationInfo || accountSetupCurrentPage || accountSetupAgreementVersion) {
        preloadedState.accountSetup = initAccountSetupStore();
        if (patientVerificationInfo) {
            preloadedState.accountSetup.patientVerificationInfo = patientVerificationInfo;
        }
        if (accountSetupCurrentPage) {
            preloadedState.accountSetup.currentPage = accountSetupCurrentPage;
        }
        if (accountSetupAgreementVersion) {
            preloadedState.accountSetup.agreementVersion = accountSetupAgreementVersion;
        }
    }

    const store = createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(sagaMiddleware)));

    // set token and device token on axios header, if present
    // Configure axios.
    configureAxios({ login, deviceToken });

    sagaMiddleware.run(rootSaga);

    return store;
};

export default configureStore;
