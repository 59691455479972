import axios from 'axios';

const pathRoot = '/fhir';

export const getQuestionnaire = () => {
    return axios.get(pathRoot + '/Questionnaire/wellnessSurvey');
};

export const postQuestionnaireResponse = questionnaireResponse => {
    return axios.post(pathRoot + '/QuestionnaireResponse', questionnaireResponse);
};

export const getValueSets = async answerValueSetRefs => {
    return await valueSetRequests(answerValueSetRefs);
};

const valueSetRequests = async answerValueSetRefs => {
    const responses = [];
    // cache response for a given url to avoid reapeatedly making the same request
    const cache = {};
    for (const answerUrl of answerValueSetRefs) {
        if (cache[answerUrl] === undefined) {
            cache[answerUrl] = await axios.get(pathRoot + `/${answerUrl}`);
        }
        responses.push(cache[answerUrl]);
    }
    return responses;
};
