import React from 'react';
import PropTypes from 'prop-types';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { ReactComponent as NedLogo } from '../../resources/images/Ned-logo.svg';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton } from '../utilities/commonUtilities/GenericButtonElements';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as HospitalLogo } from '../../resources/images/hospital.svg';
import { styleBreakDesktop, styleBreakMobile, styleBreakSmallMobile } from '../utilities/layouts/Globals';

const useStyles = makeStyles(theme => ({
    nedLogo: {
        height: '37px',
        width: '67px',
        marginTop: theme.spacing(2),
        [styleBreakDesktop(theme)]: {
            marginTop: theme.spacing(5),
        },
        [styleBreakSmallMobile(theme)]: {
            height: '24px',
        },
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(10),
        [styleBreakSmallMobile(theme)]: {
            marginTop: theme.spacing(4),
        },
        [styleBreakDesktop(theme)]: {
            width: theme.spacing(35),
        },
    },
    title: {
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(10),
        },
        [styleBreakSmallMobile(theme)]: {
            marginTop: theme.spacing(4),
        },
        marginBottom: theme.spacing(2),
        lineHeight: 1.25,
        letterSpacing: '0.3px',
    },
    hospitalLogo: {
        height: theme.spacing(25),
        width: theme.spacing(23),
        display: 'block',
        position: 'fixed',
        left: theme.spacing(-2),
        bottom: theme.spacing(-2),
        [styleBreakDesktop(theme)]: {
            height: theme.spacing(40),
            width: theme.spacing(37),
        },
        [styleBreakSmallMobile(theme)]: {
            zIndex: '-9999',
            height: '185px',
        },
    },
    content: {
        [styleBreakDesktop(theme)]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    },
    body: {
        [styleBreakDesktop(theme)]: {
            maxWidth: '378px',
            textAlign: 'center',
        },
    },
    pageContainer: {
        [styleBreakDesktop(theme)]: {
            height: '100vh',
        },
    },
}));

const SetupAccountStep1Welcome = ({ currentPage, onNextPage }) => {
    const classes = useStyles();

    // Don't draw the component if it is not our page.
    if (currentPage !== 1) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column" className={classes.pageContainer}>
            <Box display="flex" justifyContent="center" alignItems="center">
                <NedLogo aria-labelledby="Ned logo" className={classes.nedLogo} />
            </Box>
            <ContentLayout className={classes.content}>
                <Typography variant="h1" className={classes.title}>
                    Welcome to Ned Virtual Clinic!
                </Typography>
                <Typography variant="body1" className={classes.body}>
                    Set up your account to get access to virtual services with your specialists
                </Typography>
                <PrimaryButton onClick={onNextPage} className={classes.button}>
                    Get Started
                </PrimaryButton>
            </ContentLayout>
            <HospitalLogo aria-labelledby="Hospital logo" className={classes.hospitalLogo} />
        </Box>
    );
};

/* type checking for props */
SetupAccountStep1Welcome.propTypes = {
    currentPage: PropTypes.number.isRequired,
    onNextPage: PropTypes.func.isRequired,
};

export default SetupAccountStep1Welcome;
