import { logoutPatient, discardCurrentVisit, discardEntireWellnessQuestionnaire, discardCurrentOutcome } from '../actions';
import { putResolve } from 'redux-saga/effects';
import { actionSaga } from './dispatchSaga';

export function actionLogout() {
    return actionSaga('SAGA_LOGOUT', logoutSaga);
}

export function* logoutSaga() {
    yield putResolve(logoutPatient());
    yield putResolve(discardCurrentVisit());
    yield putResolve(discardEntireWellnessQuestionnaire());
    yield putResolve(discardCurrentOutcome());
}
