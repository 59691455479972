/* This file contains sagas related to Password reset */

import { put, call } from 'redux-saga/effects';
import * as PasswordResetAPI from '../apis/passwordReset';
import {
    passwordResetSaveSecurityQuestions,
    passwordResetSaveOneTimeResetToken,
    passwordResetRecordFailureInSecurityQuestion,
    passwordResetRecordNetworkError,
    passwordResetClearErrorAndOneTimeTokenStates,
} from '../actions';

export function* PasswordResetRetrieveSecurityQuestionsSaga(action) {
    try {
        const response = yield call(PasswordResetAPI.retrieveSecurityQuestions, action.resetToken);
        const securityQuestions = extractSecurityQuestions(response.data);
        yield put(passwordResetSaveSecurityQuestions(securityQuestions));
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        // We will call the error no matter what.
        yield call(action.errorCallBack, error.response);
    }
}

export function* PasswordResetSubmitAnswersToSecurityQuestionsSaga(action) {
    yield put(passwordResetClearErrorAndOneTimeTokenStates());
    try {
        const response = yield call(PasswordResetAPI.submitAnswersToSecurityQuestions, action.securityQuestionsAndAnswersAndToken);
        yield put(passwordResetSaveOneTimeResetToken(response.data.resetToken));
    } catch (error) {
        if (!!error.response) {
            if (error.response.status === 401) {
                // This is an authentication/unauthorized error (currently failure in answering security question is a 401).
                yield put(passwordResetRecordFailureInSecurityQuestion(error.response.data));
            } else {
                // TODO: need to handle other non-2xx errors
                console.log(error);
            }
        } else {
            // Either the request was made but no response was received, or
            // something happened in setting up the request that triggered an Error (most likely network error)
            yield put(passwordResetRecordNetworkError());
        }
    }
}

export function* PasswordResetSubmitNewPasswordSaga(action) {
    try {
        yield call(PasswordResetAPI.submitNewPassword, action.newPasswordAndOneTimeToken);
        yield call(action.onSubmitSuccess);
    } catch (error) {
        if (!!error.response && error.response.status === 401) {
            //error submitting new password
            console.log(error.response);
        } else {
            // fill in with right error message
            console.log('all other error messages');
        }

        // We will call the error no matter what.
        yield call(action.onSubmitFail, error.response);
    }
}

function extractSecurityQuestions(responseData) {
    const securityQuestions = [];
    for (const entry of responseData.entries) {
        securityQuestions.push(entry);
    }
    return securityQuestions;
}
