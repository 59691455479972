/** This file contains helper functions that handle persisting
 * the current client session to a sessionStorage object
 * in the browser so that it can be retrieved on page refresh/reload.
 *
 * The sessionStorage object only persists when the browsertab/window is open, and
 * is different for every session (browsertab/window).
 *
 * NOTE:
 * 1. When considering performance, the operations to watch out for are JSON.stringify
 * and JSON.parse, which could turn out to be costly when saving a deeply nested
 * part of the redux store that changes frequently.
 */

export const loadSessionStorage = () => {
    let login;
    let deviceToken;
    let isSurveyInitiated;
    let patientVerificationInfo;
    let accountSetupCurrentPage;
    let accountSetupAgreementVersion;
    try {
        // load login object
        const serializedLogin = sessionStorage.getItem('login');
        login = serializedLogin && JSON.parse(serializedLogin);
        // load device token
        const serializedDeviceToken = sessionStorage.getItem('deviceToken');
        deviceToken = serializedDeviceToken && JSON.parse(serializedDeviceToken);
        // load isSurveyInitiated
        const serializedIsSurveyInitiated = sessionStorage.getItem('isSurveyInitiated');
        isSurveyInitiated = serializedIsSurveyInitiated && JSON.parse(serializedIsSurveyInitiated);
        // load patient verification info
        const serializedPatientVerificationInfo = sessionStorage.getItem('patientVerificationInfo');
        patientVerificationInfo = serializedPatientVerificationInfo && JSON.parse(serializedPatientVerificationInfo);
        // load account setup current page
        const serializedAccountSetupCurrentPage = sessionStorage.getItem('accountSetupCurrentPage');
        accountSetupCurrentPage = serializedAccountSetupCurrentPage && JSON.parse(serializedAccountSetupCurrentPage);
        // load account setup agreement version
        const serializedAccountSetupAgreementVersion = sessionStorage.getItem('accountSetupAgreementVersion');
        accountSetupAgreementVersion = serializedAccountSetupAgreementVersion && JSON.parse(serializedAccountSetupAgreementVersion);
    } catch (err) {
        // If loading the login object, device token, patientVerificationInfo, accountSetupCurrentPage, or accountSetupAgreementVersion fails, return empty object
        return {};
    }
    return { login, deviceToken, patientVerificationInfo, accountSetupCurrentPage, accountSetupAgreementVersion, isSurveyInitiated };
};

export const persistLogin = login => {
    try {
        const serializedLogin = JSON.stringify(login);
        sessionStorage.setItem('login', serializedLogin);
    } catch (err) {
        console.log(err);
    }
};

export const persistDeviceToken = deviceToken => {
    try {
        const serializedDeviceToken = JSON.stringify(deviceToken);
        sessionStorage.setItem('deviceToken', serializedDeviceToken);
    } catch (err) {
        console.log(err);
    }
};

export const persistIsSurveyInitiated = isSurveyInitiated => {
    try {
        const serializedIsSurveyInitiated = JSON.stringify(isSurveyInitiated);
        sessionStorage.setItem('isSurveyInitiated', serializedIsSurveyInitiated);
    } catch (err) {
        console.log(err);
    }
};

export const persistPatientVerificationInfo = patientVerificationInfo => {
    try {
        const serializedPatientVerificationInfo = JSON.stringify(patientVerificationInfo);
        sessionStorage.setItem('patientVerificationInfo', serializedPatientVerificationInfo);
    } catch (err) {
        console.log(err);
    }
};

export const persistAccountSetupCurrentPage = accountSetupCurrentPage => {
    try {
        const serializedAccountSetupCurrentPage = JSON.stringify(accountSetupCurrentPage);
        sessionStorage.setItem('accountSetupCurrentPage', serializedAccountSetupCurrentPage);
    } catch (err) {
        console.log(err);
    }
};

export const persistAccountSetupAgreementVersion = accountSetupAgreementVersion => {
    try {
        const serializedAccountSetupAgreementVersion = JSON.stringify(accountSetupAgreementVersion);
        sessionStorage.setItem('accountSetupAgreementVersion', serializedAccountSetupAgreementVersion);
    } catch (err) {
        console.log(err);
    }
};

export const clearClientSession = () => {
    sessionStorage.clear();
};
