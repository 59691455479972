/* This file contains generic spans */
import styled from 'styled-components';

export const SmallBody1 = styled.span`
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.16px;
    color: #212121;
`;

export const SmallBody2 = styled(SmallBody1)`
    font-weight: 500;
    letter-spacing: 0.24px;
    color: #000000;
`;

export const SmallBody3 = styled(SmallBody2)`
    font-weight: 600;
`;

export const SmallBodyli = styled(SmallBody1)`
    color: inherit;
`;

export const ToggleSpan = styled(SmallBody2)`
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 14px;
    right: 6px;
    color: #979797;
`;

export const BoldText = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #000000;
`;

export const BoldTextB = styled(BoldText)`
    font-weight: 600;
    color: #212121;
`;

export const SmallBody1Date = styled(SmallBody1)`
    color: #000000;
`;

export const DialogBody = styled.span`
    font-size: 16px;
    letter-spacing: 0.24px;
    color: #000000;
`;
