import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actionOutcomeFetch } from './NoteUtils';

const NotesUserComponent = ({ fetchOutcomes, children }) => {
    useEffect(() => {
        fetchOutcomes();
    }, [fetchOutcomes]);
    return <>{children}</>;
};

const mapDispatch = {
    fetchOutcomes: actionOutcomeFetch,
};

/**
 * Component that requests loading of the outcome notes because the children below
 * it intend to use notes from the store. Note that the reqest is not a guarantee
 * that the load is successful or completed by the time that the children are rendered.
 *
 * Usage:
 *
 * <NotesConsumer>
 *    ... <Content that maps state.outcomes.notes />
 */
export const NotesConsumer = connect(null, mapDispatch)(NotesUserComponent);
