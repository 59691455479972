import React from 'react';
import MainLayout from '../utilities/layouts/MainLayout';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { PageTitleBlock } from '../utilities/commonUtilities/GenericDivElements';
import { useHistory } from 'react-router-dom';
import { CardList, CardItem, EmptyItem } from '../utilities/commonUtilities/CardItem';
import { ReactComponent as HistoryBadge } from '../../resources/images/history-badge.svg';
import { ReactComponent as HistoryEmpty } from '../../resources/images/history-empty.svg';
import { PATH_NOTES } from '../../containers/RoutePaths';
import { NotesConsumer } from './NotesConsumer';
import { LoadingResult, LoadingStatus } from '../../utils/LoadingUtils';
import LoadingErrorPage from '../LoadingErrorPage';
import IsLoadingPage from '../IsLoadingPage';

const NoteHistory = ({ allOutcomes, isLoadingOutcomes, outcomesLoadingResult }) => {
    return (
        <MainLayout>
            <NotesConsumer>
                {isLoadingOutcomes ? (
                    <IsLoadingPage noNavBar={true} />
                ) : outcomesLoadingResult === LoadingResult.FAILED ? (
                    <LoadingErrorPage noNavBar={true} />
                ) : (
                    <>
                        {allOutcomes.length > 0 && (
                            <PageTitleBlock>
                                <Typography variant="h1">Notes</Typography>
                            </PageTitleBlock>
                        )}
                        <FollowUpNoteCards allOutcomes={allOutcomes} />
                    </>
                )}
            </NotesConsumer>
        </MainLayout>
    );
};

function FollowUpNoteCards({ allOutcomes = [] }) {
    const history = useHistory();

    return allOutcomes.length > 0 ? (
        <CardList>
            {allOutcomes.map(item => {
                return (
                    <CardItem
                        key={item.id}
                        Badge={HistoryBadge}
                        title="Follow-up Note"
                        subtitle={item.receivedDate}
                        onClick={() => handleCardClick(item, history)}
                    />
                );
            })}
        </CardList>
    ) : (
        <EmptyItem image={HistoryEmpty} title="No Notes Found" message="The doctor's note you receive after each virtual visit will be saved here." />
    );
}

function handleCardClick(note, history) {
    history.push(`${PATH_NOTES}/${note.id}`);
}

const mapStateToProps = state => {
    return {
        isLoadingOutcomes: state.outcomes?.loading !== LoadingStatus.ENDED,
        outcomesLoadingResult: state.outcomes?.loadingResult,
        allOutcomes: state.outcomes?.notes,
    };
};

export default connect(mapStateToProps)(NoteHistory);
