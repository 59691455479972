/* Definition of different action types */

export const LOGIN_PATIENT = 'LOGIN_PATIENT';
export const LOGIN_PATIENT_SUCCESS = 'LOGIN_PATIENT_SUCCESS';
export const LOGIN_PATIENT_AUTH_FAILURE = 'LOGIN_PATIENT_AUTH_FAILURE';
export const LOGIN_NETWORK_ERROR = 'LOGIN_NETWORK_ERROR'; // associated with a network error
export const LOGIN_REQUEST_PASSWORD_RESET = 'LOGIN_REQUEST_PASSWORD_RESET';
export const LOGIN_RESET_STATE = 'LOGIN_RESET_STATE';
export const WELLNESS_BUILD_QUESTIONNAIRE = 'WELLNESS_BUILD_QUESTIONNAIRE';
export const WELLNESS_UPDATE_CURRENT_QUESTION = 'WELLNESS_UPDATE_CURRENT_QUESTION';
export const WELLNESS_UPDATE_ACTIVE_ANSWER_LINK_ID = 'WELLNESS_UPDATE_ACTIVE_ANSWER_LINK_ID';
export const WELLNESS_RETRIEVE_QUESTIONNAIRE = 'WELLNESS_RETRIEVE_QUESTIONNAIRE';
export const WELLNESS_SEND_QUESTIONNAIRE_RESPONSE = 'WELLNESS_SEND_QUESTIONNAIRE_RESPONSE';
export const WELLNESS_DISCARD_ANSWERS = 'WELLNESS_DISCARD_ANSWERS';
export const WELLNESS_TOGGLE_ALERT = 'WELLNESS_TOGGLE_ALERT';
export const WELLNESS_DISCARD_ENTIRE_QUESTIONNAIRE = 'WELLNESS_DISCARD_ENTIRE_QUESTIONNAIRE';
export const WELLNESS_SET_IS_SURVEY_INITIATED = 'WELLNESS_SET_IS_SURVEY_INITIATED';
export const LOGOUT_PATIENT = 'LOGOUT_PATIENT';
export const LOGOUT_PATIENT_SUCCESS = 'LOGOUT_PATIENT_SUCCESS';
export const LOGOUT_PATIENT_ERROR = 'LOGOUT_PATIENT_ERROR';
export const LOGIN_RETRIEVE_SESSION = 'LOGIN_RETRIEVE_SESSION';
export const LOGIN_PROCESS_DATA = 'LOGIN_PROCESS_DATA';
export const ACCOUNT_SETUP_DISCARD = 'ACCOUNT_SETUP_DISCARD';
export const ACCOUNT_SETUP_CREATION_COMPLETE_SUCCESS = 'ACCOUNT_SETUP_CREATION_COMPLETE_SUCCESS';
export const ACCOUNT_SETUP_CREATION_COMPLETE_ERROR = 'ACCOUNT_SETUP_CREATION_COMPLETE_ERROR';
export const ACCOUNT_SETUP_SET_DISPLAY_ALERT = 'ACCOUNT_SETUP_SET_DISPLAY_ALERT';
export const ACCOUNT_SETUP_SECURITY_QUESTIONS_AND_PASSWORD_SUBMIT = 'ACCOUNT_SETUP_SECURITY_QUESTIONS_AND_PASSWORD_SUBMIT';
export const ACCOUNT_SETUP_SET_CURRENT_PAGE = 'ACCOUNT_SETUP_SET_CURRENT_PAGE';
export const ACCOUNT_SETUP_SET_VERIFICATION_PARAMETERS = 'ACCOUNT_SETUP_SET_VERIFICATION_PARAMETERS';
export const ACCOUNT_SETUP_VERIFY_PATIENT = 'ACCOUNT_SETUP_VERIFY_PATIENT';
export const ACCOUNT_SETUP_SET_VERIFICATION_RESPONSE = 'ACCOUNT_SETUP_SET_VERIFICATION_RESPONSE';
export const ACCOUNT_SETUP_SET_PATIENT_VERIFICATION_INFO = 'ACCOUNT_SETUP_SET_PATIENT_VERIFICATION_INFO';
export const CURRENT_VISIT_RETRIEVE = 'CURRENT_VISIT_RETRIEVE';
export const CURRENT_VISIT_CLOSE = 'CURRENT_VISIT_CLOSE';
export const CURRENT_VISIT_BUILD = 'CURRENT_VISIT_BUILD';
export const CURRENT_VISIT_DISCARD = 'CURRENT_VISIT_DISCARD';
export const CURRENT_VISIT_SET_RECENTLY_COMPLETED_ALL_TASKS = 'CURRENT_VISIT_SET_RECENTLY_COMPLETED_ALL_TASKS';
export const CURRENT_VISIT_SET_COMPLETED_TASK = 'CURRENT_VISIT_SET_COMPLETED_TASK';
export const ACCOUNT_SETUP_SET_AGREEMENT_VERSION = 'ACCOUNT_SETUP_SET_AGREEMENT_VERSION';
export const PASSWORD_RESET_SAVE_SECURITY_QUESTIONS = 'PASSWORD_RESET_SAVE_SECURITY_QUESTIONS';
export const PASSWORD_RESET_RETRIEVE_SECURITY_QUESTIONS = 'PASSWORD_RESET_RETRIEVE_SECURITY_QUESTIONS';
export const PASSWORD_RESET_SUBMIT_ANSWERS_TO_SECURITY_QUESTIONS = 'PASSWORD_RESET_SUBMIT_ANSWERS_TO_SECURITY_QUESTIONS';
export const PASSWORD_RESET_SAVE_ONE_TIME_RESET_TOKEN = 'PASSWORD_RESET_SAVE_ONE_TIME_RESET_TOKEN';
export const PASSWORD_RESET_SUBMIT_NEW_PASSWORD = 'PASSWORD_RESET_SUBMIT_NEW_PASSWORD';
export const PASSWORD_RESET_RECORD_FAILURE_IN_SECURITY_QUESTION = 'PASSWORD_RESET_RECORD_FAILURE_IN_SECURITY_QUESTION';
export const PASSWORD_RESET_RECORD_NETWORK_ERROR = 'PASSWORD_RESET_RECORD_NETWORK_ERROR';
export const PASSWORD_RESET_CLEAR_ERROR_AND_ONE_TIME_TOKEN_STATES = 'PASSWORD_RESET_CLEAR_ERROR_AND_ONE_TIME_TOKEN_STATES';
export const DEBUG_FIRE_EVENT = 'DEBUG_FIRE_EVENT';
export const OBSERVATION_STORE = 'OBSERVATION_STORE';
export const OBSERVATION_APPEND = 'OBSERVATION_APPEND';
export const RETRIEVE_CURRENT_OUTCOME = 'RETRIEVE_CURRENT_OUTCOME';
export const CURRENT_OUTCOME_SAVE = 'CURRENT_OUTCOME_SAVE';
export const CURRENT_OUTCOME_DISCARD = 'CURRENT_OUTCOME_DISCARD';
export const PDF_ID_STORE = 'PDF_ID_STORE';
export const PATIENT_FHIR_INFO_STORE = 'PATIENT_FHIR_INFO_STORE';
export const PATIENT_FHIR_INFO_RETRIEVE = 'PATIENT_FHIR_INFO_RETRIEVE';
export const PATIENT_NED_USER_INFO_STORE = 'PATIENT_NED_USER_INFO_STORE';
export const NED_USER_INFO_RETRIEVE = 'NED_USER_INFO_RETRIEVE';
export const SURVEY_RESPONSE_STORE = 'SURVEY_RESPONSE_STORE';
export const SURVEY_RESPONSE_APPEND = 'SURVEY_RESPONSE_APPEND';
export const SURVEY_RESPONSE_FETCH_END = 'SURVEY_RESPONSE_FETCH_END';
export const QUESTIONNAIRE_RESPONSE_LOADING = 'QUESTIONNAIRE_RESPONSE_LOADING';
export const QUESTIONNAIRE_RESPONSE_LOADING_RESULT = 'QUESTIONNAIRE_RESPONSE_LOADING_RESULT';
export const OUTCOME_STORE = 'OUTCOME_STORE';
export const OUTCOME_APPEND = 'OUTCOME_APPEND';
export const OUTCOME_LOAD_ENDED = 'OUTCOME_LOAD_ENDED';
export const OUTCOMES_LOADING = 'OUTCOMES_LOADING';
export const OUTCOME_LOADING_RESULT = 'OUTCOME_LOADING_RESULT';
export const CLEAR_STORE = 'CLEAR_STORE';
export const DATA_SUBMISSION_RESULT = 'DATA_SUBMISSION_RESULT';
export const PROFILE_CHANGE_PASSWORD = 'PROFILE_CHANGE_PASSWORD';
export const PROFILE_CHANGE_SECURITY_QUESTIONS = 'PROFILE_CHANGE_SECURITY_QUESTIONS';
export const DATA_VISIT_LOADING = 'DATA_VISIT_LOADING';
export const DATA_VISIT_LOADING_RESULT = 'DATA_VISIT_LOADING_RESULT';
export const DATA_LAB_REQ_ID_LOADING = 'DATA_LAB_REQ_ID_LOADING';
export const DATA_LAB_REQ_ID_LOADING_RESULT = 'DATA_LAB_REQ_ID_LOADING_RESULT';
export const DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING = 'DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING';
export const DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING_RESULT = 'DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING_RESULT';
export const DATA_OBSERVATIONS_LOADING = 'DATA_OBSERVATIONS_LOADING';
export const DATA_OBSERVATIONS_LOADING_RESULT = 'DATA_OBSERVATIONS_LOADING_RESULT';
export const DATA_FHIR_PATIENT_INFO_LOADING = 'DATA_FHIR_PATIENT_INFO_LOADING';
export const DATA_FHIR_PATIENT_INFO_LOADING_RESULT = 'DATA_FHIR_PATIENT_INFO_LOADING_RESULT';
export const DATA_NED_USER_INFO_LOADING = 'DATA_NED_USER_INFO_LOADING';
export const DATA_NED_USER_INFO_LOADING_RESULT = 'DATA_NED_USER_INFO_LOADING_RESULT';
export const MARK_LAB_WORK_TASK_AS_IN_PROGRESS = 'MARK_LAB_WORK_TASK_AS_IN_PROGRESS';
export const MARK_LAB_WORK_TASK_COMPLETE = 'MARK_LAB_WORK_TASK_COMPLETE';
export const ANALYTICS_SEND_CLIENT_ANALYTICS = 'ANALYTICS_SEND_CLIENT_ANALYTICS';
