import { makeStyles } from '@material-ui/core';

export const SMALL_MARGIN = 16;
export const MED_MARGIN = 24;

// The min size of content, excluding the margins
export const CONTENT_MIN_WIDTH = 288;

// The max size of content, excluding the margins
export const CONTENT_MAX_WIDTH = 782;

// The min size of the entire layout, including the margins
export const LAYOUT_MIN_WIDTH = CONTENT_MIN_WIDTH + SMALL_MARGIN * 2;

// The max size of the entire layout, including the margins
export const LAYOUT_MAX_WIDTH = CONTENT_MAX_WIDTH + MED_MARGIN * 2;

export const useLayoutStyles = makeStyles(theme => ({
    // Style that implements the breakpoint paddings
    // Note that the theme spacing units is 8px (the default)
    contentPadding: {
        padding: theme.spacing(3), // 3 x 8px = 24px
        [styleBreakSmallMobile(theme)]: {
            padding: theme.spacing(2), // 2 x 8px = 16px
        },
    },

    contentHorizontalPadding: {
        paddingLeft: theme.spacing(3), // 3 x 8px = 24px
        paddingRight: theme.spacing(3),
        [styleBreakSmallMobile(theme)]: {
            paddingLeft: theme.spacing(2), // 2 x 8px = 16px
            paddingRight: theme.spacing(2),
        },
    },
}));

/**
 * Helper function for makeStyles() to select a desktop breakpoint.
 *
 * Usage:
 *
 * makeStyles((theme)=> ({
 * ...
 *     [styleBreakDesktop(theme)]: {
 *           padding: <desktop specific value>,
 *     },
 */
export function styleBreakDesktop(theme) {
    return theme.breakpoints.up('lg');
}

/**
 * Helper function for makeStyles() to select the mobile breakpoint.
 * This breakpoint includes all sizes of mobile.
 *
 * Usage:
 *
 * makeStyles((theme)=> ({
 * ...
 *     [styleBreakMobile(theme)]: {
 *           padding: <mobile value>,
 *     },
 */
export function styleBreakMobile(theme) {
    return theme.breakpoints.down('md');
}

/**
 * Helper function for makeStyles() to select the smallest mobile breakpoint.
 * This breakpoint is a subset of all mobile breakpoints.
 *
 * Usage:
 *
 * makeStyles((theme)=> ({
 * ...
 *     [styleBreakSmallMobile(theme)]: {
 *           padding: <small mobile specific value>,
 *     },
 */
export function styleBreakSmallMobile(theme) {
    return theme.breakpoints.down('sm');
}
