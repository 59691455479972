import axios from 'axios';

export const getLabReq = documentId => {
    return axios.get(`/api/document/labReq?id=${documentId}`, {
        responseType: 'arraybuffer',
        headers: {
            Accept: 'application/pdf',
        },
    });
};
