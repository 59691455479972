import React from 'react';
import NotLoggedInLayout from './utilities/layouts/NotLoggedInLayout';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styleBreakMobile } from './utilities/layouts/Globals';
import { ReactComponent as LinkBadge } from '../resources/images/link-badge.svg';
import { PATH_LOGIN } from '../containers/RoutePaths';
import { SizeSelector } from './utilities/layouts/SizeSelector';

const useStyles = makeStyles(theme => ({
    contentTitle: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
    linkBadge: {
        marginTop: theme.spacing(0),
    },
    contentBody: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(1),
        },
    },
    resetPasswordLink: {
        cursor: 'pointer',
        color: theme.palette.primary.dark,
        fontWeight: 500,
    },
}));

export default function RestLinkExpiredPage() {
    const classes = useStyles();
    const buttonText = 'Back to Sign In';

    const onButtonClick = () => {
        window.location = PATH_LOGIN;
    };

    const resetLinkClicked = () => {
        window.location = '/patient/login/requestPasswordReset';
    };

    const PasswordLinkText = () => (
        <>
            You can{' '}
            <span className={classes.resetPasswordLink} onClick={resetLinkClicked}>
                request a new password reset link here
            </span>
            .
        </>
    );

    const MobileTextLayout = (
        <Typography variant="body1" component="div" className={classes.contentBody}>
            For your security, password reset links expire after 48 hours. <PasswordLinkText />
        </Typography>
    );

    const DesktopTextLayout = (
        <>
            <Typography variant="body1" component="div" className={classes.contentBody}>
                For your security, password reset links expire after 48 hours.
            </Typography>
            <Typography variant="body1" component="div" className={classes.contentBody} style={{ marginTop: '0px' }}>
                <PasswordLinkText />
            </Typography>
        </>
    );

    const content = (
        <Box display="flex" flexDirection="column" alignItems="center">
            <LinkBadge aria-labelledby="Link badge" className={classes.linkBadge} />
            <Typography variant="h2" component="div" className={classes.contentTitle}>
                The password reset link has expired
            </Typography>
            <SizeSelector mobile={MobileTextLayout} desktop={DesktopTextLayout} />
        </Box>
    );

    return <NotLoggedInLayout onClick={onButtonClick} buttonText={buttonText} content={content} centerLoggedInButton />;
}
