import React, { useEffect, useState } from 'react';
import SetupAccountStep1Welcome from './SetupAccountStep1Welcome.js';
import SetupAccountStep2TermsOfService from './SetupAccountStep2TermsOfService.js';
import SetupAccountStep3Verify from './SetupAccountStep3Verify.js';
import SetupAccountStep4Password from './SetupAccountStep4Password.js';
import SetupAccountStep5SecurityQuestions from './SetupAccountStep5SecurityQuestions.js';
import SetupAccountStep6Complete from './SetupAccountStep6Complete.js';
import PropTypes from 'prop-types';
import AlertModalYesNo from '../utilities/AlertModal/AlertModalYesNo';
import queryString from 'query-string';
import { PATH_HOME } from '../../containers/RoutePaths';
import { persistAccountSetupCurrentPage } from '../../utils/ClientSessionHandler';

/**
 * This is a wizard for the setup account flow.
 */
const SetupAccountWizard = ({
    location,
    setupAccountWizardCurrentPage,
    setCurrentPage,
    displayAccountSetupAlert,
    exitSetupAccountWizardHandler,
    isDisplayAccountSetupAlert,
    dispatchSetSecurityQuestionsAndSubmitAccountCreationInfo,
    setAgreementVersion,
    verifyPatientInformation,
    verificationResponse,
    isAccountSetupSuccessful,
    accountCreationCompleteResponse,
    setVerificationInformation,
    healthCardSystem,
    healthCardNumber,
    dateOfBirth,
    sessionToken,
}) => {
    const NUMBER_WIZARD_PAGES = 7;

    // Use a warapper for verifyPatientInformation, which sets the local state of health card information and dateOfBirth
    const setHealthCardAndDOBAndVerifyPatientInformation = (token, healthCardSystem, healthCardNumber, dateOfBirth, dispatchDataSubmissionResult) => {
        setVerificationInformation(dateOfBirth, healthCardSystem, healthCardNumber);
        return verifyPatientInformation(token, healthCardSystem, healthCardNumber, dateOfBirth, dispatchDataSubmissionResult);
    };

    // For security reasons, manage password locally, and not in redux store
    const [createdPassword, setCreatedPassword] = useState('');

    // For security reasons, manage security questions locally, and not in redux store
    const [securityQuestions, setSecurityQuestions] = useState([
        {
            selectedQuestion: '',
            answer: '',
        },
        {
            selectedQuestion: '',
            answer: '',
        },
        {
            selectedQuestion: '',
            answer: '',
        },
    ]);

    const setSecurityQuestionsAndPasswordThenSubmit = (securityQuestions, dispatchDataSubmissionResult) => {
        const securityQuestionsAndPassword = {};
        securityQuestionsAndPassword.createdPassword = createdPassword;
        securityQuestionsAndPassword.securityQuestions = [{}, {}, {}];

        securityQuestionsAndPassword.securityQuestions[0].selectedQuestion = securityQuestions.selectedQuestions[0];
        securityQuestionsAndPassword.securityQuestions[1].selectedQuestion = securityQuestions.selectedQuestions[1];
        securityQuestionsAndPassword.securityQuestions[2].selectedQuestion = securityQuestions.selectedQuestions[2];
        securityQuestionsAndPassword.securityQuestions[0].answer = securityQuestions.answers[0];
        securityQuestionsAndPassword.securityQuestions[1].answer = securityQuestions.answers[1];
        securityQuestionsAndPassword.securityQuestions[2].answer = securityQuestions.answers[2];

        setSecurityQuestions(securityQuestionsAndPassword.securityQuestions);

        return dispatchSetSecurityQuestionsAndSubmitAccountCreationInfo(securityQuestionsAndPassword, dispatchDataSubmissionResult);
    };

    function saveNewPage(newPage) {
        setCurrentPage(newPage);
        persistAccountSetupCurrentPage(newPage);
    }

    let [token, setToken] = useState('');
    useEffect(() => {
        if (!!location) {
            const queryValues = queryString.parse(location.search);
            setToken(queryValues.token);
        }
    }, [location, setToken]);

    useEffect(() => {
        if (isAccountSetupSuccessful) {
            setCurrentPage(6);
            persistAccountSetupCurrentPage(6);
        }
    }, [isAccountSetupSuccessful, setCurrentPage]);

    const onNextPage = () => {
        if (setupAccountWizardCurrentPage < NUMBER_WIZARD_PAGES) {
            saveNewPage(setupAccountWizardCurrentPage + 1);
        } else {
            console.log('Cannot go to next page as we are currently on : ' + setupAccountWizardCurrentPage);
        }
    };

    const onPreviousPage = () => {
        if (setupAccountWizardCurrentPage > 0) {
            saveNewPage(setupAccountWizardCurrentPage - 1);
        } else {
            console.log('Cannot go to previous page as we are currently on : ' + setupAccountWizardCurrentPage);
        }
    };

    const onCompleteWizard = () => {
        // Load the app to start a clean session for the user
        window.location = PATH_HOME;
    };

    const onCancelWizard = () => {
        displayAccountSetupAlert(true);
    };

    const hideSetupAccountAlert = () => {
        displayAccountSetupAlert(false);
    };

    if (setupAccountWizardCurrentPage === 5 && !createdPassword) {
        // If the current page is 'security questions' page (5) and the
        // password is not set, make the current page the password page (4).
        // This functionality supports refreshing the page while on the 'security
        // questions' page, in which case the user is redirected to the password
        // page to first set their password
        saveNewPage(4);
    }

    return (
        <>
            {/* Here are the pages.  Each gets the current wizard state. */}
            <SetupAccountStep1Welcome currentPage={setupAccountWizardCurrentPage} onNextPage={onNextPage} />

            {/* Below are incomplete pages */}
            <SetupAccountStep2TermsOfService
                currentPage={setupAccountWizardCurrentPage}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                setAgreementVersion={setAgreementVersion}
            />
            <SetupAccountStep3Verify
                token={token}
                currentPage={setupAccountWizardCurrentPage}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                healthCardSystem={healthCardSystem}
                healthCardNumber={healthCardNumber}
                dateOfBirth={dateOfBirth}
                verifyPatientInformation={setHealthCardAndDOBAndVerifyPatientInformation}
                verificationResponse={verificationResponse}
                onCancel={onCancelWizard}
                sessionToken={sessionToken}
            />
            <SetupAccountStep4Password
                currentPage={setupAccountWizardCurrentPage}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                onCancel={onCancelWizard}
                createPasswordHandler={setCreatedPassword}
                password={createdPassword}
            />
            <SetupAccountStep5SecurityQuestions
                currentPage={setupAccountWizardCurrentPage}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                onCancel={onCancelWizard}
                securityQuestions={securityQuestions}
                setSecurityQuestionsAndSubmitAccountCreationInfo={setSecurityQuestionsAndPasswordThenSubmit}
                accountCreationCompleteResponse={accountCreationCompleteResponse}
                isAccountSetupSuccessful={isAccountSetupSuccessful}
            />
            <SetupAccountStep6Complete currentPage={setupAccountWizardCurrentPage} onComplete={onCompleteWizard} />
            <AlertModalYesNo
                isDisplayed={isDisplayAccountSetupAlert}
                alertModalTitle={'Are you Sure?'}
                alertModalBody={'If you leave now, your responses will be lost. You will need to complete your account setup from the start.'}
                alertModalBody2={'Are you sure you want to leave?'}
                onNoSelected={hideSetupAccountAlert}
                onYesSelected={exitSetupAccountWizardHandler}
                onCloseSelected={hideSetupAccountAlert}
            />
        </>
    );
};

/* type checking for props */
SetupAccountWizard.propTypes = {
    setupAccountWizardCurrentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    displayAccountSetupAlert: PropTypes.func.isRequired,
    exitSetupAccountWizardHandler: PropTypes.func.isRequired,
    isDisplayAccountSetupAlert: PropTypes.bool.isRequired,
    dispatchSetSecurityQuestionsAndSubmitAccountCreationInfo: PropTypes.func.isRequired,
    verifyPatientInformation: PropTypes.func.isRequired,
    verificationResponse: PropTypes.object.isRequired,
    isAccountSetupSuccessful: PropTypes.bool.isRequired,
    setVerificationInformation: PropTypes.func.isRequired,
    healthCardSystem: PropTypes.string.isRequired,
    healthCardNumber: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
};

export default SetupAccountWizard;
