import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { PasswordSetup } from '../PasswordSetup';
import StepsLayout from '../utilities/layouts/StepsLayout';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../utilities/Material';
import { styleBreakDesktop, styleBreakMobile, styleBreakSmallMobile } from '../utilities/layouts/Globals';

export const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        marginBottom: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        [styleBreakDesktop(theme)]: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            //negative margins to offset margins set from marginSelector in StepsLayout.js
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
            marginBottom: theme.spacing(12),
            backgroundColor: COLOR_WHITE,
        },
        [styleBreakMobile(theme)]: {
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            //negative margins to offset margins set from marginSelector in StepsLayout.js
            marginLeft: theme.spacing(-3),
            marginRight: theme.spacing(-3),
        },
        [styleBreakSmallMobile(theme)]: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
        },
    },
    headingText: {
        marginTop: theme.spacing(3),
    },
}));

const SetupAccountStep4Password = ({ currentPage, onNextPage, onPreviousPage, onCancel, password, createPasswordHandler }) => {
    const classes = useStyles();
    const formikBagRef = useRef();
    const [submitDisable, setSubmitDisable] = useState(true);

    // Don't draw the component if it is not our page.
    if (currentPage !== 4) {
        return null;
    }

    const processPassword = password => {
        createPasswordHandler(password);
        onNextPage();
    };
    const defaultOnNextClick = () => processPassword(password);

    return (
        <StepsLayout
            onPrevClick={onPreviousPage}
            onCancelClick={onCancel}
            currentCount={2}
            totalCount={3}
            titleText={'Account Setup'}
            nextButtonText={'Next'}
            isNextButtonDisabled={submitDisable}
            id={'Next-button-of-create-password'}
            onNextClick={formikBagRef.current?.submitForm || defaultOnNextClick}
        >
            <Paper elevation={0} className={classes.container}>
                <Box className={classes.headingText}>
                    <Typography variant="h2">Create Password</Typography>
                </Box>
                <PasswordSetup password={password} processPassword={processPassword} formikBagRef={formikBagRef} setSubmitDisable={setSubmitDisable} />
            </Paper>
        </StepsLayout>
    );
};

/* type checking for props */
SetupAccountStep4Password.propTypes = {
    currentPage: PropTypes.number.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onPreviousPage: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    createPasswordHandler: PropTypes.func.isRequired,
    password: PropTypes.string.isRequired,
};

export default SetupAccountStep4Password;
