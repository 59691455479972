import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SecurityQuestion from './SecurityQuestion';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { styleBreakMobile } from './utilities/layouts/Globals';

const QUESTION_1_LIST = ['What high school did you attend?', 'Which was your favourite subject in school?', 'Who was your favourite teacher?'];
const QUESTION_2_LIST = ['Who is your favourite author?', 'Who is your favourite athlete?', 'Who is your favourite movie star?'];
const QUESTION_3_LIST = ['In what country were you born?', "What is your mother's middle name?", 'What is the name of the hospital you were born in?'];

const MAX_LENGTH_ANSWER_ERROR_MESSAGE = 'The maximum length for an answer is 200 characters.';
const REQUIRED_FIELD_MESSAGE = 'This is a required field.';
const securityQuestionsValidation = Yup.object({
    answer1: Yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .max(200, MAX_LENGTH_ANSWER_ERROR_MESSAGE),
    answer2: Yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .max(200, MAX_LENGTH_ANSWER_ERROR_MESSAGE),
    answer3: Yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .max(200, MAX_LENGTH_ANSWER_ERROR_MESSAGE),
    question1: Yup.string().required(),
    question2: Yup.string().required(),
    question3: Yup.string().required(),
});

const useStyles = makeStyles(theme => ({
    questionLabelFirst: {
        marginTop: theme.spacing(4),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(3),
        },
    },
    questionLabel: {
        marginTop: theme.spacing(5),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(4),
        },
    },
}));

const SecurityQuestionsDiv = styled.div`
    margin-top: 10px;
`;

const sanitizeQuestions = (question, questionList) => {
    if (questionList.indexOf(question) < 0) {
        question = '';
    }
    return question;
};

/**
 * Formik security questions form.
 *
 * submitHandler(values, formikBag) : values contained are answer1, answer2, answer3, question1, question2, question3
 */
const SecurityQuestions = ({
    answer1 = '',
    answer2 = '',
    answer3 = '',
    question1 = '',
    question2 = '',
    question3 = '',
    submitHandler,
    formikBagRef,
    setIsFormDirty,
    setIsFormValid,
    bindSubmitForm,
    setSubmitDisable,
}) => {
    const classes = useStyles();

    // Sanitize the questions.
    question1 = sanitizeQuestions(question1, QUESTION_1_LIST);
    question2 = sanitizeQuestions(question2, QUESTION_2_LIST);
    question3 = sanitizeQuestions(question3, QUESTION_3_LIST);

    return (
        <Formik
            initialValues={{ answer1, answer2, answer3, question1, question2, question3 }}
            validationSchema={securityQuestionsValidation}
            onSubmit={submitHandler}
            innerRef={formikBagRef}
            enableReinitialize={true}
        >
            {({ isValid, dirty, submitForm, errors, values }) => {
                //Work around for warning about setting state while rendering another component:
                //https://github.com/formium/formik/issues/1218
                setTimeout(() => {
                    if (!!setIsFormDirty) {
                        setIsFormDirty(dirty);
                    }
                    if (!!setIsFormValid) {
                        setIsFormValid(isValid);
                    }
                }, 0);

                if (!!bindSubmitForm) {
                    bindSubmitForm(submitForm);
                }

                //an alternative to controlling submit button
                const DisableSubmitHandler = ({ values, errors }) => {
                    useEffect(() => {
                        if (setSubmitDisable) {
                            setSubmitDisable(
                                values.question1 === '' ||
                                    values.answer1 === '' ||
                                    values.question2 === '' ||
                                    values.answer2 === '' ||
                                    values.question3 === '' ||
                                    values.answer3 === '' ||
                                    Object.keys(errors).length !== 0
                            );
                        }
                    });

                    return null;
                };

                return (
                    <Form data-testid="securityQuestionsForm">
                        {setSubmitDisable && <DisableSubmitHandler values={values} errors={errors} />}
                        <SecurityQuestionsDiv>
                            <SecurityQuestion securityQuestionIndex="1" questionList={QUESTION_1_LIST} questionLabelClass={classes.questionLabelFirst} />
                            <SecurityQuestion securityQuestionIndex="2" questionList={QUESTION_2_LIST} questionLabelClass={classes.questionLabel} />
                            <SecurityQuestion securityQuestionIndex="3" questionList={QUESTION_3_LIST} questionLabelClass={classes.questionLabel} />
                        </SecurityQuestionsDiv>
                    </Form>
                );
            }}
        </Formik>
    );
};

SecurityQuestions.propTypes = {
    question1: PropTypes.string,
    question2: PropTypes.string,
    question3: PropTypes.string,
    answer1: PropTypes.string,
    answer2: PropTypes.string,
    answer3: PropTypes.string,
    setIsFormDirty: PropTypes.func,
    setIsFormValid: PropTypes.func,
    bindSubmitForm: PropTypes.func,
    formikBagRef: PropTypes.object,
    submitHandler: PropTypes.func.isRequired,
};

export default SecurityQuestions;
