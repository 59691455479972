/* Renders survey question page */
import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AlertModalYesNo from '../utilities/AlertModal/AlertModalYesNo';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../utilities/Material';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import StepsLayout from '../utilities/layouts/StepsLayout';
import clsx from 'clsx';
import {
    discardWellnessAnswers,
    retrieveWellnessQuestionnaire,
    sendWellnessQuestionnaireResponse,
    toggleWellnessAlert,
    updateActiveWellnessAnswer,
    updateCurrentWellnessQuestion,
} from '../../actions';
import { useHistory, Redirect } from 'react-router-dom';
import { PATH_HOME, PATH_WELLNESS_SURVEY_HOME } from '../../containers/RoutePaths';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import DataSubmissionHandler from '../DataSubmissionHandler';
import { useCurrentVisit, useCurrentVisitAPI } from '../../userDataProviders/CurrentVisitProvider';
import { LoadingStatus, LoadingResult } from '../../utils/LoadingUtils';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';
import AllTasksCompletedPage from '../AllTasksCompletedPage';
import { taskCompleted } from '../../utils/VisitTaskHandler';
import * as TaskNames from './TaskNames';
import { persistIsSurveyInitiated } from '../../utils/ClientSessionHandler';
import { setIsSurveyInitiated, sendClientAnalytics } from '../../actions';
import { AuditEventAction, createClientAnalytics } from '../../utils/ClientAnalytics';

const useStyles = makeStyles(theme => ({
    questionContainer: {
        justifyContent: 'flex-start',
        margin: '0 auto',
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        height: '100%',
        [theme.breakpoints.up(theme.breakpoints.values['lg'])]: {
            margin: theme.spacing(0),
            backgroundColor: COLOR_WHITE,
            padding: theme.spacing(4),
        },
    },
    surveyQuestion: {
        marginBottom: theme.spacing(1),
    },
    gridItemMargin: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up(theme.breakpoints.values['lg'])]: {
            marginBottom: theme.spacing(5),
        },
    },
    choiceButton: {
        width: '100%',
        background: 'inherit',
        borderRadius: '5px',
        border: '1px solid rgba(80, 106, 126, 0.5)',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    choiceSelected: {
        background: '#eeedfc',
        border: '1px solid #666ca1',
        '&&:hover': {
            background: '#eeedfc',
        },
    },
    label: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.body2.fontWeight,
        color: '#39434F',
        justifyContent: 'left',
        lineHeight: '150%',
        textAlign: 'left',
    },
    questionText: {
        marginRight: theme.spacing(0),
        [theme.breakpoints.up(theme.breakpoints.values['lg'])]: {
            marginRight: theme.spacing(21),
        },
    },
    outlinedPrimary: {
        height: 'fit-content',
    },
}));

function beforeUnloadListener(event) {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
        e.returnValue = ''; // Legacy method for cross browser support
    }
    return ''; // Legacy method for cross browser support
}

function registerBeforeUnloadListener() {
    // Adding this listener will prompt the user when trying to exit the page,
    // if there is some user input data that could be lost upon exit
    window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
}

function unregisterBeforeUnloadListener() {
    // unregister the listener for beforeunload event
    window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
}

export const WellnessSurveyQuestion = ({
    wellnessSurvey,
    prevClickHandler,
    cancelClickHandler,
    nextClickHandler,
    selectedAnswerHandler,
    stayOnWellnessSurvey,
    exitWellnessSurvey,
    fetchWellnessSurveyQuestionnaire,
    isLoadingWellnessSurveyQuestionnaire,
    wellnessSurveyQuestionnaireLoadingResult,
    isLoadingVisit,
    visitLoadingResult,
    isSurveyInitiated,
}) => {
    const history = useHistory();
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const [showExitPrompt, setShowExitPrompt] = useState(false);
    const currentVisit = useCurrentVisit();
    const { fetchCurrentVisit } = useCurrentVisitAPI();
    const dispatch = useDispatch();

    const questions = wellnessSurvey.questions;
    const currQuestionIndex = wellnessSurvey.currQuestionIndex;
    const displayWellnessSurveyAlert = wellnessSurvey.displayWellnessSurveyAlert;

    const question = questions[currQuestionIndex];
    const nextText = question.next === null ? 'Send to my Doctor' : 'Next';
    const currentCount = currQuestionIndex + 1;
    const totalCount = questions.length;
    const questionTitle = question.title;
    const questionText = question.text;
    const answers = question.answers;
    const activeAnswerLinkId = question.activeAnswerLinkId;

    useEffect(() => {
        if (!currentVisit.visit) {
            fetchCurrentVisit();
        }
    }, [fetchCurrentVisit, currentVisit]);

    useEffect(() => {
        if (!wellnessSurvey.questionnaireRetrieved) {
            fetchWellnessSurveyQuestionnaire();
        }
    }, [fetchWellnessSurveyQuestionnaire, wellnessSurvey.questionnaireRetrieved]);

    useEffect(() => {
        if (showExitPrompt) {
            registerBeforeUnloadListener();
            // Specify how to clean up after this effect:
            return function cleanUpShowExitPromptFunctionality() {
                // unregister the listener for beforeunload event
                unregisterBeforeUnloadListener();
                setShowExitPrompt(false);
            };
        }
    }, [showExitPrompt, setShowExitPrompt]);

    useEffect(() => {
        if (isSurveyInitiated) {
            return () => {
                persistIsSurveyInitiated(false);
            };
        }
    });

    const surveySubmissionSuccess = () => {
        // Discard survey answers
        dispatch(discardWellnessAnswers());
        dispatch(setIsSurveyInitiated(false));
    };

    const onCardClick = e => {
        let value;
        /* since material ui button uses html span element inside the html button,
        a user could click either of the two elements.
        */
        if (e.target.value === undefined) {
            value = e.target.parentNode.value;
        } else {
            value = e.target.value;
        }
        selectedAnswerHandler(value);

        // Since there is some user input data on the page, set show exit prompt
        setShowExitPrompt(true);
    };

    const onPrevClick = () => {
        prevClickHandler(question.prev === null, currQuestionIndex - 1);
    };

    const goToParentRoute = () => {
        history.push(PATH_WELLNESS_SURVEY_HOME);
    };

    const onNextClick = () => {
        nextClickHandler(question.next === null, currQuestionIndex + 1, setSubmitting);
    };

    const onExitWellnessSurveyQuestion = () => {
        goToParentRoute();
        exitWellnessSurvey();
        dispatch(setIsSurveyInitiated(false));
        const cancelSurveyAnalytics = createClientAnalytics(AuditEventAction.CANCEL_SURVEY.category, AuditEventAction.CANCEL_SURVEY.action);
        dispatch(sendClientAnalytics(cancelSurveyAnalytics));
    };

    const surveySubmissionFailureTitle = 'Submission Error';
    const surveySubmissionFailureBody =
        "We're having trouble submitting your survey responses. Your computer might be offline or the Ned server may be experiencing problems. Please try again.";

    // If this questionnaire task is completed, but the visit is not completed, we bump the user to the home screen.
    if (currentVisit.isAllTasksRecentlyCompleted) {
        return <AllTasksCompletedPage />;
    } else if (taskCompleted(currentVisit.visit, TaskNames.WELLNESS_SURVEY) && !currentVisit.isAllTasksRecentlyCompleted) {
        return <Redirect to={PATH_HOME} />;
    } else if (!isSurveyInitiated) {
        // To fill the wellness survey, user must go through the wellness survey home page
        return <Redirect to={PATH_WELLNESS_SURVEY_HOME} />;
    } else if (isLoadingVisit || isLoadingWellnessSurveyQuestionnaire) {
        return <IsLoadingPage />;
    } else {
        if (visitLoadingResult === LoadingResult.FAILED || wellnessSurveyQuestionnaireLoadingResult === LoadingResult.FAILED) {
            return <LoadingErrorPage />;
        } else {
            return (
                <RequiresLogin>
                    <StepsLayout
                        onPrevClick={question.prev !== null ? onPrevClick : null}
                        onCancelClick={cancelClickHandler}
                        currentCount={currentCount}
                        totalCount={totalCount}
                        titleText={'Wellness Survey'}
                        nextButtonText={nextText}
                        isNextButtonDisabled={!(activeAnswerLinkId !== null)}
                        id={'Next-button-of-wellness-survey'}
                        onNextClick={onNextClick}
                        submitting={submitting}
                    >
                        <Grid container direction="column" wrap="nowrap" className={classes.questionContainer}>
                            <Grid item className={classes.gridItemMargin}>
                                <Typography variant="h2" component="div" className={classes.surveyQuestion}>
                                    {questionTitle}
                                </Typography>
                                <Typography variant="body1" component="div" className={`${classes.surveyQuestion} ${classes.questionText}`}>
                                    {questionText}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <WellnessSurveyMultiChoice
                                    answers={answers}
                                    onCardClick={onCardClick}
                                    question={question}
                                    activeAnswerLinkId={activeAnswerLinkId}
                                />
                                <AlertModalYesNo
                                    isDisplayed={displayWellnessSurveyAlert}
                                    alertModalTitle={'Are you Sure?'}
                                    alertModalBody={
                                        'If you leave now, your responses will be lost. You will need to complete the survey from start when you return.'
                                    }
                                    alertModalBody2={'Are you sure you want to leave?'}
                                    onNoSelected={stayOnWellnessSurvey}
                                    onYesSelected={onExitWellnessSurveyQuestion}
                                    onCloseSelected={stayOnWellnessSurvey}
                                    datatestid="cancelSurveyButton"
                                />
                            </Grid>
                        </Grid>
                    </StepsLayout>
                    <DataSubmissionHandler
                        setSubmitting={setSubmitting}
                        submitting={submitting}
                        actionCreator={dispatchDataSubmissionResult => {
                            return sendWellnessQuestionnaireResponse(dispatchDataSubmissionResult, true);
                        }}
                        failureMsgTitle={surveySubmissionFailureTitle}
                        failureMsgBody={surveySubmissionFailureBody}
                        submissionSuccessHandler={surveySubmissionSuccess}
                    />
                </RequiresLogin>
            );
        }
    }
};

/* utility components */
const WellnessSurveyMultiChoice = ({ answers, onCardClick, question, activeAnswerLinkId }) => {
    const classes = useStyles();

    return (
        <>
            {answers.map(answer => (
                <Button
                    variant="outlined"
                    onClick={onCardClick}
                    value={answer.linkId}
                    key={question.linkId.toString() + answer.linkId.toString()}
                    className={clsx(classes.choiceButton, {
                        [classes.choiceSelected]: answer.linkId === activeAnswerLinkId,
                    })}
                    classes={{
                        label: classes.label,
                        outlinedPrimary: classes.outlinedPrimary,
                    }}
                >
                    {answer.text}
                </Button>
            ))}
        </>
    );
};

const mapStateToProps = state => {
    return {
        wellnessSurvey: state.wellnessSurvey,
        isLoadingWellnessSurveyQuestionnaire: state?.dataLoading?.wellnessSurveyQuestionnaire?.loading !== LoadingStatus.ENDED,
        wellnessSurveyQuestionnaireLoadingResult: state?.dataLoading?.wellnessSurveyQuestionnaire?.loadingResult,
        isLoadingVisit: state?.dataLoading?.visit?.loading !== LoadingStatus.ENDED,
        visitLoadingResult: state?.dataLoading?.visit?.loadingResult,
        isSurveyInitiated: state?.wellnessSurvey?.isSurveyInitiated,
    };
};

const mapDispatchToProps = dispatch => {
    const nextClickHandler = (isLast, nextQuestionIndex, setSubmitting) => {
        if (isLast) {
            setSubmitting(true);
        } else {
            //dispatch to store to update current question index to next index
            dispatch(updateCurrentWellnessQuestion(nextQuestionIndex));
        }
    };

    const prevClickHandler = (isFirst, prevQuestionIndex) => {
        if (isFirst) {
            dispatch(discardWellnessAnswers());
        } else {
            //dispatch to store to update current question index to prev index
            dispatch(updateCurrentWellnessQuestion(prevQuestionIndex));
        }
    };

    const selectedAnswerHandler = linkId => {
        dispatch(updateActiveWellnessAnswer(linkId));
    };

    const cancelClickHandler = () => {
        dispatch(toggleWellnessAlert());
    };

    const stayOnWellnessSurvey = () => {
        dispatch(toggleWellnessAlert());
    };

    const exitWellnessSurvey = () => {
        dispatch(toggleWellnessAlert());
        dispatch(discardWellnessAnswers());
    };

    const fetchWellnessSurveyQuestionnaire = () => {
        dispatch(retrieveWellnessQuestionnaire());
    };

    return {
        nextClickHandler,
        prevClickHandler,
        cancelClickHandler,
        selectedAnswerHandler,
        stayOnWellnessSurvey,
        exitWellnessSurvey,
        fetchWellnessSurveyQuestionnaire,
    };
};

/* type checking for props */
WellnessSurveyQuestion.propTypes = {
    nextClickHandler: PropTypes.func.isRequired,
    prevClickHandler: PropTypes.func.isRequired,
    cancelClickHandler: PropTypes.func.isRequired,
    selectedAnswerHandler: PropTypes.func.isRequired,
    stayOnWellnessSurvey: PropTypes.func.isRequired,
    exitWellnessSurvey: PropTypes.func.isRequired,
    wellnessSurvey: PropTypes.object.isRequired,
    fetchWellnessSurveyQuestionnaire: PropTypes.func.isRequired,
    isSurveyInitiated: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WellnessSurveyQuestion);
