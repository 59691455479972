import axios from 'axios';

const pathRoot = '/api/securityQuestions';

export const updateSecurityQuestions = (question1, answer1, question2, answer2, question3, answer3) => {
    return axios.put(pathRoot, {
        securityQuestion1: {
            question: question1,
            answer: answer1,
        },
        securityQuestion2: {
            question: question2,
            answer: answer2,
        },
        securityQuestion3: {
            question: question3,
            answer: answer3,
        },
    });
};
