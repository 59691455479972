import React from 'react';
import { Select } from 'formik-material-ui';
import useTheme from '@material-ui/core/styles/useTheme';
import { makeStyles, Typography } from '@material-ui/core';
import { styleBreakDesktop } from './utilities/layouts/Globals';
import MenuItem from '@material-ui/core/MenuItem';
import { Field } from 'formik';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    healthCardSystemDropDownSelect: {
        width: '100%',
        [styleBreakDesktop(theme)]: {
            width: theme.spacing(41),
        },
    },
    monthSelector: {
        width: theme.spacing(16.5),
    },
    paperStyle: {
        maxHeight: theme.spacing(18),
    },
    italize: {
        fontStyle: 'italic',
    },
    span: {
        fontWeight: '500',
        color: '#212121',
    },
}));

const Span = props => {
    const classes = useStyles();

    return <Typography component="span" className={classes.span} {...props} />;
};

const StyledSelect = props => {
    const classes = useStyles();

    const menuProps = {
        classes: { paper: classes.paperStyle },
    };

    if (props.dropDownBelowSelect) {
        menuProps.anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'left',
        };
        menuProps.getContentAnchorEl = null;
    }
    return <Select MenuProps={menuProps} {...props} />;
};

export const MonthSelectorField = props => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <FormControl variant="outlined">
            <Field component={StyledSelect} theme={theme} {...props} required displayEmpty={true} className={classes.monthSelector}>
                <MenuItem value="" disabled={true} key="month_heading">
                    <Typography component={Span} className={classes.italize}>
                        Month
                    </Typography>
                </MenuItem>
                <MenuItem value="0" key="month0">
                    <Span>January</Span>
                </MenuItem>
                <MenuItem value="1" key="month1">
                    <Span>February</Span>
                </MenuItem>
                <MenuItem value="2" key="month2">
                    <Span>March</Span>
                </MenuItem>
                <MenuItem value="3" key="month3">
                    <Span>April</Span>
                </MenuItem>
                <MenuItem value="4" key="month4">
                    <Span>May</Span>
                </MenuItem>
                <MenuItem value="5" key="month5">
                    <Span>June</Span>
                </MenuItem>
                <MenuItem value="6" key="month6">
                    <Span>July</Span>
                </MenuItem>
                <MenuItem value="7" key="month7">
                    <Span>August</Span>
                </MenuItem>
                <MenuItem value="8" key="month8">
                    <Span>September</Span>
                </MenuItem>
                <MenuItem value="9" key="month9">
                    <Span>October</Span>
                </MenuItem>
                <MenuItem value="10" key="month10">
                    <Span>November</Span>
                </MenuItem>
                <MenuItem value="11" key="month11">
                    <Span>December</Span>
                </MenuItem>
            </Field>
        </FormControl>
    );
};

export const HealthCardSystemSelectorField = props => {
    const theme = useTheme();
    const classes = useStyles();

    return (
        <FormControl variant="outlined" fullWidth={true}>
            <Field
                component={StyledSelect}
                className={classes.healthCardSystemDropDownSelect}
                theme={theme}
                {...props}
                dropDownBelowSelect={true}
                required
                displayEmpty={true}
            >
                <MenuItem value="" disabled={true} key="province_title">
                    <Typography component={Span} className={classes.italize}>
                        Select a province
                    </Typography>
                </MenuItem>
                <MenuItem value={HealthCardSystem.ONTARIO} key="ontario">
                    <Span>Ontario</Span>
                </MenuItem>
                <MenuItem value={HealthCardSystem.ALBERTA} key="alberta">
                    <Span>Alberta</Span>
                </MenuItem>
                <MenuItem value={HealthCardSystem.NOVA_SCOTIA} key="nova_scotia">
                    <Span> Nova Scotia</Span>
                </MenuItem>
            </Field>
        </FormControl>
    );
};

export const HealthCardSystem = {
    ALBERTA: 'ALBERTA',
    NOVA_SCOTIA: 'NOVA_SCOTIA',
    ONTARIO: 'ONTARIO',
};
