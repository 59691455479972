import React from 'react';
import { Container, Box } from '@material-ui/core';
import { useLayoutStyles, LAYOUT_MIN_WIDTH, LAYOUT_MAX_WIDTH } from './Globals';

/**
 * Implements padding and max widths for page content.
 * Usage:
 * ...
 *    <NavBar />
 *    <ContentLayout>
 *    ... children subject to padding and screen widths here
 *    </ContentLayout>
 * ...
 */
export function ContentLayout({ children, ...props }) {
    const styles = useLayoutStyles();
    return (
        <Box component={Container} minWidth={LAYOUT_MIN_WIDTH} maxWidth={LAYOUT_MAX_WIDTH} className={styles.contentHorizontalPadding} {...props}>
            {children}
        </Box>
    );
}
