/* renders surveys page */
import React from 'react';
import MainLayout from '../utilities/layouts/MainLayout';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardItem, CardList, EmptyItem } from '../utilities/commonUtilities/CardItem';
import { ReactComponent as SymptomImage } from '../../resources/images/symptom-badge.svg';
import { PageTitleBlock } from '../utilities/commonUtilities/GenericDivElements';
import { Typography } from '@material-ui/core';
import { ReactComponent as SurveysEmpty } from '../../resources/images/survey-empty.svg';
import { PATH_SURVEY_RESPONSES } from '../../containers/RoutePaths';
import SurveyResponseLoader from './SurveyResponseLoader';
import { LoadingResult, LoadingStatus } from '../../utils/LoadingUtils';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';

const SurveysPage = ({
    isLoadingCompletedSurveys,
    completedSurveysLoadingResult,
    isLoadingQuestionnaire,
    questionnaireLoadingResult,
    completedSurveys = [],
}) => {
    return (
        <MainLayout>
            <SurveyResponseLoader>
                {isLoadingCompletedSurveys || isLoadingQuestionnaire ? (
                    <IsLoadingPage noNavBar={true} />
                ) : completedSurveysLoadingResult === LoadingResult.FAILED || questionnaireLoadingResult === LoadingResult.FAILED ? (
                    <LoadingErrorPage noNavBar={true} />
                ) : (
                    <>
                        {completedSurveys.length > 0 && (
                            <PageTitleBlock>
                                <Typography variant="h1">Surveys</Typography>
                            </PageTitleBlock>
                        )}
                        <CompletedSurveyCards completedSurveys={completedSurveys} />
                    </>
                )}
            </SurveyResponseLoader>
        </MainLayout>
    );
};

function CompletedSurveyCards({ completedSurveys = [] }) {
    return completedSurveys.length > 0 ? (
        <CardList>
            {completedSurveys.map(item => {
                return <SurveyCard key={item.id} survey={item} />;
            })}
        </CardList>
    ) : (
        <EmptyItem image={SurveysEmpty} title="No Surveys Found" message="Your completed surveys will show up here." />
    );
}

function SurveyCard({ survey }) {
    const history = useHistory();
    return (
        <CardItem
            Badge={SymptomImage}
            title="Wellness Survey"
            subtitle={`Completed on ${survey.authored}`}
            onClick={() => handleCardClick(survey.id, history)}
        />
    );
}

function handleCardClick(surveyId, history) {
    history.push({
        pathname: PATH_SURVEY_RESPONSES.concat(surveyId),
    });
}

const mapStateToProps = state => {
    return {
        completedSurveys: state.completedSurveys.surveys,
        isLoadingCompletedSurveys: state.completedSurveys?.loading !== LoadingStatus.ENDED,
        completedSurveysLoadingResult: state.completedSurveys?.loadingResult,
        isLoadingQuestionnaire: state?.dataLoading?.wellnessSurveyQuestionnaire?.loading !== LoadingStatus.ENDED,
        questionnaireLoadingResult: state?.dataLoading?.wellnessSurveyQuestionnaire?.loadingResult,
    };
};

export default connect(mapStateToProps)(SurveysPage);
