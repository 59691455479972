import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../utilities/Material';
import { ReactComponent as BackIcon } from '../../resources/images/back.svg';
import Typography from '@material-ui/core/Typography';
import { SizeSelector } from '../utilities/layouts/SizeSelector';
import { IconButton } from '@material-ui/core';
import { styleBreakMobile } from '../utilities/layouts/Globals';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import { getPatientNedUserInfo } from '../../apis/patient';
import { connect } from 'react-redux';
import { retrievePatientInfoFromFhir, putNedUserInfoIntoStore, retrieveNedUserInfo, loadNedUserInfo, setNedUserInfoLoadingResult } from '../../actions';
import { LoadingStatus, LoadingResult } from '../../utils/LoadingUtils';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../actions/actionTypes';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';

const useStyles = makeStyles(theme => ({
    mobileNavBar: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        background: COLOR_WHITE,
        boxShadow: '0px 2px 3px rgba(192, 192, 192, 0.25)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: theme.spacing(0),
    },
    backButton: {
        position: 'fixed',
        left: theme.spacing(3),
    },
    detailsCard: {
        background: COLOR_WHITE,
        marginTop: theme.spacing(5),
        [styleBreakMobile(theme)]: {
            marginTop: '92px',
        },
    },
    footNoteText: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
    },
    column1: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(0),
        minWidth: theme.spacing(18),
    },
    column2: {
        [styleBreakMobile(theme)]: {
            whiteSpace: 'normal',
            wordBreak: 'break-word',
        },
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(0),
    },
}));

function AccountDetailsMobileNavBar({ onPrevClick, title }) {
    const classes = useStyles();
    return (
        <Box position="fixed" width="100%" className={`${classes.mobileNavBar}`}>
            <IconButton aria-label="back arrow" edge="start" onClick={onPrevClick} className={classes.backButton}>
                <BackIcon />
            </IconButton>
            <Typography variant="subtitle1">{title}</Typography>
        </Box>
    );
}

function AccountDetailsMobilePage({ onPrevClick, title, name, dateOfBirth, email }) {
    return (
        <>
            <AccountDetailsMobileNavBar onPrevClick={onPrevClick} title={title} />
            <AccountDetailsPageContent name={name} dateOfBirth={dateOfBirth} email={email} />
        </>
    );
}

function AccountDetailsPageContent({ name, dateOfBirth, email }) {
    const classes = useStyles();

    const accountDetailsRows = [
        {
            detailName: 'Name',
            detailValue: name,
        },
        {
            detailName: 'Date of Birth',
            detailValue: dateOfBirth,
        },
        {
            detailName: 'Email',
            detailValue: email,
        },
    ];

    const AccountDetailsTable = ({ rows }) => (
        <Box className={classes.detailsCard}>
            <Divider />
            <Table aria-label="account details table">
                <TableBody>
                    {rows.map((row, indx) => (
                        <TableRow key={indx}>
                            <TableCell component="th" scope="row" className={classes.column1}>
                                {row.detailName}
                            </TableCell>
                            <TableCell align="right" className={classes.column2}>
                                {row.detailValue}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );

    return (
        <>
            <AccountDetailsTable rows={accountDetailsRows} />
            <Typography variant="body1" className={classes.footNoteText}>
                If any of the above information is incorrect, please contact the Ned Clinic at{' '}
                <Typography component="span" variant="body2">
                    support@nedclinic.ca
                </Typography>
            </Typography>
        </>
    );
}

function ProfileAccountDetailsPage({
    patientId,
    fhirpatientInfo,
    nedUserInfo,
    fetchNedUserInfo,
    retrievePatientInfo,
    history,
    isLoadingFhirPatientInfo,
    fhirPatientInfoLoadingResult,
    isLoadingNedUserInfo,
    nedUserInfoLoadingResult,
}) {
    useEffect(() => {
        if (!fhirpatientInfo) {
            retrievePatientInfo(patientId);
        }
    }, [patientId, retrievePatientInfo, fhirpatientInfo]);

    useEffect(() => {
        if (!nedUserInfo) {
            fetchNedUserInfo();
        }
    }, [fetchNedUserInfo, nedUserInfo]);

    const title = 'Account Details';
    const onPrevClick = () => {
        history.goBack();
    };

    if (isLoadingFhirPatientInfo || isLoadingNedUserInfo) {
        return <IsLoadingPage />;
    } else {
        if (fhirPatientInfoLoadingResult === LoadingResult.FAILED || nedUserInfoLoadingResult === LoadingResult.FAILED) {
            return <LoadingErrorPage />;
        } else {
            let patientName = fhirpatientInfo?.name?.firstName;
            if (fhirpatientInfo?.name?.middleName) {
                patientName += ' ' + fhirpatientInfo?.name?.middleName;
            }
            patientName += ' ' + fhirpatientInfo?.name?.familyName;
            const patientDateOfBirth = fhirpatientInfo.birthDate;
            const patientEmail = nedUserInfo.emailAddress;
            const Mobile = (
                <AccountDetailsMobilePage onPrevClick={onPrevClick} title={title} name={patientName} dateOfBirth={patientDateOfBirth} email={patientEmail} />
            );
            const Desktop = (
                <DetailsLayout title={title} onClick={onPrevClick} noMobileHeader>
                    <ContentLayout>
                        <AccountDetailsPageContent name={patientName} dateOfBirth={patientDateOfBirth} email={patientEmail} />
                    </ContentLayout>
                </DetailsLayout>
            );
            return <SizeSelector mobile={Mobile} desktop={Desktop} />;
        }
    }
}

/** connect to store */
const mapStateToProps = ({ login, patientAccountDetails, dataLoading }) => ({
    patientId: login && login.session && login.session.patientId,
    fhirpatientInfo: patientAccountDetails?.fhirPatientInfo,
    nedUserInfo: patientAccountDetails?.nedUserInfo,
    isLoadingFhirPatientInfo: dataLoading?.fhirPatientInfo?.loading !== LoadingStatus.ENDED,
    fhirPatientInfoLoadingResult: dataLoading?.fhirPatientInfo?.loadingResult,
    isLoadingNedUserInfo: dataLoading?.nedUserInfo?.loading !== LoadingStatus.ENDED,
    nedUserInfoLoadingResult: dataLoading?.nedUserInfo?.loadingResult,
});

const retrievePatientInfo = patientId => retrievePatientInfoFromFhir(patientId);

const mapDispatchToProps = {
    fetchNedUserInfo: retrieveNedUserInfo,
    retrievePatientInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccountDetailsPage);

/* api layer */
export function* retrieveNedUserInfoSaga() {
    yield put(loadNedUserInfo(LoadingStatus.STARTED));
    try {
        const response = yield call(getPatientNedUserInfo);
        yield put(putNedUserInfoIntoStore(response.data.user));
        yield put(setNedUserInfoLoadingResult(LoadingResult.SUCCESSFUL));
    } catch (error) {
        yield put(setNedUserInfoLoadingResult(LoadingResult.FAILED));
        console.log(error);
    }
    yield put(loadNedUserInfo(LoadingStatus.ENDED));
}

export function* watchNedUserInfoRetrieve() {
    yield takeLatest(actions.NED_USER_INFO_RETRIEVE, retrieveNedUserInfoSaga);
}
