import React from 'react';
import styled from 'styled-components';
import { FlexRowTaskCardA, FlexRowTaskCardB, FlexRowTaskCardC } from './Layout';
import { PointerMedBody2, MedBody3, MedBody1, MedBody3A, MedBody1B, SmallBody1A } from './GenericDivElements';
import { Heading2 } from './Typography';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export const TodoTaskCard = ({ taskDone, taskBody, taskTitle, className, todoLink, todoLinkIcon, todoLinkIconId, TodotaskCardType, disableTodoLink }) => {
    const history = useHistory();

    const onClick = () => {
        history.push(todoLink);
    };

    const TodoLinkWrapper = props => {
        return props.render(todoLinkIconId);
    };

    const content = (
        <>
            <div>
                {taskDone && TodotaskCardType !== 'A' ? <MedBody3A>{taskTitle}</MedBody3A> : <MedBody3>{taskTitle}</MedBody3>}
                {taskDone && TodotaskCardType !== 'A' ? <MedBody1B>{taskBody}</MedBody1B> : <MedBody1>{taskBody}</MedBody1>}
            </div>
            <TodoLinkWrapper
                render={id => {
                    let props = {
                        onClick,
                        disabled: disableTodoLink,
                    };
                    props = id ? { ...props, id } : props;
                    return <PointerMedBody2 {...props}>{todoLinkIcon}</PointerMedBody2>;
                }}
            />
        </>
    );

    if (TodotaskCardType === 'A') {
        return (
            <FlexRowTaskCardA className={className} taskDone={taskDone}>
                {content}
            </FlexRowTaskCardA>
        );
    } else if (TodotaskCardType === 'B') {
        return <FlexRowTaskCardB className={className}>{content}</FlexRowTaskCardB>;
    } else {
        return <FlexRowTaskCardC className={className}>{content}</FlexRowTaskCardC>;
    }
};

export const TaskSummaryCard = ({ title, taskDueDate }) => {
    const { processedDueDate, timeDiff } = processDueDate(taskDueDate);
    return (
        <FlexRowTaskCardC>
            <div>
                <Heading2>{title}</Heading2>
                <MedBody1>{`Due ${processedDueDate}`}</MedBody1>
            </div>
            <SmallBody1A>{timeDiff}</SmallBody1A>
        </FlexRowTaskCardC>
    );
};

export const ToDoTaskCardWithMarginA = styled(TodoTaskCard)`
    margin-top: 37px;
`;

export const TaskBoard = styled.div`
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(179, 179, 179, 0.5);
    border-radius: 3px;
    margin-top: 26px;
`;

/* utility function */
export function processDueDate(dueDate, nowDate) {
    const dueDateObj = moment(dueDate, moment.ISO_8601);
    const nowDateObj = moment(nowDate || moment(), moment.ISO_8601);
    let diffDate;

    if (dueDateObj.isBefore(nowDateObj)) {
        diffDate = `${nowDateObj.startOf('day').from(dueDateObj.startOf('day'), true)} overdue`;
        if (diffDate === 'a day overdue') {
            diffDate = '1 day overdue';
        }
    } else {
        diffDate = `${dueDateObj.endOf('day').from(nowDateObj.startOf('day'), true)} left`;
        if (diffDate === 'a day left') {
            diffDate = 'last day';
        }
    }

    return {
        processedDueDate: dueDateObj.format('MMM D, YYYY'),
        timeDiff: diffDate,
    };
}

export function computeProgressPercent(submittedDateObj, expectedRespDateObj) {
    const nowObj = moment(moment(), moment.ISO_8601);

    const interval = expectedRespDateObj.diff(submittedDateObj);
    const elapsed = nowObj.diff(submittedDateObj);
    const fraction = (elapsed / interval) * 100;

    return Math.min(100, Math.max(0, fraction));
}
