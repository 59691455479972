import React from 'react';
import animatedCheckMark from '../resources/images/animated_check_mark.gif';
import FullScreenButtonPage from './FullScreenButtonPage';
import { useHistory } from 'react-router-dom';
import { PATH_HOME } from '../containers/RoutePaths';
import { setCurrentVisitRecentlyCompletedTasks } from '../actions';
import { useDispatch } from 'react-redux';

export default function AllTasksCompletedPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <FullScreenButtonPage
            isLoginRequired={true}
            Icon={<img src={animatedCheckMark} alt="done" width="90" height="90" />}
            subtitleText={'Great Job!'}
            bodyText={'Your tasks are complete. We have sent your lab results and survey responses to your doctor for review.'}
            buttonText={'Continue'}
            onButtonClick={() => {
                dispatch(setCurrentVisitRecentlyCompletedTasks(false));
                history.push(PATH_HOME);
            }}
        />
    );
}
