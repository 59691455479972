/* Renders the wellness survey home page */

import React, { useEffect } from 'react';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { taskCompleted } from '../../utils/VisitTaskHandler';
import * as TaskNames from '../home/TaskNames';
import { CardItem } from '../utilities/commonUtilities/CardItem';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { retrieveWellnessQuestionnaire } from '../../actions';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import { PATH_WELLNESS_SURVEY_QUESTION, PATH_HOME } from '../../containers/RoutePaths';
import { useCurrentVisit, useCurrentVisitAPI } from '../../userDataProviders/CurrentVisitProvider';
import { LoadingStatus, LoadingResult } from '../../utils/LoadingUtils';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';
import { setIsSurveyInitiated, sendClientAnalytics } from '../../actions';
import { persistIsSurveyInitiated } from '../../utils/ClientSessionHandler';
import { AuditEventAction, createClientAnalytics } from '../../utils/ClientAnalytics';

const useStyles = makeStyles(theme => ({
    wellnessSurveyCard: {
        background: theme.palette.action.main,
        color: theme.palette.action.contrastText,
        marginTop: theme.spacing(4),
        boxShadow: 'none',
    },
    wellnessSurveyCardDone: {
        background: theme.palette.action.disabled,
        color: theme.palette.action.disabledContrastText,
        marginTop: theme.spacing(4),
        boxShadow: 'none',
    },
}));

export const WellnessSurveyHome = ({
    wellnessSurvey,
    fetchWellnessSurveyQuestionnaire,
    isLoadingWellnessSurveyQuestionnaire,
    wellnessSurveyQuestionnaireLoadingResult,
    isLoadingVisit,
    visitLoadingResult,
}) => {
    const styling = useStyles();
    const currentVisit = useCurrentVisit();
    const { fetchCurrentVisit } = useCurrentVisitAPI();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!currentVisit.visit) {
            fetchCurrentVisit();
        }
    }, [fetchCurrentVisit, currentVisit]);

    useEffect(() => {
        if (!wellnessSurvey.questionnaireRetrieved) {
            fetchWellnessSurveyQuestionnaire();
        }
    }, [fetchWellnessSurveyQuestionnaire, wellnessSurvey.questionnaireRetrieved]);

    if (isLoadingVisit || isLoadingWellnessSurveyQuestionnaire) {
        return <IsLoadingPage />;
    } else {
        if (visitLoadingResult === LoadingResult.FAILED || wellnessSurveyQuestionnaireLoadingResult === LoadingResult.FAILED) {
            return <LoadingErrorPage />;
        } else {
            const wellnessSurveyTaskCompleted = !!(currentVisit.visit && taskCompleted(currentVisit.visit, TaskNames.WELLNESS_SURVEY));
            const totalQuestionCount = wellnessSurvey.questions.length;
            const onCardClick = () => {
                if (!wellnessSurveyTaskCompleted) {
                    dispatch(setIsSurveyInitiated(true));
                    persistIsSurveyInitiated(true);
                    const openSurveyAnalytics = createClientAnalytics(AuditEventAction.OPEN_SURVEY.category, AuditEventAction.OPEN_SURVEY.action);
                    dispatch(sendClientAnalytics(openSurveyAnalytics));
                    window.location = PATH_WELLNESS_SURVEY_QUESTION;
                }
            };

            return (
                <RequiresLogin>
                    <DetailsLayout title={'Record your symptoms'} backTo={PATH_HOME}>
                        <ContentLayout pt={2}>
                            <Box>
                                <Typography variant="caption">
                                    Please let your doctor know how you have been feeling over the last 4 weeks. Your answers will help your doctor to assess
                                    your progress and decide if you will need a video visit.
                                </Typography>
                                <CardItem
                                    className={!wellnessSurveyTaskCompleted ? styling.wellnessSurveyCard : styling.wellnessSurveyCardDone}
                                    title="Wellness survey"
                                    subtitle={`${totalQuestionCount} questions`}
                                    onClick={onCardClick}
                                    actionText={wellnessSurveyTaskCompleted ? 'Done' : 'Start now'}
                                    datatestid="startNowCard"
                                />
                            </Box>
                        </ContentLayout>
                    </DetailsLayout>
                </RequiresLogin>
            );
        }
    }
};

const mapStateToProps = state => {
    return {
        wellnessSurvey: state.wellnessSurvey,
        isLoadingWellnessSurveyQuestionnaire: state?.dataLoading?.wellnessSurveyQuestionnaire?.loading !== LoadingStatus.ENDED,
        wellnessSurveyQuestionnaireLoadingResult: state?.dataLoading?.wellnessSurveyQuestionnaire?.loadingResult,
        isLoadingVisit: state?.dataLoading?.visit?.loading !== LoadingStatus.ENDED,
        visitLoadingResult: state?.dataLoading?.visit?.loadingResult,
    };
};

const mapDispatchToProps = dispatch => {
    const fetchWellnessSurveyQuestionnaire = () => {
        dispatch(retrieveWellnessQuestionnaire());
    };

    return {
        fetchWellnessSurveyQuestionnaire,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WellnessSurveyHome);
