import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorDiv } from '../../components/utilities/commonUtilities/FormElements';
import { ReactComponent as NedLogo } from '../../resources/images/Ned-logo.svg';
import { CONTENT_MIN_WIDTH, styleBreakMobile, styleBreakSmallMobile, styleBreakDesktop } from '../../components/utilities/layouts/Globals';
import { COLOR_WHITE } from '../../components/utilities/Material';
import { PrimaryButton } from '../../components/utilities/commonUtilities/GenericButtonElements';
import { ContentLayout } from '../../components/utilities/layouts/ContentLayout';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { CodeInput } from '../../components/utilities/commonUtilities/FormElements';
import AlertModalYesNo from '../../components/utilities/AlertModal/AlertModalYesNo';
import { authenticateDevice, resendDeviceAuthenticationAnswer } from '../../apis/authentication';
import { PATH_HOME } from '../../containers/RoutePaths';
import { Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { setDeviceToken } from '../../apis/api';
import { persistDeviceToken } from '../../utils/ClientSessionHandler';

const useStyles = makeStyles(theme => ({
    nedLogo: {
        display: 'block',
        height: '37px',
        width: '67px',
        [styleBreakMobile(theme)]: {
            height: '30px',
            width: '55px',
        },
    },
    codeInput: {
        width: '280px',
        [styleBreakMobile(theme)]: {
            width: '100%',
        },
    },
    header: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: COLOR_WHITE,
        boxShadow: `0px 1px 4px rgba(186, 186, 186, 0.25)`,
        zIndex: '9999',
        justifyContent: 'center',
        marginBottom: theme.spacing(4),
        [styleBreakDesktop(theme)]: {
            paddingLeft: theme.spacing(6),
            justifyContent: 'flex-start',
            marginBottom: theme.spacing(10),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    lowerContent: {
        display: 'flex',
        width: 'fit-content',
        marginTop: theme.spacing(6),
        [styleBreakMobile(theme)]: {
            flexDirection: 'column',
            left: theme.spacing(3),
            right: theme.spacing(3),
            width: 'calc(100% - 48px)',
            minWidth: CONTENT_MIN_WIDTH,
            position: 'fixed',
            bottom: theme.spacing(3),
            marginTop: theme.spacing(0),
        },
        [styleBreakSmallMobile(theme)]: {
            left: theme.spacing(2),
            right: theme.spacing(2),
            width: 'calc(100% - 32px)',
        },
    },
    button: {
        width: 'fit-content',
        minWidth: theme.spacing(35),
        marginRight: theme.spacing(4),
        [styleBreakMobile(theme)]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
        [styleBreakDesktop(theme)]: {
            order: '1',
        },
    },
    resendElement: {
        cursor: 'pointer',
        fontSize: theme.spacing(2),
        textAlign: 'center',
        letterSpacing: '0.16px',
        color: theme.palette.primary.dark,
        [styleBreakDesktop(theme)]: {
            order: '2',
            marginTop: '12px',
            marginBottom: '8px',
        },
    },
    contentTitle: {
        marginBottom: theme.spacing(1),
    },
    inputTitle: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(0.5),
        },
    },
    remember: {
        marginTop: theme.spacing(2),
    },
}));

const onePlusAuthenticationResult = {
    SUCCESS: {
        type: 'SUCCESS',
        msg: '',
    },
    ERROR_INCORRECT_ANSWER: {
        type: 'ERROR_INCORRECT_ANSWER',
        msg: 'Incorrect code, please try again.',
    },
    ERROR_ANSWER_EXPIRED: {
        type: 'ERROR_ANSWER_EXPIRED',
        msg: 'This code has expired. Select resend code to receive a new verification code.',
    },
    // NO_ANSWER_FOUND error will display answer expiry as well.
    ERROR_NO_ANSWER_FOUND: {
        type: 'ERROR_NO_ANSWER_FOUND',
        msg: 'This code has expired. Select resend code to receive a new verification code.',
    },
    ERROR_NO_MORE_ATTEMPTS: {
        type: 'ERROR_NO_MORE_ATTEMPTS',
        msg: 'You have reached the maximum number of incorrect attempts. Select resend code to receive a new verification code.',
    },
    ERROR_CATCH_ALL: {
        type: 'ERROR_CATCH_ALL',
        msg: 'Something went wrong with verification.',
    },
};

function Header() {
    const classes = useStyles();
    return (
        <Box display="flex" width="100%" className={classes.header}>
            <NedLogo aria-labelledby="Ned logo" className={classes.nedLogo} />
        </Box>
    );
}

function LowerContent({ buttonOnClick, resendOnClick, buttonText, buttonDisabled, submitting }) {
    const classes = useStyles();
    return (
        <div className={classes.lowerContent}>
            <Typography variant="h3" onClick={resendOnClick} className={classes.resendElement}>
                Resend Code
            </Typography>
            <PrimaryButton onClick={buttonOnClick} className={classes.button} disabled={buttonDisabled}>
                {submitting ? <CircularProgress size={20} /> : buttonText}
            </PrimaryButton>
        </div>
    );
}

function ContentHolder({ children, buttonText, resendOnClick, buttonOnClick, buttonDisabled, submitting }) {
    return (
        <ContentLayout>
            <Box display="flex" flexDirection="column">
                {children}
                <LowerContent
                    buttonOnClick={buttonOnClick}
                    resendOnClick={resendOnClick}
                    buttonText={buttonText}
                    buttonDisabled={buttonDisabled}
                    submitting={submitting}
                />
            </Box>
        </ContentLayout>
    );
}

const hasError = resultType => {
    return (
        resultType === onePlusAuthenticationResult.ERROR_ANSWER_EXPIRED.type ||
        resultType === onePlusAuthenticationResult.ERROR_INCORRECT_ANSWER.type ||
        resultType === onePlusAuthenticationResult.ERROR_NO_ANSWER_FOUND.type ||
        resultType === onePlusAuthenticationResult.ERROR_NO_MORE_ATTEMPTS.type ||
        resultType === onePlusAuthenticationResult.ERROR_CATCH_ALL.type
    );
};

function Content({ checked, onCheckBoxChange, onCodeChange, code, resultType, setResultType, emailAddress }) {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h1" component="div" className={classes.contentTitle}>
                Verification
            </Typography>
            <Typography variant="body1" component="div">
                To help protect your account, we've sent a 4-digit verification code to{' '}
                <Typography variant="body2" component="span">
                    {emailAddress}
                </Typography>
                .
            </Typography>
            <Typography variant="body1" component="div" style={{ marginTop: '16px' }}>
                Please open a new tab on your web browser to access the email with 4-digit code, and return to this page to enter the code below to continue.
            </Typography>
            <Typography variant="body2" component="div" className={classes.inputTitle}>
                Verification Code
            </Typography>
            <CodeInput
                name="code"
                value={code}
                onChange={onCodeChange}
                error={hasError(resultType)}
                className={classes.codeInput}
                setResultType={setResultType}
            />
            {hasError(resultType) && (
                <div>
                    <ErrorDiv text={onePlusAuthenticationResult[resultType]?.msg} />
                </div>
            )}
            <Box display="flex" alignItems="center" className={classes.remember}>
                <Checkbox checked={checked} onChange={onCheckBoxChange} inputProps={{ 'aria-label': 'checkbox' }} color="primary" />
                <Typography variant="body1" component="div">
                    Remember this device for 30 days
                </Typography>
            </Box>
        </div>
    );
}

function OnePlusAuthentication({ emailAddress }) {
    const [requestCookie, setRequestCookie] = useState(false);
    const [code, setCode] = useState('');
    const [resultType, setResultType] = useState('');
    const [displayModal, setDisplayModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    if (resultType === onePlusAuthenticationResult.SUCCESS.type) {
        return <Redirect to={PATH_HOME} />;
    }

    const onCodeChange = e => {
        setCode(e.target.value.trim());
    };
    const onCheckBoxChange = event => {
        setRequestCookie(event.target.checked);
    };
    const buttonOnClick = () => {
        setSubmitting(true);
        performDeviceAuthentication(code, setResultType, setSubmitting, requestCookie);
    };
    const resendOnClick = () => {
        setResultType(''); // clear result
        resendCode();
        setDisplayModal(true);
    };
    const onModalAcknowledge = () => {
        setDisplayModal(false);
    };

    return (
        <>
            <Header />
            <ContentHolder
                buttonOnClick={buttonOnClick}
                resendOnClick={resendOnClick}
                buttonText={'Submit'}
                buttonDisabled={code === ''}
                submitting={submitting}
            >
                <Content
                    checked={requestCookie}
                    onCheckBoxChange={onCheckBoxChange}
                    onCodeChange={onCodeChange}
                    code={code}
                    resultType={resultType}
                    setResultType={setResultType}
                    emailAddress={emailAddress}
                />
            </ContentHolder>
            <AlertModalYesNo
                isDisplayed={displayModal}
                alertModalTitle={'Verification Code Sent'}
                alertModalBody={'Check your email for your new 4-digit verification code.'}
                onYesSelected={onModalAcknowledge}
                yesButtonText={'Ok'}
                onCloseSelected={onModalAcknowledge}
            />
        </>
    );
}

/** connect to redux store **/
const mapStateToProps = ({ login }) => {
    return {
        emailAddress: login?.session?.extraFields?.emailAddress,
    };
};

export default connect(mapStateToProps, null)(OnePlusAuthentication);

/** API layer **/
async function performDeviceAuthentication(answerCode, setResultType, setSubmitting, requestCookie) {
    try {
        const response = await authenticateDevice(answerCode, requestCookie);
        setSubmitting(false);
        setResultType(response.data.result);
        if (response.data.deviceToken) {
            setDeviceToken(response.data.deviceToken);
            persistDeviceToken(response.data.deviceToken);
        }
    } catch (error) {
        setSubmitting(false);
        setResultType('ERROR_CATCH_ALL');
        console.log(error);
    }
}

async function resendCode() {
    try {
        await resendDeviceAuthenticationAnswer();
    } catch (error) {
        console.log(error);
    }
}
