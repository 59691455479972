/* This file contains sagas related to patient's current visit */

import { put, call } from 'redux-saga/effects';
import * as OutcomeAPI from '../apis/outcome';
import { saveCurrentOutcome } from '../actions';

export function* retrieveCurrentOutcome(action) {
    try {
        const response = yield call(OutcomeAPI.getCurrentOutcome);
        yield put(saveCurrentOutcome(response.data));
    } catch (error) {
        // something went wrong retrieving current outcome
        console.log(error);
    }
}
