import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { agreementVersion, TermsOfServiceSummaryText, TermsOfSeviceFullText } from './TermsOfServiceText';
import StepsLayout from '../utilities/layouts/StepsLayout';
import { makeStyles } from '@material-ui/core/styles';
import { FONT_WEIGHT_BOLD } from '../utilities/Material';
import { Typography } from '@material-ui/core/';
import { styleBreakDesktop, styleBreakMobile, styleBreakSmallMobile } from '../utilities/layouts/Globals';
import Paper from '@material-ui/core/Paper';
import { COLOR_WHITE } from '../utilities/Material';

const useStyles = makeStyles(theme => ({
    // Need to override to make the scroll bar take up no space.
    stepsContainer: {
        height: '100vh',
        overflowY: 'overlay',
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            background: COLOR_WHITE,
        },
    },
    textContainer: {
        marginBottom: theme.spacing(6),
        [styleBreakDesktop(theme)]: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            marginBottom: theme.spacing(12),
        },
        [styleBreakMobile(theme)]: {
            paddingTop: theme.spacing(3),
        },
        [styleBreakSmallMobile(theme)]: {
            paddingTop: theme.spacing(2),
        },
    },
    readMoreText: {
        cursor: 'pointer',
        userSelect: 'none',
        textAlign: 'start',
        fontWeight: FONT_WEIGHT_BOLD,
        color: theme.palette.primary.main,
        marginTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        [styleBreakSmallMobile(theme)]: {
            marginTop: theme.spacing(1),
        },
    },
}));

const SetupAccountStep2TermsOfService = ({ currentPage, onNextPage, onPreviousPage, setAgreementVersion }) => {
    const [showFullText, setShowFullText] = useState(false);
    const style = useStyles();
    // Don't draw the component if it is not our page.
    if (currentPage !== 2) {
        return null;
    }

    const onShowFullText = () => {
        setShowFullText(true);
    };

    const cancelClickHandler = () => {
        setShowFullText(false);
    };

    const onAccept = () => {
        setAgreementVersion(agreementVersion);
        onNextPage();
    };
    const onCancel = showFullText && cancelClickHandler;
    const readMoreVisibility = showFullText ? 'none' : 'block';
    return (
        <StepsLayout
            onPrevClick={onPreviousPage}
            onCancelClick={onCancel}
            currentCount={currentPage}
            titleText={'Terms and Conditions'}
            nextButtonText={'Accept'}
            isNextButtonDisabled={false}
            id={'Accept-button-of-terms-service'}
            onNextClick={onAccept}
            backgroundStretch={true}
            stepsContainerClass={style.stepsContainer}
        >
            <Paper elevation={0} borderRadius={4} className={style.textContainer}>
                {showFullText ? <TermsOfSeviceFullText /> : <TermsOfServiceSummaryText />}
                <Typography onClick={onShowFullText} className={style.readMoreText} style={{ display: readMoreVisibility }}>
                    Read the Full Terms and Conditions
                </Typography>
            </Paper>
        </StepsLayout>
    );
};

/* type checking for props */
SetupAccountStep2TermsOfService.propTypes = {
    currentPage: PropTypes.number.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onPreviousPage: PropTypes.func.isRequired,
};

export default SetupAccountStep2TermsOfService;
