import axios from 'axios';

const pathRoot = '/api/auth';

export const login = (username, password) => {
    return axios.post(pathRoot + '/login', {
        username: username,
        password: password,
    });
};

export const logout = () => {
    return axios.post(pathRoot + '/logout');
};

export const requestPasswordReset = email => {
    return axios.post(pathRoot + '/resetPassword', {
        email: email,
    });
};

export const changePassword = (oldPassword, newPassword) => {
    return axios.post(pathRoot + '/changePassword', {
        oldPassword,
        newPassword,
    });
};

export const retrieveLoginSession = () => {
    return axios.get(pathRoot + '/authorized');
};

export const authenticateDevice = (answerCode, requestCookie) => {
    return axios.post(pathRoot + '/authenticateDevice', {
        answerCode,
        requestCookie,
    });
};

export const resendDeviceAuthenticationAnswer = () => {
    return axios.post(pathRoot + '/resendDeviceAuthenticationAnswer', {});
};
