import React, { useEffect } from 'react';
import MainLayout from '../utilities/layouts/MainLayout';
import { useHistory } from 'react-router-dom';
import { CardItem, CardList, EmptyItem } from '../utilities/commonUtilities/CardItem';
import { ReactComponent as LabBadge } from '../../resources/images/lab-badge.svg';
import { Typography } from '@material-ui/core';
import { PageTitleBlock } from '../utilities/commonUtilities/GenericDivElements';
import { connect } from 'react-redux';
import { PATH_LABS_PSA, PATH_LABS_TESTOSTERONE } from '../../containers/RoutePaths';
import { ReactComponent as LabEmpty } from '../../resources/images/lab-empty.svg';
import { actionObservation } from './ObservationPage';
import { observationCodes } from './ObservationPage';
import { LoadingResult, LoadingStatus } from '../../utils/LoadingUtils';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';

export const filterObservationResults = (observationResults, filterParam) => {
    return observationResults.filter(result => result.code === filterParam);
};

function LabsContent({ observationResults, patientFhirId, fetchObservation, isLoadingObservations, observationsLoadingResult }) {
    const history = useHistory();

    useEffect(() => {
        fetchObservation(patientFhirId);
    }, [fetchObservation, patientFhirId]);

    if (isLoadingObservations) {
        return <IsLoadingPage noNavBar={true} />;
    } else {
        if (observationsLoadingResult === LoadingResult.FAILED) {
            return <LoadingErrorPage noNavBar={true} />;
        } else {
            if (observationResults.length === 0) {
                return <EmptyItem image={LabEmpty} title="No Lab Results" message="Lab results completed for your virtual visit will show up here." />;
            } else {
                const psaResults = filterObservationResults(observationResults, observationCodes.PSA.code);
                const testosteroneResults = filterObservationResults(observationResults, observationCodes.Testosterone.code);
                return (
                    <CardList>
                        {psaResults.length > 0 && (
                            <CardItem
                                Badge={LabBadge}
                                title="PSA"
                                onClick={() => history.push(PATH_LABS_PSA)}
                                subtitle={`Latest result ${psaResults[0].date}`}
                            />
                        )}
                        {testosteroneResults.length > 0 && (
                            <CardItem
                                Badge={LabBadge}
                                title="Testosterone"
                                onClick={() => history.push(PATH_LABS_TESTOSTERONE)}
                                subtitle={`Latest result ${testosteroneResults[0].date}`}
                            />
                        )}
                    </CardList>
                );
            }
        }
    }
}

function LabsPage({ observationResults, patientFhirId, fetchObservation, isLoadingObservations, observationsLoadingResult }) {
    return (
        <MainLayout>
            {observationResults.length > 0 && (
                <PageTitleBlock>
                    <Typography variant="h1">Labs</Typography>
                </PageTitleBlock>
            )}
            <LabsContent
                observationResults={observationResults}
                patientFhirId={patientFhirId}
                fetchObservation={fetchObservation}
                isLoadingObservations={isLoadingObservations}
                observationsLoadingResult={observationsLoadingResult}
            />
        </MainLayout>
    );
}

const mapStateToProps = state => {
    return {
        observationResults: state.observation.labResults,
        patientFhirId: state.login.session.patientId,
        isLoadingObservations: state?.dataLoading?.observations?.loading !== LoadingStatus.ENDED,
        observationsLoadingResult: state?.dataLoading?.observations?.loadingResult,
    };
};

const mapDispatchToProps = {
    fetchObservation: actionObservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabsPage);
