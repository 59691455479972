import axios from 'axios';
import { HealthCardSystem } from '../components/DropDownSelector';

const pathRoot = '/api/auth';

export const postAccountSetup = (password, question1, answer1, question2, answer2, question3, answer3) => {
    return axios.post(pathRoot + '/account-creation-completion', {
        password: password,
        securityQuestion1: {
            question: question1,
            answer: answer1,
        },
        securityQuestion2: {
            question: question2,
            answer: answer2,
        },
        securityQuestion3: {
            question: question3,
            answer: answer3,
        },
    });
};

/**
 * Verify a ned patient.
 * @param {string} agreementVersion
 * @param {string} dateOfBirth i.e. "2020-01-16T15:39:15.754Z"
 * @param {string} ohip
 * @param {string} token
 */
export const verifyNedPatient = accountSetupVerificationParameters => {
    // Sanitize the health card number.
    let sanitizedHealthCardNumber = sanitizeHealthCardNumber(
        accountSetupVerificationParameters.healthCardSystem,
        accountSetupVerificationParameters.healthCardNumber
    );

    return axios.post(pathRoot + '/verify-ned-patient', {
        agreementVersion: accountSetupVerificationParameters.agreementVersion,
        dateOfBirth: accountSetupVerificationParameters.dateOfBirth,
        healthCardNumber: sanitizedHealthCardNumber,
        healthCardSystem: accountSetupVerificationParameters.healthCardSystem,
        token: accountSetupVerificationParameters.accountSetupToken,
    });
};

function sanitizeHealthCardNumber(healthCardSystem, healthCardNumber) {
    let sanitizedHealthCardNumber = '';
    if (healthCardSystem === HealthCardSystem.ALBERTA || healthCardSystem === HealthCardSystem.ONTARIO) {
        sanitizedHealthCardNumber = healthCardNumber.split('-').join('');
    } else if (healthCardSystem === HealthCardSystem.NOVA_SCOTIA) {
        sanitizedHealthCardNumber = healthCardNumber.split(' ').join('');
    }
    return sanitizedHealthCardNumber;
}
