import { actionSaga } from '../../sagas/dispatchSaga';
import { call, put } from 'redux-saga/effects';
import { getAllOutcome, getAllOutcomePagable } from '../../apis/outcome';
import { storeOutcomes, appendOutcomes, endLoadOutcomes, loadOutcomes, setOutcomeLoadingResult } from '../../actions';
import { formatDateMMMDYYYY } from '../utilities/commonUtilities/dateTools';
import { LoadingResult, LoadingStatus } from '../../utils/LoadingUtils';

/**
 * Produces a display ready note item from a outcome. The note is:
 *
 * {
 *    note: Text body of the note,
 *    receivedDate: A pretty-printed date when the note was received.
 *    visitAssessment: The assessment represented by the note.
 * }
 */
export function noteFromOutcome(outcome) {
    const outcomeDate = formatDateMMMDYYYY(outcome.assessmentReceivedDate);
    return {
        id: outcome.id,
        note: outcome.clinicianNote,
        receivedDate: outcomeDate,
        visitAssessment: outcome.visitAssessmentResult,
    };
}

/**
 * Coerces a value intented to be used as an id of a note to
 * a specific value type in order to match notes by id.
 */
export function toNoteId(id) {
    return `${id}`;
}

export function actionOutcomeFetch() {
    return actionSaga('TASK_FETCH_ALL_OUTCOME', outcomeSaga);
}

function* outcomeSaga() {
    yield put(loadOutcomes(LoadingStatus.STARTED));
    try {
        const response = yield call(getAllOutcome);
        if (response.status === 200) {
            if (response.data.entries !== undefined && response.data.totalEntries > 0) {
                const nextPageObj = yield call(storeOutcome, response, storeOutcomes);
                if (nextPageObj) {
                    yield call(outcomeSagaPaging, nextPageObj);
                }
            }
            yield put(setOutcomeLoadingResult(LoadingResult.SUCCESSFUL));
        } else {
            console.log(response);
            yield put(setOutcomeLoadingResult(LoadingResult.FAILED));
        }
    } catch (e) {
        console.log(e);
        yield put(setOutcomeLoadingResult(LoadingResult.FAILED));
    }
    yield put(endLoadOutcomes());
}

/**
 * Stores current page of outcomes into store.
 * Returns next page if it exists.
 */
function* storeOutcome(response, actionCreator) {
    const outcomes = response.data.entries || [];
    yield put(actionCreator(outcomes));
    return response.data.nextPage;
}

function* outcomeSagaPaging(nextPageObj) {
    while (nextPageObj) {
        let splitString = nextPageObj.url.split('?');
        let nextPageParams = splitString[1];
        let response = yield call(getAllOutcomePagable, nextPageParams);
        if (response.status === 200) {
            nextPageObj = yield call(storeOutcome, response, appendOutcomes);
        } else {
            break;
        }
    }
}
