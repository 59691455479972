import React from 'react';
import * as PageNames from './WizardPageNames';
import OnCompleteLayout from '../utilities/layouts/OnCompleteLayout';
import { ReactComponent as DoneBadge } from '../../resources/images/done-badge-large.svg';

export default function ResetPasswordComplete({ currentPage, completeWizard }) {
    if (currentPage !== PageNames.RESET_PASSWORD_COMPLETE_PAGE) {
        return null;
    }

    const imageComponent = <DoneBadge aria-labelledby="Done Badge" />;
    return (
        <OnCompleteLayout
            onClick={completeWizard}
            imageComponent={imageComponent}
            title="Password Reset"
            body="Please use your new password to sign into your account."
        />
    );
}
