import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckMark } from '../../resources/images/done-badge-large.svg';
import OnCompleteLayout from '../utilities/layouts/OnCompleteLayout';

const SetupAccountStep7Complete = ({ currentPage, onComplete }) => {
    // Don't draw the component if it is not our page.
    if (currentPage !== 6) {
        return null;
    }
    const imageComponent = <CheckMark aria-labelledby="check mark" />;
    return (
        <OnCompleteLayout buttonText="Continue" onClick={onComplete} imageComponent={imageComponent} title="Setup Complete" body="Great job, you're all set!" />
    );
};

/* type checking for props */
SetupAccountStep7Complete.propTypes = {
    currentPage: PropTypes.number.isRequired,
    onComplete: PropTypes.func.isRequired,
};

export default SetupAccountStep7Complete;
