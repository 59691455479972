/* This file contains form elements like input fields */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { IconButton, InputAdornment, TextField, SvgIcon, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PasswordSvg } from '../../../resources/images/password.svg';
import { ReactComponent as UserSvg } from '../../../resources/images/username.svg';
import { ReactComponent as EyeSvg } from '../../../resources/images/eye.svg';
import { ReactComponent as EyeOffSvg } from '../../../resources/images/eye-off.svg';
import { COLOR_GREY } from '../Material';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    errorDiv: {
        marginTop: theme.spacing(0.5),
    },
}));

export const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1px solid #747B84',
                borderRadius: '5px',
            },
        },
    },
})(TextField);

const VIEWBOX_NED_ICON = '-4 -4 24 24'; // Ned icons are 16 x 16, need to place inside a 24x24 adornment

const NedInputIcon = ({ viewBox = VIEWBOX_NED_ICON, hasFocus, color = 'primary', htmlColor = COLOR_GREY.main, ...props }) => {
    const iconColor = hasFocus ? color : 'inherit';
    return <SvgIcon viewBox={viewBox} color={iconColor} htmlColor={htmlColor} {...props} />;
};

const CodeMask = props => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/]}
        />
    );
};

CodeMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export const CodeInput = ({ setResultType, error, ...props }) => {
    return (
        <CssTextField
            error={error}
            inputProps={{
                style: { textAlign: 'center', letterSpacing: '8px' },
            }}
            InputProps={{
                inputComponent: CodeMask,
            }}
            onFocus={() => {
                if (setResultType) {
                    setResultType('');
                }
            }}
            {...props}
        />
    );
};

export const EmailInput = ({ onFocus, onBlur, error, adornStart, ...props }) => {
    const [hasFocus, setFocus] = useState(false);

    const startAdornment = adornStart ? (
        <InputAdornment position="start">
            <NedInputIcon component={UserSvg} hasFocus={hasFocus} />
        </InputAdornment>
    ) : (
        undefined
    );

    return (
        <CssTextField
            error={error}
            InputProps={{
                startAdornment,
            }}
            type="text"
            onFocus={() => {
                setFocus(true);
                if (onFocus) {
                    onFocus();
                }
            }}
            onBlur={() => {
                setFocus(false);
                if (onBlur) {
                    onBlur();
                }
            }}
            {...props}
        />
    );
};

export const PasswordInput = ({ focusLostCallback, isInvalid, value, name, errors, showErrors = true, adornStart, BoxStyling, ...props }) => {
    const [type, setType] = useState('password');
    const [hasFocus, setFocus] = useState(false);
    const [hasError, setError] = useState(false);

    const onVisibilityToggle = () => {
        if (type !== 'password') {
            setType('password');
        } else {
            setType('text');
        }
    };

    const iconSvg = type === 'password' ? EyeOffSvg : EyeSvg;

    const startAdornment = adornStart ? (
        <InputAdornment position="start">
            <NedInputIcon component={PasswordSvg} hasFocus={hasFocus} />
        </InputAdornment>
    ) : (
        undefined
    );

    return (
        <Box display="flex" flexDirection="column" id={`PasswordRoot-${name}`} className={BoxStyling ? BoxStyling : ''}>
            <CssTextField
                id={`TextFieldGetsMargin-${name}`}
                error={hasError && !hasFocus && !!value}
                isinvalid={isInvalid}
                InputProps={{
                    onFocus: () => {
                        if (focusLostCallback) {
                            // indicate globally focus not lost for element
                            focusLostCallback(false);
                        }
                        setFocus(true);
                    },
                    onBlur: () => {
                        if (focusLostCallback && !!value) {
                            //indicate globally focus is lost for element
                            focusLostCallback(true);
                        }
                        setFocus(false);
                        handleBlur(name, setError, errors);
                    },
                    startAdornment,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={onVisibilityToggle} edge="end">
                                <NedInputIcon component={iconSvg} hasFocus={hasFocus} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                value={value}
                type={type}
                name={name}
                {...props}
            />
            {!hasFocus && hasError && showErrors ? <ErrorDiv text={errors[name]} /> : null}
        </Box>
    );
};

export function ErrorDiv({ text }) {
    const classes = useStyles();
    return (
        <Typography variant="caption" color="error" className={classes.errorDiv}>
            {text}
        </Typography>
    );
}

function handleBlur(name, setError, errors) {
    if (errors && errors[name]) {
        setError(true);
    } else {
        setError(false);
    }
}

/* styled */
export const Input = styled.input`
    font-family: Avenir Next;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #212121;
    border: 1px solid #979797;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 11px;
    padding-right: 60px;
    padding-bottom: 10px;
    box-sizing: border-box;
    &:focus {
        outline: 0;
    }

    ${props => {
        if (props.isinvalid) {
            return css`
                border-color: #ff0000;
            `;
        } else {
            return css`
                border-color: #979797;
            `;
        }
    }}
`;

export const InputWithMarginA = styled(Input)`
    margin-top: 10px;
    width: 100%;
`;

export const PasswordInputWithMarginA = styled(PasswordInput)`
    margin-bottom: 10px;
`;

export const Select = styled.select`
    border: 1px solid #979797;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    font-family: Avenir Next;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.16px;
    color: #212121;
    padding-top: 12px;
    padding-bottom: 11px;
    padding-left: 10px;
    padding-right: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    appearance: none;
    overflow: hidden;
    white-space: normal;
`;
