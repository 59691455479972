import moment from 'moment';

/**
 * Create a moment from a ISO 8601 date string.
 */
export function isoMoment(isoDate) {
    return moment(isoDate, moment.ISO_8601);
}

/**
 * Format an iso 8601 date string into the specified date format.
 *
 * @param {*} isoDate an ios 8601 date string.
 * @param {*} format @see moment.format
 *
 * @see moment#format
 */
export function formatDate(isoDate, format) {
    const date = isoMoment(isoDate);
    const formattedDate = date.format(format);
    return formattedDate;
}

/**
 * Format an iso 8601 date string into MMMM D, YYYY
 *
 * e.g. May 4, 2020
 */
export function formatDateMMMMDYYYY(isoDate) {
    return formatDate(isoDate, 'MMMM D, YYYY');
}

/**
 * Format an iso 8601 date string into MMM D, YYYY
 *
 * e.g. Jan 1, 2020
 */
export function formatDateMMMDYYYY(isoDate) {
    return formatDate(isoDate, 'MMM D, YYYY');
}

/**
 * Get a display string that describes the number of days between now and the provided due date.
 * If the date is in the future, the description is 'x days left'.
 * If the date is in the past, the description is 'x days late'.
 * If the date is today, the description is due today.
 *
 * @param dueDate The provided isoDate
 * @param onlyDayCount Optional. If true, only day count is returned e.g 5 instead of '5 days left'
 * @returns a string representing the number of days between now and the provided date.
 *          e.g. '5 days left'
 */
export function daysLeftFromToday(dueDate, onlyDayCount) {
    const normalizedDueDate = isoMoment(dueDate).startOf('day');
    const normalizedNowDate = moment().startOf('day');
    const dayDiff = normalizedDueDate.diff(normalizedNowDate, 'days');
    if (dayDiff === 0) {
        return onlyDayCount ? 0 : 'Due today';
    }

    if (onlyDayCount) {
        return dayDiff;
    }

    const absDayDiff = Math.abs(dayDiff);

    const dayOrDays = absDayDiff > 1 ? 'days' : 'day';

    let leftOrLate = '';
    if (dayDiff > 0) {
        leftOrLate = 'left';
    } else {
        leftOrLate = 'late';
    }

    return `${absDayDiff} ${dayOrDays} ${leftOrLate}`;
}

/**
 * Compute number of minutes between two unix time stamps
 * @param timeA  string representing number of milliseconds of time A since the Unix Epoch (Jan 1 1970 12AM UTC)
 * @param timeB  string representing number of milliseconds of time B since the Unix Epoch (Jan 1 1970 12AM UTC)
 */
export function computeMinutes(timeA, timeB) {
    const momentA = moment(parseInt(timeA));
    const momentB = moment(parseInt(timeB));

    const diffInMinutesWithPrecision = momentA.diff(momentB, 'minutes', true);
    let roundedDiffInMinutes = Math.round(diffInMinutesWithPrecision);

    if (roundedDiffInMinutes < diffInMinutesWithPrecision) {
        return roundedDiffInMinutes + 1; // adding 1 to offset rounding down, i.e treat 0.49 like 1, and not 0
    } else {
        return roundedDiffInMinutes;
    }
}
