import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as NedLogo } from '../../../resources/images/Ned-logo.svg';
import { CONTENT_MIN_WIDTH, styleBreakMobile, styleBreakSmallMobile, styleBreakDesktop } from './Globals';
import { COLOR_WHITE } from '../Material';
import { PrimaryButton } from '../commonUtilities/GenericButtonElements';
import { ContentLayout } from './ContentLayout';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    nedLogo: {
        display: 'block',
        height: '37px',
        width: '67px',
        [styleBreakMobile(theme)]: {
            height: '30px',
            width: '55px',
        },
    },
    notLoggedInHeader: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: COLOR_WHITE,
        boxShadow: `0px 1px 4px rgba(186, 186, 186, 0.25)`,
        zIndex: '9999',
        justifyContent: 'center',
        marginBottom: theme.spacing(12.5),
        [styleBreakDesktop(theme)]: {
            paddingLeft: theme.spacing(6),
            justifyContent: 'flex-start',
            marginBottom: theme.spacing(15),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    NotLoggedInButton: {
        width: 'fit-content',
        minWidth: theme.spacing(35),
        marginTop: theme.spacing(3),
        [styleBreakMobile(theme)]: {
            left: theme.spacing(3),
            right: theme.spacing(3),
            width: 'calc(100% - 48px)',
            minWidth: CONTENT_MIN_WIDTH,
            position: 'fixed',
            bottom: theme.spacing(3),
            marginTop: theme.spacing(0),
        },
        [styleBreakSmallMobile(theme)]: {
            left: theme.spacing(2),
            right: theme.spacing(2),
            width: 'calc(100% - 32px)',
        },
    },
    NotLoggedInButtonCentered: {
        marginLeft: 'auto',
        marginRight: 'auto',
        [styleBreakMobile(theme)]: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(0),
        },
    },
    contentHorizontallyCentered: {
        alignItems: 'center',
    },
}));

function NotLoggedInHeader() {
    const classes = useStyles();
    return (
        <Box display="flex" width="100%" className={classes.notLoggedInHeader}>
            <Box>
                <NedLogo aria-labelledby="Ned logo" className={classes.nedLogo} />
            </Box>
        </Box>
    );
}

function NotLoggedInButton({ onClick, buttonText, buttonDisabled, centerLoggedInButton }) {
    const classes = useStyles();
    return (
        <PrimaryButton
            onClick={onClick}
            className={clsx(classes.NotLoggedInButton, {
                [classes.NotLoggedInButtonCentered]: centerLoggedInButton,
            })}
            disabled={buttonDisabled}
        >
            {buttonText}
        </PrimaryButton>
    );
}

function NotLoggedInContent({ content, buttonText, onClick, buttonDisabled, contentHorizontallyCentered, centerLoggedInButton }) {
    const classes = useStyles();
    return (
        <ContentLayout>
            <Box display="flex" flexDirection="column" className={contentHorizontallyCentered ? classes.contentHorizontallyCentered : ''}>
                {content}
                <NotLoggedInButton onClick={onClick} buttonText={buttonText} buttonDisabled={buttonDisabled} centerLoggedInButton={centerLoggedInButton} />
            </Box>
        </ContentLayout>
    );
}

export default function NotLoggedInLayout({ onClick, buttonText, content, buttonDisabled, contentHorizontallyCentered, centerLoggedInButton }) {
    return (
        <>
            <NotLoggedInHeader />
            <NotLoggedInContent
                onClick={onClick}
                buttonText={buttonText}
                content={content}
                buttonDisabled={buttonDisabled}
                contentHorizontallyCentered={contentHorizontallyCentered}
                centerLoggedInButton={centerLoggedInButton}
            />
        </>
    );
}
