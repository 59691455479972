/* Container for connecting NotFound component to the Redux store */

import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h3>Sorry, page not found!</h3>
        </div>
    );
};

export default NotFound;
