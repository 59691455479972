/* Handles state transitions associated with current visit actions dispatched */

import produce from 'immer';
import * as actions from '../actions/actionTypes';
import { VisitState } from '../utils/VisitTaskHandler';

const currentVisit = (state = { visit: null, isAllTasksRecentlyCompleted: false }, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.CURRENT_VISIT_BUILD:
                draft.visit = action.currentVisitObject;
                // Is visit recently completed occurs when the current state was started and it is updated to pending.
                draft.isAllTasksRecentlyCompleted =
                    state.visit?.visitState === VisitState.STARTED &&
                    (action.currentVisitObject?.visitState === VisitState.PENDING_CLINICIAN_MANUAL_LABS_RETRIEVAL ||
                        action.currentVisitObject?.visitState === VisitState.PENDING_CLINICIAN_REVIEW);
                break;
            case actions.CURRENT_VISIT_DISCARD:
                draft.visit = null;
                draft.isAllTasksRecentlyCompleted = false;
                break;
            case actions.CURRENT_VISIT_SET_RECENTLY_COMPLETED_ALL_TASKS:
                draft.isAllTasksRecentlyCompleted = action.isAllTasksRecentlyCompleted;
                break;
            default:
                break;
        }
    });

export default currentVisit;
