import { actionSaga } from '../../sagas/dispatchSaga';
import { call, put } from 'redux-saga/effects';
import { getAllResponses, getAllResponsesPaging } from '../../apis/questionnaireResponse';
import {
    appendQuestionnaireResponse,
    endLoadQuestionnaireResponse,
    loadQuestionnaireResponse,
    setQuestionnaireResponseLoadingResult,
    storeQuestionnaireResponse,
} from '../../actions';
import { LoadingResult, LoadingStatus } from '../../utils/LoadingUtils';

export function actionResponseFetch(patientFhirId) {
    return actionSaga('TASK_FETCH_QUESTIONNAIRE_RESPONSE', questionnaireResponseSaga, patientFhirId);
}

//Fetch first page of questionnaire responses
function* questionnaireResponseSaga(patientFhirId) {
    yield put(loadQuestionnaireResponse(LoadingStatus.STARTED));
    try {
        if (patientFhirId) {
            const response = yield call(getAllResponses, patientFhirId);
            if (response.status === 200) {
                if (response.data.entry !== undefined) {
                    const nextPageObj = yield call(storeResponses, response, storeQuestionnaireResponse);
                    if (nextPageObj) {
                        yield call(responseSagaPaging, nextPageObj);
                    }
                }
                yield put(setQuestionnaireResponseLoadingResult(LoadingResult.SUCCESSFUL));
            } else {
                console.log(response);
                yield put(setQuestionnaireResponseLoadingResult(LoadingResult.FAILED));
            }
        }
    } catch (e) {
        console.log(e);
        yield put(setQuestionnaireResponseLoadingResult(LoadingResult.FAILED));
    }
    yield put(endLoadQuestionnaireResponse());
}

//Fetches remaining pages of questionnaire responses
function* responseSagaPaging(nextPageObj) {
    while (nextPageObj) {
        let url = nextPageObj.url;
        let response = yield call(getAllResponsesPaging, url);
        if (response.status === 200) {
            nextPageObj = yield call(storeResponses, response, appendQuestionnaireResponse);
        } else {
            break;
        }
    }
}

function* storeResponses(response, actionCreator) {
    const responses = response.data.entry || [];
    yield put(actionCreator(responses));
    return response.data.link.find(item => item.relation === 'next');
}
