import React from 'react';
import { Hidden } from '@material-ui/core';

/**
 * Chooses between a mobile and a desktop component based on the media query size.
 * If the current component is not provided, returns null (shows nothing).
 */
export function SizeSelector({ mobile = null, desktop = null }) {
    return (
        <>
            <Hidden mdDown>{desktop}</Hidden>
            <Hidden lgUp>{mobile}</Hidden>
        </>
    );
}
