import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageWrapper, PageHeaderWithMarginA } from '../utilities/commonUtilities/Layout';
import { Header1BMargin } from '../utilities/commonUtilities/Typography';
import { SmallBody1 } from '../utilities/commonUtilities/GenericSpanElements';
import { makeStyles, Grid, TableContainer, Table, TableBody, TableCell, TableRow, Button, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { actionSaga } from '../../sagas/dispatchSaga';
import { put, call } from 'redux-saga/effects';
import { actionPutObservationIntoStore, actionAppendObservationIntoStore, loadObservations, setObservationsLoadingResult } from '../../actions';
import { getObservations, getObservationsPagable } from '../../apis/observationFhir';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { styleBreakMobile } from '../utilities/layouts/Globals';
import { FONT_WEIGHT_BOLD } from '../utilities/Material';
import { PATH_LABS } from '../../containers/RoutePaths';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import { LoadingResult, LoadingStatus } from '../../utils/LoadingUtils';

/** observation style components **/
const useStyles = makeStyles(theme => ({
    dataSection: {
        background: theme.palette.background.default,
        minHeight: '80vh', // HACKASAURUS: I cannot get the height of the data section to fill the rest of the vertical space.
    },
    largeObservationValueTypography: {
        fontSize: '70px',
        lineHeight: '96px',
        color: theme.palette.info.contrastText,
        [styleBreakMobile(theme)]: {
            fontSize: '7vw',
        },
    },
    latestResult: {
        marginBottom: theme.spacing(2),
    },
    latestResultTypography: {
        fontWeight: FONT_WEIGHT_BOLD,
    },
    currentObservationSection: {
        marginBottom: theme.spacing(4),
        [styleBreakMobile(theme)]: {
            marginBottom: theme.spacing(3),
        },
    },
    pastObservationTypography: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    gridItem: {
        marginLeft: '5px',
    },
    tableCell: {
        fontSize: '16px',
        fontFamily: 'Avenir Next',
        fontStyle: 'normal',
        fontWeight: 'normal',
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    button: {
        textTransform: 'none',
        borderColor: '#979797',
        border: '1px solid',
        width: '100%',
        marginTop: '15px',
        marginBottom: '15px',
    },
}));

const ObservationPageWrapper = styled(PageWrapper)`
    justify-content: flex-start;
    height: auto;
`;

const FixedObservationCard = styled.div`
    position: fixed;
    background: #ffffff;
    width: 100%;
    left: 0;
`;

const FixedEmptyObservationCard = styled(FixedObservationCard)`
    height: 40%;
`;

const NoObservationResult = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    margin-top: 15px;
`;

export const observationCodes = {
    PSA: {
        code: '2857-1',
        name: 'PSA',
    },
    Testosterone: {
        code: '14913-8',
        name: 'Testosterone',
    },
};

/** Observation components and page **/
const DISPLAY_ITEM_LENGTH = 25;

// This wraps the RequiresLogin around the actual content so the useEffect (lab fetch) will not be necessary without a login.
function ObservationPage({ fetchObservation, observationResults, patientFhirId, observationCode }) {
    return (
        <RequiresLogin>
            <ObservationPageContent
                fetchObservation={fetchObservation}
                observationResults={observationResults}
                patientFhirId={patientFhirId}
                observationCode={observationCode}
            />
        </RequiresLogin>
    );
}

function ObservationPageContent({ fetchObservation, observationResults, patientFhirId, observationCode }) {
    const styling = useStyles();
    const history = useHistory();
    const [endIndx, setEndIndx] = useState(DISPLAY_ITEM_LENGTH);
    const [moreObservationResults, setMoreObservationResults] = useState(observationResults.length > 0 ? true : false);

    useEffect(() => {
        fetchObservation(patientFhirId);
    }, [fetchObservation, patientFhirId]);

    useEffect(() => {
        // (-1) is to exclude latest observation from table
        if (observationResults.length - 1 <= DISPLAY_ITEM_LENGTH) {
            setMoreObservationResults(false);
        } else {
            setMoreObservationResults(true);
        }
    }, [observationResults]);

    const handleShowMore = () => {
        if (endIndx + DISPLAY_ITEM_LENGTH >= observationResults.length - 1) {
            // reached end of observationResult list
            setMoreObservationResults(false);
        }
        setEndIndx(endIndx + DISPLAY_ITEM_LENGTH);
    };

    return (
        <>
            {observationResults.length > 0 ? (
                <NonEmptyObservationResult
                    history={history}
                    styling={styling}
                    observationResults={observationResults}
                    moreObservationResults={moreObservationResults}
                    handleShowMore={handleShowMore}
                    endIndx={endIndx}
                    observationCode={observationCode}
                />
            ) : (
                <EmptyObservationResult history={history} observationCode={observationCode} />
            )}
        </>
    );
}
function ResultPageHeader({ history, observationCode }) {
    return (
        <>
            <PageHeaderWithMarginA onPrevClick={() => history.push(PATH_LABS)} />
            <Header1BMargin>{observationCode.name}</Header1BMargin>
        </>
    );
}

function NonEmptyObservationResult({ styling, observationResults, moreObservationResults, handleShowMore, endIndx, observationCode }) {
    const latestObservation = observationResults[0];
    const latestDate = latestObservation.date;
    const latestObsValue = latestObservation.value;
    const latestObsComparator = latestObservation.comparator;
    const latestObsUnit = latestObservation.unit;
    //Remove latest observation from array to not display in table
    const resultsCopy = observationResults.slice(1);

    const psaCaptiontext =
        'PSA results can be interpreted in different ways. Please contact your healthcare provider to learn what these results mean for you.';
    const TestosteroneCaptionText =
        'Testosterone results can be interpreted in different ways. Your healthcare provider can help you learn what these results mean for you.';

    const observationResult = (
        // We cannot use content layout here as the maxwidth of the container must be able to span the screen.
        <Grid container direction="column">
            <ContentLayout className={styling.currentObservationSection}>
                <Grid container wrap="nowrap" alignItems="baseline">
                    <Grid item>
                        <Typography variant="h1" className={styling.largeObservationValueTypography}>
                            {latestObsComparator ? latestObsComparator + ' ' : ''}
                            {latestObsValue}
                        </Typography>
                    </Grid>
                    <Grid item className={styling.gridItem}>
                        <SmallBody1>{latestObsUnit ? latestObsUnit : ''}</SmallBody1>
                    </Grid>
                </Grid>
                <Box className={styling.latestResult}>
                    <Typography variant="caption" className={styling.latestResultTypography}>
                        Latest result on {latestDate}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption">{observationCode.name === 'PSA' ? psaCaptiontext : TestosteroneCaptionText}</Typography>
                </Box>
            </ContentLayout>
            <Box flexGrow={1} className={styling.dataSection}>
                <ContentLayout>
                    <Typography variant="h4" className={styling.pastObservationTypography}>
                        PAST RESULTS
                    </Typography>
                    {resultsCopy.length > 0 && (
                        <ObservationTable styling={styling} observationResults={resultsCopy.slice(0, Math.min(resultsCopy.length, endIndx))} />
                    )}
                    {// https://collaborate.uhnresearch.ca/jira/browse/NEDVC-451 will be removing this code.
                    moreObservationResults && (
                        <Button className={styling.button} onClick={handleShowMore}>
                            <Typography>Show More</Typography>
                        </Button>
                    )}
                </ContentLayout>
            </Box>
        </Grid>
    );

    return <DetailsLayout backTo={PATH_LABS} title={observationCode.name} children={observationResult} />;
}

function EmptyObservationResult({ history, observationCode }) {
    return (
        <FixedEmptyObservationCard>
            <ObservationPageWrapper>
                <ResultPageHeader history={history} observationCode={observationCode} />
                <NoObservationResult>{`You currently do not have any ${observationCode.name} data.`}</NoObservationResult>
            </ObservationPageWrapper>
        </FixedEmptyObservationCard>
    );
}

function ObservationTable({ styling, observationResults }) {
    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {observationResults.map((row, indx) => (
                        <TableRow key={indx}>
                            <TableCell className={styling.tableCell} align="left">
                                <Typography variant="body1">{row.date}</Typography>
                            </TableCell>
                            <TableCell className={styling.tableCell} align="right">
                                <Typography variant="body1">
                                    {row.comparator ? row.comparator + ' ' : ''}
                                    {row.value} {row.unit ? row.unit : ''}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

/** Hooking up observation component to redux store **/
function mapStateToProps(state) {
    return {
        patientFhirId: state.login.session.patientId,
    };
}

const mapDispatchToProps = {
    fetchObservation: actionObservation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObservationPage);

/** Data fetching layer of Observation functionality  **/

export function actionObservation(patientFhirId) {
    return actionSaga('OBSERVATION_FETCH_FROM_SERVER', observationSaga, patientFhirId);
}

function* observationSaga(patientFhirId) {
    yield put(loadObservations(LoadingStatus.STARTED));
    try {
        const response = yield call(getObservations, patientFhirId);
        if (response.status === 200) {
            const nextPageObj = yield call(storeObservation, response, actionPutObservationIntoStore);
            if (nextPageObj) {
                yield call(observationPagingSaga, nextPageObj);
            }
            yield put(setObservationsLoadingResult(LoadingResult.SUCCESSFUL));
        } else {
            console.log(response);
            yield put(setObservationsLoadingResult(LoadingResult.FAILED));
        }
    } catch (e) {
        console.log(e);
        yield put(setObservationsLoadingResult(LoadingResult.FAILED));
    }
    yield put(loadObservations(LoadingStatus.ENDED));
}

function* observationPagingSaga(nextPageObj) {
    while (nextPageObj) {
        let response = yield call(getObservationsPagable, nextPageObj.url);
        if (response.status === 200) {
            nextPageObj = yield call(storeObservation, response, actionAppendObservationIntoStore);
        } else {
            break;
        }
    }
}

/**
 * Stores current page of observations into store.
 * Returns next page if it exists.
 */
function* storeObservation(response, actionCreator) {
    const observations = response.data.entry || [];
    yield put(actionCreator(observations));
    return response.data.link.find(item => item.relation === 'next');
}
