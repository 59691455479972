/* Renders password setup page */
import React, { useState, useEffect } from 'react';
import { PasswordInput } from './utilities/commonUtilities/FormElements';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ReactComponent as PassIcon } from '../resources/images/check.svg';
import { ReactComponent as FailIcon } from '../resources/images/close-small.svg';
import { ReactComponent as EllipseIcon } from '../resources/images/ellipse_black.svg';
import clsx from 'clsx';
import { styleBreakDesktop } from './utilities/layouts/Globals';
import { handleOnChange } from '../components/profile/ChangePasswordPage';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
    normal: {
        fontWeight: 'normal',
    },
    failIcon: {
        fill: theme.palette.error.main,
    },
    listItem: {
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    listItemText: {
        marginLeft: theme.spacing(-4),
    },
    textPositive: {
        color: theme.palette.success.contrastText,
    },
    textNegative: {
        color: theme.palette.error.main,
    },
    list: {
        padding: theme.spacing(0),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
        [styleBreakDesktop(theme)]: {
            marginBottom: theme.spacing(4),
        },
    },
    title: {
        marginTop: theme.spacing(1),
        [styleBreakDesktop(theme)]: {
            marginTop: theme.spacing(2),
        },
    },
    labelMargin: {
        marginBottom: theme.spacing(0.5),
    },
    BoxStyling: {
        marginBottom: theme.spacing(3),
        [styleBreakDesktop(theme)]: {
            maxWidth: theme.spacing(60),
        },
    },
    errorDiv: {
        marginTop: theme.spacing(0.5),
    },
}));

const H4 = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography variant="h4" className={classes.normal}>
            {children}
        </Typography>
    );
};

const passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z\s])/;

const lowerCaseLettersRegex = /[a-z]/g;
const upperCaseLettersRegex = /[A-Z]/g;
const numberRegex = /[^a-zA-Z\s]/g;

export const passwordRequirementState = {
    NEUTRAL: '',
    POSITIVE: true,
    NEGATIVE: false,
};

const defaultTitle = 'To protect your account, please make sure your password:';
const charLimitRequirementText = 'Is at least 8 characters';
const caseRequimentText = 'Has at least 1 uppercase and 1 lowercase letter';
const numSpecialRequiremtnText = 'Has at least 1 number or symbol';
const passwordErrorMsg = 'Invalid password';
const confirmPasswordErrorMsg = 'Passwords do not match';

const passwordSchema = Yup.object({
    password: Yup.string()
        .min(8, passwordErrorMsg)
        .matches(passwordRegex, passwordErrorMsg)
        .required(' '),
    confirmPassword: Yup.string()
        .test('passwords-match', confirmPasswordErrorMsg, function(value) {
            if (value === undefined) {
                //Do not want error to appear if field is empty
                return true;
            }
            return this.parent.password === value;
        })
        .required(''),
});

/**
 *
 * @param setSubmitDisable Sets a local state boolean variable 'submitDisabled' in any component
 * using PassWordSetup. Such a component must explicitly maintain such a localstate e.g by using UseState
 */
export function PasswordSetup({ password, processPassword, formikBagRef, setSubmitDisable, passwordSubtitle }) {
    const classes = useStyles();

    const [lengthValid, setLengthValid] = useState('');
    const [letterValid, setLetterValid] = useState('');
    const [numberValid, setNumberValid] = useState('');
    const [passwordFocusLost, setPasswordFocusLost] = useState(false);

    useEffect(() => {
        checkPasswordValidity(password, setLengthValid, setLetterValid, setNumberValid);
    }, [password]);

    return (
        <>
            <PasswordRequirementComponent
                lengthValid={lengthValid}
                letterValid={letterValid}
                PasswordCreationFailed={passwordFocusLost}
                numberValid={numberValid}
                title={passwordSubtitle}
            />
            <Formik
                initialValues={{ password: password, confirmPassword: password }}
                onSubmit={values => processPassword(values.password)}
                validationSchema={passwordSchema}
                innerRef={formikBagRef}
            >
                {({ errors, handleChange, values }) => {
                    const DisableSubmitHandler = ({ values, errors }) => {
                        useEffect(() => {
                            setSubmitDisable(!values.password || !values.confirmPassword || Object.keys(errors).length !== 0);
                        });

                        return null;
                    };

                    return (
                        <Form>
                            <DisableSubmitHandler values={values} errors={errors} />
                            <Typography variant="body2" className={classes.labelMargin}>
                                Password
                            </Typography>
                            <Field
                                as={PasswordInput}
                                name="password"
                                placeholder="Enter Password"
                                errors={errors}
                                onChange={event => handleOnChange(event, handleChange, setLengthValid, setLetterValid, setNumberValid)}
                                BoxStyling={classes.BoxStyling}
                                focusLostCallback={setPasswordFocusLost}
                            />
                            <Typography variant="body2" className={classes.labelMargin}>
                                Confirm Password
                            </Typography>
                            <Field as={PasswordInput} name="confirmPassword" placeholder="Confirm Password" errors={errors} BoxStyling={classes.BoxStyling} />
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

function PasswordListItem({ itemState, itemText }) {
    const classes = useStyles();

    return (
        <ListItem className={classes.listItem}>
            {itemState === passwordRequirementState.POSITIVE ? (
                <ListItemIcon>
                    <PassIcon />
                </ListItemIcon>
            ) : itemState === passwordRequirementState.NEGATIVE ? (
                <ListItemIcon>
                    <FailIcon className={classes.failIcon} />
                </ListItemIcon>
            ) : (
                <ListItemIcon>
                    <EllipseIcon />
                </ListItemIcon>
            )}
            <ListItemText
                disableTypography
                className={clsx(classes.listItemText, {
                    [classes.textPositive]: itemState === passwordRequirementState.POSITIVE,
                    [classes.textNegative]: itemState === passwordRequirementState.NEGATIVE,
                })}
            >
                <H4>{itemText}</H4>
            </ListItemText>
        </ListItem>
    );
}

export function PasswordRequirementComponent({ title = defaultTitle, lengthValid, letterValid, PasswordCreationFailed, numberValid }) {
    const classes = useStyles();
    return (
        <>
            <Typography variant="body1" className={classes.title}>
                {title}
            </Typography>
            <List aria-label="password requirements" className={classes.list}>
                <PasswordListItem itemText={charLimitRequirementText} itemState={!lengthValid ? (PasswordCreationFailed ? false : '') : lengthValid} />
                <PasswordListItem itemText={caseRequimentText} itemState={!letterValid ? (PasswordCreationFailed ? false : '') : letterValid} />
                <PasswordListItem itemText={numSpecialRequiremtnText} itemState={!numberValid ? (PasswordCreationFailed ? false : '') : numberValid} />
            </List>
        </>
    );
}

/* utility functions */
//Neutral - Initial state
//Positive - A requirement is met
//Negative - A requirement is not met
export function checkPasswordValidity(password, setLengthValid, setLetterValid, setNumberValid) {
    if (password === '') {
        setLengthValid(passwordRequirementState.NEUTRAL);
        setLetterValid(passwordRequirementState.NEUTRAL);
        setNumberValid(passwordRequirementState.NEUTRAL);
        return;
    }

    // Validate letters
    if (password.match(lowerCaseLettersRegex) && password.match(upperCaseLettersRegex)) {
        setLetterValid(passwordRequirementState.POSITIVE);
    } else {
        setLetterValid(passwordRequirementState.NEGATIVE);
    }

    // Validate numbers( or symbols)
    if (password.match(numberRegex)) {
        setNumberValid(passwordRequirementState.POSITIVE);
    } else {
        setNumberValid(passwordRequirementState.NEGATIVE);
    }

    // Validate length
    if (password.length >= 8) {
        setLengthValid(passwordRequirementState.POSITIVE);
    } else {
        setLengthValid(passwordRequirementState.NEGATIVE);
    }
}
