import produce from 'immer';
import * as actions from '../actions/actionTypes';
import { formatDateMMMDYYYY } from '../components/utilities/commonUtilities/dateTools';

function createData(date, value, unit, comparator, code) {
    return { date, value, unit, comparator, code };
}

const observation = (
    state = {
        labResults: [],
    },
    action
) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.OBSERVATION_STORE:
                draft.labResults = parseObservations(action.observations);
                break;
            case actions.OBSERVATION_APPEND:
                let results = parseObservations(action.observations);
                draft.labResults = state.labResults.concat(results);
                break;
            default:
                break;
        }
    });

export default observation;

function parseObservations(observations) {
    return observations.map(item => {
        const obsDate = formatDateMMMDYYYY(item.resource.effectiveDateTime);
        const value = item?.resource?.valueQuantity?.value || item?.resource?.valueString;
        const unit = item?.resource?.valueQuantity?.unit;
        const comparator = item?.resource?.valueQuantity?.comparator;
        return createData(obsDate, value, unit, comparator, item.resource.code.coding[0].code);
    });
}
