import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SecurityQuestions from '../SecurityQuestions';
import StepsLayout from '../utilities/layouts/StepsLayout';
import { COLOR_WHITE } from '../utilities/Material';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Paper } from '@material-ui/core/';
import { styleBreakDesktop, styleBreakMobile, styleBreakSmallMobile } from '../utilities/layouts/Globals';
import DataSubmissionHandler from '../DataSubmissionHandler';

const useStyles = makeStyles(theme => ({
    headingText: {
        marginTop: theme.spacing(3),
    },
    descriptionText: {
        marginTop: theme.spacing(1),
    },
    // Need to overide the stepsLayoutContainer as it only goes to 100vh.  We need 100% since our page extends past the bottom.
    stepsContainer: {
        height: '100%',
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            background: COLOR_WHITE,
        },
    },
    // Pretty much the same as step 3 except we need a padding here as the page extends beyond the window height.
    container: {
        height: '100%',
        marginBottom: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        [styleBreakDesktop(theme)]: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            //negative margins to offset margins set from marginSelector in StepsLayout.js
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
            marginBottom: theme.spacing(12),
        },
        [styleBreakMobile(theme)]: {
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            //negative margins to offset margins set from marginSelector in StepsLayout.js
            marginLeft: theme.spacing(-3),
            marginRight: theme.spacing(-3),
        },
        [styleBreakSmallMobile(theme)]: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
}));

const SetupAccountStep5SecurityQuestions = ({
    currentPage,
    onNextPage,
    onPreviousPage,
    onCancel,
    securityQuestions,
    setSecurityQuestionsAndSubmitAccountCreationInfo,
    accountCreationCompleteResponse,
    isAccountSetupSuccessful,
}) => {
    const classes = useStyles();
    const [answer1, setAnswer1] = useState(securityQuestions[0].answer);
    const [answer2, setAnswer2] = useState(securityQuestions[1].answer);
    const [answer3, setAnswer3] = useState(securityQuestions[2].answer);
    const [question1, setQuestion1] = useState(securityQuestions[0].selectedQuestion);
    const [question2, setQuestion2] = useState(securityQuestions[1].selectedQuestion);
    const [question3, setQuestion3] = useState(securityQuestions[2].selectedQuestion);
    const formikBagRef = useRef();
    const [submittingGlobal, setSubmittingGlobal] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    // creates an action that is dispatched when form is submitted
    const [submissionActionCreator, setSubmissionActionCreator] = useState(null);

    // Run on re-ender only if the selected questions and answers changed. 'questions' is a constant, so shouldn't change at all.
    useEffect(() => {
        // answers
        setAnswer1(securityQuestions[0].answer);
        setAnswer2(securityQuestions[1].answer);
        setAnswer3(securityQuestions[2].answer);

        //questions
        setQuestion1(securityQuestions[0].selectedQuestion);
        setQuestion2(securityQuestions[1].selectedQuestion);
        setQuestion3(securityQuestions[2].selectedQuestion);
    }, [securityQuestions]);

    // Don't draw the component if it is not our page.
    if (currentPage !== 5) {
        return null;
    }

    const onSubmit = (values, formikBag) => {
        if (isAccountSetupSuccessful) {
            if (formikBag?.setSubmitting) {
                formikBag.setSubmitting(false);
            }
            onNextPage();
        } else {
            setSubmissionActionCreator(prevFunc => dispatchDataSubmissionResult => {
                return setSecurityQuestionsAndSubmitAccountCreationInfo(
                    {
                        selectedQuestions: [values.question1, values.question2, values.question3],
                        answers: [values.answer1, values.answer2, values.answer3],
                    },
                    dispatchDataSubmissionResult
                );
            });
            setSubmittingGlobal(true); // indicates that data is being sent to backend
            if (formikBag?.setSubmitting) {
                formikBag.setSubmitting(false);
            }
        }
    };

    const setSecurityQnsSubmissionFailureTitle = 'Submission Error';
    const setSecurityQnsSubmissionFailureBody =
        'We’re having trouble setting up your account. Your computer might be offline or the Ned server may be experiencing problems. Please try again.';

    const defaultOnNextClick = () => {
        const values = {
            question1,
            answer1,
            question2,
            answer2,
            question3,
            answer3,
        };
        onSubmit(values, {});
    };

    return (
        <>
            <StepsLayout
                onPrevClick={onPreviousPage}
                onCancelClick={onCancel}
                currentCount={3}
                totalCount={3}
                titleText={'Account Setup'}
                nextButtonText={'Next'}
                isNextButtonDisabled={submitDisable}
                id={'Accept-button-of-security-questions'}
                onNextClick={formikBagRef.current?.submitForm || defaultOnNextClick}
                stepsContainerClass={classes.stepsContainer}
                submitting={submittingGlobal}
                submittingText={'Submitting'}
            >
                <Paper elevation={0} className={classes.container}>
                    <Box className={classes.headingText}>
                        <Typography variant="h2">Set Security Questions</Typography>
                    </Box>
                    <Box className={classes.descriptionText}>
                        <Typography variant="body1">
                            If you forget your password, these new questions will help you get back into Ned Virtual Clinic.
                        </Typography>
                    </Box>
                    <SecurityQuestions
                        answer1={answer1}
                        answer2={answer2}
                        answer3={answer3}
                        question1={question1}
                        question2={question2}
                        question3={question3}
                        formikBagRef={formikBagRef}
                        submitHandler={onSubmit}
                        setSubmitDisable={setSubmitDisable}
                    ></SecurityQuestions>
                    {!!accountCreationCompleteResponse.error && <Typography color="error">{accountCreationCompleteResponse.error}</Typography>}
                </Paper>
            </StepsLayout>
            <DataSubmissionHandler
                setSubmitting={setSubmittingGlobal}
                submitting={submittingGlobal}
                actionCreator={submissionActionCreator}
                failureMsgTitle={setSecurityQnsSubmissionFailureTitle}
                failureMsgBody={setSecurityQnsSubmissionFailureBody}
            />
        </>
    );
};

/* type checking for props */
SetupAccountStep5SecurityQuestions.propTypes = {
    currentPage: PropTypes.number.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onPreviousPage: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    securityQuestions: PropTypes.array.isRequired,
    setSecurityQuestionsAndSubmitAccountCreationInfo: PropTypes.func.isRequired,
    accountCreationCompleteResponse: PropTypes.object.isRequired,
    isAccountSetupSuccessful: PropTypes.bool.isRequired,
};

export default SetupAccountStep5SecurityQuestions;
