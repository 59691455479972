/* Handles state transitions associated with Password Reset Wizard */

import produce from 'immer';
import * as actions from '../actions/actionTypes';

const passwordReset = (
    state = {
        securityQuestions: [],
        oneTimeResetToken: '',
        securityQuestionFailed: {},
        isNetworkError: false,
    },
    action
) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.PASSWORD_RESET_SAVE_SECURITY_QUESTIONS:
                draft.securityQuestions = action.securityQuestions;
                draft.oneTimeResetToken = '';
                draft.securityQuestionFailed = {};
                draft.isNetworkError = false;
                break;
            case actions.PASSWORD_RESET_SAVE_ONE_TIME_RESET_TOKEN:
                draft.oneTimeResetToken = action.oneTimeResetToken;
                draft.securityQuestionFailed = {};
                draft.isNetworkError = false;
                break;
            case actions.PASSWORD_RESET_RECORD_FAILURE_IN_SECURITY_QUESTION:
                draft.securityQuestionFailed = action.securityQuestionFailed;
                draft.oneTimeResetToken = '';
                draft.isNetworkError = false;
                break;
            case actions.PASSWORD_RESET_RECORD_NETWORK_ERROR:
                draft.isNetworkError = true;
                draft.securityQuestionFailed = {};
                draft.oneTimeResetToken = '';
                break;
            case actions.PASSWORD_RESET_CLEAR_ERROR_AND_ONE_TIME_TOKEN_STATES:
                draft.isNetworkError = false;
                draft.securityQuestionFailed = {};
                draft.oneTimeResetToken = '';
                break;
            default:
                break;
        }
    });

export default passwordReset;
