import axios from 'axios';

/**
 * Retrieve the current generated outcome
 */
export const getCurrentOutcome = () => {
    return axios.get('/api/assessment/current');
};

export const getAllOutcome = () => {
    return axios.get('api/assessment');
};

export const getAllOutcomePagable = nextPageParams => {
    return axios.get(`api/assessment?${nextPageParams}`);
};
