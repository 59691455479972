import React, { useEffect, useState, useRef } from 'react';
import * as PageNames from './WizardPageNames';
import StepsLayout from '../utilities/layouts/StepsLayout';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { styleBreakDesktop } from '../utilities/layouts/Globals';
import { COLOR_WHITE } from '../utilities/Material';
import { CssTextField } from '../utilities/commonUtilities/FormElements';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { computeMinutes } from '../utilities/commonUtilities/dateTools';

const useStyles = makeStyles(theme => ({
    questionsContainer: {
        justifyContent: 'flex-start',
        margin: '0 auto',
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        height: '100%',
        [styleBreakDesktop(theme)]: {
            margin: theme.spacing(0),
            backgroundColor: COLOR_WHITE,
            padding: theme.spacing(4),
        },
    },
    inputStyling: {
        marginTop: theme.spacing(0.5),
        [styleBreakDesktop(theme)]: {
            maxWidth: theme.spacing(60),
            marginTop: theme.spacing(1),
        },
    },
    title: {
        marginBottom: theme.spacing(1),
    },
    subTitle: {
        marginBottom: theme.spacing(-2),
        [styleBreakDesktop(theme)]: {
            marginBottom: theme.spacing(-1),
        },
    },
    questionText: {
        marginTop: theme.spacing(5),
    },
    errorMessage: {
        marginTop: theme.spacing(1),
    },
}));

const QuestionInput = ({ ...props }) => {
    const classes = useStyles();
    return (
        <Box className={classes.inputStyling}>
            <CssTextField type="text" fullWidth={true} {...props} />
        </Box>
    );
};

const generateLockedOutMsg = (lockedUntilInMillis, serverTimeInMillis) => {
    const diffInMinutes = computeMinutes(lockedUntilInMillis, serverTimeInMillis);
    const unit = diffInMinutes === 1 ? 'minute' : 'minutes';

    return `You have reached the maximum number of attempts to answer your security questions. Please try again in ${diffInMinutes} ${unit}.`;
};

export default function AnswerSecurityQuestions({
    currentPage,
    setCurrentPage,
    securityQuestions,
    hasSecurityQuestionRetrievalError,
    submitAnswersToSecurityQuestions,
    resetTokenFromEmail,
    oneTimeResetToken,
    failedAttempts,
    lockedUntil,
    serverTimeInMillis,
    isLocked,
    isNetworkError,
    maxAttempts,
}) {
    const FAILED_SECURITY_QUESTION_MESSAGE = 'One or more of your answers are incorrect. Please try again.';
    const FAILED_SECURITY_QUESTION_RETRIEVAL_MESSAGE = 'Could not retrieve security questions.';
    const NETWORK_ERROR_MESSAGE = 'Something went wrong with answering security questions';
    const classes = useStyles();
    const formikBagRef = useRef();
    const [submitDisable, setSubmitDisable] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (oneTimeResetToken !== '') {
            setCurrentPage(PageNames.RESET_YOUR_PASSWORD_PAGE);
        } else if (isLocked) {
            if (failedAttempts < maxAttempts) {
                setErrorMessage(FAILED_SECURITY_QUESTION_MESSAGE);
            } else {
                setErrorMessage(generateLockedOutMsg(lockedUntil, serverTimeInMillis));
            }
        } else if (failedAttempts > 0) {
            setErrorMessage(FAILED_SECURITY_QUESTION_MESSAGE);
        } else if (isNetworkError) {
            setErrorMessage(NETWORK_ERROR_MESSAGE);
        } else if (hasSecurityQuestionRetrievalError) {
            setErrorMessage(FAILED_SECURITY_QUESTION_RETRIEVAL_MESSAGE);
        }
    }, [
        oneTimeResetToken,
        setCurrentPage,
        failedAttempts,
        isNetworkError,
        isLocked,
        lockedUntil,
        serverTimeInMillis,
        securityQuestions,
        hasSecurityQuestionRetrievalError,
        maxAttempts,
    ]);

    if (currentPage !== PageNames.ANSWER_SECURITY_QUESTIONS_PAGE) {
        return null;
    }

    return (
        <StepsLayout
            currentCount={1}
            totalCount={2}
            titleText={'Reset Password'}
            nextButtonText={'Next'}
            isNextButtonDisabled={submitDisable}
            id={'Next-button-of-answer-security-questions'}
            onNextClick={formikBagRef.current?.submitForm}
            alwaysShowNedLogoInDesktop
        >
            <Box display="flex" flexDirection="column" className={classes.questionsContainer}>
                <Typography variant="h2" className={classes.title}>
                    Answer Security Questions
                </Typography>
                <Typography variant="body1" className={classes.subTitle}>
                    To reset your password, please answer your security questions to verify your identity.
                </Typography>
                {securityQuestions.length !== 0 && (
                    <Formik
                        initialValues={{ answer1: '', answer2: '', answer3: '' }}
                        onSubmit={values => {
                            setErrorMessage('');
                            submitAnswersToSecurityQuestions({
                                answer_1: values.answer1,
                                answer_2: values.answer2,
                                answer_3: values.answer3,
                                question_1_id: securityQuestions[0].id,
                                question_2_id: securityQuestions[1].id,
                                question_3_id: securityQuestions[2].id,
                                token: resetTokenFromEmail,
                            });
                        }}
                        validationSchema={Yup.object({
                            answer1: Yup.string().required(),
                            answer2: Yup.string().required(),
                            answer3: Yup.string().required(),
                        })}
                        innerRef={formikBagRef}
                    >
                        {({ errors, dirty }) => {
                            const DisableSubmitHandler = ({ dirty, errors }) => {
                                useEffect(() => {
                                    setSubmitDisable(!dirty || Object.keys(errors).length !== 0);
                                });

                                return null;
                            };

                            return (
                                <Form>
                                    <DisableSubmitHandler dirty={dirty} errors={errors} />
                                    <Typography variant="body2" className={classes.questionText}>
                                        {securityQuestions[0].question}
                                    </Typography>
                                    <Field as={QuestionInput} name="answer1" placeholder="Type your answer here" />
                                    <Typography variant="body2" className={classes.questionText}>
                                        {securityQuestions[1].question}
                                    </Typography>
                                    <Field as={QuestionInput} name="answer2" placeholder="Type your answer here" />
                                    <Typography variant="body2" className={classes.questionText}>
                                        {securityQuestions[2].question}
                                    </Typography>
                                    <Field as={QuestionInput} name="answer3" placeholder="Type your answer here" />
                                </Form>
                            );
                        }}
                    </Formik>
                )}
                {errorMessage && (
                    <Typography component="div" variant="caption" color="error" className={classes.errorMessage}>
                        {errorMessage}
                    </Typography>
                )}
            </Box>
        </StepsLayout>
    );
}
