export const getQuestions = ({ wellnessSurvey }) => wellnessSurvey.questions;
export const getPatientId = ({ login }) => login.session.patientId;
export const getPasswordAndSecurityQuestions = securityQuestionsAndPassword => {
    const passwordAndSecurityQuestions = {};
    passwordAndSecurityQuestions.password = securityQuestionsAndPassword.createdPassword;

    passwordAndSecurityQuestions.question1 = securityQuestionsAndPassword.securityQuestions[0].selectedQuestion;
    passwordAndSecurityQuestions.answer1 = securityQuestionsAndPassword.securityQuestions[0].answer;

    passwordAndSecurityQuestions.question2 = securityQuestionsAndPassword.securityQuestions[1].selectedQuestion;
    passwordAndSecurityQuestions.answer2 = securityQuestionsAndPassword.securityQuestions[1].answer;

    passwordAndSecurityQuestions.question3 = securityQuestionsAndPassword.securityQuestions[2].selectedQuestion;
    passwordAndSecurityQuestions.answer3 = securityQuestionsAndPassword.securityQuestions[2].answer;

    return passwordAndSecurityQuestions;
};

export const getAccountSetupVerificationParameters = ({ accountSetup }) => {
    const accountSetupVerificationParameters = {};
    accountSetupVerificationParameters.accountSetupToken = accountSetup.accountSetupToken;
    accountSetupVerificationParameters.agreementVersion = accountSetup.agreementVersion;
    return accountSetupVerificationParameters;
};

export const getUserIdAndUsername = ({ login }) => {
    const userId = login?.session?.userId;
    const username = login?.session?.extraFields?.emailAddress;
    return { userId, username };
};
