/* Handles state transitions associated with current outcome actions dispatched */

import produce from 'immer';
import * as actions from '../actions/actionTypes';

const currentOutcome = (state = { outcome: null }, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.CURRENT_OUTCOME_SAVE:
                draft.outcome = action.currentOutcome;
                break;
            case actions.CURRENT_OUTCOME_DISCARD:
                draft.outcome = null;
                break;
            default:
                break;
        }
    });

export default currentOutcome;
