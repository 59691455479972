/* This file contains watchers for dispatched actions associated with
   the saga middleware */

import * as actions from '../actions/actionTypes';
import { takeLatest } from 'redux-saga/effects';
import { loginSaga, logoutSaga, loginRequestPasswordResetSaga, loginRetrieveSessionSaga, loginProcessDataSaga } from './authenticationSaga';
import { questionnaireSaga, questionnaireResponseSaga } from './wellnessSurveySaga';
import { AccountSetupSubmitSaga, AccountSetupVerifySaga } from './accountSetupSaga';
import { RetrieveCurrentVisitSaga, CloseCurrentVisitSaga } from './currentVisitSaga';
import {
    PasswordResetRetrieveSecurityQuestionsSaga,
    PasswordResetSubmitAnswersToSecurityQuestionsSaga,
    PasswordResetSubmitNewPasswordSaga,
} from './passwordResetSaga';
import { retrieveCurrentOutcome } from './outcomeSaga';
import { sendClientAnalyticsSaga } from './clientAnalyticsSaga';

export function* watchUserAuthentication() {
    yield takeLatest(actions.LOGIN_PATIENT, loginSaga); // fork a new login saga, that overrides any currently running login saga
    yield takeLatest(actions.LOGOUT_PATIENT, logoutSaga);
    yield takeLatest(actions.LOGIN_REQUEST_PASSWORD_RESET, loginRequestPasswordResetSaga);
    yield takeLatest(actions.LOGIN_RETRIEVE_SESSION, loginRetrieveSessionSaga);
    yield takeLatest(actions.LOGIN_PROCESS_DATA, loginProcessDataSaga);
}

export function* watchquestionnaireRetrieval() {
    yield takeLatest(actions.WELLNESS_RETRIEVE_QUESTIONNAIRE, questionnaireSaga); // fork a new questionnaire saga, that overrides any currently running questionnaire saga
}

export function* watchquestionnaireResponseSubmission() {
    yield takeLatest(actions.WELLNESS_SEND_QUESTIONNAIRE_RESPONSE, questionnaireResponseSaga); // fork a new questionnaireResponse saga, that overrides any currently running
}

export function* watchAccountSetupSubmission() {
    yield takeLatest(actions.ACCOUNT_SETUP_SECURITY_QUESTIONS_AND_PASSWORD_SUBMIT, AccountSetupSubmitSaga); // fork a new AccountSetupSubmit saga, that overrides any currently running
}

export function* watchAccountSetupVerifyPatient() {
    yield takeLatest(actions.ACCOUNT_SETUP_VERIFY_PATIENT, AccountSetupVerifySaga); // fork a new AccountSetupVerifySaga, that overrides any currently running
}

export function* watchCurrentVisitRetrieval() {
    yield takeLatest(actions.CURRENT_VISIT_RETRIEVE, RetrieveCurrentVisitSaga); // fork a new RetrieveCurrentVisit saga, that overrides any currently running
}

export function* watchCurrentVisitClose() {
    yield takeLatest(actions.CURRENT_VISIT_CLOSE, CloseCurrentVisitSaga); // fork a new CloseCurrentVisit saga, that overrides any currently running
}

export function* watchPasswordReset() {
    yield takeLatest(actions.PASSWORD_RESET_RETRIEVE_SECURITY_QUESTIONS, PasswordResetRetrieveSecurityQuestionsSaga); // fork a new PasswordResetRetrieveSecurityQuestions saga, that overrides any currently running
    yield takeLatest(actions.PASSWORD_RESET_SUBMIT_ANSWERS_TO_SECURITY_QUESTIONS, PasswordResetSubmitAnswersToSecurityQuestionsSaga); // fork a new PasswordResetSubmitAnswersToSecurityQuestions saga, that overrides any currently running
    yield takeLatest(actions.PASSWORD_RESET_SUBMIT_NEW_PASSWORD, PasswordResetSubmitNewPasswordSaga); // fork a new PasswordResetSubmitNewPassword saga, that overrides any currently running
}

export function* watchOutcome() {
    yield takeLatest(actions.RETRIEVE_CURRENT_OUTCOME, retrieveCurrentOutcome);
}

export function* watchSendClientAnalytics() {
    yield takeLatest(actions.ANALYTICS_SEND_CLIENT_ANALYTICS, sendClientAnalyticsSaga);
}
