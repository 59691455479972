import produce from 'immer';
import * as actions from '../actions/actionTypes';
import { formatDateMMMMDYYYY } from '../components/utilities/commonUtilities/dateTools';

const patientAccountDetails = (state = { fhirPatientInfo: null, nedUserInfo: null }, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.PATIENT_FHIR_INFO_STORE:
                const patientName = action.patient.name[0];
                const firstName = patientName.given?.[0];
                const middleName = patientName.given?.[1];
                const familyName = patientName.family;
                draft.fhirPatientInfo = {
                    name: { firstName, middleName, familyName },
                    gender: action.patient.gender,
                    birthDate: formatDateMMMMDYYYY(action.patient.birthDate),
                };
                break;
            case actions.PATIENT_NED_USER_INFO_STORE:
                draft.nedUserInfo = action.user;
                break;
            default:
                break;
        }
    });

export default patientAccountDetails;
