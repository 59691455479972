/* Renders Login page */

import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { PrimaryButton } from '../utilities/commonUtilities/GenericButtonElements';
import { computeMinutes } from '../utilities/commonUtilities/dateTools';
import { LoginLayout } from '../utilities/layouts/LoginLayout';
import { PasswordInput, EmailInput } from '../utilities/commonUtilities/FormElements';
import { ReactComponent as NedLogo } from '../../resources/images/Ned-logo.svg';
import { PATH_HOME, PATH_ONE_PLUS_AUTHENTICATION } from '../../containers/RoutePaths';

const useStyles = makeStyles(theme => ({
    nedLogo: {
        marginBottom: theme.spacing(10),
        display: 'flex',
        justifyContent: 'center',
    },
}));

const COLOR_FORGOT_MAIN = '#666ca1';
const COLOR_FORGOT_DARK = '#393f70';
const PasswordResetLink = styled(Link)`
    text-decoration: none;
    cursor: pointer;

    &:link {
        color: ${COLOR_FORGOT_MAIN};
    }
    &:visited {
        color: ${COLOR_FORGOT_MAIN};
    }
    &:hover {
        color: ${COLOR_FORGOT_DARK};
    }
    &:active {
        color: ${COLOR_FORGOT_DARK};
    }
`;

const CenteredText = styled(Typography)`
    display: flex;
    justify-content: center;
`;

const UserField = styled(EmailInput)`
    margin-bottom: 16px;
`;

const PasswordField = styled(PasswordInput)`
    margin-bottom: 16px;
`;

const TextError = styled(CenteredText)`
    margin-bottom: 16px;
`;

const LoginButton = styled(PrimaryButton)`
    margin-bottom: 16px;
`;

const TextForgot = styled(CenteredText)``;

const failureCodes = {
    LOCKED_ACCOUNT: 'LOCKED_ACCOUNT',
};

const generateLockedOutMsg = (lockedUntilInMillis, serverTimeInMillis) => {
    const diffInMinutes = computeMinutes(lockedUntilInMillis, serverTimeInMillis);
    const unit = diffInMinutes === 1 ? 'minute' : 'minutes';

    return `You have reached the maximum number of sign-in attempts. Please try again in ${diffInMinutes} ${unit}.`;
};

const Login = ({ hasAuthenticated, token, onSubmit, isNetworkError, failureCode, serverTimeInMillis, lockedUntil, isDeviceTokenRequired }) => {
    const INVALID_LOGIN_STRING = 'Incorrect username or password. Please try again.';
    const NETWORK_ERROR_STRING = 'Something went wrong with login';
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const classes = useStyles();
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);

    useEffect(() => {
        if (hasAuthenticated && token === '') {
            // set error message
            if (isNetworkError) {
                setErrorMessage(NETWORK_ERROR_STRING);
            } else if (failureCode === failureCodes.LOCKED_ACCOUNT) {
                setErrorMessage(generateLockedOutMsg(lockedUntil, serverTimeInMillis));
            } else {
                setErrorMessage(INVALID_LOGIN_STRING);
            }
            setDisableOnSubmit(false);
        }
    }, [hasAuthenticated, token, isNetworkError, failureCode, lockedUntil, serverTimeInMillis]);

    const handleSubmit = e => {
        e.preventDefault();
        setDisableOnSubmit(true); // disable login button on submit
        setErrorMessage(''); //clear error message
        onSubmit(username, password);
    };

    const loginButtonEnabled = () => {
        if (disableOnSubmit || username === '' || password === '') {
            return false;
        }
        return true;
    };

    return (
        <LoginLayout>
            <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column">
                    <Box className={classes.nedLogo}>
                        <NedLogo aria-labelledby="Ned" />
                    </Box>
                    <UserField name="username" placeholder="Email" value={username} onChange={e => setUsername(e.target.value)} adornStart />
                    <PasswordField name="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} adornStart />
                    {token === '' ? (
                        <TextError variant="caption" color="error">
                            {errorMessage}
                        </TextError>
                    ) : isDeviceTokenRequired === 'true' ? (
                        <Redirect to={PATH_ONE_PLUS_AUTHENTICATION} />
                    ) : (
                        <Redirect to={PATH_HOME} />
                    )}
                    <LoginButton type="submit" disabled={!loginButtonEnabled()}>
                        Sign In
                    </LoginButton>
                    <PasswordResetLink to="/patient/login/requestPasswordReset">
                        <TextForgot variant="caption">Forgot your password?</TextForgot>
                    </PasswordResetLink>
                </Box>
            </form>
        </LoginLayout>
    );
};

/* type checking for props */
Login.propTypes = {
    token: PropTypes.string.isRequired,
    hasAuthenticated: PropTypes.bool.isRequired,
    isNetworkError: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default Login;
