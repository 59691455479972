/* This file contains generic buttons */
import styled, { css } from 'styled-components';
import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';

export const Button = styled.button`
    width: 100%;
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 17px;
    padding-bottom: 11px;
    font-size: 16px;
    letter-spacing: 0.24px;
    text-align: left;
    border: none;
    background: #c4c4c4;
    color: #000000;
    font-family: Avenir Next;
`;

export const PrimaryButton = MaterialButton;

/* attach an id, if available, to Button element */
const ButtonComponent = ({ id, children, ...props }) => {
    if (id) {
        return (
            <Button id={id} {...props}>
                {children}
            </Button>
        );
    } else {
        return <Button {...props}>{children}</Button>;
    }
};

export const ChoiceButton = styled(ButtonComponent)`
    ${props =>
        props.choiceEnabled === true &&
        css`
            background: #4b4b4b;
            font-weight: 500;
            color: #ffffff;
        `}
`;

export const ChoiceButtonWithMarginA = styled(ChoiceButton)`
    margin-bottom: 10px;
`;

export const NextButton = styled(ButtonComponent)`
    letter-spacing: 0.16px;
    color: #ffffff;
    background: rgba(75, 75, 75, 0.2);
    text-align: center;
    font-weight: 500;
    padding-left: 0px;
    font-style: normal;

    ${props =>
        !props.disabled &&
        css`
            background: #4b4b4b;
        `}
`;

export const ContinueButton = styled(NextButton)`
    width: 100%;
    font-weight: 600;
`;

export const ContinueButtonWithMarginA = styled(ContinueButton)`
    margin-bottom: 20px;

    ${props =>
        props.hide &&
        css`
            display: none;
        `}
`;

export const ContinueButtonWithMarginB = styled(ContinueButtonWithMarginA)`
    margin-top: 30px;
`;

export const NextButtonWithMarginA = styled(NextButton)`
    margin-bottom: 20px;
`;

export const NextButtonWithMarginB = styled(NextButtonWithMarginA)`
    margin-top: 10px;
`;
