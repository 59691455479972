import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { Input } from './utilities/commonUtilities/FormElements';
import { COLOR_GREY } from './utilities/Material';
import { makeStyles } from '@material-ui/core/styles';
import { styleBreakDesktop } from './utilities/layouts/Globals';

const useStyles = makeStyles(theme => ({
    input: {
        width: '100%',
        [styleBreakDesktop(theme)]: {
            width: theme.spacing(27),
        },
    },
}));

const HealthCardNumberSpecificInput = styled(Input)`
    font-family: ${props => props.theme.typography.fontFamily};
    ::placeholder {
        color: ${COLOR_GREY.light};
    }
`;

export const OhipInput = props => {
    const classes = useStyles();

    return (
        <HealthCardNumberSpecificInput
            className={classes.input}
            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            {...props}
            placeholder="####-###-###"
            as={MaskedInput}
        />
    );
};

export const NovaScotiaInput = props => {
    const classes = useStyles();

    return (
        <HealthCardNumberSpecificInput
            className={classes.input}
            mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
            {...props}
            placeholder="#### ### ###"
            as={MaskedInput}
        />
    );
};

export const AlbertaInput = props => {
    const classes = useStyles();

    return (
        <HealthCardNumberSpecificInput
            className={classes.input}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            {...props}
            placeholder="#####-####"
            as={MaskedInput}
        />
    );
};

export const NoHealthCardSystemSelectedInput = props => {
    const classes = useStyles();

    return <HealthCardNumberSpecificInput className={classes.input} {...props} />;
};
