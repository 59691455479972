import produce from 'immer';
import * as actions from '../actions/actionTypes';
import { noteFromOutcome } from '../components/noteHistory/NoteUtils';
import { LoadingResult, LoadingStatus } from '../utils/LoadingUtils';

const outcomes = (
    state = {
        notes: [],
        loading: LoadingStatus.NONE,
        loadingResult: LoadingResult.NONE,
    },
    action
) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.OUTCOME_STORE:
                draft.notes = processOutcomes(action.data);
                draft.loading = LoadingStatus.STARTED;
                break;
            case actions.OUTCOME_APPEND:
                let results = processOutcomes(action.data);
                draft.notes = state.notes.concat(results);
                break;
            case actions.OUTCOME_LOAD_ENDED:
                draft.loading = LoadingStatus.ENDED;
                break;
            case actions.OUTCOMES_LOADING:
                draft.loading = action.outcomesLoading;
                break;
            case actions.OUTCOME_LOADING_RESULT:
                draft.loadingResult = action.loadingResult;
                break;
            default:
                break;
        }
    });

export default outcomes;

function processOutcomes(outcomes) {
    return outcomes.map(noteFromOutcome);
}
