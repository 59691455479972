import React from 'react';
import { connect } from 'react-redux';
import { SmallBody1Black } from '../utilities/commonUtilities/GenericDivElements';
import { Redirect } from 'react-router-dom';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { Box } from '@material-ui/core';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { PATH_NOTES } from '../../containers/RoutePaths';
import { LoadingStatus } from '../../utils/LoadingUtils';
import { NotesConsumer } from './NotesConsumer';
import { toNoteId } from './NoteUtils';

const NotePage = ({ note, loading }) => {
    if (!note && loading === LoadingStatus.ENDED) {
        return <Redirect to={PATH_NOTES} />;
    } else {
        return (
            <RequiresLogin>
                <NotesConsumer>
                    <SingleNoteComponent note={note} parentUrl={PATH_NOTES} />
                </NotesConsumer>
            </RequiresLogin>
        );
    }
};

/**
 * Component that displays a note item.
 *
 * @param note A {note, receivedDate, onClick} display item.
 * @param parentUrl Optional. The url of the parent this component returns to for back click.
 *                  Provide this or onClick.
 * @param onClick Optional. The click handler to use when clicking the back button.
 *                Provide tthis or parentUrl.
 */
export const SingleNoteComponent = ({ note, parentUrl, onClick }) => {
    if (!note) {
        return null;
    }
    return (
        <DetailsLayout title="Doctor's Note" backTo={parentUrl} onClick={onClick}>
            <ContentLayout>
                <SmallBody1Black>{note.receivedDate}</SmallBody1Black>
                <Box component="div">{prettyPrint(note.note)}</Box>
            </ContentLayout>
        </DetailsLayout>
    );
};

/**
 * Convert newlined text into paragraphs.
 * TODO depends on what the source note content is. If it's already html this isn't needed.
 */
function prettyPrint(note) {
    const paragraphs = (note ?? '').split('\n');
    return (
        <>
            {paragraphs.map((line, i) => {
                return <p key={i}>{line}</p>;
            })}
        </>
    );
}

const mapStateToProps = (state, props) => {
    const noteId = props?.match?.params?.id ?? '';
    const note = state.outcomes.notes.find(note => toNoteId(note.id) === toNoteId(noteId));
    return {
        noteId,
        note,
        loading: state.outcomes.loading,
    };
};

export default connect(mapStateToProps)(NotePage);
