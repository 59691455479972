import React from 'react';
import { Typography } from '@material-ui/core/';
import { FONT_WEIGHT_BOLD, FONT_WEIGHT_NORMAL } from '../utilities/Material';
import { makeStyles } from '@material-ui/core/styles';
import { styleBreakMobile } from '../utilities/layouts/Globals';
import { SizeSelector } from '../utilities/layouts/SizeSelector';

const useStyles = makeStyles(theme => ({
    sectionHeading: {
        fontWeight: FONT_WEIGHT_BOLD,
        marginTop: theme.spacing(5),
    },
    bold: {
        fontWeight: FONT_WEIGHT_BOLD,
    },
    normal: {
        fontWeight: FONT_WEIGHT_NORMAL,
    },
    sectionList: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
    paragraph: {
        marginTop: theme.spacing(2),
        display: 'block',
    },
    underline: {
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
    },
    unOrderList: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(8),
    },
    termsAndConditonsTitle: {
        marginTop: theme.spacing(3),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(1),
        },
    },
    bottomPadding: {
        paddingBottom: theme.spacing(0.5),
        [styleBreakMobile(theme)]: {
            paddingBottom: theme.spacing(0),
        },
    },
}));

export const agreementVersion = '1.0';

const Paragraph = ({ children }) => {
    const classes = useStyles();
    return <Typography className={classes.paragraph}>{children}</Typography>;
};

const SectionList = ({ type, children, ...props }) => {
    const classes = useStyles();

    const mobileList = (
        <ol style={{ paddingInlineStart: '28px' }} type={type} className={classes.sectionList} {...props}>
            {children}
        </ol>
    );

    const desktopList = (
        <ol type={type} className={classes.sectionList} {...props}>
            {children}
        </ol>
    );
    return <SizeSelector mobile={mobileList} desktop={desktopList} />;
};

const SectionListItem = ({ keyValue, isBold = true, children }) => {
    const classes = useStyles();
    return (
        <li list-style-positionkey={keyValue} className={isBold ? classes.bold : classes.normal}>
            {children}
        </li>
    );
};

const UnOrderList = ({ children }) => {
    const classes = useStyles();
    return (
        <ul type="disc" className={classes.unOrderList}>
            {children}
        </ul>
    );
};

const UnOrderedListItem = ({ children }) => {
    return <li style={{ fontWeight: `${FONT_WEIGHT_NORMAL}` }}>{children}</li>;
};

const Bold = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography component="span" className={classes.bold}>
            {children}
        </Typography>
    );
};

const Underline = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography component="span" className={classes.underline}>
            {children}
        </Typography>
    );
};

const PrivacyPolicyLink = () => {
    return (
        <a href="https://nedclinic.ca/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
        </a>
    )
};

export const TermsOfServiceSummaryText = () => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h2" className={classes.termsAndConditonsTitle}>
                Terms and Conditions Summary{' '}
            </Typography>
            <Paragraph>
                You agree to use the Ned Clinic to facilitate the delivery of virtual follow-up care between you and your healthcare provider. Through this
                Application, you are able to complete outcome survey to report symptoms, download lab requisitions to get blood tests, get access to your
                prostate-related lab results, and receive clinical feedback on your health progress from your healthcare provider after each virtual follow-up
                visit. The Ned Clinic is not a medical device, and is not intended to diagnose, cure, mitigate, treat or prevent disease or other conditions.
            </Paragraph>
            <Paragraph>
                UHN is not responsible for any consequences related to the misuse of this Application. Information that you put in this Application will be sent
                to UHN. If you have questions or concerns, please contact us at:
            </Paragraph>
            <Paragraph>
                University Health Network 
                <br/>
                Centre for Global eHealth Innovation 
                <br/>
                Toronto General Hospital 
                <br/>
                R. Fraser Elliott Building, 4th Floor 
                <br/>
                200 Elizabeth Street 
                <br/>
                Toronto, ON 
                <br/>
                M5G 2C4 
                <br/>
                Email: support@nedclinic.ca
            </Paragraph>
        </>
    );
};

const thirdPartySoftwares = [
    '@bugsnag/js 7.6.0: Bugsnag; MIT',
    '@bugsnag/plugin-react 7.6.0: Bugsnag; MIT',
    'material-ui 4.10.1: Material-UI Team; MIT',
    'axios 0.19.2: Matt Zabriskie; MIT',
    'env-cmd 10.1.0: Todd Bluhm; MIT',
    'formik 2.1.4: Jared Palmer; Apache-2.0',
    'immer 5.3.6: Michel Weststrate; MIT',
    'moment 2.26.0: Iskren Ivov Chernev; MIT',
    'query-string 5.1.1: Sindre Sorhus; MIT',
    'react 16.13.1: Facebook; MIT',
    'react-dom 16.13.1: Facebook; MIT',
    'react-redux 7.2.0: Dan Abramov; MIT',
    'react-router-dom 5.2.0: React Training; MIT',
    'react-scripts 3.4.1: Facebook; MIT',
    'react-text-mask 5.4.3: M.K. Safi; unlicense',
    'redux 4.0.5: Dan Abramov; MIT',
    'redux-devtools-extension 2.13.8: Mihail Diordiev; MIT',
    'redux-saga 1.1.3: Yassine ELOUAFI; MIT',
    'styled-components 4.4.1: Glen Maddern; MIT',
    'yup 0.28.5: @monasticpanic Jason Quense; MIT',
    '@testing-library/react 9.5.0: Kent C. Dodds; MIT',
    'cypress 5.0.0: Brian Mann; MIT',
    'cypress-upload-file-post-form 1.1.0: Javier Aviles; MIT',
    'eslint-plugin-cypress 2.11.1: Chris Breiding; MIT',
    'jest-junit 10.0.0: Jason Palmer; Apache-2.0',
    'mocha 6.2.3: TJ Holowaychuk; MIT',
    'mochawesome 4.1.0: Adam Gruber; MIT',
    'react-test-renderer 16.13.1: Facebook; MIT',
    'redux-mock-store 1.5.4: Arnaud Benard; MIT',
    'wait-on 3.3.0: Jeff Barczewski; MIT',
];

export const TermsOfSeviceFullText = () => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="h2" className={classes.termsAndConditonsTitle}>
                Terms and Conditions{' '}
            </Typography>
            <SectionList type={'A'}>
                <SectionListItem key={'sectionA'}>
                    <Typography className={classes.sectionHeading}>COPYRIGHT</Typography>
                    <Paragraph>
                        <Bold>Ned Clinic</Bold> © <Bold>2022</Bold> University Health Network.
                    </Paragraph>
                    <Paragraph>
                        Developed by: <Bold>Centre for Global eHealth Innovation (University Health Network)</Bold>
                    </Paragraph>
                    <Paragraph>All rights reserved.</Paragraph>
                    <Paragraph>
                        All information in this website and web application (the <Bold>“Application”</Bold>) is protected by copyright in Canada and
                        internationally. The Application, including all images, photographs, animations, text and other audiovisual materials, and any and all
                        patents, copyrights, moral rights, trademarks, trade secrets and any other form of intellectual property rights recognized in any
                        jurisdiction, including applications and registrations for any of the foregoing embodied therein are owned by University Health Network
                        (<Bold>“UHN”</Bold>) or its licensors. Information in this Application has been developed by UHN. No information can be reproduced or 
                        distributed without the prior and express permission of UHN.
                    </Paragraph>
                    <Paragraph>For more information, questions, or complaints, please write to:</Paragraph>
                    <Paragraph>
                        University Health Network
                        <br />
                        Centre for Global eHealth Innovation
                        <br />
                        Toronto General Hospital,
                        <br />
                        R. Fraser Elliott Building, 4th Floor
                        <br />
                        200 Elizabeth Street
                        <br />
                        Toronto, ON
                        <br />
                        M5G 2C4
                        <br />
                        Email: support@nedclinic.ca
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB'}>
                    <Typography className={classes.sectionHeading}>END USER LICENSE AGREEMENT</Typography>
                    <Paragraph>
                        Please read the following End User License Agreement (<Bold>“EULA”</Bold>), including all disclaimers and terms and conditions of use
                        below, carefully before using the Application. By using or accessing this Application you acknowledge that you have read, understood and
                        accepted the disclaimers and terms and conditions of use contained in this EULA. If you do not accept any of these disclaimers or terms
                        and conditions, you must not use the Application.
                    </Paragraph>
                    <SectionList type={'a'}>
                        <SectionListItem keyValue={'sectionBa'} isBold={false}>
                            <Paragraph>
                                You are a prostate cancer survivor, and you are using this Application for your personal use and interest around virtual
                                follow-up care, and not on behalf of another party.
                            </Paragraph>
                        </SectionListItem>
                        <SectionListItem keyValue={'sectionBb'} isBold={false}>
                            <Paragraph>
                                You have read, understood and accepted the disclaimers and terms and conditions of use contained in this EULA and in the
                                corresponding <PrivacyPolicyLink/>, and accept all obligations and terms outlined within each, including all obligations regarding 
                                the use and disclosure of any personal information (“PI”) and personal health information (“PHI”) submitted by you through your 
                                use of this Application. More specifically you understand that your PHI will only be disclosed by the Application as outlined in 
                                the <PrivacyPolicyLink/>.
                            </Paragraph>
                        </SectionListItem>
                    </SectionList>
                    <Paragraph>
                        If you do not accept any of the disclaimers or terms and conditions outlined above and further herein, you must not use the Application.
                    </Paragraph>
                </SectionListItem>
            </SectionList>
            <SectionList type={'1'}>
                <SectionListItem keyValue={'sectionB 1'}>
                    <Paragraph>
                        <Underline>
                            <Bold>DISCLAIMER.</Bold>
                        </Underline>{' '}
                        <Bold>
                            UHN MAKES NO WARRANTIES OR REPRESENTATIONS AS TO THE ACCURACY OF THE INFORMATION PROVIDED VIA THIS
                            APPLICATION, INCLUDING DETAILS PROVIDED BY THE APPLICATION PARTICIPANT. THE INFORMATION CONTAINED IN OR PRESENTED FROM THE
                            APPLICATION IS EXCLUSIVELY INTENDED FOR INFORMATION PURPOSES AND COMES WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESSED
                            OR IMPLIED. ANY IMPLIED WARRANTY OR CONDITION OF ELIGIBILITY FOR A PARTICULAR PURPOSE, MERCHANTABILITY OR OTHERWISE IN RESPECT OF
                            THE APPLICATION AND/OR ANY MAINTENANCE OR SUPPORT FOR THE APPLICATION IS EXPRESSLY DISCLAIMED. APPLICATION OF THE UNITED NATIONS
                            CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS, INCLUDING ANY DOMESTIC LEGISLATION IMPLEMENTING SAME, IS EXPRESSLY
                            DISCLAIMED.
                        </Bold>
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 2'}>
                    <Paragraph>
                        <Underline>
                            <Bold>DISCLAIMER.</Bold>
                        </Underline>{' '}
                        Neither UHN, as an entity, nor any of its staff or contractors cannot under any circumstance be held liable for consequences caused by
                        or deriving from the use of the Application or any information contained in the Application. UHN is not liable for damages arising from
                        use of the Application, including any that may arise from any defects or viruses (or similarly disruptive elements) caused from using
                        the Application, or from websites (via hyperlinks) to which references are made in the Application, nor from information provided in the
                        Application or these referred websites. In no event shall UHN be liable for direct, indirect, consequential, special, exemplary, or
                        other damages related to your use of the Application (including any loss of data), regardless of how arising or the theory of liability
                        whether arising in contract, tort, negligence or otherwise. Frequent saving on the application is recommended because computer devices, 
                        tablets, and/or smartphones may malfunction from time to time and any unsaved data may be lost. You are responsible for backing up any 
                        data you enter into the Application. Computer devices, tablets, and/or smartphones may malfunction from time to time and any unsaved data 
                        may be lost.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 3'}>
                    <Paragraph>
                        <Underline>
                            <Bold>Governing Law and Jurisdiction.</Bold>
                        </Underline>{' '}
                        This EULA shall be interpreted under, and any action or claim arising from or related to the Application shall be brought in the courts
                        of, and governed exclusively by the laws of Ontario and Canada without regard to its conflicts of laws principles. Unless prohibited by
                        applicable law, you expressly waive the right to participate in a class action proceeding.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 4'}>
                    <Paragraph>
                        <Underline>
                            <Bold>Changes and Updates.</Bold>
                        </Underline>{' '}
                        UHN reserves the right to update or change information contained in the Application without prior notice or prior acceptance.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 5'}>
                    <Paragraph>
                        <Underline>
                            <Bold>Contact Information.</Bold>
                        </Underline>{' '}
                        If you have any questions about this EULA or any of its disclaimers, terms or conditions of use or general questions, please contact us
                        using the contact information set out above.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 6'}>
                    <Paragraph>
                        <Underline>
                            <Bold>Purpose of Application.</Bold>
                        </Underline>{' '}
                        Ned Clinic is intended to support prostate cancer patients who are in the survivorship phase of their cancer journey. The Ned Clinic system 
                        consists of a patient-facing application (Patient Application) as well as a clinician-facing Dashboard (Clinician Dashboard). Ned Clinic is 
                        intended to facilitate the delivery of virtual follow-up care for prostate cancer patients and to improve clinical management by sharing 
                        health information with their healthcare provider. Health data shared with their healthcare provider include patient-reported symptoms and 
                        lab results. Ned Clinic interfaces with Ontario Laboratory Information System (OLIS) to query prostate-related lab results. Based on the 
                        results of the patient-reported outcome survey and lab tests, the healthcare provider would then generate a care plan, in the form of a 
                        clinical note, for patients to view on the Patient Application. The feedback in the clinical note may tell patients of their next virtual 
                        follow-up visit date, schedule a phone or video call, or tell patients that an in-person visit is needed. The Application is not a medical 
                        device. Nothing contained in the Application should be construed, directly or indirectly, as the practice of medicine or providing medical 
                        advice or medical services by UHN. The Application is not intended to diagnose, cure, mitigate, treat or prevent disease or other conditions. 
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 7'}>
                    <Paragraph>
                        <Underline>
                            <Bold>Not Medical Advice.</Bold>
                        </Underline>{' '}
                        The Application is not a substitute for professional medical advice, diagnosis or treatment. Never make changes to your medication, nor
                        adjust your dose, without first consulting your health care provider. Always seek the advice of a physician or other qualified
                        healthcare provider concerning questions you have regarding 1) your participation in receiving follow-up care virtually, through this 
                        Application, 2) a medical condition, and 3) before starting, stopping or modifying any treatment or medication.
                    </Paragraph>
                    <Paragraph>
                        You are always free to exit your participation in the Ned Clinic, and return for in-person clinic visits to receive follow-up care as you 
                        wish. You are also free to seek clarification from your physician or other qualified healthcare provider concerning questions you may have 
                        regarding your participation in receiving follow-up care virtually through this Application.
                    </Paragraph>
                    <Paragraph>
                        Never delay obtaining medical advice or disregard medical advice because of something you have or have not read on the Application. If
                        you have, or suspect you have, a health problem, or if you experience an adverse side effect, please consult your healthcare provider. 
                        If you have, or suspect you are experiencing a health emergency, please call 911 and/or promptly visit a Hospital Emergency Department 
                        in your area.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 8'}>
                    <Paragraph>
                        <Underline>
                            <Bold>No Regulatory Approval.</Bold>
                        </Underline>{' '}
                        The Application has not been evaluated by Health Canada or any other domestic or foreign regulatory agency and is not approved to
                        diagnose, treat, cure or prevent any disease or for the prescription of any medication or treatment or as indication of their approval
                        of any. No other review or approval has been determined. This Application is provided and designed for use in Canada.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionB 9'}>
                    <Paragraph>
                        <Underline>
                            <Bold>Express Consent to Receiving Electronic Messages and Upgrades from UHN.</Bold>
                        </Underline>{' '}
                        By using the Application, You hereby expressly consent to receiving electronic messages from UHN, including alerts and reminders,
                        information, notifications, requests for your feedback, and other communications from UHN directly related to the Application, any
                        applicable software updates or upgrades to the Application, ongoing use by You of the Application. Any additional contact by email is
                        only as outlined in the Ned Clinic <PrivacyPolicyLink/>.
                    </Paragraph>
                </SectionListItem>
            </SectionList>
            <SectionList type={'A'} start="3">
                <SectionListItem keyValue={'sectionC'}>
                    <Typography className={classes.sectionHeading}>COLLECTION OF DATA</Typography>
                </SectionListItem>
            </SectionList>
            <SectionList type={'1'}>
                <SectionListItem keyValue={'sectionC 1'}>
                    <Paragraph>
                        <Bold>DISCLAIMER.</Bold> THE APPLICATION RECEIVES AND TRANSMITS YOUR ENTERED INFORMATION (WHICH MAY INCLUDE PERSONAL INFORMATION AND/OR PERSONAL
                        HEALTH INFORMATION) OVER THE INTERNET. INFORMATION TRANSMITTED OVER THE INTERNET MAY BE AT RISK OF INADVERTENT LOSS OR THEFT. UHN CANNOT
                        GUARANTEE THE SECURITY OF ANY INFORMATION TRANSMITTED TO OR FROM THE APPLICATION. BY USING THE APPLICATION, YOU AGREE THAT YOUR ACCESS
                        AND USE OF THE APPLICATION AND THE CONTENT, INCLUDING BUT NOT LIMITED TO PERSONAL INFORMATION AND PERSONAL HEALTH INFORMATION, IS
                        ENTIRELY AT YOUR OWN RISK AND LIABILITY.
                    </Paragraph>
                    <Paragraph>
                        <Underline>
                            <Bold>Transmission of non-identifying data to UHN.</Bold>
                        </Underline>{' '}
                        The Application collects non-personally identifiable data (which is transmitted back to us) through the Application regarding the use of
                        the Application in order to better understand use of the Application and to help us identify areas for improvement. If you do not
                        consent to the transmission of this data to UHN, then you must not use the Application.
                    </Paragraph>
                    <Paragraph>
                        <Underline>
                            <Bold>Transmission and Storage of Identifying Data and PHI.</Bold>
                        </Underline>{' '}
                        The Application collects the users’ personal information and/or personal health information, including your questionnaire results, your
                        lab requisition form, and your clinical note. This information will only be shared with your health care team and will be used to help
                        them provide follow-up care for you in this Application. This information will be kept in your clinical file within this Application, as
                        long as it is needed. More information can be found in the Ned Clinic <PrivacyPolicyLink/>.
                    </Paragraph>
                    <Paragraph>
                        <Underline>
                            <Bold>Transmission of Identifying Data and PHI from OLIS.</Bold>
                        </Underline>{' '}
                        The Application collects users’ personal health information, including Prostate-Specific Antigen and Testosterone lab results, from 
                        OLIS. This empowers users to view their own lab results before their follow-up encounter with their providers. This
                        information will only be shared with your health care team and will be used to help them provide follow-up care for you in this
                        Application. This information will be kept in your clinical file within this Application, as long as it is needed. UHN is not
                        responsible for the availability and accuracy of the data transmitted from OLIS.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionC 2'}>
                    <Paragraph>
                        <Bold>USE OF DATA</Bold>
                        <br />
                        The information provided by You to the Application is considered Personal Health Information. During the registration process, you will
                        provide your full name and contact information to create your account in the Application. When your visit encounter begins, you will
                        provide to the Application, additional personal health information about yourself through the completion of one or more symptom
                        questionnaires. In addition, you will receive your provincial lab requisition form in the Application to undergo tests at your desired 
                        lab center.
                    </Paragraph>
                    <Paragraph>
                        If you are a user in Ontario, the Application will automatically collect your lab results when made available from OLIS, and the lab 
                        results will then be made available in this Application for you to view. If you are a user outside of Ontario, your healthcare provider 
                        will manually collect your lab results, and upload your lab results to this Application for you to view. 
                    </Paragraph>
                    <Paragraph>
                        The results of these tasks (e.g., symptom questionnaires and lab results) will be kept in this Application for your record and will also 
                        be provided to your healthcare provider in their Clinician Application to facilitate the completion of your virtual follow-up. Your 
                        healthcare provider will then review the results of these tasks, and provide an appropriate care plan for you that is documented in
                        a clinical note. You will receive a clinical note, reflecting your healthcare provider's conclusion and plan of your health status, and 
                        your next routine virtual visit date, in this Application.
                    </Paragraph>
                    <Paragraph>
                        Any information you provide or receive from the Application will only be shared with your health care team, and are used to help them
                        facilitate the delivery of virtual follow-up care. In addition, this information will be kept in your clinical file within this
                        Application, as long as it is needed.
                    </Paragraph>
                    <Paragraph>
                        Any information used for product improvement purposes of this Application to better understand the use of the Application, and to help
                        us identify areas for improvement, shall only utilize aggregate/summary information, and will not disclose individual level data.
                    </Paragraph>
                    <Paragraph>
                        You are consenting to the sharing of your personal health information, and any additional information solely for the purposes for your
                        health care provider to facilitate the delivery of virtual follow-up care for you.
                    </Paragraph>
                </SectionListItem>
            </SectionList>
            <SectionList type={'A'} start="4">
                <SectionListItem keyValue={'sectionD'}>
                    <Typography className={classes.sectionHeading}>LIMITED LICENSE.</Typography>
                    <Paragraph>
                        Through your account creation in the Ned Clinic, provided that you comply with the terms of this EULA, you are acquiring and UHN grants
                        you a personal, limited, non-exclusive and non-transferable license to use the Application through your device for personal, non-
                        commercial use solely as described herein.
                    </Paragraph>
                </SectionListItem>
                <SectionListItem keyValue={'sectionE'}>
                    <div className={classes.bottomPadding}>
                        <Typography className={classes.sectionHeading}>THIRD PARTY SOFTWARE LICENSES.</Typography>
                        <Paragraph>
                            Portion of this Application were derived from various third party and Open Source Libraries and are used under the following terms:
                        </Paragraph>
                        <Paragraph>
                            <Bold>Web Client:</Bold>
                        </Paragraph>
                        <UnOrderList>
                            {thirdPartySoftwares.map((value, index) => {
                                return <UnOrderedListItem key={`sectionE_${index}`}>{value}</UnOrderedListItem>;
                            })}
                        </UnOrderList>
                    </div>
                </SectionListItem>
            </SectionList>
        </>
    );
};
