/* Action creators for generating various action objects */

import * as actions from './actionTypes';

// TODO: Organize these actions into different action files.
export const loginPatient = (username, password) => {
    return {
        type: actions.LOGIN_PATIENT,
        username: username,
        password: password,
    };
};

export const logoutPatient = () => {
    return {
        type: actions.LOGOUT_PATIENT,
    };
};

export const logoutPatientSuccess = () => {
    return {
        type: actions.LOGOUT_PATIENT_SUCCESS,
    };
};

export const clearStore = () => {
    return {
        type: actions.CLEAR_STORE,
    };
};

export const logoutPatientError = response => {
    return {
        type: actions.LOGOUT_PATIENT_ERROR,
        payload: {
            response: response,
        },
    };
};

export const loginSuccess = response => {
    return {
        type: actions.LOGIN_PATIENT_SUCCESS,
        payload: {
            session: response,
        },
    };
};

export const loginFailure = unauthorized => {
    return {
        type: actions.LOGIN_PATIENT_AUTH_FAILURE,
        unauthorized,
    };
};

export const loginNetworkError = () => {
    return {
        type: actions.LOGIN_NETWORK_ERROR,
        payload: {
            isNetworkError: true,
        },
    };
};

export const loginRequestPasswordReset = (email, setRequestSubmissionStatus) => {
    return {
        type: actions.LOGIN_REQUEST_PASSWORD_RESET,
        email,
        setRequestSubmissionStatus,
    };
};

export const loginRetrieveSession = () => {
    return {
        type: actions.LOGIN_RETRIEVE_SESSION,
    };
};

export const loginProcessData = data => {
    return {
        type: actions.LOGIN_PROCESS_DATA,
        data,
    };
};

export const loginResetState = () => {
    return {
        type: actions.LOGIN_RESET_STATE,
    };
};

export const buildWellnessQuestionnaire = questions => {
    return {
        type: actions.WELLNESS_BUILD_QUESTIONNAIRE,
        questions: questions,
    };
};

export const updateCurrentWellnessQuestion = index => {
    return {
        type: actions.WELLNESS_UPDATE_CURRENT_QUESTION,
        index: index,
    };
};

export const updateActiveWellnessAnswer = linkId => {
    return {
        type: actions.WELLNESS_UPDATE_ACTIVE_ANSWER_LINK_ID,
        linkId: linkId,
    };
};

export const retrieveWellnessQuestionnaire = () => {
    return {
        type: actions.WELLNESS_RETRIEVE_QUESTIONNAIRE,
    };
};

export const sendWellnessQuestionnaireResponse = (dispatchDataSubmissionResult, refreshVisit = false) => {
    return {
        type: actions.WELLNESS_SEND_QUESTIONNAIRE_RESPONSE,
        dispatchDataSubmissionResult,
        refreshVisit,
    };
};

export const discardWellnessAnswers = () => {
    return {
        type: actions.WELLNESS_DISCARD_ANSWERS,
    };
};

export const toggleWellnessAlert = () => {
    return {
        type: actions.WELLNESS_TOGGLE_ALERT,
    };
};

export const discardEntireWellnessQuestionnaire = () => {
    return {
        type: actions.WELLNESS_DISCARD_ENTIRE_QUESTIONNAIRE,
    };
};

export const setIsSurveyInitiated = isSurveyInitiated => {
    return {
        type: actions.WELLNESS_SET_IS_SURVEY_INITIATED,
        isSurveyInitiated,
    };
};

export const discardAccountSetup = () => {
    return {
        type: actions.ACCOUNT_SETUP_DISCARD,
    };
};

export const setDisplayAccountSetupAlert = isDisplay => {
    return {
        type: actions.ACCOUNT_SETUP_SET_DISPLAY_ALERT,
        isDisplay,
    };
};

export const setSecurityQuestionsAndSubmitAccountCreationInfo = (securityQuestionsAndPassword, dispatchDataSubmissionResult) => {
    return {
        type: actions.ACCOUNT_SETUP_SECURITY_QUESTIONS_AND_PASSWORD_SUBMIT,
        securityQuestionsAndPassword,
        dispatchDataSubmissionResult,
    };
};

export const setSetupAccountCurrentPage = pageNumber => {
    return {
        type: actions.ACCOUNT_SETUP_SET_CURRENT_PAGE,
        currentPage: pageNumber,
    };
};

export const verifyAccountSetupPatient = (token, healthCardSystem, healthCardNumber, dateOfBirth, dispatchDataSubmissionResult) => {
    return {
        type: actions.ACCOUNT_SETUP_VERIFY_PATIENT,
        token,
        healthCardSystem,
        healthCardNumber,
        dateOfBirth,
        dispatchDataSubmissionResult,
    };
};

export const setSetupAccountVerificationParameters = token => {
    return {
        type: actions.ACCOUNT_SETUP_SET_VERIFICATION_PARAMETERS,
        token,
    };
};

export const setSetupAccountVerificationResponse = response => {
    return {
        type: actions.ACCOUNT_SETUP_SET_VERIFICATION_RESPONSE,
        response,
    };
};

export const accountSetupCreationCompleteError = response => {
    return {
        type: actions.ACCOUNT_SETUP_CREATION_COMPLETE_ERROR,
        response,
    };
};

export const accountSetupCreationCompleteSuccess = response => {
    return {
        type: actions.ACCOUNT_SETUP_CREATION_COMPLETE_SUCCESS,
        response,
    };
};

export const setVersionOfAgreement = version => {
    return {
        type: actions.ACCOUNT_SETUP_SET_AGREEMENT_VERSION,
        version,
    };
};

export const setPatientVerificationInfo = (dateOfBirth, healthCardSystem, healthCardNumber) => {
    return {
        type: actions.ACCOUNT_SETUP_SET_PATIENT_VERIFICATION_INFO,
        patientVerificationInfo: {
            healthCardSystem,
            healthCardNumber,
            dateOfBirth,
        },
    };
};

export const retrieveCurrentVisit = () => {
    return {
        type: actions.CURRENT_VISIT_RETRIEVE,
    };
};

/**
 * @param refreshVisit Optional. True to request a refresh of the visit after close. Default false.
 */
export const closeCurrentVisit = (refreshVisit = false, refreshLoginSession = false) => {
    return {
        type: actions.CURRENT_VISIT_CLOSE,
        refreshVisit,
        refreshLoginSession,
    };
};

export const buildCurrentVisit = currentVisit => {
    return {
        type: actions.CURRENT_VISIT_BUILD,
        currentVisitObject: currentVisit,
    };
};

export const discardCurrentVisit = () => {
    return {
        type: actions.CURRENT_VISIT_DISCARD,
    };
};

export const setCurrentVisitRecentlyCompletedTasks = (isAllTasksRecentlyCompleted = false) => {
    return {
        type: actions.CURRENT_VISIT_SET_RECENTLY_COMPLETED_ALL_TASKS,
        isAllTasksRecentlyCompleted,
    };
};

export const setCompletedCurrentVisitTask = taskName => {
    return {
        type: actions.CURRENT_VISIT_SET_COMPLETED_TASK,
        taskName: taskName,
    };
};

export const passwordResetSaveSecurityQuestions = securityQuestions => {
    return {
        type: actions.PASSWORD_RESET_SAVE_SECURITY_QUESTIONS,
        securityQuestions: securityQuestions,
    };
};

export const PasswordResetRetrieveSecurityQuestions = (resetToken, errorCallBack) => {
    return {
        type: actions.PASSWORD_RESET_RETRIEVE_SECURITY_QUESTIONS,
        resetToken,
        errorCallBack,
    };
};

export const PasswordResetSubmitAnswersToSecurityQuestions = securityQuestionsAndAnswersAndToken => {
    return {
        type: actions.PASSWORD_RESET_SUBMIT_ANSWERS_TO_SECURITY_QUESTIONS,
        securityQuestionsAndAnswersAndToken,
    };
};

export const passwordResetSaveOneTimeResetToken = oneTimeResetToken => {
    return {
        type: actions.PASSWORD_RESET_SAVE_ONE_TIME_RESET_TOKEN,
        oneTimeResetToken,
    };
};

export const passwordResetSubmitNewPassword = (newPasswordAndOneTimeToken, onSubmitSuccess, onSubmitFail) => {
    return {
        type: actions.PASSWORD_RESET_SUBMIT_NEW_PASSWORD,
        newPasswordAndOneTimeToken,
        onSubmitSuccess,
        onSubmitFail,
    };
};

export const passwordResetRecordFailureInSecurityQuestion = securityQuestionFailed => {
    return {
        type: actions.PASSWORD_RESET_RECORD_FAILURE_IN_SECURITY_QUESTION,
        securityQuestionFailed,
    };
};

export const passwordResetRecordNetworkError = () => {
    return {
        type: actions.PASSWORD_RESET_RECORD_NETWORK_ERROR,
    };
};

export const passwordResetClearErrorAndOneTimeTokenStates = () => {
    return {
        type: actions.PASSWORD_RESET_CLEAR_ERROR_AND_ONE_TIME_TOKEN_STATES,
    };
};

export const actionPutObservationIntoStore = observations => {
    return {
        type: actions.OBSERVATION_STORE,
        observations,
    };
};

export const actionAppendObservationIntoStore = observations => {
    return {
        type: actions.OBSERVATION_APPEND,
        observations,
    };
};

export const retrieveCurrentOutcome = () => {
    return {
        type: actions.RETRIEVE_CURRENT_OUTCOME,
    };
};

export const saveCurrentOutcome = currentOutcome => {
    return {
        type: actions.CURRENT_OUTCOME_SAVE,
        currentOutcome,
    };
};

export const discardCurrentOutcome = () => {
    return {
        type: actions.CURRENT_OUTCOME_DISCARD,
    };
};

export const actionPutLabReqIdIntoStore = labReqId => {
    return {
        type: actions.PDF_ID_STORE,
        labReqId,
    };
};

export const putPatienFhirInfoIntoStore = patient => {
    return {
        type: actions.PATIENT_FHIR_INFO_STORE,
        patient,
    };
};

export const retrievePatientInfoFromFhir = patientId => {
    return {
        type: actions.PATIENT_FHIR_INFO_RETRIEVE,
        patientId,
    };
};

export const putNedUserInfoIntoStore = user => {
    return { type: actions.PATIENT_NED_USER_INFO_STORE, user };
};

export const retrieveNedUserInfo = () => {
    return {
        type: actions.NED_USER_INFO_RETRIEVE,
    };
};

export const storeQuestionnaireResponse = questionnaireResponses => {
    return {
        type: actions.SURVEY_RESPONSE_STORE,
        data: questionnaireResponses,
    };
};

export const appendQuestionnaireResponse = questionnaireResponses => {
    return {
        type: actions.SURVEY_RESPONSE_APPEND,
        data: questionnaireResponses,
    };
};

export const storeOutcomes = outcomes => {
    return {
        type: actions.OUTCOME_STORE,
        data: outcomes,
    };
};

export const appendOutcomes = outcomes => {
    return {
        type: actions.OUTCOME_APPEND,
        data: outcomes,
    };
};

/**
 * Action that signifies the end of loading outcomes.
 * Success or error, for any instance where no more paging is being performed.
 * Generally dispatched by the outcome loading process.
 */
export const endLoadOutcomes = () => {
    return {
        type: actions.OUTCOME_LOAD_ENDED,
    };
};

export const loadOutcomes = outcomesLoading => {
    return {
        type: actions.OUTCOMES_LOADING,
        outcomesLoading,
    };
};

export const setOutcomeLoadingResult = loadingResult => {
    return {
        type: actions.OUTCOME_LOADING_RESULT,
        loadingResult,
    };
};

export const endLoadQuestionnaireResponse = () => {
    return {
        type: actions.SURVEY_RESPONSE_FETCH_END,
    };
};

export const loadQuestionnaireResponse = questionnaireResponseLoading => {
    return {
        type: actions.QUESTIONNAIRE_RESPONSE_LOADING,
        questionnaireResponseLoading,
    };
};

export const setQuestionnaireResponseLoadingResult = loadingResult => {
    return {
        type: actions.QUESTIONNAIRE_RESPONSE_LOADING_RESULT,
        loadingResult,
    };
};

export const dataSubmissionResult = (isSuccessful, onSubmissionSuccess, onSubmissionFailure) => {
    return {
        type: actions.DATA_SUBMISSION_RESULT,
        isSuccessful,
        onSubmissionSuccess,
        onSubmissionFailure,
    };
};

export const profileChangePassword = (submittedValues, setErrors, onSubmitSuccess, dispatchDataSubmissionResult) => {
    return {
        type: actions.PROFILE_CHANGE_PASSWORD,
        submittedValues,
        setErrors,
        onSubmitSuccess,
        dispatchDataSubmissionResult,
    };
};

export const profileChangeSecurityQuestions = (securityQuestionsAndAnswers, onSubmitSuccess, onSubmitFail, dispatchDataSubmissionResult) => {
    return {
        type: actions.PROFILE_CHANGE_SECURITY_QUESTIONS,
        securityQuestionsAndAnswers,
        onSubmitSuccess,
        onSubmitFail,
        dispatchDataSubmissionResult,
    };
};

export const loadVisit = visitLoading => {
    return {
        type: actions.DATA_VISIT_LOADING,
        visitLoading,
    };
};

export const setVisitLoadingResult = loadingResult => {
    return {
        type: actions.DATA_VISIT_LOADING_RESULT,
        loadingResult,
    };
};

export const loadLabReqId = labReqIdLoading => {
    return {
        type: actions.DATA_LAB_REQ_ID_LOADING,
        labReqIdLoading,
    };
};

export const setLabReqIdLoadingResult = loadingResult => {
    return {
        type: actions.DATA_LAB_REQ_ID_LOADING_RESULT,
        loadingResult,
    };
};

export const loadWellnessSurveyQuestionnaire = questionnaireLoading => {
    return {
        type: actions.DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING,
        questionnaireLoading,
    };
};

export const setWellnessSurveyQuestionnaireLoadingResult = loadingResult => {
    return {
        type: actions.DATA_WELLNESS_SURVEY_QUESTIONNAIRE_LOADING_RESULT,
        loadingResult,
    };
};

export const loadObservations = observationsLoading => {
    return {
        type: actions.DATA_OBSERVATIONS_LOADING,
        observationsLoading,
    };
};

export const setObservationsLoadingResult = loadingResult => {
    return {
        type: actions.DATA_OBSERVATIONS_LOADING_RESULT,
        loadingResult,
    };
};

export const loadFhirPatientInfo = fhirPatientInfoLoading => {
    return {
        type: actions.DATA_FHIR_PATIENT_INFO_LOADING,
        fhirPatientInfoLoading,
    };
};

export const setFhirPatientInfoLoadingResult = loadingResult => {
    return {
        type: actions.DATA_FHIR_PATIENT_INFO_LOADING_RESULT,
        loadingResult,
    };
};

export const loadNedUserInfo = nedUserInfoLoading => {
    return {
        type: actions.DATA_NED_USER_INFO_LOADING,
        nedUserInfoLoading,
    };
};

export const setNedUserInfoLoadingResult = loadingResult => {
    return {
        type: actions.DATA_NED_USER_INFO_LOADING_RESULT,
        loadingResult,
    };
};

export const labWorkTaskInProgressMarking = (dispatchDataSubmissionResult, refreshVisit = false) => {
    return {
        type: actions.MARK_LAB_WORK_TASK_AS_IN_PROGRESS,
        dispatchDataSubmissionResult,
        refreshVisit,
    };
};

export const labWorkTaskCompleteMarking = (dispatchDataSubmissionResult, refreshVisit = false) => {
    return {
        type: actions.MARK_LAB_WORK_TASK_COMPLETE,
        dispatchDataSubmissionResult,
        refreshVisit,
    };
};

export const sendClientAnalytics = clientAnalyticsData => {
    return {
        type: actions.ANALYTICS_SEND_CLIENT_ANALYTICS,
        clientAnalyticsData,
    };
};
