export const PATH_LOGIN = '/patient/login';
export const PATH_ONE_PLUS_AUTHENTICATION = '/patient/login/enterCode';
export const PATH_HOME = '/patient/home';
export const PATH_WELLNESS_SURVEY_HOME = '/patient/home/wellnessSurvey';
export const PATH_WELLNESS_SURVEY_QUESTION = '/patient/wellnessSurvey/questions';
export const PATH_LAB_REQUISITION = '/patient/home/labRequisition';
export const PATH_DOCTOR_NOTE = '/patient/home/doctorNote';
export const PATH_NOTES = '/patient/notes';
export const PATH_LABS_PSA = '/patient/labs/psa';
export const PATH_LABS = '/patient/labs';
export const PATH_LABS_TESTOSTERONE = '/patient/labs/testosterone';
export const PATH_SURVEY_RESPONSES = '/patient/surveys/responses/';
export const PATH_RESET_LINK_EXPIRED = '/patient/resetLinkExpired';
export const PATH_PROFILE_ACCOUNT_DETAILS = '/patient/profile/accountDetails';
export const PATH_PROFILE_PASSWORD = '/patient/profile/password';
export const PATH_PROFILE_SECURITY_QUESTIONS = '/patient/profile/securityQuestions';
