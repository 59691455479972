import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import configureStore from './store/configureStore';
import { NedTheme } from './components/utilities/Material';
import { CssBaseline, ThemeProvider, StylesProvider } from '@material-ui/core';
import { CurrentVisitProvider, CurrentVisitAPIProvider } from './userDataProviders/CurrentVisitProvider';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { version } from '../package.json';

// Start bugsnag with the ned-patient api key.
Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: version,
    releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
});

const store = configureStore();

const Providers = ({ children }) => {
    return (
        <StoreProvider store={store}>
            <CurrentVisitProvider>
                <CurrentVisitAPIProvider>
                    <StylesProvider injectFirst>
                        <ThemeProvider theme={NedTheme}>{children}</ThemeProvider>
                    </StylesProvider>
                </CurrentVisitAPIProvider>
            </CurrentVisitProvider>
        </StoreProvider>
    );
};

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
    <ErrorBoundary>
        <Providers>
            <CssBaseline />
            <Router>
                <App />
            </Router>
        </Providers>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
