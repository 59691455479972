import * as actions from '../actions/actionTypes';
import { formatDateMMMDYYYY } from '../components/utilities/commonUtilities/dateTools';
import produce from 'immer';
import { LoadingResult, LoadingStatus } from '../utils/LoadingUtils';

const completedSurveys = (state = { surveys: [], loading: LoadingStatus.NONE, loadingResult: LoadingResult.NONE }, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.SURVEY_RESPONSE_STORE:
                draft.surveys = processData(action.data);
                draft.loading = LoadingStatus.STARTED;
                break;
            case actions.SURVEY_RESPONSE_APPEND:
                let results = processData(action.data);
                draft.surveys = state.surveys.concat(results);
                break;
            case actions.SURVEY_RESPONSE_FETCH_END:
                draft.loading = LoadingStatus.ENDED;
                break;
            case actions.QUESTIONNAIRE_RESPONSE_LOADING:
                draft.loading = action.QUESTIONNAIRE_RESPONSE_LOADING;
                break;
            case actions.QUESTIONNAIRE_RESPONSE_LOADING_RESULT:
                draft.loadingResult = action.loadingResult;
                break;
            default:
                return state;
        }
    });

function processData(data) {
    return data.map(item => {
        return formatData(item.resource);
    });
}

function formatData(fhirResource) {
    const formattedDate = formatDateMMMDYYYY(fhirResource.authored);
    return {
        id: fhirResource.id,
        authored: formattedDate,
        responses: processQuestionAnswer(fhirResource),
    };
}

function processQuestionAnswer(resource) {
    if (resource.item) {
        return resource.item.map(element => {
            return element.item.map(response => {
                const answer = response.answer[0].valueString;
                return {
                    linkId: response.linkId,
                    valueStringAnswer: answer,
                };
            });
        });
    }
}

export default completedSurveys;
