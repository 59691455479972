/* Container for connecting Login component to the Redux store */

import { connect } from 'react-redux';
import Login from '../components/login/Login';
import { loginPatient } from '../actions';

const mapStateToProps = ({ login }) => {
    return {
        hasAuthenticated: login.hasAuthenticated,
        token: !!login.session && !!login.session.token ? login.session.token : '',
        isNetworkError: login.isNetworkError,
        isDeviceTokenRequired: login?.session?.extraFields?.isDeviceTokenRequired,
        failureCode: login?.unauthorized?.failureCode,
        serverTimeInMillis: login?.unauthorized?.extraFields?.serverTimeInMillis,
        lockedUntil: login?.unauthorized?.lockedUntil,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (username, password) => {
            dispatch(loginPatient(username, password));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
