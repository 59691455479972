import axios from 'axios';

/**
 * Retrieve the active visit if it exists.
 */
export const getCurrentVisit = () => {
    return axios.get('/api/visit/current');
};

/**
 * Retrieve the earliest future visit if it exists.
 */
export function getFutureVisit() {
    return axios.get('/api/visit/future');
}

export function closeCurrentVisit() {
    return axios.post('/api/visit/acknowledgeAssessment');
}

export function markLabWorkTaskAsInProgress() {
    return axios.post('/api/visit/markLabTaskInProgress');
}

export function markLabTaskComplete() {
    return axios.post('/api/visit/markLabTaskComplete');
}
