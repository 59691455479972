import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { retrieveWellnessQuestionnaire } from '../../actions';
import { actionResponseFetch } from './SurveyUtils';

const SurveyResponseLoader = ({ fetchWellnessSurveyQuestionnaire, fetchQuestionnaireResponses, patientFhirId, children }) => {
    useEffect(() => {
        fetchWellnessSurveyQuestionnaire();
    }, [fetchWellnessSurveyQuestionnaire]);

    useEffect(() => {
        fetchQuestionnaireResponses(patientFhirId);
    }, [patientFhirId, fetchQuestionnaireResponses]);

    return <>{children}</>;
};

const mapStateToProps = state => {
    return {
        patientFhirId: state?.login?.session?.patientId ?? '',
    };
};

const mapDispatch = {
    fetchWellnessSurveyQuestionnaire: retrieveWellnessQuestionnaire,
    fetchQuestionnaireResponses: actionResponseFetch,
};

/**
 * Component that requests loading of questionnaire responses and wellness survey questionnaire
 * because the children components intend to use questionnaire responses and wellness survey from the store.
 *
 * Note:
 * The request is not guaranteed to be successful or completed by the time that the children are rendered.
 *
 * Usage:
 *
 * <SurveyResponseLoader>
 *    ... <Content/>
 * </SurveyResponseLoader>
 */
export default connect(mapStateToProps, mapDispatch)(SurveyResponseLoader);
