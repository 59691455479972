import React from 'react';
import { IconButton, SvgIcon, Paper, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackSvg } from '../../../resources/images/back.svg';
import { ContentLayout } from './ContentLayout';

/**
 * A left aligned back button.
 *
 * @param {*} backTo The target URL to return to when clicked.
 * @param optional onClick function primarily used to navigate away from sub-pages when route is not present
 */
export function BackButton({ backTo, onClick }) {
    const history = useHistory();
    const onBackClick = () => {
        history.push(backTo);
    };
    const clickAction = onClick ?? onBackClick;
    return (
        <IconButton onClick={clickAction} edge="start">
            <SvgIcon component={BackSvg} />
        </IconButton>
    );
}

/**
 * A cross-screen floating app bar with just a back button.
 *
 * @param {*} backTo The target URL to return to when clicked.
 */
export function BackBar({ backTo, onClick }) {
    return (
        <Box boxShadow="0px 1px 4px rgba(116, 123, 132, 0.1)">
            <Paper elevation={0} square>
                <ContentLayout>
                    <BackButton backTo={backTo} onClick={onClick} />
                </ContentLayout>
            </Paper>
        </Box>
    );
}
