/* This wraps around all routes */

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import LoginPage from './LoginPage';
import SetupAccountWizardPage from './SetupAccountWizardPage';
import RequestPasswordResetPage from './RequestPasswordResetPage';
import ResetPasswordWizardPage from './ResetPasswordWizardPage';
import LabsPage from '../components/labs/LabsPage';
import PSAResultsPage from '../components/labs/PSAResultsPage';
import TestosteroneResultsPage from '../components/labs/TestosteroneResultsPage';
import SurveysPage from '../components/surveys/SurveysPage';
import SurveysResponsePage from '../components/surveys/SurveyResponsePage';
import NoteHistory from '../components/noteHistory/NoteHistory';
import NotePage from '../components/noteHistory/NotePage';
import ProfilePage from '../components/profile/ProfilePage';
import ChangePasswordPage from '../components/profile/ChangePasswordPage';
import ChangeSecurityQuestionsPage from '../components/profile/ChangeSecurityQuestionsPage';
import WellnessSurveyHome from '../components/home/WellnessSurveyHome';
import LabRequisitionHome from '../components/home/LabRequisitionHome';
import RestLinkExpiredPage from '../components/ResetLinkExpiredPage';
import ProfileAccountDetailsPage from '../components/profile/ProfileAccountDetailsPage';
import OnePlusAuthentication from '../components/login/OnePlusAuthentication';

import TaskHome from '../components/home/TaskHome';
import DoctorNote from '../components/home/DoctorNote';
import {
    PATH_LOGIN,
    PATH_WELLNESS_SURVEY_HOME,
    PATH_WELLNESS_SURVEY_QUESTION,
    PATH_LAB_REQUISITION,
    PATH_HOME,
    PATH_DOCTOR_NOTE,
    PATH_NOTES,
    PATH_LABS_PSA,
    PATH_LABS_TESTOSTERONE,
    PATH_SURVEY_RESPONSES,
    PATH_LABS,
    PATH_RESET_LINK_EXPIRED,
    PATH_PROFILE_PASSWORD,
    PATH_PROFILE_SECURITY_QUESTIONS,
    PATH_PROFILE_ACCOUNT_DETAILS,
    PATH_ONE_PLUS_AUTHENTICATION,
} from './RoutePaths';
import WellnessSurveyQuestion from '../components/home/WellnessSurveyQuestion';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/patient" component={LoginPage} />
            <Route exact path={PATH_LOGIN} component={LoginPage} />
            <Route exact path="/patient/login/requestPasswordReset" component={RequestPasswordResetPage} />
            <Route exact path={PATH_HOME} component={TaskHome} />
            <Route exact path={PATH_NOTES} component={NoteHistory} />
            <Route exact path={PATH_NOTES.concat('/:id')} component={NotePage} />
            <Route exact path="/patient/profile" component={ProfilePage} />
            <Route exact path={PATH_WELLNESS_SURVEY_HOME} component={WellnessSurveyHome} />
            <Route exact path={PATH_WELLNESS_SURVEY_QUESTION} component={WellnessSurveyQuestion} />
            <Route exact path={PATH_LAB_REQUISITION} component={LabRequisitionHome} />
            <Route exact path={PATH_DOCTOR_NOTE} component={DoctorNote} />
            <Route exact path={PATH_LABS} component={LabsPage} />
            <Route exact path={PATH_LABS_PSA} component={PSAResultsPage} />
            <Route exact path={PATH_LABS_TESTOSTERONE} component={TestosteroneResultsPage} />
            <Route exact path="/patient/surveys" component={SurveysPage} />
            <Route exact path={PATH_SURVEY_RESPONSES.concat(':id')} component={SurveysResponsePage} />
            <Route exact path="/patient/accountSetup" component={SetupAccountWizardPage} />
            <Route path="/patient/passwordreset" component={ResetPasswordWizardPage} />
            <Route exact path={PATH_PROFILE_PASSWORD} component={ChangePasswordPage} />
            <Route exact path={PATH_PROFILE_SECURITY_QUESTIONS} component={ChangeSecurityQuestionsPage} />
            <Route exact path={PATH_PROFILE_ACCOUNT_DETAILS} component={ProfileAccountDetailsPage} />
            <Route exact path={PATH_RESET_LINK_EXPIRED} component={RestLinkExpiredPage} />
            <Route exact path={PATH_ONE_PLUS_AUTHENTICATION} component={OnePlusAuthentication} />
            <Route component={NotFoundPage} />
        </Switch>
    );
};

export default Routes;
