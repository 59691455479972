import React, { useState, useReducer } from 'react';
import { ErrorDiv, EmailInput } from '../utilities/commonUtilities/FormElements';
import NotLoggedInLayout from '../utilities/layouts/NotLoggedInLayout';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { styleBreakMobile } from '../utilities/layouts/Globals';
import * as yup from 'yup';
import { PATH_LOGIN } from '../../containers/RoutePaths';
import { ReactComponent as MailBadge } from '../../resources/images/mail-badge.svg';

const useStyles = makeStyles(theme => ({
    contentTitle: {
        marginBottom: theme.spacing(1),
    },
    emailText: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(0.5),
        },
    },
    emailInput: {
        width: '380px',
        [styleBreakMobile(theme)]: {
            width: '100%',
        },
    },
    checkEmailBody: {
        maxWidth: theme.spacing(37),
        textAlign: 'center',
        marginTop: theme.spacing(1),
    },
    mailBadge: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(4),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(6),
        },
    },
}));

export default function RequestPasswordReset({ onContinue, history }) {
    const [email, setEmail] = useState('');
    const [pageToDisplay, setIsRequestSucessful] = useReducer((state, action) => {
        switch (action.type) {
            case 'success':
                return 'CheckEmail';
            default:
                return 'TroubleLogging';
        }
    }, 'TroubleLogging');

    switch (pageToDisplay) {
        case 'TroubleLogging':
            return (
                <TroubleLogging
                    onContinue={email => {
                        onContinue(email, setIsRequestSucessful);
                    }}
                    setEmail={setEmail}
                />
            );
        case 'CheckEmail':
            return <CheckEmail email={email} history={history} />;
        default:
            return null;
    }
}

const INVALID_EMAIL_MESSAGE = 'Please enter a valid email.';

const emailSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
});

const TroubleLogging = ({ onContinue, setEmail }) => {
    const classes = useStyles();
    const [localEmail, setLocalEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [emailFocusLost, setEmailFocusLost] = useState(false);

    const onButtonClick = () => {
        onContinue(localEmail);
        setEmail(localEmail);
    };

    const checkEmailValidity = email => {
        // check validity
        emailSchema
            .isValid({
                email: email,
            })
            .then(function(valid) {
                setEmailValid(valid);
            });
    };

    const content = (
        <div>
            <Typography variant="h1" component="div" className={classes.contentTitle}>
                Forgot Password?
            </Typography>
            <Typography variant="body1" component="div">
                To reset your password, please enter the email address you used to register with Ned Virtual Clinic.
            </Typography>
            <Typography variant="body2" component="div" className={classes.emailText}>
                Email address
            </Typography>
            <EmailInput
                name="email"
                placeholder="Enter email address"
                value={localEmail}
                onChange={e => {
                    setLocalEmail(e.target.value);
                    checkEmailValidity(e.target.value);
                }}
                error={emailFocusLost && !emailValid}
                className={classes.emailInput}
                onFocus={() => setEmailFocusLost(false)}
                onBlur={() => setEmailFocusLost(true)}
            />
            {emailFocusLost && !emailValid && (
                <div>
                    <ErrorDiv text={INVALID_EMAIL_MESSAGE} />
                </div>
            )}
        </div>
    );

    const buttonText = 'Send Email Link';

    return <NotLoggedInLayout onClick={onButtonClick} buttonDisabled={!emailValid} buttonText={buttonText} content={content} />;
};

const CheckEmail = ({ email, history }) => {
    const classes = useStyles();
    const content = (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box className={classes.mailBadge}>
                <MailBadge aria-labelledby="Mail" />
            </Box>
            <Typography variant="h2" component="div">
                Check Your Email
            </Typography>
            <Typography variant="body1" component="div" className={classes.checkEmailBody}>
                Please check your email for instructions on how to reset your password.
            </Typography>
        </Box>
    );

    const onButtonClick = () => {
        history.push(PATH_LOGIN);
    };

    const buttonText = 'Back to Sign In';
    return <NotLoggedInLayout onClick={onButtonClick} buttonText={buttonText} content={content} contentHorizontallyCentered={true} />;
};
