import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { ContentLayout } from './utilities/layouts/ContentLayout';
import { styleBreakMobile, CONTENT_MIN_WIDTH } from './utilities/layouts/Globals';
import AdaptiveNavBar from './utilities/NavBar/AdaptiveNavBar';
import { RequiresLogin } from './utilities/commonUtilities/RequiresLogin';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(15),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(12),
        },
    },
    button: {
        marginTop: theme.spacing(5),
        width: 'fit-content',
        minWidth: theme.spacing(35),
        [styleBreakMobile(theme)]: {
            position: 'fixed',
            marginBottom: theme.spacing(3),
            bottom: '0',
            minWidth: CONTENT_MIN_WIDTH,
            width: 'calc(100% - 48px)',
        },
    },
    subtitle: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
        maxWidth: '303px',
    },
    body: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
        maxWidth: '325px',
        [styleBreakMobile(theme)]: {
            maxWidth: '295px',
        },
    },
}));

export default function FullScreenButtonPage({ isLoginRequired = false, Icon, subtitleText, bodyText, buttonText, onButtonClick, noNavBar }) {
    const classes = useStyles();

    const pageContent = (
        <ContentLayout>
            <Box className={classes.root} display="flex" flexDirection="column" alignItems="center">
                {Icon}
                <Typography variant="h2" className={classes.subtitle}>
                    {subtitleText}
                </Typography>
                <Typography variant="body1" className={classes.body}>
                    {bodyText}
                </Typography>
                <Button className={classes.button} onClick={onButtonClick}>
                    {buttonText}
                </Button>
            </Box>
        </ContentLayout>
    );

    if (!isLoginRequired) {
        return (
            <>
                {!noNavBar && <AdaptiveNavBar mobilePresent />}
                {pageContent}
            </>
        );
    } else {
        return (
            <RequiresLogin>
                {!noNavBar && <AdaptiveNavBar mobilePresent />}
                {pageContent}
            </RequiresLogin>
        );
    }
}

/* type checking for props */
FullScreenButtonPage.propTypes = {
    isLoginRequired: PropTypes.bool.isRequired,
    Icon: PropTypes.elementType.isRequired,
    subtitleText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
};
