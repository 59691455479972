import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { PATH_LOGIN } from '../../../containers/RoutePaths';
import { loginRetrieveSession } from '../../../actions';

const mapStateToProps = state => {
    return {
        token: state?.login?.session?.token ?? '',
    };
};

const RequiresLoginComponent = ({ token, children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(loginRetrieveSession());
        }
    }, [token, dispatch]);

    if (!token) {
        return <Redirect to={PATH_LOGIN} />;
    } else {
        return <>{children}</>;
    }
};

export const RequiresLogin = connect(mapStateToProps, null)(RequiresLoginComponent);
