import React from 'react';
import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from './MobileNavBar';
import { makeStyles } from '@material-ui/core/styles';
import { PATH_HOME, PATH_NOTES } from '../../../containers/RoutePaths';

const useStyles = makeStyles(theme => ({
    desktop: {
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            display: 'none',
        },
    },
    mobile: {
        [theme.breakpoints.up(theme.breakpoints.values['lg'])]: {
            display: 'none',
        },
    },
}));

export const menuLinks = [
    { link: PATH_HOME, text: 'Home' },
    { link: '/patient/labs', text: 'Labs' },
    { link: '/patient/surveys', text: 'Surveys' },
    { link: PATH_NOTES, text: 'Notes' },
    { link: '/patient/profile', text: 'Profile' },
];

export default function AdaptiveNavBar({ mobilePresent, links = menuLinks }) {
    const classes = useStyles();

    return (
        <>
            {mobilePresent ? (
                <div className={classes.mobile}>
                    <MobileNavBar />
                </div>
            ) : null}
            <div className={classes.desktop}>
                <DesktopNavBar linkObj={links} />
            </div>
        </>
    );
}
