/* This file contains sagas related to patient's current visit */

import { put, call } from 'redux-saga/effects';
import * as CurrentVisitAPI from '../apis/currentVisit';
import { buildCurrentVisit, retrieveCurrentVisit, loginRetrieveSession, loadVisit, setVisitLoadingResult } from '../actions';
import { LoadingStatus, LoadingResult } from '../utils/LoadingUtils';

export function* RetrieveCurrentVisitSaga() {
    yield put(loadVisit(LoadingStatus.STARTED));
    try {
        const response = yield call(CurrentVisitAPI.getCurrentVisit);
        if (response.data.hasVisit) {
            yield put(buildCurrentVisit(response.data.visit));
        } else {
            const future = yield call(CurrentVisitAPI.getFutureVisit);
            if (future.data.hasVisit) {
                yield put(buildCurrentVisit(future.data.visit));
            } else {
                // clear out the current visit if no visit.
                yield put(buildCurrentVisit(null));
            }
        }
        yield put(setVisitLoadingResult(LoadingResult.SUCCESSFUL));
    } catch (error) {
        if (!!error.response && error.response.status === 401) {
            // fill in with right error message
            console.log('Error accessing current visit');
        } else {
            // fill in with right error message
            console.log('all other error messages');
        }
        yield put(setVisitLoadingResult(LoadingResult.FAILED));
    }
    yield put(loadVisit(LoadingStatus.ENDED));
}

export function* CloseCurrentVisitSaga(action) {
    try {
        yield call(CurrentVisitAPI.closeCurrentVisit);
        if (action.refreshLoginSession) {
            yield put(loginRetrieveSession());
        }

        if (action.refreshVisit) {
            yield put(retrieveCurrentVisit());
        }
    } catch (error) {
        console.log(error);
    }
}
