/**
 * Usage:
 *
 * <MainLayout>
 *  {content}
 * </MainLayout>
 */

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AdaptiveNavBar from '../NavBar/AdaptiveNavBar';
import { CONTENT_MIN_WIDTH, CONTENT_MAX_WIDTH, styleBreakMobile } from './Globals';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    container: {
        minWidth: CONTENT_MIN_WIDTH,
        maxWidth: CONTENT_MAX_WIDTH,
        height: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
        [theme.breakpoints.down(theme.breakpoints.values['md'])]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.between(theme.breakpoints.values['md'], theme.breakpoints.values['lg'])]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        [theme.breakpoints.up(theme.breakpoints.values['lg'])]: {
            marginTop: theme.spacing(6),
        },
    },
    verticalOverlay: {
        [styleBreakMobile(theme)]: {
            overflowY: 'overlay',
        },
    },
    mainLayoutDiv: {
        position: 'relative',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
}));

function MainLayout({ token, verticalOverlay, SubPageHeader, children }) {
    const classes = useStyles();

    if (!token) {
        return <Redirect to="/patient/login" />;
    }

    return (
        <div className={classes.mainLayoutDiv}>
            <AdaptiveNavBar mobilePresent />
            {SubPageHeader ? <SubPageHeader /> : ''}
            <div
                className={clsx({
                    [classes.verticalOverlay]: !!verticalOverlay,
                })}
            >
                <div className={classes.container}>{children}</div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ login }) => {
    return {
        token: login.session && login.session.token ? login.session.token : '',
    };
};

export default connect(mapStateToProps)(MainLayout);
