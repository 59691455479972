import React from 'react';
import { connect } from 'react-redux';
import { actionLogout } from '../../../sagas/logoutSaga';
import { Box, Button, makeStyles } from '@material-ui/core';
import { CONTENT_MIN_WIDTH } from './Globals';
import AdaptiveNavBar from '../NavBar/AdaptiveNavBar';
import { ContentLayout } from './ContentLayout';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        marginTop: theme.spacing(6),
        width: 'fit-content',
        minWidth: theme.spacing(35),
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            position: 'fixed',
            marginBottom: theme.spacing(3),
            bottom: '0',
            minWidth: CONTENT_MIN_WIDTH,
            width: 'calc(100% - 48px)',
        },
    },
}));

const ConfirmationLayout = ({ onLogout, onClick, children, buttonText = 'Continue to Sign In' }) => {
    const classes = useStyles();

    onClick = onClick ? onClick : onLogout;

    return (
        <>
            <AdaptiveNavBar links={[]} />
            <ContentLayout>
                <Box className={classes.root} display="flex" flexDirection="column">
                    {children}
                    <Button className={classes.button} onClick={onClick}>
                        {buttonText}
                    </Button>
                </Box>
            </ContentLayout>
        </>
    );
};

const mapDispatchToProps = {
    onLogout: actionLogout,
};

export default connect(null, mapDispatchToProps)(ConfirmationLayout);
