import axios from 'axios';
import { clearClientSession } from '../utils/ClientSessionHandler';
import { PATH_LOGIN } from '../containers/RoutePaths';

export function setToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function setDeviceToken(deviceToken) {
    axios.defaults.headers.common['DEVICE_TOKEN'] = deviceToken;
}

export function clearToken() {
    delete axios.defaults.headers.common.Authorization;
}

export function clearDeviceToken() {
    delete axios.defaults.headers.common.DEVICE_TOKEN;
}

export function configureAxios({ login, deviceToken }) {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.responseType = 'json';
    axios.defaults.withCredentials = true;

    // Set token
    if (login && login.session && login.session.token) {
        setToken(login.session.token);
    }

    // Set device token
    if (deviceToken) {
        setDeviceToken(deviceToken);
    }

    // We are exempting these base paths from 401 error interceptions. Redirecting to login page doesn't make sense for them.
    const interceptExemptBasePaths = ['/api/auth', '/api/reset'];

    // Axios interceptor for 401 error responses
    axios.interceptors.response.use(undefined, function(error) {
        if (error?.response?.status === 401) {
            let isExempt = false;
            interceptExemptBasePaths.forEach(interceptExemptBasePath => {
                if (typeof error?.response?.config?.url === 'string' && error.response.config.url.includes(interceptExemptBasePath)) {
                    isExempt = true;
                }
            });

            if (!isExempt) {
                // clear client session
                clearClientSession();

                // Load login page from server so as to discard any pending states in memory, such as unresolved sagas.
                // This is to ensure the app stays in a consistent state for the user
                window.location = PATH_LOGIN;
            }
        }
        return Promise.reject(error);
    });
}
