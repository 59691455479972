import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { FONT_WEIGHT_BOLD } from '../Material';
import { styleBreakDesktop, styleBreakMobile } from '../layouts/Globals';

const useStyles = makeStyles(theme => ({
    linkStyle: {
        color: theme.palette.primary.dark,
        fontWeight: FONT_WEIGHT_BOLD,
        marginTop: theme.spacing(2),
        cursor: 'pointer',
    },
    card: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    cardContent: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(0),
    },
    H2: {
        marginBottom: theme.spacing(1),
        [styleBreakDesktop(theme)]: {
            marginBottom: theme.spacing(2),
        },
    },
    cardTitle: {
        marginBottom: theme.spacing(1),
    },
    nextUpBox: {
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(7),
    },
    cardImage: {
        objectFit: 'contain',
        [styleBreakMobile(theme)]: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
    },
    roundedBorder: {
        borderRadius: '6px',
    },
}));

/**
 * @param cardTitle Title of the next up card.
 * @param cardText Text of the next up card.
 * @param cardImage Image of the next up card.
 * @param linkText Text used in the link associated with next up card (optional).
 * @param onclick Function called when the link is clicked (optional).
 */
export default function NextUpComponent({ cardTitle, cardText, CardImage, linkText, onClick }) {
    const classes = useStyles();
    return (
        <Box component="div" display="flex" flexDirection="column" justifyContent="flex-start" className={classes.nextUpBox}>
            <Typography variant="h2" className={classes.H2}>
                Next Up
            </Typography>
            <Card elevation={0} className={classes.roundedBorder}>
                <Box component="div" display="flex" flexDirection="row" justifyContent="space-between" className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h4" className={classes.cardTitle}>
                            {cardTitle}
                        </Typography>
                        <Typography variant="caption">{cardText}</Typography>
                    </CardContent>
                    <Box component="div" display="flex" flexDirection="column" justifyContent="flex-end">
                        <CardMedia component={CardImage} aria-labelledby="next up logo" title="next up logo" className={classes.cardImage} />
                    </Box>
                </Box>
            </Card>
            {linkText && (
                <Typography variant="body1" onClick={onClick} className={classes.linkStyle}>
                    {linkText}
                </Typography>
            )}
        </Box>
    );
}
