import React from 'react';
import { ReactComponent as ErrorBadge } from '../resources/images/error-badge.svg';
import FullScreenButtonPage from './FullScreenButtonPage';

export default function LoadingErrorPage({ loginNotRequired, noNavBar }) {
    return (
        <FullScreenButtonPage
            isLoginRequired={!loginNotRequired}
            Icon={<ErrorBadge aria-labelledby="Badge" width="90" height="90" />}
            subtitleText={"Sorry, we're having trouble loading this page"}
            bodyText={'Your computer might be offline or the Ned server might be experiencing problems.'}
            buttonText={'Refresh Page'}
            onButtonClick={() => window.location.reload()}
            noNavBar={noNavBar}
        />
    );
}
