import React from 'react';
import { Paper, Typography, Box, makeStyles } from '@material-ui/core';
import { BackBar, BackButton } from './BackBar';
import { SizeSelector } from './SizeSelector';
import { ContentLayout } from './ContentLayout';
import AdaptiveNavBar from '../NavBar/AdaptiveNavBar';

const useStyles = makeStyles({
    // Give a height hint of 100 percent of the viewport height.
    detailsLayout: {
        minHeight: '100vh',
    },
});

// For mobile we're just a back button bar and title.
function HeaderMobile({ backTo, title, onClick }) {
    return (
        <>
            <Box mb={4}>
                <BackBar backTo={backTo} onClick={onClick} />
            </Box>
            <ContentLayout>
                <Typography variant="h1">{title}</Typography>
            </ContentLayout>
        </>
    );
}

// For desktop, we're the full menu bar, back button and title.
function HeaderDesktop({ backTo, title, onClick }) {
    return (
        <>
            <AdaptiveNavBar />
            <ContentLayout>
                <Box mt={4} mb={4}>
                    <BackButton backTo={backTo} onClick={onClick} />
                </Box>
                <Typography variant="h1">{title}</Typography>
            </ContentLayout>
        </>
    );
}

/**
 * Details layout. Includes app bar, back button and details title.
 * Detail layouts are white background.
 * Use a ContentLayout as the container of its children for conventional content padding/sizing.
 *
 * @param {*} backTo The target url of the back button
 * @param {*} title The title to display for the details page.
 */
export function DetailsLayout({ title, backTo, children, onClick, noMobileHeader }) {
    const mobileHeader = !noMobileHeader ? <HeaderMobile backTo={backTo} title={title} onClick={onClick} /> : null;
    const desktopHeader = <HeaderDesktop backTo={backTo} title={title} onClick={onClick} />;

    return (
        <Paper elevation={0} square className={useStyles().detailsLayout}>
            <SizeSelector mobile={mobileHeader} desktop={desktopHeader} />
            {children}
        </Paper>
    );
}
