import { call, select } from 'redux-saga/effects';
import * as clientAnalyticsAPI from '../apis/clientAnalytics';
import { getUserIdAndUsername } from '../selectors';

export function* sendClientAnalyticsSaga(action) {
    try {
        const { userId, username } = yield select(getUserIdAndUsername);
        action.clientAnalyticsData.userId = userId;
        action.clientAnalyticsData.userIdentifier = username;
        yield call(clientAnalyticsAPI.sendClientAnalytics, action.clientAnalyticsData);
    } catch (error) {
        console.log(error);
    }
}
