import { connect } from 'react-redux';
import SetupAccountWizard from '../components/setupAccountWizard/SetupAccountWizard.js';
import {
    setSetupAccountCurrentPage,
    setDisplayAccountSetupAlert,
    discardAccountSetup,
    setSecurityQuestionsAndSubmitAccountCreationInfo,
    setVersionOfAgreement,
    verifyAccountSetupPatient,
    setPatientVerificationInfo,
} from '../actions';
import { persistPatientVerificationInfo, persistAccountSetupAgreementVersion } from '../utils/ClientSessionHandler';

const mapStateToProps = ({ accountSetup, login }) => {
    return {
        verificationResponse: accountSetup.verificationResponse,
        setupAccountWizardCurrentPage: accountSetup.currentPage,
        isDisplayAccountSetupAlert: accountSetup.displayAccountSetupAlert,
        isAccountSetupSuccessful: accountSetup.isAccountSetupSuccessful,
        accountCreationCompleteResponse: accountSetup.accountCreationCompleteResponse,
        dateOfBirth: accountSetup.patientVerificationInfo.dateOfBirth,
        healthCardSystem: accountSetup.patientVerificationInfo.healthCardSystem,
        healthCardNumber: accountSetup.patientVerificationInfo.healthCardNumber,
        sessionToken: login?.session?.token,
    };
};

const mapDispatchToProps = dispatch => {
    const setCurrentPage = pageNumber => {
        dispatch(setSetupAccountCurrentPage(pageNumber));
    };

    const displayAccountSetupAlert = isDisplay => {
        dispatch(setDisplayAccountSetupAlert(isDisplay));
    };

    const exitSetupAccountWizardHandler = () => {
        dispatch(setDisplayAccountSetupAlert(false));
        dispatch(discardAccountSetup());
    };

    const dispatchSetSecurityQuestionsAndSubmitAccountCreationInfo = (securityQuestionsAndPassword, dispatchDataSubmissionResult) => {
        return setSecurityQuestionsAndSubmitAccountCreationInfo(securityQuestionsAndPassword, dispatchDataSubmissionResult);
    };

    const setAgreementVersion = version => {
        dispatch(setVersionOfAgreement(version));
        persistAccountSetupAgreementVersion(version);
    };

    const verifyPatientInformation = (token, healthCardSystem, healthCardNumber, dateOfBirth, dispatchDataSubmissionResult) => {
        return verifyAccountSetupPatient(token, healthCardSystem, healthCardNumber, dateOfBirth, dispatchDataSubmissionResult);
    };

    const setVerificationInformation = (dateOfBirth, healthCardSystem, healthCardNumber) => {
        dispatch(setPatientVerificationInfo(dateOfBirth, healthCardSystem, healthCardNumber));
        persistPatientVerificationInfo({
            healthCardSystem,
            healthCardNumber,
            dateOfBirth,
        });
    };

    return {
        setCurrentPage,
        displayAccountSetupAlert,
        exitSetupAccountWizardHandler,
        dispatchSetSecurityQuestionsAndSubmitAccountCreationInfo,
        setAgreementVersion,
        verifyPatientInformation,
        setVerificationInformation,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SetupAccountWizard);
