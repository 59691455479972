/* Container for connecting RequestPasswordReset component to the Redux store */

import { connect } from 'react-redux';
import RequestPasswordReset from '../components/login/RequestPasswordReset';
import { loginRequestPasswordReset } from '../actions';

const mapDispatchToProps = dispatch => {
    return {
        onContinue: (email, setRequestSubmissionStatus) => {
            dispatch(loginRequestPasswordReset(email, setRequestSubmissionStatus));
        },
    };
};
export default connect(null, mapDispatchToProps)(RequestPasswordReset);
