import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AlertModalYesNo from './utilities/AlertModal/AlertModalYesNo';
import { dataSubmissionResult } from '../actions';
import * as actions from '../actions/actionTypes';
import { call, takeLatest } from 'redux-saga/effects';

/**
 * A component for handling data submission to the backend from any front-end page/component.
 *
 * @param submitting A local boolean state that must be defined (by useState) in whatever react component that uses this component. It indicates the status of data submission.
 * @param setSubmitting Function that handles setting 'submitting' state. This is typically generated from useState above.
 * @param actionCreator Function that creates an action to be dispatched to trigger data submission for whatever react component that uses this component.
 * @param submissionSuccessHandler Callback to run when data submission is successful.
 * @param failureMsgTitle Title of the modal displayed in case of submission failure.
 * @param failureMsgBody Body of the modal displayed in case of submission failure.
 * For an example of how to use this component, look at WellnessSurveyQuestion
 */
export default function DataSubmissionHandler({ setSubmitting, submitting, actionCreator, failureMsgTitle, failureMsgBody, submissionSuccessHandler }) {
    const dispatch = useDispatch();
    const [submissionFailed, setSubmissionFailed] = useState(false);

    useEffect(() => {
        const onSubmissionFailure = () => {
            const delayInMilliseconds = 350;
            setTimeout(() => {
                setSubmitting(false);
                setSubmissionFailed(true);
            }, delayInMilliseconds);
        };

        const onSubmissionSuccess = () => {
            setSubmitting(false);
            if (submissionSuccessHandler) {
                submissionSuccessHandler();
            }
        };

        const dispatchDataSubmissionResult = isSuccessful => {
            return dataSubmissionResult(isSuccessful, onSubmissionSuccess, onSubmissionFailure);
        };

        if (submitting && !submissionFailed && actionCreator) {
            const action = actionCreator(dispatchDataSubmissionResult);
            dispatch(action);
        }
    }, [submitting, submissionFailed, actionCreator, dispatch, setSubmitting, submissionSuccessHandler]);

    const onTryAgainHandler = () => {
        setSubmissionFailed(false);
        setSubmitting(true);
    };

    return (
        <AlertModalYesNo
            isDisplayed={submissionFailed}
            alertModalTitle={failureMsgTitle}
            alertModalBody={failureMsgBody}
            onYesSelected={onTryAgainHandler}
            yesButtonText={'Try Again'}
            onCloseSelected={() => setSubmissionFailed(false)}
        />
    );
}

/** data submission result saga */
export function* dataSubmissionResultSaga(action) {
    try {
        if (action.isSuccessful) {
            yield call(action.onSubmissionSuccess);
        } else {
            yield call(action.onSubmissionFailure);
        }
    } catch (error) {
        console.dir(error);
    }
}

export function* watchDataSubmissionResult() {
    yield takeLatest(actions.DATA_SUBMISSION_RESULT, dataSubmissionResultSaga);
}
