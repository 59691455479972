/* This file contains sagas related to wellness survey */

import { put, call, select } from 'redux-saga/effects';
import { getQuestions, getPatientId } from '../selectors';
import * as QuestionnaireAPI from '../apis/wellnessSurvey';
import { buildWellnessQuestionnaire, retrieveCurrentVisit, loadWellnessSurveyQuestionnaire, setWellnessSurveyQuestionnaireLoadingResult } from '../actions';
import moment from 'moment';
import { LoadingStatus, LoadingResult } from '../utils/LoadingUtils';

export function* questionnaireSaga() {
    yield put(loadWellnessSurveyQuestionnaire(LoadingStatus.STARTED));
    try {
        const questionnaire = yield call(QuestionnaireAPI.getQuestionnaire);
        const { questions, answerValueSetRefs } = processQuestionnaire(questionnaire.data);
        let answers = yield call(QuestionnaireAPI.getValueSets, answerValueSetRefs);
        answers = processRetrievedAnswer(answers);
        addAnswersToQuestion(questions, answers);
        yield put(buildWellnessQuestionnaire(questions));
        yield put(setWellnessSurveyQuestionnaireLoadingResult(LoadingResult.SUCCESSFUL));
    } catch (error) {
        if (!!error.response && error.response.status === 401) {
            // fill in with right error message
            console.log('authentication error');
        } else {
            // fill in with right error message
            console.log('all other error messages');
        }
        yield put(setWellnessSurveyQuestionnaireLoadingResult(LoadingResult.FAILED));
    }
    yield put(loadWellnessSurveyQuestionnaire(LoadingStatus.ENDED));
}

export function* questionnaireResponseSaga(action) {
    try {
        const questions = yield select(getQuestions);
        const patientId = yield select(getPatientId);
        const questionnaireResponse = generateQuestionnaireResponse(questions, patientId);
        yield call(QuestionnaireAPI.postQuestionnaireResponse, questionnaireResponse);
        if (action.refreshVisit) {
            yield put(retrieveCurrentVisit());
        }
        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(true));
        }
    } catch (error) {
        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(false));
        }
        console.dir(error);
    }
}

const generateQuestionnaireResponse = (questions, patientId) => {
    const currentTime = moment().format();

    const questionnaireResponse = {
        resourceType: 'QuestionnaireResponse',
        questionnaire: {
            reference: 'Questionnaire/wellnessSurvey',
        },
        status: 'completed',
        subject: {
            reference: `Patient/${patientId}`,
        },
        authored: `${currentTime}`,
        author: {
            reference: `Patient/${patientId}`,
        },
        item: [],
    };

    //add question groups
    const questionGrps = new Set();
    for (const question of questions) {
        if (!questionGrps.has(question.title)) {
            questionnaireResponse.item.push({
                linkId: question.title,
                item: [],
            });
            questionGrps.add(question.title);
        }
    }

    //fill in questions in each question group
    for (const grp of questionnaireResponse.item) {
        const title = grp.linkId;
        for (const question of questions) {
            if (question.title === title) {
                grp.item.push({
                    linkId: question.linkId,
                    answer: [
                        {
                            valueString: question.activeAnswerLinkId,
                        },
                    ],
                });
            }
        }
    }

    return questionnaireResponse;
};

const processQuestionnaire = questionnaire => {
    const questions = [];
    const answerValueSetRefs = [];
    const questionGroups = questionnaire.item;
    let currentIndx = 0;
    for (const questionGroup of questionGroups) {
        const title = questionGroup.text;
        for (let i = 0; i < questionGroup.item.length; i++) {
            const question = questionGroup.item[i];
            questions.push({
                linkId: question.linkId,
                title: title,
                text: question.text,
                prev: currentIndx === 0 ? null : currentIndx - 1,
                next: currentIndx + 1,
                activeAnswerLinkId: null,
            });
            answerValueSetRefs.push(question.options.reference);
            currentIndx++;
        }
    }
    questions[currentIndx - 1].next = null;

    return {
        questions,
        answerValueSetRefs,
    };
};

const addAnswersToQuestion = (questions, answers) => {
    for (let i = 0; i < questions.length; i++) {
        questions[i].answers = answers[i];
    }
};

const processRetrievedAnswer = answers => {
    const answerArray = [];
    for (const answer of answers) {
        answerArray.push(
            answer.data.compose.include[0].concept.map(answerObj => {
                return { linkId: answerObj.code, text: answerObj.display };
            })
        );
    }
    return answerArray;
};
