import produce from 'immer';
import * as actions from '../actions/actionTypes';

const labReqId = (state = {}, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case actions.PDF_ID_STORE:
                draft.id = action.labReqId;
                break;
            default:
                break;
        }
    });

export default labReqId;
