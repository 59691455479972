/* Handles state transitions associated with login actions dispatched */

import produce from 'immer';
import * as actions from '../actions/actionTypes';

const login = (
    state = {
        hasAuthenticated: false,
        session: {},
        isNetworkError: false,
        unauthorized: {},
    },
    action
) =>
    produce(state, draft => {
        switch (action.type) {
            case actions.LOGIN_PATIENT_SUCCESS:
                draft.hasAuthenticated = true;
                draft.session = action.payload.session;
                draft.isNetworkError = false;
                draft.unauthorized = {};
                break;
            case actions.LOGIN_PATIENT_AUTH_FAILURE:
                draft.hasAuthenticated = true;
                draft.session = {};
                draft.isNetworkError = false;
                draft.unauthorized = action.unauthorized;
                break;
            case actions.LOGIN_NETWORK_ERROR:
                draft.hasAuthenticated = true;
                draft.session = {};
                draft.isNetworkError = true;
                draft.unauthorized = {};
                break;
            case actions.LOGOUT_PATIENT_SUCCESS:
            case actions.LOGOUT_PATIENT_ERROR:
            case actions.LOGIN_RESET_STATE:
                // Either way after logout we will just reset the state.
                draft.hasAuthenticated = false;
                draft.session = {};
                draft.isNetworkError = false;
                draft.unauthorized = {};
                break;
            default:
                break;
        }
    });

export default login;
