import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { FONT_WEIGHT_BOLD } from '../utilities/Material';
import { styleBreakDesktop } from '../utilities/layouts/Globals';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import SurveyResponseLoader from './SurveyResponseLoader';
import { LoadingStatus } from '../../utils/LoadingUtils';

const List = styled.ol`
    margin: 0;
    padding: 0;
`;

//Want to align list item with Header (ResultPageHeader)
const ListItem = styled.li`
    list-style-position: inside;
`;

// special case font size, 12 is even smaller than caption (14)
const CATEGORY_FONT_SIZE = '12px';
const CATEGORY_FONT_WEIGHT = FONT_WEIGHT_BOLD;

const PARENT_URL = '/patient/surveys';

const useStyles = makeStyles(theme => ({
    categoryBox: {
        minHeight: '48px',
        marginBottom: theme.spacing(2),
        [styleBreakDesktop(theme)]: {
            marginBottom: theme.spacing(3),
        },
        background: theme.palette.background.default,
    },
    categoryText: {
        textTransform: 'uppercase',
        fontWeight: CATEGORY_FONT_WEIGHT,
        fontSize: CATEGORY_FONT_SIZE,
    },
    subTitleText: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    answerCard: {
        minHeight: '40px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        [styleBreakDesktop(theme)]: {
            marginBottom: theme.spacing(4),
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: theme.palette.info.main,
        color: theme.palette.info.contrastText,
    },
}));

const SurveyResponse = ({ questionnaireFetched, questionnaireLoading, completedSurveyLoading, surveyId, questionnaire, completedSurveys }) => {
    if (
        (!questionnaireFetched && questionnaireLoading === LoadingStatus.ENDED) ||
        (completedSurveys.length === 0 && completedSurveyLoading === LoadingStatus.ENDED)
    ) {
        return <Redirect to={PARENT_URL} />;
    } else {
        return (
            <RequiresLogin>
                <SurveyResponseLoader>
                    <SurveyResponsePageContent questionnaire={questionnaire} completedSurveys={completedSurveys} surveyId={surveyId} />
                </SurveyResponseLoader>
            </RequiresLogin>
        );
    }
};

const mapStateToProps = (state, props) => {
    return {
        surveyId: props?.match?.params?.id ?? '',
        token: state.login.session && state.login.session.token ? state.login.session.token : '',
        questionnaire: state.wellnessSurvey.questions,
        completedSurveys: state.completedSurveys.surveys,
        patientFhirId: state?.login?.session?.patientId ?? '',
        questionnaireLoading: state?.dataLoading?.wellnessSurveyQuestionnaire?.loading,
        completedSurveyLoading: state.completedSurveys.loading,
        questionnaireFetched: state.wellnessSurvey.questionnaireRetrieved,
    };
};

function findSurvey(completedSurveys, surveyId) {
    return completedSurveys && completedSurveys.find(item => item.id === surveyId);
}

function getCategory(questionnaire, questionLinkId) {
    const question = questionnaire.find(item => item.linkId === questionLinkId);
    if (question) {
        return question.title;
    }
}

function findValueSet(questionnaire, questionLinkId, answerLinkId) {
    const question = questionnaire.find(item => item.linkId === questionLinkId);
    const answer = question?.answers?.find(item => item.linkId === answerLinkId);
    if (answer) {
        return {
            title: question.text,
            answer: answer.text,
        };
    } else {
        return {};
    }
}

/**
 * The response category row. This spans the full width of the page, but
 * its contents are constrained to the content area.
 */
function CategoryItem({ styles, category }) {
    return (
        <Box className={styles.categoryBox} display="flex" alignItems="center">
            <ContentLayout>
                <Typography variant="caption" className={styles.categoryText}>
                    {category}
                </Typography>
            </ContentLayout>
        </Box>
    );
}

const SurveyResponsePageContent = ({ questionnaire, completedSurveys, surveyId }) => {
    const theSurveyResponse = findSurvey(completedSurveys, surveyId);
    const subTitle = 'Completed on ' + theSurveyResponse?.authored;
    return (
        <DetailsLayout backTo={PARENT_URL} title="Wellness Survey">
            <ContentLayout>
                <Box mt={0.5} mb={2}>
                    <Typography variant="caption">{subTitle}</Typography>
                </Box>
            </ContentLayout>
            <ResponseComponent questionnaire={questionnaire} theSurveyResponse={theSurveyResponse} />
            <Box pb={2} />
        </DetailsLayout>
    );
};

/**
 * Generates a list of grouped response rows.
 * Note that some rows spand the full width of the page (the headers), some rows
 * are constrained to the content area.
 */
function ResponseComponent({ questionnaire, theSurveyResponse }) {
    const styling = useStyles();

    const retVal = [];

    if (!theSurveyResponse) {
        return null;
    }

    theSurveyResponse.responses.forEach(responseGroup => {
        const id = responseGroup[0].linkId;
        const category = getCategory(questionnaire, id);
        if (category) {
            const categoryHeader = <CategoryItem styles={styling} category={category} key={category} />;
            retVal.push(categoryHeader);
        }
        responseGroup.forEach((item, index) => {
            const userResponse = item.valueStringAnswer;
            const values = findValueSet(questionnaire, item.linkId, userResponse);
            const LIST_NUMBER_WIDTH = '2em'; // constant width numbering, with indent. Enough space for double digits + padding.

            /*
            Creates a single row that is constrained to the content width.
            The list number has a fixed size. The question text wraps.
            Below it is a shrink-wrapped card that holds the answer.

                Margin  |   Number   | Question text that can wrap lines |  Margin
                        |            | Answer Card |                      |
            */
            const body = (
                <ContentLayout key={index + '_' + item.linkId}>
                    <Box display="flex">
                        <Box width={LIST_NUMBER_WIDTH} flexShrink={0} component={Typography}>
                            <ListItem />
                        </Box>
                        <Box>
                            <Typography>{values.title}</Typography>
                            <Box display="flex">
                                <Paper elevation={0} className={styling.answerCard} component={Box} display="flex" alignItems="center" flexGrow={0}>
                                    <Typography variant="body2">{values.answer}</Typography>
                                </Paper>
                            </Box>
                        </Box>
                    </Box>
                </ContentLayout>
            );
            retVal.push(body);
        });
    });
    return <List>{retVal}</List>;
}

export default connect(mapStateToProps, null)(SurveyResponse);
