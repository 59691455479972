import { connect } from 'react-redux';
import ResetPasswordWizard from '../components/resetPasswordWizard/ResetPasswordWizard';
import { PasswordResetRetrieveSecurityQuestions, PasswordResetSubmitAnswersToSecurityQuestions, passwordResetSubmitNewPassword } from '../actions';

const mapStateToProps = ({ passwordReset }) => {
    return {
        securityQuestions: passwordReset.securityQuestions,
        oneTimeResetToken: passwordReset.oneTimeResetToken,
        failedAttempts: passwordReset.securityQuestionFailed?.failedAttempts,
        lockedUntil: passwordReset.securityQuestionFailed?.lockedUntil,
        serverTimeInMillis: passwordReset.securityQuestionFailed?.serverTimeInMillis,
        isLocked: passwordReset.securityQuestionFailed?.isLocked,
        isNetworkError: passwordReset.isNetworkError,
        maxAttempts: passwordReset.securityQuestionFailed?.maxAttempts,
    };
};

const mapDispatchToProps = dispatch => {
    const retrieveSecurityQuestions = (ResetToken, errorCallBack) => {
        dispatch(PasswordResetRetrieveSecurityQuestions(ResetToken, errorCallBack));
    };

    const submitAnswersToSecurityQuestions = securityQuestionsAndAnswersAndToken => {
        dispatch(PasswordResetSubmitAnswersToSecurityQuestions(securityQuestionsAndAnswersAndToken));
    };

    const submitNewPassword = (newPasswordAndOneTimeToken, onSubmitSuccess, onSubmitFail) => {
        dispatch(passwordResetSubmitNewPassword(newPasswordAndOneTimeToken, onSubmitSuccess, onSubmitFail));
    };

    return {
        retrieveSecurityQuestions,
        submitAnswersToSecurityQuestions,
        submitNewPassword,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordWizard);
