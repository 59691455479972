import React from 'react';
import moment from 'moment';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { CardItem, CardContainer } from '../utilities/commonUtilities/CardItem';
import { ReactComponent as LabImage } from '../../resources/images/lab-badge.svg';
import { ReactComponent as StethoscopeIcon } from '../../resources/images/stethoscope.svg';
import ProgressBar from '../ProgressBar';
import { computeProgressPercent } from '../utilities/commonUtilities/TaskCard';
import { ReactComponent as NoteIcon } from '../../resources/images/note.svg';
import NextUp from '../utilities/commonUtilities/NextUpComponent';
import { AuditEventAction, createClientAnalytics } from '../../utils/ClientAnalytics';
import { useDispatch } from 'react-redux';
import { sendClientAnalytics } from '../../actions';
import { PATH_LABS } from '../../containers/RoutePaths';

const useStyles = makeStyles(theme => ({
    progressCard: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down(theme.breakpoints.values['lg'])]: {
            marginTop: theme.spacing(3),
        },
    },
    gridItem: {
        marginRight: theme.spacing(1),
    },
    cardHeaderContainer: {
        marginBottom: theme.spacing(4),
    },
    dateContainer: {
        marginTop: theme.spacing(1),
    },
    endText: {
        textAlign: 'right',
        justifyContent: 'flex-end',
        fontSize: '14px',
    },
}));

const ProgressTaskCard = ({ submittedDateObj, expectedRespDateObj }) => {
    const classes = useStyles();
    const progressPercent = computeProgressPercent(submittedDateObj, expectedRespDateObj);

    return (
        <CardContainer className={classes.progressCard}>
            <Grid container wrap="nowrap" className={classes.cardHeaderContainer}>
                <Grid item xs zeroMinWidth className={classes.gridItem}>
                    <Typography variant="h2">Doctor's Review</Typography>
                    <Typography>Waiting for your doctor</Typography>
                </Grid>
                <Grid item>
                    <StethoscopeIcon aria-labelledby="img" />
                </Grid>
            </Grid>
            <ProgressBar widthPercentage={progressPercent} />
            <Grid container wrap="nowrap" alignItems="center" className={classes.dateContainer}>
                <Grid item xs zeroMinWidth>
                    <Typography variant="h4">Submitted</Typography>
                    <Typography variant="caption">{submittedDateObj.format('MMMM D')}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">Expected response</Typography>
                    <Typography className={classes.endText}>{expectedRespDateObj.format('MMMM D')}</Typography>
                </Grid>
            </Grid>
        </CardContainer>
    );
};

export const WaitingReviewTaskBoard = ({ submittedDate, expectedResponseDate, isPendingClinicianManualLabsRetrieval }) => {
    const dispatch = useDispatch();
    const expectedRespDateObj = moment(expectedResponseDate, moment.ISO_8601);
    const submittedDateObj = moment(submittedDate, moment.ISO_8601);
    const labCardTitle = 'View your lab results';
    const labCardBody = 'Tap to view';

    const nextUpCardTitle = "Doctor's Note";
    const nextUpCardText = "Once your doctor reviews your results they will send you a note about how you're doing.";
    const nextUpCardImage = NoteIcon;

    const onCardClick = () => {
        const labViewedAnalytics = createClientAnalytics(AuditEventAction.LAB_VIEWED.category, AuditEventAction.LAB_VIEWED.action);
        dispatch(sendClientAnalytics(labViewedAnalytics));
        window.location = PATH_LABS;
    };

    return (
        <>
            <ProgressTaskCard submittedDateObj={submittedDateObj} expectedRespDateObj={expectedRespDateObj} />
            {!isPendingClinicianManualLabsRetrieval && (
                <CardItem Badge={LabImage} onClick={onCardClick} title={labCardTitle} subtitle={labCardBody} datatestid="viewLabResultsCard" />
            )}
            <NextUp cardTitle={nextUpCardTitle} cardText={nextUpCardText} CardImage={nextUpCardImage} />
        </>
    );
};
