import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from 'formik-material-ui';
import { Select } from 'formik-material-ui';
import { Field } from 'formik';
import { Box, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    answerLabel: {
        marginTop: theme.spacing(2),
    },
    field: {
        marginTop: theme.spacing(1),
    },
}));

const QUESTION_INSTRUCTION = 'Select a question';

// A security question component for formik.  Note that the names of the fields will be 'questionX' and 'answerX' where X is the securityQuestionIndex.
const SecurityQuestion = ({ securityQuestionIndex, questionList, questionLabelClass }) => {
    const classes = useStyles();

    // This creates an array of option elements that are the questions.  The first item is the header for the question and has an empty value.  You can use this fact for validation.
    const questionOptions = questionList.map((optionItem, optionIndex) => {
        return (
            <MenuItem value={optionItem} key={'question' + securityQuestionIndex + 'Option' + optionIndex}>
                {optionItem}
            </MenuItem>
        );
    });

    return (
        <Box>
            <Box className={questionLabelClass}>
                <Typography variant="body2">Question {securityQuestionIndex}</Typography>
            </Box>
            <FormControl variant="outlined" fullWidth={true} className={classes.field}>
                <Field
                    component={Select}
                    inputProps={{
                        id: 'question' + securityQuestionIndex,
                        'data-testid': 'securityQuestionSelect' + securityQuestionIndex,
                    }}
                    name={'question' + securityQuestionIndex}
                    displayEmpty={true}
                    data-testid={'securityQuestionControl' + securityQuestionIndex}
                >
                    <MenuItem value="">
                        <em>{QUESTION_INSTRUCTION}</em>
                    </MenuItem>
                    {questionOptions}
                </Field>
            </FormControl>
            <Box className={classes.answerLabel}>
                <Typography variant="body2">Answer {securityQuestionIndex}</Typography>
            </Box>
            <FormControl variant="outlined" fullWidth={true} className={classes.field}>
                <Field
                    component={TextField}
                    inputProps={{
                        id: 'answer' + securityQuestionIndex,
                        'data-testid': 'securityQuestionAnswerInput' + securityQuestionIndex,
                    }}
                    name={'answer' + securityQuestionIndex}
                    placeholder="Type your answer here"
                />
            </FormControl>
        </Box>
    );
};

/* type checking for props */
SecurityQuestion.propTypes = {
    securityQuestionIndex: PropTypes.string.isRequired,
    questionList: PropTypes.array.isRequired,
    questionLabelClass: PropTypes.string,
};

export default SecurityQuestion;
