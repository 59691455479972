import React from 'react';
import { connect } from 'react-redux';
import ObservationPage from './ObservationPage';
import { observationCodes } from './ObservationPage';
import { filterObservationResults } from './LabsPage';

const TestosteroneResultsPage = ({ observationResults }) => {
    return (
        <ObservationPage
            observationCode={observationCodes.Testosterone}
            observationResults={filterObservationResults(observationResults, observationCodes.Testosterone.code)}
        />
    );
};

/** Hooking up to redux store **/
function mapStateToProps(state) {
    return {
        observationResults: state.observation.labResults,
    };
}

export default connect(mapStateToProps, null)(TestosteroneResultsPage);
