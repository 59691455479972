import React, { useState, useEffect } from 'react';
import { PasswordRequirementComponent, checkPasswordValidity, passwordRequirementState } from '../PasswordSetup';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { PasswordInput } from '../utilities/commonUtilities/FormElements';
import Typography from '@material-ui/core/Typography';
import { changePassword } from '../../apis/authentication';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { FormLeaveConfirmationModal } from '../utilities/commonUtilities/FormLeaveConfirmation';
import { Box } from '@material-ui/core';
import { ReactComponent as DoneBadge } from '../../resources/images/done-badge-large.svg';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { PrimaryButton } from '../utilities/commonUtilities/GenericButtonElements';
import { styled, withTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { styleBreakDesktop } from '../utilities/layouts/Globals';
import OnCompleteLayout from '../utilities/layouts/OnCompleteLayout';
import { profileChangePassword } from '../../actions';
import DataSubmissionHandler from '../DataSubmissionHandler';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as actions from '../../actions/actionTypes';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    BoxStyling: {
        marginBottom: theme.spacing(3),
        [styleBreakDesktop(theme)]: {
            maxWidth: theme.spacing(60),
        },
    },
    labelMargin: {
        marginBottom: theme.spacing(0.5),
    },
    saveChangesButton: {
        [styleBreakDesktop(theme)]: {
            width: 'fit-content',
            minWidth: theme.spacing(35),
        },
    },
    errorDiv: {
        marginTop: theme.spacing(0.5),
    },
    bottomSpace: {
        marginBottom: theme.spacing(3),
    },
    buttonCircularProgress: {
        marginRight: theme.spacing(0.5),
    },
}));

const PASSWORD_CHANGE_COMPLETE = 'PASSWORD_CHANGE_COMPLETE';

const currentNewPasswordErrorMsg = 'New password cannot be the same as current password';
const newPasswordErrorMsg = 'Password does not meet requirements';
const confirmPasswordErrorMsg = 'The confirmation password does not match the new password. Please try again.';
const genericError = 'Current password is incorrect or password requirements are not met';

const StyledPrimaryButton = styled(withTheme(PrimaryButton))(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

const passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z\s])/;

export const passwordSchema = Yup.object({
    currentPassword: Yup.string().required(' '),
    newPassword: Yup.string()
        .test('password-new', currentNewPasswordErrorMsg, function(value) {
            if (value === undefined) {
                //Do not want error to appear if field is empty
                return true;
            }
            return this.parent.currentPassword !== value;
        })
        .matches(passwordRegex, newPasswordErrorMsg)
        .required(' '),
    retypeNewpassword: Yup.string()
        .test('passwords-match', confirmPasswordErrorMsg, function(value) {
            if (value === undefined) {
                //Do not want error to appear if field is empty
                return true;
            }
            return this.parent.newPassword === value;
        })
        .required(' '),
});

const ChangePasswordPage = ({ token }) => {
    const [hasErrors, setHasErrors] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const history = useHistory();
    const [page, setPage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    // creates an action that is dispatched when form is submitted
    const [submissionActionCreator, setSubmissionActionCreator] = useState(null);

    if (!token) {
        return <Redirect to="/patient/login" />;
    }

    const onSubmitSuccess = () => {
        setPage(PASSWORD_CHANGE_COMPLETE);
    };

    const handleBackPress = () => {
        if (isFormDirty) {
            setDialogOpen(true);
        } else {
            history.goBack();
        }
    };

    const changePasswordSubmissionFailureTitle = 'Submission Error';
    const changePasswordSubmissionFailureBody =
        'We’re having trouble updating your account information. Your computer might be offline or the Ned server may be experiencing problems. Please try again.';

    switch (page) {
        case PASSWORD_CHANGE_COMPLETE:
            const imageComponent = <DoneBadge aria-labelledby="Done Badge" />;
            return <OnCompleteLayout imageComponent={imageComponent} title="Password Changed" body="Please use your new password to sign into your account." />;
        default:
            return (
                <>
                    <DetailsLayout title="Change Password" onClick={handleBackPress}>
                        <ContentLayout>
                            <ChangePasswordForm
                                hasErrors={hasErrors}
                                setHasErrors={setHasErrors}
                                setIsFormDirty={setIsFormDirty}
                                onSubmitSuccess={onSubmitSuccess}
                                setIsSubmitting={setIsSubmitting}
                                setSubmissionActionCreator={setSubmissionActionCreator}
                                isSubmitting={isSubmitting}
                            />
                            <FormLeaveConfirmationModal isDisplayed={isDialogOpen} setDialogOpen={setDialogOpen} onYesSelected={() => history.goBack()} />
                        </ContentLayout>
                    </DetailsLayout>
                    <DataSubmissionHandler
                        setSubmitting={setIsSubmitting}
                        submitting={isSubmitting}
                        actionCreator={submissionActionCreator}
                        failureMsgTitle={changePasswordSubmissionFailureTitle}
                        failureMsgBody={changePasswordSubmissionFailureBody}
                    />
                </>
            );
    }
};

function ChangePasswordForm({ setHasErrors, setIsFormDirty, onSubmitSuccess, hasErrors, setIsSubmitting, setSubmissionActionCreator, isSubmitting }) {
    const passwordChangeTitle = 'Please make sure your new password:';
    const [lengthValid, setLengthValid] = useState(passwordRequirementState.NEUTRAL);
    const [letterValid, setLetterValid] = useState(passwordRequirementState.NEUTRAL);
    const [numberValid, setNumberValid] = useState(passwordRequirementState.NEUTRAL);
    const [passwordFocusLost, setPasswordFocusLost] = useState(false);
    const classes = useStyles();

    return (
        <>
            <PasswordRequirementComponent
                title={passwordChangeTitle}
                lengthValid={lengthValid}
                letterValid={letterValid}
                numberValid={numberValid}
                PasswordCreationFailed={passwordFocusLost}
            />
            <Formik
                initialValues={{ currentPassword: '', newPassword: '', retypeNewpassword: '' }}
                onSubmit={(values, formikBag) => {
                    const submittedValues = {
                        currentPassword: values.currentPassword,
                        newPassword: values.newPassword,
                        retypeNewpassword: values.retypeNewpassword,
                    };
                    setSubmissionActionCreator(prevFunc => dispatchDataSubmissionResult => {
                        return profileChangePassword(submittedValues, setHasErrors, onSubmitSuccess, dispatchDataSubmissionResult);
                    });
                    setIsSubmitting(true); // indicates that data is being sent to backend
                    formikBag.setSubmitting(false);
                }}
                validationSchema={passwordSchema}
            >
                {({ errors, dirty, handleChange }) => {
                    const FormDirtyHandler = ({ dirty, setIsFormDirty }) => {
                        useEffect(() => {
                            setIsFormDirty(dirty);
                        }, [dirty, setIsFormDirty]);

                        return null;
                    };

                    return (
                        <Form>
                            <Box display="flex" flexDirection="column">
                                <FormDirtyHandler dirty={dirty} setIsFormDirty={setIsFormDirty} />
                                <Typography variant="body2" className={classes.labelMargin}>
                                    Current Password
                                </Typography>
                                <Field as={PasswordInput} name="currentPassword" placeholder="Enter Current Password" BoxStyling={classes.BoxStyling} />
                                <Typography variant="body2" className={classes.labelMargin}>
                                    New Password
                                </Typography>
                                <Field
                                    as={PasswordInput}
                                    name="newPassword"
                                    placeholder="Enter New Password"
                                    errors={errors}
                                    onChange={event => handleOnChange(event, handleChange, setLengthValid, setLetterValid, setNumberValid)}
                                    BoxStyling={classes.BoxStyling}
                                    focusLostCallback={setPasswordFocusLost}
                                />
                                <Typography variant="body2" className={classes.labelMargin}>
                                    Confirm New Password
                                </Typography>
                                <Field
                                    as={PasswordInput}
                                    name="retypeNewpassword"
                                    placeholder="Confirm New Password"
                                    errors={errors}
                                    BoxStyling={classes.BoxStyling}
                                />

                                <StyledPrimaryButton type="submit" disabled={!dirty || Object.keys(errors).length !== 0} className={classes.saveChangesButton}>
                                    {isSubmitting ? (
                                        <>
                                            <CircularProgress className={classes.buttonCircularProgress} size={20} />
                                            <span>{'Saving Changes...'}</span>
                                        </>
                                    ) : (
                                        'Save Changes'
                                    )}
                                </StyledPrimaryButton>
                                {hasErrors ? <ErrorDiv text={genericError} /> : <div className={classes.bottomSpace}></div>}
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}

export function handleOnChange(event, handleChange, setLengthValid, setLetterValid, setNumberValid) {
    const password = event.target.value;
    checkPasswordValidity(password, setLengthValid, setLetterValid, setNumberValid);
    handleChange(event); //Need to call to update input field with character user has typed
}

function ErrorDiv({ text }) {
    const classes = useStyles();
    return (
        <Typography variant="caption" color="error" className={`${classes.errorDiv} ${classes.bottomSpace}`}>
            {text}
        </Typography>
    );
}

const mapStateToProps = state => {
    return {
        token: state.login?.session?.token ?? '',
    };
};

export default connect(mapStateToProps)(ChangePasswordPage);

/** profile password change saga */
export function* profilePasswordChangeSaga(action) {
    try {
        yield call(changePassword, action.submittedValues.currentPassword, action.submittedValues.newPassword);
        yield call(action.onSubmitSuccess);

        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(true));
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            yield call(action.setErrors, true);

            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(true));
            }
        } else {
            // Either the request was made but no response was received, or
            // something happened in setting up the request that triggered an Error
            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(false));
            }
        }
    }
}

export function* watchProfilePasswordChange() {
    yield takeLatest(actions.PROFILE_CHANGE_PASSWORD, profilePasswordChangeSaga);
}
