import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OhipInput, NovaScotiaInput, AlbertaInput, NoHealthCardSystemSelectedInput } from '../HealthCardNumberInput';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import StepsLayout from '../utilities/layouts/StepsLayout';
import { Box, Input, Paper, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { styleBreakDesktop, styleBreakMobile, styleBreakSmallMobile } from '../utilities/layouts/Globals';
import { useTheme } from '@material-ui/styles';
import { MonthSelectorField, HealthCardSystemSelectorField, HealthCardSystem } from '../DropDownSelector';
import { COLOR_GREY, FONT_WEIGHT_SEMI } from '../utilities/Material';
import { ErrorTypography } from '../utilities/commonUtilities/Typography';
import DataSubmissionHandler from '../DataSubmissionHandler';

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        marginBottom: theme.spacing(6),
        [styleBreakDesktop(theme)]: {
            width: theme.spacing(102),
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            //negative margins to offset margins set from marginSelector in StepsLayout.js
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
            marginBottom: theme.spacing(12),
        },
        [styleBreakMobile(theme)]: {
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            //negative margins to offset margins set from marginSelector in StepsLayout.js
            marginLeft: theme.spacing(-3),
            marginRight: theme.spacing(-3),
        },
        [styleBreakSmallMobile(theme)]: {
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
}));

const ErrorContainer = ({ shouldShow, children }) => {
    return (
        <Box mt={0.5} style={{ display: shouldShow ? 'block' : 'none' }}>
            {children}
        </Box>
    );
};

const SetupAccountStep3Verify = ({
    token,
    currentPage,
    onNextPage,
    onPreviousPage,
    healthCardNumber,
    healthCardSystem,
    dateOfBirth,
    verifyPatientInformation,
    verificationResponse,
    onCancel,
    sessionToken,
}) => {
    const theme = useTheme();
    const style = useStyles();
    const formRefBag = useRef();
    let newYear = '';
    let newMonth = '';
    let newDate = '';
    if (!!dateOfBirth) {
        let dob = moment(dateOfBirth);
        newYear = dob.year();
        newMonth = dob.month();
        newDate = dob.format('DD');
    }

    let newHealthCardNumber = healthCardNumber;
    let newHealthCardSystem = healthCardSystem;
    let [hasPageAdvanced, setHasPageAdvanced] = useState(false);

    useEffect(() => {
        // The store will update with the token.  If we have the token and this page has not yet been automatically advanced, just go to the next page.
        // Otherwise, do nothing.
        if (!!verificationResponse && !!verificationResponse.token && !hasPageAdvanced) {
            onNextPage();
            setHasPageAdvanced(true);
        }
    }, [verificationResponse, onNextPage, hasPageAdvanced, setHasPageAdvanced]);

    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const [submittingGlobal, setSubmittingGlobal] = useState(false);
    // creates an action that's dispatch when form is submitted
    const [submissionActionCreator, setSubmissionActionCreator] = useState(null);

    // Don't draw the component if it is not our page.
    if (currentPage !== 3) {
        return null;
    }

    // Calculate some state.
    const isVerificationAlreadySuccessful = !!verificationResponse?.token ? verificationResponse.token : sessionToken;
    const isMaximumAttemptsExceeded = verificationResponse?.error === 'MAXIMUM_ATTEMPTS_EXCEEDED';
    const isOtherError =
        verificationResponse?.status && verificationResponse.status !== 400 && verificationResponse.status !== 200 && verificationResponse.status !== 403;
    const isIncorrectInput = verificationResponse?.status === 400;

    // Using Yup here for validation as it is built into Formik.
    const IdentitySchema = Yup.object().shape({
        year: Yup.number()
            .required('Required')
            .min(1900, 'Invalid Year')
            .max(moment().year(), 'Invalid Year'),
        month: Yup.string().required('Month is required'),
        date: Yup.number()
            .required('Required')
            .min(1, 'Invalid Date')
            .max(31, 'Invalid Date'),
        healthCardNumber: Yup.string().required('Health card number is required'),
    });

    const healthCardPatternMatch = (healthCardSystem, healthCardNumber) => {
        if (healthCardSystem === HealthCardSystem.ONTARIO) {
            return /^\d{4}-\d{3}-\d{3}$/.test(healthCardNumber);
        } else if (healthCardSystem === HealthCardSystem.ALBERTA) {
            return /^\d{5}-\d{4}$/.test(healthCardNumber);
        } else if (healthCardSystem === HealthCardSystem.NOVA_SCOTIA) {
            return /^\d{4} \d{3} \d{3}$/.test(healthCardNumber);
        } else {
            return false;
        }
    };

    const AutoWidthInput = styled(Input)`
        font-style: normal;
        font-family: ${props => props.theme.typography.fontFamily};
        font-weight: ${FONT_WEIGHT_SEMI};
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.16px;
        color: #212121;
        border: 1px solid #979797;
        border-radius: 5px;
        padding-top: 11px;
        padding-bottom: 10px;
        padding-left: 10px;
        ::placeholder {
            color: ${COLOR_GREY.light};
        }
    `;

    const DateInput = props => <AutoWidthInput theme={theme} style={{ width: theme.spacing(9) }} mask={[/\d/, /\d/]} type="text" {...props} as={MaskedInput} />;
    const YearInput = props => (
        <AutoWidthInput theme={theme} style={{ width: theme.spacing(11) }} mask={[/\d/, /\d/, /\d/, /\d/]} type="text" {...props} as={MaskedInput} />
    );
    const OhipInputComponent = props => <OhipInput theme={theme} {...props} />;
    const AlbertaInputComponent = props => <AlbertaInput theme={theme} {...props} />;
    const NovaScotiaInputComponent = props => <NovaScotiaInput theme={theme} {...props} />;
    const NoHealthCardSystemSelectedInputComponent = props => <NoHealthCardSystemSelectedInput theme={theme} {...props} disabled={true} />;

    const FormDivSectionWrapper = styled.div`
        margin-top: 8px;
        display: flex;
        flex-direction: row;
    `;

    const accountVerifySubmissionFailureTitle = 'Submission Error';
    const accountVerifySubmissionFailureBody =
        'We’re having trouble verifying your account. Your computer might be offline or the Ned server may be experiencing problems. Please try again.';

    const defaultOnNextClick = () => {
        if (isVerificationAlreadySuccessful) {
            onNextPage();
        }
    };

    return (
        <>
            <StepsLayout
                onPrevClick={onPreviousPage}
                currentCount={1}
                onCancelClick={onCancel}
                titleText={'Account Setup'}
                nextButtonText={'Next'}
                isNextButtonDisabled={isButtonEnabled}
                id={'Accept-button-of-terms-service'}
                onNextClick={formRefBag.current?.submitForm || defaultOnNextClick}
                totalCount={3}
                submitting={submittingGlobal}
                submittingText={'Verifying'}
            >
                <Paper elevation={0} className={style.container}>
                    <Box mb={1} mt={3}>
                        <Typography variant="h2">Verify Identity</Typography>
                    </Box>
                    <Typography>To verify your identity, enter your province, health card number, and date of birth.</Typography>
                    <Box mt={4}>
                        <Formik
                            innerRef={formRefBag}
                            initialValues={{
                                year: newYear,
                                month: newMonth,
                                date: newDate,
                                healthCardNumber: newHealthCardNumber,
                                healthCardSystem: newHealthCardSystem,
                            }}
                            validationSchema={IdentitySchema}
                            onSubmit={(values, { setSubmitting }) => {
                                if (!isVerificationAlreadySuccessful) {
                                    // Save the state of our page here upon submit.
                                    let newDob = moment()
                                        .year(values.year)
                                        .month(values.month)
                                        .date(values.date)
                                        .hour(0)
                                        .minute(0)
                                        .second(0)
                                        .millisecond(0);
                                    setSubmissionActionCreator(prevFunc => dispatchDataSubmissionResult => {
                                        return verifyPatientInformation(
                                            token,
                                            values.healthCardSystem,
                                            values.healthCardNumber,
                                            newDob.toISOString(),
                                            dispatchDataSubmissionResult
                                        );
                                    });
                                    setSubmittingGlobal(true); // indicates that data is being sent to backend
                                    setSubmitting(false);
                                } else {
                                    // The verification has already been submitted.  We are probably in this code because the user had come back to this page.  Just go to the next page.
                                    setSubmitting(false);
                                    onNextPage();
                                }
                            }}
                        >
                            {({ errors, touched, isSubmitting, isValid, dirty, values }) => {
                                //Work around for warning:
                                //Cannot update a component (`SetupAccountStep3Verify`) while rendering a different component (`Formik`)
                                //https://github.com/formium/formik/issues/1218
                                setTimeout(
                                    () =>
                                        setButtonEnabled(
                                            !isVerificationAlreadySuccessful &&
                                                (isSubmitting ||
                                                    !isValid ||
                                                    !dirty ||
                                                    !healthCardPatternMatch(values.healthCardSystem, values.healthCardNumber))
                                        ),
                                    0
                                );

                                return (
                                    <>
                                        <Box mt={3} mb={1}>
                                            <Typography>Province</Typography>
                                        </Box>
                                        <HealthCardSystemSelectorField name="healthCardSystem" disabled={isVerificationAlreadySuccessful} />
                                        <Box mt={3} mb={1}>
                                            <Typography>Health Card Number</Typography>
                                        </Box>
                                        <Field
                                            name="healthCardNumber"
                                            as={
                                                values.healthCardSystem === HealthCardSystem.ALBERTA
                                                    ? AlbertaInputComponent
                                                    : values.healthCardSystem === HealthCardSystem.NOVA_SCOTIA
                                                    ? NovaScotiaInputComponent
                                                    : values.healthCardSystem === HealthCardSystem.ONTARIO
                                                    ? OhipInputComponent
                                                    : NoHealthCardSystemSelectedInputComponent
                                            }
                                            isinvalid={
                                                touched.healthCardNumber &&
                                                (errors.healthCardNumber || !healthCardPatternMatch(values.healthCardSystem, values.healthCardNumber))
                                            }
                                            disabled={isVerificationAlreadySuccessful}
                                        />
                                        <ErrorContainer
                                            shouldShow={
                                                touched.healthCardNumber &&
                                                (errors.healthCardNumber || !healthCardPatternMatch(values.healthCardSystem, values.healthCardNumber))
                                            }
                                        >
                                            <ErrorTypography variant="caption" color="error">
                                                Please enter a valid health card number
                                            </ErrorTypography>
                                        </ErrorContainer>
                                        <Box mt={3} mb={1}>
                                            <Typography>Date of Birth</Typography>
                                        </Box>
                                        <FormDivSectionWrapper>
                                            <MonthSelectorField name="month" disabled={isVerificationAlreadySuccessful} />
                                            <Box ml={1}>
                                                <Field
                                                    name="date"
                                                    as={DateInput}
                                                    placeholder="DD"
                                                    isinvalid={touched.date && errors.date}
                                                    disabled={isVerificationAlreadySuccessful}
                                                />
                                            </Box>
                                            <Box ml={1}>
                                                <Field
                                                    name="year"
                                                    as={YearInput}
                                                    placeholder="YYYY"
                                                    isinvalid={touched.year && errors.year}
                                                    disabled={isVerificationAlreadySuccessful}
                                                />
                                            </Box>
                                        </FormDivSectionWrapper>
                                        <ErrorContainer
                                            shouldShow={(touched.month && errors.month) || (touched.date && errors.date) || (touched.year && errors.year)}
                                        >
                                            <ErrorTypography variant="caption" color="error">
                                                Please enter valid date
                                            </ErrorTypography>
                                        </ErrorContainer>
                                        <ErrorContainer shouldShow={isMaximumAttemptsExceeded}>
                                            <ErrorTypography variant="caption" color="error">
                                                Your account has been locked because you have reached the maximum number of attempts.
                                            </ErrorTypography>
                                            <ErrorTypography variant="caption" color="error">
                                                To unlock your account, please contact us at support@getned.com
                                            </ErrorTypography>
                                        </ErrorContainer>
                                        <ErrorContainer shouldShow={isOtherError}>
                                            <ErrorTypography variant="caption" color="error">
                                                Error validating identity:{' '}
                                                {verificationResponse?.error ? verificationResponse.error : verificationResponse?.status}
                                            </ErrorTypography>
                                        </ErrorContainer>
                                        <ErrorContainer shouldShow={isIncorrectInput}>
                                            <ErrorTypography variant="caption" color="error">
                                                Your date of birth or health card number is incorrect.
                                            </ErrorTypography>{' '}
                                            <ErrorTypography variant="caption" color="error">
                                                Please try again.
                                            </ErrorTypography>
                                        </ErrorContainer>
                                    </>
                                );
                            }}
                        </Formik>
                    </Box>
                </Paper>
            </StepsLayout>
            <DataSubmissionHandler
                setSubmitting={setSubmittingGlobal}
                submitting={submittingGlobal}
                actionCreator={submissionActionCreator}
                failureMsgTitle={accountVerifySubmissionFailureTitle}
                failureMsgBody={accountVerifySubmissionFailureBody}
            />
        </>
    );
};

/* type checking for props */
SetupAccountStep3Verify.propTypes = {
    token: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onPreviousPage: PropTypes.func.isRequired,
    healthCardNumber: PropTypes.string.isRequired,
    healthCardSystem: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    verifyPatientInformation: PropTypes.func.isRequired,
    verificationResponse: PropTypes.object.isRequired,
};

export default SetupAccountStep3Verify;
