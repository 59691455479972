import React, { createContext, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { retrieveCurrentVisit, closeCurrentVisit } from '../actions';

/* create a new context to be used for the current visit */
const CurrentVisitContext = createContext();

/* create a new context to be used for the current visit APIS */
const CurrentVisitAPIContext = createContext();

/**
 * A context provider that retrieves the current visit object from the redux store, and injects it in any descendent components/children.
 * @param {object} currentVisit current visit of user retrieved from the redux store.
 * @param {node} children node that encapsulates all descent components of CurrentVisitProvider.
 */
function CurrentVisitProviderComponent({ currentVisit, children }) {
    return <CurrentVisitContext.Provider value={{ currentVisit }}>{children}</CurrentVisitContext.Provider>;
}

/**
 * A context provider that injects APIs related to the current visit in any descendent components/children.
 * @param {function} fetchCurrentVisit callback for retrieving the user's current visit from backend.
 * * @param {function} currentVisitClose callback for closing the user's current visit from backend.
 * @param {node} children node that encapsulates all descent components of CurrentVisitProvider.
 */
function CurrentVisitAPIProviderComponent({ fetchCurrentVisit, currentVisitClose, children }) {
    return <CurrentVisitAPIContext.Provider value={{ fetchCurrentVisit, currentVisitClose }}>{children}</CurrentVisitAPIContext.Provider>;
}

/**
 * Custom hook for accessing the currentVisit object.
 * Usage: use in any descendant component of the currentVisitProvider
 */
export function useCurrentVisit() {
    const { currentVisit } = useContext(CurrentVisitContext);
    return currentVisit;
}

/**
 * Custom hook for accessing the currentVisit API.
 * Usage: use in any descendant component of the currentVisitProvider
 */
export function useCurrentVisitAPI() {
    return useContext(CurrentVisitAPIContext);
}

/* connect to redux store */
const mapStateToProps = ({ currentVisit }) => {
    return {
        currentVisit,
    };
};

const mapDispatchToProps = dispatch => {
    const fetchCurrentVisit = () => {
        dispatch(retrieveCurrentVisit());
    };

    const currentVisitClose = (refreshVisit, refreshLoginSession) => {
        dispatch(closeCurrentVisit(refreshVisit, refreshLoginSession));
    };

    return {
        fetchCurrentVisit,
        currentVisitClose,
    };
};

/**
 * Ensures that a current visit is loaded for the children of the CurrentVisitUser.
 * Must be a child of a CurrentVisitAPIProvider. Must be a child of RequiresLogin
 *
 * Usage:
 *
 * <CurrentVisitAPIProvider>
 * ...
 *    <RequiresLogin>
 *    ...
 *       <CurrentVisitUser>
 *       ... componentst that require the current visit
 */
export function CurrentVisitUser({ children }) {
    const { fetchCurrentVisit } = useContext(CurrentVisitAPIContext);
    const { currentVisit } = useContext(CurrentVisitContext);
    useEffect(() => {
        if (!currentVisit.visit) {
            fetchCurrentVisit();
        }
    }, [fetchCurrentVisit, currentVisit]);
    return <>{children}</>;
}

export const CurrentVisitProvider = connect(mapStateToProps, null)(CurrentVisitProviderComponent);
export const CurrentVisitAPIProvider = connect(null, mapDispatchToProps)(CurrentVisitAPIProviderComponent);
