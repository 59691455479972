import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AnswerSecurityQuestions from './AnswerSecurityQuestions';
import ResetYourPassword from './ResetYourPassword';
import ResetPasswordComplete from './ResetPasswordComplete';
import YourAccountIsLocked from './YourAccountIsLocked';
import * as PageNames from './WizardPageNames';
import queryString from 'query-string';
import { PATH_RESET_LINK_EXPIRED } from '../../containers/RoutePaths';

export default function ResetPasswordWizard({
    retrieveSecurityQuestions,
    location,
    securityQuestions,
    submitAnswersToSecurityQuestions,
    oneTimeResetToken,
    submitNewPassword,
    failedAttempts,
    lockedUntil,
    serverTimeInMillis,
    isLocked,
    isNetworkError,
    maxAttempts,
}) {
    const [currentPage, setCurrentPage] = useState(PageNames.ANSWER_SECURITY_QUESTIONS_PAGE);
    const [resetTokenFromEmail, setResetTokenFromEmail] = useState('');
    const [hasSecurityQuestionRetrievalError, setHasSecurityQuestionRetrievalError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const retrieveSecurityQuestionsErrorCallBack = response => {
            /* token on the reset password link does not exist/has expired on the backend, so redirect
            to link expired page */
            if (response?.status === 401) {
                history.push(PATH_RESET_LINK_EXPIRED);
            } else {
                // It was some other error.  No security questions have been returned.
                if (response?.message) {
                    console.log(response?.message);
                }
                setHasSecurityQuestionRetrievalError(true);
            }
        };

        const queryValues = queryString.parse(location.search);
        setResetTokenFromEmail(queryValues.token);
        retrieveSecurityQuestions(queryValues.token, retrieveSecurityQuestionsErrorCallBack);
    }, [location, retrieveSecurityQuestions, history]);

    const completeWizard = () => {
        history.push('/patient/login');
    };

    return (
        <>
            <AnswerSecurityQuestions
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                hasSecurityQuestionRetrievalError={hasSecurityQuestionRetrievalError}
                securityQuestions={securityQuestions}
                submitAnswersToSecurityQuestions={submitAnswersToSecurityQuestions}
                resetTokenFromEmail={resetTokenFromEmail}
                oneTimeResetToken={oneTimeResetToken}
                failedAttempts={failedAttempts}
                lockedUntil={lockedUntil}
                serverTimeInMillis={serverTimeInMillis}
                isLocked={isLocked}
                isNetworkError={isNetworkError}
                maxAttempts={maxAttempts}
            />
            <ResetYourPassword
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                oneTimeResetToken={oneTimeResetToken}
                submitNewPassword={submitNewPassword}
            />
            <YourAccountIsLocked currentPage={currentPage} />
            <ResetPasswordComplete currentPage={currentPage} completeWizard={completeWizard} />
        </>
    );
}
