import * as TaskNames from '../components/home/TaskNames';

export function taskCompleted(visit, taskName) {
    const taskList = visit?.tasks ?? [];
    for (const task of taskList) {
        if (task.nedvcTaskIdentifier === taskName) {
            return task.status === 'COMPLETED';
        }
    }
    return false;
}

export const LabworkStatus = {
    INPROGRESS: 'INPROGRESS',
    COMPLETED: 'COMPLETED',
};

export const VisitState = {
    /* Newly created visit. It is not currently worked on. */
    NEW: 'NEW',

    /* State in which the user can now begin completing tasks */
    STARTED: 'STARTED',

    /*
     * State in which all tasks have been completed but clinician has yet to retrieve the manual labs.
     */
    PENDING_CLINICIAN_MANUAL_LABS_RETRIEVAL: 'PENDING_CLINICIAN_MANUAL_LABS_RETRIEVAL',

    /* State in which all tasks have been completed but clinician has not yet reviewed. */
    PENDING_CLINICIAN_REVIEW: 'PENDING_CLINICIAN_REVIEW',

    /* State where the clinician has reviewed the patient's visit. */
    REVIEWED: 'REVIEWED',

    /*
     * A closed visit is no longer active, due to completion or other reasons. Closed visits can not
     * be reopened.
     */
    CLOSED: 'CLOSED',

    /* A visit that has been aborted. */
    ABORTED: 'ABORTED',
};

export const TASK_BUSINESS_STATUS_MANUAL_LAB = 'MANUAL LAB';

export function checkLabworkStatus(visit) {
    const taskList = visit?.tasks ?? [];
    for (const task of taskList) {
        if (task.nedvcTaskIdentifier === TaskNames.LAB_WORK) {
            return task.status;
        }
    }

    return null;
}

export function checkForLabWorkBusinessStatus(visit) {
    const taskList = visit?.tasks ?? [];
    for (const task of taskList) {
        if (task.nedvcTaskIdentifier === TaskNames.LAB_WORK) {
            return task.businessStatus;
        }
    }

    return null;
}

export function getTaskFromVisit(visit, taskName) {
    let re = new RegExp(taskName, 'i');
    if (visit && visit.tasks) {
        for (const task of visit.tasks) {
            if (task.nedvcTaskIdentifier.match(re) !== null) {
                return task;
            }
        }
    }
}

export function getTaskIdNumber(task) {
    if (task) {
        let splitString = task.id.split('/');
        return splitString[1];
    }
}

export function isTaskPresent(visit, taskName) {
    const taskList = visit?.tasks ?? [];
    for (const task of taskList) {
        if (task.nedvcTaskIdentifier === taskName) {
            return true;
        }
    }
    return false;
}
