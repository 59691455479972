/* Renders the lab requisition home page */

import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getLabReq } from '../../apis/document';
import { fetchTaskRemote } from '../../apis/taskFhir';
import { actionSaga } from '../../sagas/dispatchSaga';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    getTaskFromVisit,
    getTaskIdNumber,
    checkForLabWorkBusinessStatus,
    checkLabworkStatus,
    LabworkStatus,
    TASK_BUSINESS_STATUS_MANUAL_LAB,
} from '../../utils/VisitTaskHandler';
import * as TaskNames from './TaskNames';
import { Box, Button, Grid, makeStyles, Typography, Checkbox, FormControlLabel, withStyles } from '@material-ui/core';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { FONT_WEIGHT_BOLD } from '../utilities/Material';
import { ReactComponent as DownloadIcon } from '../../resources/images/download.svg';
import { ReactComponent as OneIcon } from '../../resources/images/one.svg';
import { ReactComponent as TwoIcon } from '../../resources/images/two.svg';
import { ReactComponent as EllipseIcon } from '../../resources/images/ellipse.svg';
import { PATH_HOME } from '../../containers/RoutePaths';
import { RequiresLogin } from '../utilities/commonUtilities/RequiresLogin';
import { styleBreakSmallMobile, styleBreakDesktop } from '../utilities/layouts/Globals';
import { useCurrentVisit, useCurrentVisitAPI } from '../../userDataProviders/CurrentVisitProvider';
import {
    loadLabReqId,
    setLabReqIdLoadingResult,
    actionPutLabReqIdIntoStore,
    labWorkTaskCompleteMarking,
    retrieveCurrentVisit,
    sendClientAnalytics,
} from '../../actions';
import IsLoadingPage from '../IsLoadingPage';
import LoadingErrorPage from '../LoadingErrorPage';
import { LoadingStatus, LoadingResult } from '../../utils/LoadingUtils';
import { markLabTaskComplete } from '../../apis/currentVisit';
import * as actions from '../../actions/actionTypes';
import DataSubmissionHandler from '../DataSubmissionHandler';
import AlertModalYesNo from '../utilities/AlertModal/AlertModalYesNo';
import { ReactComponent as DoneBadge } from '../../resources/images/done-badge.svg';
import AllTasksCompletedPage from '../AllTasksCompletedPage';
import { AuditEventAction, createClientAnalytics } from '../../utils/ClientAnalytics';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    subTitle: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.primary,
    },
    subTitleManual: {
        paddingTop: theme.spacing(0.5),
        marginLeft: theme.spacing(2),
    },
    firstGridRow: {
        marginTop: theme.spacing(2),
        [styleBreakDesktop(theme)]: {
            marginTop: theme.spacing(3),
        },
    },
    gridRow: {
        marginTop: theme.spacing(2),
    },
    gridIcon: {
        width: theme.spacing(4),
    },
    gridIconManual: {
        marginTop: theme.spacing(2),
    },
    gridTextManual: {
        marginLeft: theme.spacing(4.5),
        paddingTop: 0,
        marginTop: theme.spacing(1),
        color: theme.palette.text.primary,
    },
    listText: {
        paddingTop: theme.spacing(0.5),
        marginLeft: theme.spacing(2),
        fontWeight: FONT_WEIGHT_BOLD,
        color: theme.palette.text.primary,
    },
    bulletPointText: {
        marginLeft: theme.spacing(1.5),
    },
    hiddenGridIcon: {
        //Hide icon but still takes up space to keep margins
        visibility: 'hidden',
    },
    gridText: {
        marginLeft: theme.spacing(2),
        paddingTop: 0,
        color: theme.palette.text.primary,
    },
    buttonContainer: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
    },
    button: {
        padding: 0,
    },
    contentContainer: {
        paddingBottom: theme.spacing(3),
        [styleBreakSmallMobile(theme)]: {
            paddingBottom: theme.spacing(2),
        },
    },
    footer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
    },
    footTypographyTitle: {
        fontWeight: FONT_WEIGHT_BOLD,
        marginBottom: theme.spacing(0.5),
    },
    footTypographyTitleNoMargin: {
        fontWeight: FONT_WEIGHT_BOLD,
    },
    startMargin: {
        marginRight: theme.spacing(2),
    },
    ellipseStyle: {
        marginLeft: theme.spacing(6),
        width: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
    },
    downloadStyle: {
        marginLeft: theme.spacing(4.5),
    },
    downloadText: {
        textAlign: 'left',
        fontWeight: FONT_WEIGHT_BOLD,
        paddingTop: '7px',
    },
}));

export const BigCheckBox = withStyles({
    root: {
        '& .MuiSvgIcon-root': {
            height: '26px',
            width: '26px',
        },
    },
})(Checkbox);

const LabRequisitionHome = ({
    fetchTask,
    labworkStatus,
    labworkBusinessStatus,
    psaTaskId,
    labReqId,
    isLoadingLabReqId,
    labReqIdLoadingResult,
    isLoadingVisit,
    visitLoadingResult,
}) => {
    const classes = useStyles();
    const currentVisit = useCurrentVisit();
    const { fetchCurrentVisit } = useCurrentVisitAPI();

    const [isOpenCompleteTaskDialog, setOpenCompleteTaskDialog] = useState(false);

    // Variables for marking lab task complete
    const [submitting, setSubmitting] = useState(false);
    const labWorkCompleteSubmissionFailureTitle = 'Submission Error';
    const labWorkCompleteSubmissionFailureBody =
        "We're having trouble submitting your blood test task confirmation. Your computer might be offline or the Ned blood test task may be experiencing problems. Please try again.";

    const labWorkCompleteSubmissionSuccess = () => {};

    const submitTaskCompleteRequest = () => {
        // Leverage DataSubmissionHandler component via the submitting state.
        setSubmitting(true);
    };

    useEffect(() => {
        if (!currentVisit.visit) {
            fetchCurrentVisit();
        }
    }, [fetchCurrentVisit, currentVisit]);

    useEffect(() => {
        if (!isLoadingVisit && !labReqId) {
            fetchTask(psaTaskId);
        }
    }, [fetchTask, psaTaskId, isLoadingVisit, labReqId]);

    if (currentVisit.isAllTasksRecentlyCompleted) {
        return <AllTasksCompletedPage />;
    } else if (isLoadingVisit || isLoadingLabReqId) {
        return <IsLoadingPage />;
    } else {
        if (visitLoadingResult === LoadingResult.FAILED || labReqIdLoadingResult === LoadingResult.FAILED) {
            return <LoadingErrorPage />;
        } else {
            return (
                <>
                    <RequiresLogin>
                        <DetailsLayout title="Get a blood test" backTo={PATH_HOME}>
                            <ContentLayout>
                                <Box className={classes.contentContainer}>
                                    <LabInstructList labReqId={labReqId} downloadPdf={downloadPdf} labworkBusinessStatus={labworkBusinessStatus} />
                                    <LabReqFooter
                                        labworkStatus={labworkStatus}
                                        labworkBusinessStatus={labworkBusinessStatus}
                                        setOpenCompleteTaskDialog={setOpenCompleteTaskDialog}
                                    />
                                </Box>
                            </ContentLayout>
                        </DetailsLayout>
                    </RequiresLogin>
                    <ConfirmTaskCompletionDialog
                        isOpen={isOpenCompleteTaskDialog}
                        setOpenCompleteTaskDialog={setOpenCompleteTaskDialog}
                        handleCompleteLabWorkTask={submitTaskCompleteRequest}
                    />
                    <DataSubmissionHandler
                        setSubmitting={setSubmitting}
                        submitting={submitting}
                        actionCreator={dispatchDataSubmissionResult => {
                            return labWorkTaskCompleteMarking(dispatchDataSubmissionResult, true);
                        }}
                        failureMsgTitle={labWorkCompleteSubmissionFailureTitle}
                        failureMsgBody={labWorkCompleteSubmissionFailureBody}
                        submissionSuccessHandler={labWorkCompleteSubmissionSuccess}
                    />
                </>
            );
        }
    }
};

export const LabInstructList = ({ labReqId, downloadPdf, labworkBusinessStatus }) => {
    const dispatch = useDispatch();
    const labReqInstructionTitle = labReqId ? 'Your printed lab requisition' : 'Your lab requisition';
    const labReqInstruction = labReqId
        ? 'Please print your lab requisition to bring to the lab.'
        : 'Please bring your lab requisition received in clinic to the lab.';
    const healthCardText = 'Your health card';
    const visitLabText = "Visit your local lab and get a blood test. Don't forget to bring the items below:";
    const checkOffBoxText = 'Please check off the box below, after you have completed your blood test.';

    const onDownloadClick = () => {
        const labReqDownloadedAnalytics = createClientAnalytics(AuditEventAction.LABREQ_DOWNLOADED.category, AuditEventAction.LABREQ_DOWNLOADED.action);
        dispatch(sendClientAnalytics(labReqDownloadedAnalytics));
        if (downloadPdf) {
            downloadPdf(labReqId);
        }
    };

    return (
        <>
            {labworkBusinessStatus === TASK_BUSINESS_STATUS_MANUAL_LAB ? (
                <ManualInstructionList
                    labReqInstructionTitle={labReqInstructionTitle}
                    labReqInstruction={labReqInstruction}
                    healthCardText={healthCardText}
                    visitLabText={visitLabText}
                    onDownloadClick={onDownloadClick}
                    labReqId={labReqId}
                    checkOffBoxText={checkOffBoxText}
                />
            ) : (
                <AutoInstructionList
                    labReqInstructionTitle={labReqInstructionTitle}
                    labReqInstruction={labReqInstruction}
                    healthCardText={healthCardText}
                    visitLabText={visitLabText}
                    onDownloadClick={onDownloadClick}
                    labReqId={labReqId}
                />
            )}
        </>
    );
};

const DownloadComponent = ({ labReqId, onDownloadClick, downloadStyle }) => {
    const classes = useStyles();
    return (
        <InstructionRow>
            <Grid
                item
                xs
                zeroMinWidth
                className={clsx(classes.buttonContainer, {
                    [downloadStyle]: !!downloadStyle,
                })}
            >
                {labReqId && (
                    <Button
                        className={classes.button}
                        variant={'text'}
                        onClick={onDownloadClick}
                        startIcon={<DownloadIcon aria-labelledby="download" />}
                        datatestid="downloadLabReqButton"
                    >
                        <Typography component="span" className={classes.downloadText}>
                            Download lab requisition
                        </Typography>
                    </Button>
                )}
            </Grid>
        </InstructionRow>
    );
};

const AutoInstructionList = ({ labReqInstructionTitle, labReqInstruction, healthCardText, visitLabText, labReqId, onDownloadClick }) => {
    const classes = useStyles();
    return (
        <>
            <Typography className={classes.subTitle}>{visitLabText}</Typography>
            <InstructionRow Icon={OneIcon} styling={classes.firstGridRow}>
                <Grid item xs zeroMinWidth>
                    <Typography className={classes.listText}>{healthCardText}</Typography>
                </Grid>
            </InstructionRow>
            <InstructionRow Icon={TwoIcon} styling={classes.gridRow}>
                <Grid item xs zeroMinWidth>
                    <Typography className={classes.listText}>{labReqInstructionTitle}</Typography>
                </Grid>
            </InstructionRow>
            <InstructionRow>
                <Grid item xs zeroMinWidth>
                    <Typography className={classes.gridText}>{labReqInstruction}</Typography>
                </Grid>
            </InstructionRow>
            <DownloadComponent labReqId={labReqId} onDownloadClick={onDownloadClick} />
        </>
    );
};

const ManualInstructionList = ({ labReqInstructionTitle, labReqInstruction, healthCardText, visitLabText, labReqId, onDownloadClick, checkOffBoxText }) => {
    const classes = useStyles();
    return (
        <>
            <InstructionRow Icon={OneIcon} IconStyles={classes.gridIconManual}>
                <Grid item xs zeroMinWidth>
                    <Typography className={clsx(classes.subTitle, classes.subTitleManual)}>{visitLabText}</Typography>
                </Grid>
            </InstructionRow>
            <InstructionRow Icon={EllipseIcon} styling={classes.gridRow} IconStyles={classes.ellipseStyle}>
                <Grid item xs zeroMinWidth>
                    <Typography className={clsx(classes.listText, classes.bulletPointText)}>{healthCardText}</Typography>
                </Grid>
            </InstructionRow>
            <InstructionRow Icon={EllipseIcon} styling={classes.gridRow} IconStyles={classes.ellipseStyle}>
                <Grid item xs zeroMinWidth>
                    <Typography className={clsx(classes.listText, classes.bulletPointText)}>{labReqInstructionTitle}</Typography>
                </Grid>
            </InstructionRow>
            <InstructionRow>
                <Grid item xs zeroMinWidth>
                    <Typography className={classes.gridTextManual}>{labReqInstruction}</Typography>
                </Grid>
            </InstructionRow>
            <DownloadComponent labReqId={labReqId} onDownloadClick={onDownloadClick} downloadStyle={classes.downloadStyle} />
            <Box component="div" marginBottom={'64px'} marginTop={'-32px'}>
                <InstructionRow Icon={TwoIcon} IconStyles={classes.gridIconManual}>
                    <Grid item xs zeroMinWidth>
                        <Typography className={clsx(classes.subTitle, classes.subTitleManual)}>{checkOffBoxText}</Typography>
                    </Grid>
                </InstructionRow>
            </Box>
        </>
    );
};

const InstructionRow = ({ Icon, styling, IconStyles, children }) => {
    const classes = useStyles();
    return (
        <Grid container wrap="nowrap" className={styling ? styling : ''}>
            <Grid
                item
                className={clsx(classes.gridIcon, {
                    [IconStyles]: !!IconStyles,
                })}
            >
                {Icon && <Icon aria-labelledby="icon" />}
            </Grid>
            {children}
        </Grid>
    );
};

const ManualLabCompleteFooter = () => {
    const classes = useStyles();
    return (
        <Grid container wrap="nowrap" alignItems="center" className={classes.footer}>
            <Grid item className={classes.startMargin}>
                <DoneBadge />
            </Grid>
            <Grid item xs zeroMinWidth>
                <Typography className={classes.footTypographyTitle}>Task complete!</Typography>
            </Grid>
        </Grid>
    );
};

const ManualLabInProgressFooter = ({ setOpenCompleteTaskDialog }) => {
    const classes = useStyles();
    const onCheckBoxChange = event => {
        setOpenCompleteTaskDialog(true);
    };
    return (
        <Box display="flex" alignItems="center" className={classes.footer}>
            <FormControlLabel
                control={<BigCheckBox checked={false} onChange={onCheckBoxChange} />}
                label={<Typography className={classes.footTypographyTitleNoMargin}>Mark task as complete</Typography>}
            />
        </Box>
    );
};

const LabFooter = () => {
    const classes = useStyles();
    return (
        <Box className={classes.footer} display="flex" flexDirection="column">
            <Typography variant="caption" className={classes.footTypographyTitle}>
                Keep in Mind
            </Typography>
            <Typography variant="caption">
                Lab results can take a few days to arrive. If your results haven't arrived one week after your blood test, please contact your local lab.
            </Typography>
        </Box>
    );
};

const LabReqFooter = ({ labworkStatus, labworkBusinessStatus, setOpenCompleteTaskDialog }) => {
    const isLabworkComplete = labworkStatus === LabworkStatus.COMPLETED;

    // If we have a manual lab we need to show the checkbox.
    if (labworkBusinessStatus === TASK_BUSINESS_STATUS_MANUAL_LAB) {
        if (isLabworkComplete) {
            return <ManualLabCompleteFooter />;
        } else {
            return <ManualLabInProgressFooter setOpenCompleteTaskDialog={setOpenCompleteTaskDialog} />;
        }
    } else {
        return <LabFooter />;
    }
};

export const ConfirmTaskCompletionDialog = ({ isOpen, setOpenCompleteTaskDialog, handleCompleteLabWorkTask }) => {
    const dispatch = useDispatch();

    const onCompleteTask = () => {
        setOpenCompleteTaskDialog(false);
        const manualLabCompletionAnalytics = createClientAnalytics(
            AuditEventAction.MANUAL_LAB_COMPLETION.category,
            AuditEventAction.MANUAL_LAB_COMPLETION.action
        );
        dispatch(sendClientAnalytics(manualLabCompletionAnalytics));
        handleCompleteLabWorkTask();
    };

    const onCloseDialog = () => {
        setOpenCompleteTaskDialog(false);
    };

    return (
        <AlertModalYesNo
            isDisplayed={isOpen}
            alertModalTitle={'Confirm Completion'}
            alertModalBody={'Confirm that you have completed your blood test for this visit.'}
            onYesSelected={onCompleteTask}
            onNoSelected={onCloseDialog}
            onCloseSelected={onCloseDialog}
            yesButtonText="Confirm"
            noButtonText="Cancel"
            datatestid="confirmTaskCompletionButton"
        />
    );
};

async function downloadPdf(pdfId) {
    const data = await fetchPdf(pdfId);
    const file = new Blob([data], { type: 'application/pdf' });
    const url = URL.createObjectURL(file);
    savePdf(url, file);
}

async function fetchPdf(pdfId) {
    try {
        const response = await getLabReq(pdfId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

function savePdf(url, file) {
    const filename = 'Blood_Lab_Requisition.pdf';
    if (window.navigator.msSaveOrOpenBlob) {
        //IE support
        window.navigator.msSaveOrOpenBlob(file);
    } else {
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }
}

const mapStateToProps = state => {
    const PSATask = getTaskFromVisit(state.currentVisit.visit, TaskNames.LAB_WORK);
    const psaTaskId = getTaskIdNumber(PSATask);
    const labworkStatus = checkLabworkStatus(state.currentVisit.visit);
    const labworkBusinessStatus = checkForLabWorkBusinessStatus(state.currentVisit.visit);
    return {
        labReqId: state?.labReqId?.id,
        labworkStatus,
        labworkBusinessStatus,
        psaTaskId,
        isLoadingLabReqId: state?.dataLoading?.labReqId?.loading !== LoadingStatus.ENDED,
        labReqIdLoadingResult: state?.dataLoading?.labReqId?.loadingResult,
        isLoadingVisit: state?.dataLoading?.visit?.loading !== LoadingStatus.ENDED,
        visitLoadingResult: state?.dataLoading?.visit?.loadingResult,
    };
};

const mapDispatchToProps = {
    fetchTask: actionTaskFetch,
};

function actionTaskFetch(taskId) {
    return actionSaga('TASK_FETCH_FROM_SERVER', taskSaga, taskId);
}

function* taskSaga(taskId) {
    yield put(loadLabReqId(LoadingStatus.STARTED));
    try {
        const response = yield call(fetchTaskRemote, taskId);
        if (response.status === 200 && response.data?.total > 0 && response.data.entry[0].resource.input) {
            const pdfId = getPdfIdFromResponse(response, taskId);
            yield put(actionPutLabReqIdIntoStore(pdfId));
            yield put(setLabReqIdLoadingResult(LoadingResult.SUCCESSFUL));
        } else {
            yield put(setLabReqIdLoadingResult(LoadingResult.FAILED));
        }
    } catch (error) {
        yield put(setLabReqIdLoadingResult(LoadingResult.FAILED));
        console.log(error);
    }
    yield put(loadLabReqId(LoadingStatus.ENDED));
}

function getPdfIdFromResponse(response, taskId) {
    let pdfId = null;

    // Get entry list from fhir response bundle
    const entries = response?.data?.entry ?? [];

    let taskInputs = [];
    // Get the task input list for the task with id taskId
    for (const entry of entries) {
        if (entry?.resource?.id === taskId) {
            taskInputs = entry?.resource?.input;
            break;
        }
    }

    for (const taskInput of taskInputs) {
        // Check if the task input is a document and get the valueString property, which is the id
        if (taskInput.type?.text === 'DOCUMENT') {
            pdfId = taskInput.valueString;
            break;
        }
    }

    return pdfId;
}

// Saga and watch functions for setting the lab work task as complete.
export function* setLabWorkTaskCompleteSaga(action) {
    try {
        yield call(markLabTaskComplete);

        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(true));
        }

        if (action.refreshVisit) {
            yield put(retrieveCurrentVisit());
        }
    } catch (error) {
        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(false));
        }
        console.dir(error);
    }
}

export function* watchSetLabWorkTaskComplete() {
    yield takeLatest(actions.MARK_LAB_WORK_TASK_COMPLETE, setLabWorkTaskCompleteSaga);
}

export default connect(mapStateToProps, mapDispatchToProps)(LabRequisitionHome);
