import React from 'react';
import MainLayout from '../utilities/layouts/MainLayout';
import { EmptyItemContent as PausedPageContent } from '../utilities/commonUtilities/CardItem';
import { Box, makeStyles } from '@material-ui/core';
import { ReactComponent as AccountPaused } from '../../resources/images/account-paused.svg';

const useStyles = makeStyles(theme => ({
    box: {
        paddingTop: theme.spacing(8),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        marginBottom: theme.spacing(1),
        lineHeight: 1.5,
        letterSpacing: '0.24px',
    },
}));

export default function PausedHomePage() {
    const classes = useStyles();
    const title = 'Virtual Follow-up Paused';
    const message =
        'Your enrollment in the Ned Virtual Clinic has been paused by your doctor. You will still be able to access your previous results and doctor notes on Ned.';
    return (
        <MainLayout>
            <Box display="flex" flexDirection="column" alignItems="center" maxWidth={320} className={classes.box}>
                <PausedPageContent Image={AccountPaused} title={title} message={message} style={{ title: classes.title }} />
            </Box>
        </MainLayout>
    );
}
