/**
 * Ned Theme in material-ui.
 *
 * Tree of available customizations:
 * https://material-ui.com/customization/default-theme/
 */
import { createMuiTheme } from '@material-ui/core';

export const COLOR_WHITE = '#ffffff';
export const COLOR_BLACK = '#000000';

export const FONT_WEIGHT_NORMAL = 400;
export const FONT_WEIGHT_SEMI = 500;
export const FONT_WEIGHT_BOLD = 600;
const FONT_SIZE_BODY = '16px';

export const COLOR_GREY = {
    dark: '#2d343d',
    main: '#747b84',
    light: '#a7b4bf',
};

// See palette options - https://material-ui.com/customization/palette/
const palette = {
    primary: {
        dark: '#393f70',
        main: '#666ca1',
        light: '#eeedfc',
        contrastText: COLOR_WHITE,
    },
    secondary: {
        main: '#eeedfc',
        contrastText: '#666ca1',
    },
    info: {
        main: '#e7f2f5',
        contrastText: '#117a9b',
    },
    warning: {
        main: '#117a9b',
        contrastText: COLOR_WHITE,
    },
    error: {
        main: '#eb5160',
    },
    success: {
        main: '#e8fbfc',
        contrastText: '#18aab0',
    },
    background: {
        default: '#f3f5fa',
    },
    action: {
        main: '#eeedfc',
        contrastText: '#494372',
        disabledContrastText: '#888ca9',
        disabled: '#f5f4fd',
    },
    text: {
        primary: '#2d343d',
    },
};

// Font sizes - https://material-ui.com/customization/typography/
const typography = {
    fontFamily: ['Avenir Next', 'Nunito Sans'].join(','),
    h1: {
        fontSize: '30px',
        fontWeight: FONT_WEIGHT_BOLD,
    },
    h2: {
        fontSize: '20px',
        fontWeight: FONT_WEIGHT_BOLD,
        lineHeight: '27px',
        letterSpacing: '0.24px',
    },
    h3: {
        fontSize: '18px',
        fontWeight: FONT_WEIGHT_BOLD,
    },
    h4: {
        fontWeight: FONT_WEIGHT_BOLD,
        fontSize: '14px',
    },
    body1: {
        fontSize: FONT_SIZE_BODY,
        fontWeight: FONT_WEIGHT_NORMAL,
    },
    body2: {
        fontSize: FONT_SIZE_BODY,
        fontWeight: FONT_WEIGHT_SEMI,
    },
    button: {
        textTransform: 'none', // Disables the default material all caps of button text
        fontSize: FONT_SIZE_BODY,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    caption: {
        fontSize: '14px',
    },
    subtitle1: {
        fontSize: FONT_SIZE_BODY,
        fontWeight: FONT_WEIGHT_BOLD,
    },
};

// Breakpoint customization
// https://material-ui.com/customization/breakpoints/#custom-breakpoints
const breakpoints = {
    values: {
        sm: 0,
        md: 360,
        lg: 830,
    },
};

// Default props and css overrides for controls
// https://material-ui.com/customization/globals/#default-props
const MuiButtonProps = {
    variant: 'contained',
    color: 'primary',
};
const MuiButtonOverrides = {
    containedPrimary: {
        '&:disabled': {
            color: '#ffffffcc',
            background: '#666ca180',
        },
        height: '45px',
    },
    outlinedPrimary: {
        height: '45px',
    },
};

const MuiTextFieldProps = {
    variant: 'outlined',
    color: 'primary',
};
const MuiOutlinedInputOverrides = {
    root: {
        height: '45px',
    },
    input: {
        fontWeight: FONT_WEIGHT_SEMI,
        fontSize: FONT_SIZE_BODY,
    },
};

/**
 * The default Ned theme.
 */
export const NedTheme = createMuiTheme({
    palette,
    typography,
    breakpoints,
    props: {
        MuiButton: MuiButtonProps,
        MuiTextField: MuiTextFieldProps,
    },
    overrides: {
        MuiButton: MuiButtonOverrides,
        MuiOutlinedInput: MuiOutlinedInputOverrides,
    },
});
