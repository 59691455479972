import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ReactComponent as HammerIcon } from '../../resources/images/hammer.svg';
import { EmptyItemContent } from '../utilities/commonUtilities/CardItem';
import { styleBreakDesktop } from '../utilities/layouts/Globals';

const useStyles = makeStyles(theme => ({
    title: {
        marginBottom: theme.spacing(1),
    },
    message: {
        [styleBreakDesktop(theme)]: {
            maxWidth: '528px',
        },
    },
}));

export function NoVisitSubPage() {
    const title = 'Setting up your homepage';
    const message = `We're working to retrieve your information from the doctor's office. It may take up to 24 hours to see your next visit date on Ned.`;
    const classes = useStyles();
    return (
        <Box display="flex" justifyContent="center">
            <Box display="flex" flexDirection="column" mt={8}>
                <EmptyItemContent Image={HammerIcon} title={title} message={message} style={classes} />
            </Box>
        </Box>
    );
}
