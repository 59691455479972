import React from 'react';
import AlertModalYesNo from '../AlertModal/AlertModalYesNo';
import { DialogBody } from './GenericSpanElements';
import { DialogBodyMargin } from './GenericDivElements';

export const FormLeaveConfirmationModal = ({ isDisplayed, setDialogOpen, onYesSelected }) => {
    return (
        <AlertModalYesNo
            isDisplayed={isDisplayed}
            alertModalTitle={'Leave Page'}
            alertModalBody={
                <div>
                    <DialogBody>If you leave now, any changes you've made will not be saved.</DialogBody>
                    <DialogBodyMargin> Are you sure you want to leave?</DialogBodyMargin>
                </div>
            }
            noButtonText="Cancel"
            yesButtonText="Leave"
            onNoSelected={() => setDialogOpen(false)}
            onYesSelected={() => onYesSelected()}
            onCloseSelected={() => setDialogOpen(false)}
        />
    );
};
