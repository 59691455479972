import React from 'react';
import styled from 'styled-components';
import * as PageNames from './WizardPageNames';
import { Heading3WithMarginA } from '../utilities/commonUtilities/Typography';
import { MedBody1 } from '../utilities/commonUtilities/GenericDivElements';

const AccountLockedHeading = styled(Heading3WithMarginA)`
    margin-top: 60px;
`;

export default function YourAccountIsLocked({ currentPage }) {
    if (currentPage !== PageNames.YOUR_ACCOUNT_IS_LOCKED_PAGE) {
        return null;
    }

    return (
        <>
            <AccountLockedHeading>Your Account is Locked</AccountLockedHeading>
            <MedBody1>
                <p>You have reached the maximum number of attempts to answer your security questions.</p>
                <p>To unlock your account, please contact namename@email.com</p>
            </MedBody1>
        </>
    );
}
