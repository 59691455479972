import React, { useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SecurityQuestions from '../SecurityQuestions';
import { useHistory } from 'react-router-dom';
import { FormLeaveConfirmationModal } from '../utilities/commonUtilities/FormLeaveConfirmation';
import * as SecurityQuestionsAPI from '../../apis/securityQuestions';
import { call, takeLatest, put } from 'redux-saga/effects';
import { Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as DoneBadge } from '../../resources/images/done-badge-large.svg';
import { ContentLayout } from '../utilities/layouts/ContentLayout';
import { DetailsLayout } from '../utilities/layouts/DetailsLayout';
import { PrimaryButton } from '../utilities/commonUtilities/GenericButtonElements';
import { styleBreakDesktop, styleBreakMobile } from '../utilities/layouts/Globals';
import OnCompleteLayout from '../utilities/layouts/OnCompleteLayout';
import { profileChangeSecurityQuestions } from '../../actions';
import * as actions from '../../actions/actionTypes';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataSubmissionHandler from '../DataSubmissionHandler';

const SECURITY_CHANGE_COMPLETE = 'SECURITY_CHANGE_COMPLETE';

const useStyles = makeStyles(theme => ({
    img: {
        marginBottom: theme.spacing(4),
    },
    typography: {
        marginTop: theme.spacing(1.75),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        textAlign: 'center',
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(1),
        },
    },
    saveChangesButton: {
        width: '100%',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        [styleBreakDesktop(theme)]: {
            width: 'fit-content',
            minWidth: theme.spacing(35),
            marginTop: theme.spacing(6),
        },
    },
    bottomSpace: {
        marginBottom: theme.spacing(3),
    },
    bodyText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        [styleBreakMobile(theme)]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(3),
        },
    },
    buttonCircularProgress: {
        marginRight: theme.spacing(0.5),
    },
}));

function ErrorDiv({ text }) {
    return <Typography color="error">{text}</Typography>;
}

const ChangeSecurityQuestionsPage = ({ token }) => {
    const history = useHistory();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [page, setPage] = useState('');
    const classes = useStyles();
    const formikBagRef = useRef();
    const [isSubmitting, setIsSubmitting] = useState(false);
    // creates an action that is dispatched when form is submitted
    const [submissionActionCreator, setSubmissionActionCreator] = useState(null);

    if (!token) {
        return <Redirect to="/patient/login" />;
    }

    const onSubmitSuccess = () => {
        setPage(SECURITY_CHANGE_COMPLETE);
    };

    const onSubmitFail = error => {
        setErrorText(error);
    };

    // The onSubmit function is passed into formik which will fill out the appropriate params.
    // https://jaredpalmer.com/formik/docs/api/withFormik#handlesubmit-values-values-formikbag-formikbag--void--promiseany
    const onSubmit = (values, formikBag) => {
        setErrorText(null);
        setSubmissionActionCreator(prevFunc => dispatchDataSubmissionResult => {
            return profileChangeSecurityQuestions(values, onSubmitSuccess, onSubmitFail, dispatchDataSubmissionResult);
        });
        setIsSubmitting(true); // indicates that data is being sent to backend
        formikBag.setSubmitting(false);
    };

    const onBackHandler = () => {
        if (isFormDirty) {
            setDialogOpen(true);
        } else {
            onBack(history);
        }
    };

    const changeSecurityQnsSubmissionFailureTitle = 'Submission Error';
    const changeSecurityQnsSubmissionFailureBody =
        'We’re having trouble updating your account information. Your computer might be offline or the Ned server may be experiencing problems. Please try again.';

    switch (page) {
        case SECURITY_CHANGE_COMPLETE:
            const imageComponent = <DoneBadge aria-labelledby="Done Badge" />;
            return (
                <OnCompleteLayout
                    imageComponent={imageComponent}
                    title="Security Questions Changed"
                    body="Your security questions and responses have been updated."
                />
            );
        default:
            return (
                <>
                    <DetailsLayout title="Change Security Questions" onClick={onBackHandler}>
                        <ContentLayout>
                            <Typography variant="body1" className={classes.bodyText}>
                                If you forget your password, these new questions will help you get back into Ned Virtual Clinic.
                            </Typography>
                            <SecurityQuestions
                                submitHandler={onSubmit}
                                setIsFormDirty={setIsFormDirty}
                                setIsFormValid={setIsFormValid}
                                formikBagRef={formikBagRef}
                            ></SecurityQuestions>
                            <PrimaryButton
                                onClick={formikBagRef.current?.submitForm}
                                type="submit"
                                disabled={!isFormDirty || !isFormValid}
                                className={classes.saveChangesButton}
                            >
                                {isSubmitting ? (
                                    <>
                                        <CircularProgress className={classes.buttonCircularProgress} size={20} />
                                        <span>{'Saving Changes...'}</span>
                                    </>
                                ) : (
                                    'Save Changes'
                                )}
                            </PrimaryButton>
                            {!!errorText ? <ErrorDiv text={errorText} /> : <div className={classes.bottomSpace}></div>}
                            <FormLeaveConfirmationModal isDisplayed={isDialogOpen} setDialogOpen={setDialogOpen} onYesSelected={() => onBack(history)} />
                        </ContentLayout>
                    </DetailsLayout>
                    <DataSubmissionHandler
                        setSubmitting={setIsSubmitting}
                        submitting={isSubmitting}
                        actionCreator={submissionActionCreator}
                        failureMsgTitle={changeSecurityQnsSubmissionFailureTitle}
                        failureMsgBody={changeSecurityQnsSubmissionFailureBody}
                    />
                </>
            );
    }
};

function onBack(history) {
    history.push('/patient/profile');
}

const mapStateToProps = state => {
    return {
        token: state.login?.session?.token ?? '',
    };
};

export default connect(mapStateToProps, null)(ChangeSecurityQuestionsPage);

/** profile change security questions saga */
export function* changeSecurityQuestionsSaga(action) {
    try {
        // Let's update the security questions in the saga.  We need to report the outcome somehow.
        yield call(
            SecurityQuestionsAPI.updateSecurityQuestions,
            action.securityQuestionsAndAnswers.question1,
            action.securityQuestionsAndAnswers.answer1,
            action.securityQuestionsAndAnswers.question2,
            action.securityQuestionsAndAnswers.answer2,
            action.securityQuestionsAndAnswers.question3,
            action.securityQuestionsAndAnswers.answer3
        );

        yield call(action.onSubmitSuccess);

        /* dispatch result of data submission */
        if (action.dispatchDataSubmissionResult) {
            yield put(action.dispatchDataSubmissionResult(true));
        }
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            let errorText = '';

            if (error.response.status === 401) {
                errorText = 'unauthorized access.';
            } else {
                errorText = 'Error submitting security questions: ' + error.response.status;
            }
            yield call(action.onSubmitFail, errorText);

            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(true));
            }
        } else {
            // Either the request was made but no response was received, or
            // something happened in setting up the request that triggered an Error
            /* dispatch result of data submission */
            if (action.dispatchDataSubmissionResult) {
                yield put(action.dispatchDataSubmissionResult(false));
            }
        }
    }
}

export function* watchProfileSecurityQuestionsChange() {
    yield takeLatest(actions.PROFILE_CHANGE_SECURITY_QUESTIONS, changeSecurityQuestionsSaga);
}
