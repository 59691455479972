import React from 'react';
import { FlexRowDivWithMarginA, SpaceBtnColumnFlexWrapper } from '../commonUtilities/Layout';
import ConfirmationLayout from './ConfirmationLayout';
import { Box, Typography } from '@material-ui/core/';

export default function OnCompleteLayout({ buttonText, onClick, imageComponent, title, body }) {
    return (
        <ConfirmationLayout buttonText={buttonText} onClick={onClick}>
            <SpaceBtnColumnFlexWrapper>
                <div>
                    <FlexRowDivWithMarginA>{imageComponent}</FlexRowDivWithMarginA>
                    <Box mt={4} style={{ textAlign: 'center' }}>
                        <Typography variant="h2">{title}</Typography>
                    </Box>
                    <Box mt={1} style={{ textAlign: 'center' }}>
                        <Typography>{body}</Typography>
                    </Box>
                </div>
            </SpaceBtnColumnFlexWrapper>
        </ConfirmationLayout>
    );
}
