/**
 * Status for a loading process.
 */
export const LoadingStatus = {
    /**
     * Loading has not yet been attempted.
     */
    NONE: 'NONE',

    /**
     * Loading has begun but not yet ended.
     */
    STARTED: 'STARTED',

    /**
     * Loading has ended.
     */
    ENDED: 'ENDED',
};

/**
 * Result of loading process
 */
export const LoadingResult = {
    /**
     * No loading result
     */
    NONE: 'NONE',

    /**
     * Loading succeeded
     */
    SUCCESSFUL: 'SUCCESSFUL',

    /**
     * Loading failed
     */
    FAILED: 'FAILED',
};
