/* This file contains the progressBar component */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: theme.spacing(1.5),
        borderRadius: theme.spacing(2),
    },
    colorPrimary: {
        backgroundColor: theme.palette.info.main,
    },
    bar: {
        borderRadius: theme.spacing(2),
        backgroundColor: theme.palette.info.contrastText,
    },
}))(LinearProgress);

export default function ProgressBar({ widthPercentage }) {
    return <BorderLinearProgress variant="determinate" value={widthPercentage} />;
}
